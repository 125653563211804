import { gql } from '@lib/store/strapi-graphql-query';

export const ME_QUERY = gql`
  query Me {
    me {
      id
      username
      role {
        id
        name
        type
        description
      }

      schools {
        data {
          id
          attributes {
            name
          }
        }
      }

      district {
        data {
          id
          attributes {
            name
          }
        }
      }

      tags {
        data {
          id
          attributes {
            name
          }
        }
      }

      subscriptions {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }
`;
