import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M33.8 16.2C33.8 14.985 34.785 14 36 14C37.215 14 38.2 14.985 38.2 16.2V17.6667C38.2 18.8817 37.215 19.8667 36 19.8667C34.785 19.8667 33.8 18.8817 33.8 17.6667V16.2Z"
          fill="white"
        />
        <path
          d="M36 49.2001C42.8852 49.2001 48.4667 43.6186 48.4667 36.7334C48.4667 29.8482 42.8852 24.2667 36 24.2667C29.1149 24.2667 23.5334 29.8482 23.5334 36.7334C23.5334 43.6186 29.1149 49.2001 36 49.2001Z"
          fill="white"
        />
        <path
          d="M17.6667 33.8C18.8817 33.8 19.8667 34.785 19.8667 36C19.8667 37.2151 18.8817 38.2 17.6667 38.2H16.2C14.985 38.2 14 37.2151 14 36C14 34.785 14.985 33.8 16.2 33.8H17.6667Z"
          fill="white"
        />
        <path
          d="M58 36C58 34.785 57.0151 33.8 55.8 33.8H54.3334C53.1184 33.8 52.1334 34.785 52.1334 36C52.1334 37.2151 53.1183 38.2 54.3334 38.2H55.8C57.0151 38.2 58 37.2151 58 36Z"
          fill="white"
        />
        <path
          d="M36 52.1335C34.785 52.1335 33.8 53.1185 33.8 54.3335V55.8002C33.8 57.0152 34.785 58.0002 36 58.0002C37.215 58.0002 38.2 57.0152 38.2 55.8002V54.3335C38.2 53.1185 37.215 52.1335 36 52.1335Z"
          fill="white"
        />
        <path
          d="M20.2962 47.1258C21.1554 46.2667 22.5483 46.2667 23.4075 47.1258C24.2667 47.985 24.2666 49.3779 23.4075 50.2371L22.3704 51.2742C21.5112 52.1333 20.1183 52.1333 19.2591 51.2742C18.4 50.415 18.4 49.0221 19.2591 48.1629L20.2962 47.1258Z"
          fill="white"
        />
        <path
          d="M51.2742 20.7258C50.4151 19.8667 49.0221 19.8667 48.1629 20.7258L47.1259 21.7629C46.2667 22.6221 46.2667 24.015 47.1259 24.8742C47.985 25.7333 49.378 25.7333 50.2371 24.8742L51.2742 23.8371C52.1334 22.9779 52.1334 21.585 51.2742 20.7258Z"
          fill="white"
        />
        <path
          d="M22.3704 20.7258C21.5113 19.8667 20.1183 19.8667 19.2591 20.7258C18.4 21.585 18.4 22.9779 19.2591 23.8371L20.2962 24.8742C21.1554 25.7333 22.5484 25.7333 23.4075 24.8742C24.2667 24.015 24.2667 22.6221 23.4075 21.7629L22.3704 20.7258Z"
          fill="white"
        />
        <path
          d="M47.1259 47.1258C47.985 46.2667 49.378 46.2667 50.2371 47.1258L51.2742 48.1629C52.1334 49.0221 52.1334 50.415 51.2742 51.2742C50.4151 52.1333 49.0221 52.1333 48.163 51.2742L47.1259 50.2371C46.2667 49.3779 46.2667 47.985 47.1259 47.1258Z"
          fill="white"
        />
        <path
          d="M33.8 16.2C33.8 14.985 34.785 14 36 14C37.215 14 38.2 14.985 38.2 16.2V17.6667C38.2 18.8817 37.215 19.8667 36 19.8667C34.785 19.8667 33.8 18.8817 33.8 17.6667V16.2Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M36 49.2001C42.8852 49.2001 48.4667 43.6186 48.4667 36.7334C48.4667 29.8482 42.8852 24.2667 36 24.2667C29.1149 24.2667 23.5334 29.8482 23.5334 36.7334C23.5334 43.6186 29.1149 49.2001 36 49.2001Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M17.6667 33.8C18.8817 33.8 19.8667 34.785 19.8667 36C19.8667 37.2151 18.8817 38.2 17.6667 38.2H16.2C14.985 38.2 14 37.2151 14 36C14 34.785 14.985 33.8 16.2 33.8H17.6667Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M58 36C58 34.785 57.0151 33.8 55.8 33.8H54.3334C53.1184 33.8 52.1334 34.785 52.1334 36C52.1334 37.2151 53.1183 38.2 54.3334 38.2H55.8C57.0151 38.2 58 37.2151 58 36Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M36 52.1335C34.785 52.1335 33.8 53.1185 33.8 54.3335V55.8002C33.8 57.0152 34.785 58.0002 36 58.0002C37.215 58.0002 38.2 57.0152 38.2 55.8002V54.3335C38.2 53.1185 37.215 52.1335 36 52.1335Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M20.2962 47.1258C21.1554 46.2667 22.5483 46.2667 23.4075 47.1258C24.2667 47.985 24.2666 49.3779 23.4075 50.2371L22.3704 51.2742C21.5112 52.1333 20.1183 52.1333 19.2591 51.2742C18.4 50.415 18.4 49.0221 19.2591 48.1629L20.2962 47.1258Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M51.2742 20.7258C50.4151 19.8667 49.0221 19.8667 48.1629 20.7258L47.1259 21.7629C46.2667 22.6221 46.2667 24.015 47.1259 24.8742C47.985 25.7333 49.378 25.7333 50.2371 24.8742L51.2742 23.8371C52.1334 22.9779 52.1334 21.585 51.2742 20.7258Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M22.3704 20.7258C21.5113 19.8667 20.1183 19.8667 19.2591 20.7258C18.4 21.585 18.4 22.9779 19.2591 23.8371L20.2962 24.8742C21.1554 25.7333 22.5484 25.7333 23.4075 24.8742C24.2667 24.015 24.2667 22.6221 23.4075 21.7629L22.3704 20.7258Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
        <path
          d="M47.1259 47.1258C47.985 46.2667 49.378 46.2667 50.2371 47.1258L51.2742 48.1629C52.1334 49.0221 52.1334 50.415 51.2742 51.2742C50.4151 52.1333 49.0221 52.1333 48.163 51.2742L47.1259 50.2371C46.2667 49.3779 46.2667 47.985 47.1259 47.1258Z"
          stroke="#5A418B"
          strokeWidth="2.20001"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
