import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.71 11.71L19.71 13.71C19.5226 13.8962 19.2692 14.0008 19.005 14.0008C18.7408 14.0008 18.4874 13.8962 18.3 13.71L16.3 11.71C16.1362 11.5187 16.0506 11.2726 16.0603 11.0209C16.07 10.7693 16.1743 10.5305 16.3524 10.3524C16.5305 10.1743 16.7693 10.07 17.0209 10.0603C17.2726 10.0506 17.5187 10.1362 17.71 10.3L17.81 10.4C17.5092 9.11883 16.8533 7.94841 15.9177 7.02301C14.982 6.0976 13.8044 5.4547 12.52 5.16806C11.2356 4.88142 9.89641 4.96264 8.65606 5.40242C7.41572 5.8422 6.32447 6.62271 5.5075 7.65442C4.69053 8.68612 4.18094 9.92723 4.03719 11.2354C3.89344 12.5435 4.12135 13.8656 4.69478 15.0501C5.2682 16.2346 6.16392 17.2335 7.27916 17.9321C8.3944 18.6308 9.684 19.0009 11 19C11.2652 19 11.5196 19.1053 11.7071 19.2929C11.8946 19.4804 12 19.7348 12 20C12 20.2652 11.8946 20.5196 11.7071 20.7071C11.5196 20.8946 11.2652 21 11 21C9.27531 21.0027 7.58616 20.5098 6.1336 19.58C4.68104 18.6501 3.52624 17.3225 2.80664 15.7551C2.08705 14.1877 1.83297 12.4465 2.07464 10.7389C2.31632 9.0312 3.04357 7.42892 4.16983 6.12276C5.2961 4.81659 6.77395 3.86154 8.42749 3.37128C10.081 2.88102 11.8406 2.87619 13.4968 3.35737C15.153 3.83855 16.6361 4.78548 17.7695 6.08545C18.9029 7.38541 19.639 8.98367 19.89 10.69L20.29 10.29C20.4813 10.1262 20.7274 10.0406 20.979 10.0503C21.2307 10.06 21.4695 10.1643 21.6476 10.3424C21.8257 10.5205 21.93 10.7593 21.9397 11.0109C21.9494 11.2626 21.8638 11.5087 21.7 11.7L21.71 11.71Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
