import { useCallback, useMemo } from 'react';

import { RootState, useDispatch, useSelector } from '@store/configureStore';

import {
  EditorType,
  WidgetProps,
  WidgetData,
  AddWidgetPayload,
  RemoveWidgetPayload,
  LiveEditorState,
  SetLiveEditorStatePayload,
  SetCodeEditorStatePayload,
  CodeEditorState,
  ModuleState,
} from '@webapp/store/types';

import {
  setEditorState as LIVE_setEditorState,
  resetEditorState as LIVE_resetEditorState,
  addWidget as LIVE_addWidget,
  removeAllWidgets as LIVE_removeAllWidgets,
  removeWidget as LIVE_removeWidget,
  setWidgetsPlacement as LIVE_setWidgetsPlacement,
  setGridSize as LIVE_setGridSize,
  updateWidgetData as LIVE_updateWidgetData,
  updateWidgetProps as LIVE_updateWidgetProps,
  setPlayButtonIsBlocked as LIVE_setPlayButtonIsBlocked,
  setIsPlaying as LIVE_setIsPlaying,
} from '@webapp/store/slices/live/editor.slice';

import {
  selectUsedModulesIds as COMMON_selectUsedModulesIds,
  selectWidgetsModulesIdsMap,
} from '@webapp/store/slices/shared/selectors';

import {
  setEditorState as CODE_setEditorState,
  resetEditorState as CODE_resetEditorState,
  addWidget as CODE_addWidget,
  removeAllWidgets as CODE_removeAllWidgets,
  removeWidget as CODE_removeWidget,
  updateWidgetData as CODE_updateWidgetData,
  updateWidgetProps as CODE_updateWidgetProps,
  setPlayButtonIsBlocked as CODE_setPlayButtonIsBlocked,
  setIsPlaying as CODE_setIsPlaying,
} from '@webapp/store/slices/code/editor.slice';

import {
  prepareLiveEditorStateForSave,
  restoreLiveEditorFromSaved,
  prepareCodeEditorStateForSave,
  restoreCodeEditorStateFromSaved,
  LiveEditorStateForSave,
  CodeEditorStateForSave,
} from '@webapp/utils/editor-project';
import { ModuleId } from '@lib/robo/types';
import {
  selectConnectedModuleIdsFromModel,
  selectConnectedModuleTypesFromModel,
} from '@webapp/store/slices/model/model.selectors';
import { isModulesConditionFulfilled } from '@webapp/store/slices/shared/shared-elements-slice';

type EditorTypes = {
  [EditorType.Live]: LiveEditorState;
  [EditorType.Code]: CodeEditorState;
};

function isLiveEditor(editorType: EditorType): editorType is EditorType.Live {
  return editorType === EditorType.Live;
}

function isCodeEditor(editorType: EditorType): editorType is EditorType.Code {
  return editorType === EditorType.Code;
}

function isBlockyEditor(editorType: EditorType): editorType is EditorType.Blockly {
  return editorType === EditorType.Blockly;
}

export const useEditor = <T extends Exclude<EditorType, EditorType.Blockly>>(editorType: T) => {
  const dispatch = useDispatch();

  const editor: EditorTypes[T] = useSelector((state: RootState) => state.webapp[editorType].editor);
  const model = useSelector((state: RootState) => state.webapp.model as ModuleState);

  const widgets = editor.widgets;
  const widgetsModulesIdsMap = selectWidgetsModulesIdsMap(editor);

  const connectedModuleIds = selectConnectedModuleIdsFromModel(model);
  const connectedModuleTypes = selectConnectedModuleTypesFromModel(model);

  let addWidget;
  let _setEditorState;
  let _resetEditorState;
  let removeWidget;
  let updateWidgetProps;
  let updateWidgetData;
  let _setWidgetsPlacement;
  let _setGridSize;
  let _removeAllWidgets;
  let _setPlayButtonIsBlocked;
  let _setIsPlaying;
  let _prepareStateForSave;
  let _restoreStateFromSaved;
  let _getUsedModulesIds;

  const getWidgetModulesIds = useCallback(
    (widgetId: string) => {
      return widgetsModulesIdsMap[widgetId] ?? [];
    },
    [widgetsModulesIdsMap]
  );

  const isWidgetModulesConnected = useCallback(
    (widgetId: string) => {
      const widget = widgets[widgetId];
      if (!widget) {
        return false;
      }
      const widgetModulesIds = widget?.data?.moduleIds ?? [];
      const widgetModulesTypes = widget?.data?.requiredModuleTypes ?? [];

      return isModulesConditionFulfilled(
        widgetModulesIds,
        widgetModulesTypes,
        connectedModuleIds,
        connectedModuleTypes
      );
    },
    [connectedModuleIds, connectedModuleTypes, widgets]
  );

  const usedAndConnectedModulesIds = useMemo(() => {
    // todo: it does not take into account the widget.data.requiredModuleTypes,
    // but at the moment it does not needed, because this function is used only for sensors check
    return [...new Set(Object.values(widgetsModulesIdsMap).flatMap(modules => modules))]
      .sort()
      .filter(id => connectedModuleIds.includes(id));
  }, [widgetsModulesIdsMap, connectedModuleIds]);

  const getWidgetsByModuleIds = (moduleIds: ModuleId[]) => {
    return Object.entries(editor.widgets)
      .filter(([_, widget]) => {
        return (
          moduleIds.length === widget.data?.moduleIds.length &&
          widget.data?.moduleIds.every(id => moduleIds.includes(id as ModuleId))
        );
      })
      .map(value => value[1]);
  };

  if (isLiveEditor(editorType)) {
    // ! ==== LIVE EDITOR ============================================================================================================
    addWidget = (payload: AddWidgetPayload) => dispatch(LIVE_addWidget(payload));

    _setEditorState = (payload: SetLiveEditorStatePayload) => {
      return dispatch(LIVE_setEditorState(payload));
    };

    _resetEditorState = () => {
      return dispatch(LIVE_resetEditorState());
    };

    removeWidget = (payload: RemoveWidgetPayload) => dispatch(LIVE_removeWidget(payload));

    updateWidgetProps = (id: string, props: Partial<WidgetProps>) => dispatch(LIVE_updateWidgetProps({ id, props }));

    updateWidgetData = (id: string, data: Partial<WidgetData>) => dispatch(LIVE_updateWidgetData({ id, data }));

    _setWidgetsPlacement = (placement: LiveEditorState['runtime']['widgetsPlacement']) =>
      dispatch(LIVE_setWidgetsPlacement(placement));
    _setGridSize = (size: LiveEditorState['runtime']['gridSize']) => dispatch(LIVE_setGridSize(size));
    _removeAllWidgets = () => dispatch(LIVE_removeAllWidgets());
    _setPlayButtonIsBlocked = (isBlocked: boolean) => dispatch(LIVE_setPlayButtonIsBlocked(isBlocked));
    _setIsPlaying = (isPlaying: boolean) => dispatch(LIVE_setIsPlaying(isPlaying));
    _prepareStateForSave = prepareLiveEditorStateForSave;
    _restoreStateFromSaved = restoreLiveEditorFromSaved;

    _getUsedModulesIds = () => {
      return COMMON_selectUsedModulesIds(editor);
    };
  } else if (isCodeEditor(editorType)) {
    // ! ==== CODE EDITOR ============================================================================================================
    _prepareStateForSave = prepareCodeEditorStateForSave;

    _restoreStateFromSaved = restoreCodeEditorStateFromSaved;

    _setEditorState = (payload: SetCodeEditorStatePayload) => {
      return dispatch(CODE_setEditorState(payload));
    };

    _resetEditorState = () => {
      return dispatch(CODE_resetEditorState());
    };

    addWidget = (payload: AddWidgetPayload) => dispatch(CODE_addWidget(payload));

    removeWidget = (payload: RemoveWidgetPayload) => dispatch(CODE_removeWidget(payload));

    updateWidgetProps = (id: string, props: Partial<WidgetProps>) => dispatch(CODE_updateWidgetProps({ id, props }));

    updateWidgetData = (id: string, data: Partial<WidgetData>) => dispatch(CODE_updateWidgetData({ id, data }));

    _setWidgetsPlacement = () => console.warn('setWidgetsPlacement is not available in code editor');
    _setGridSize = () => console.warn('setGridSize is not available in code editor');

    _removeAllWidgets = () => dispatch(CODE_removeAllWidgets());

    _setPlayButtonIsBlocked = (isBlocked: boolean) => dispatch(CODE_setPlayButtonIsBlocked(isBlocked));

    _setIsPlaying = (isPlaying: boolean) => dispatch(CODE_setIsPlaying(isPlaying));

    _getUsedModulesIds = () => {
      return COMMON_selectUsedModulesIds(editor);
    };
  } else if (isBlockyEditor(editorType)) {
    throw new Error(`Blockly editor is not supported yet`);
  } else {
    throw new Error(`Editor type not supported: ${editorType}`);
  }

  return {
    ...editor,
    ...editor.runtime, // destructuring runtime properties for backward compatibility
    state: editor,
    prepareStateForSave: _prepareStateForSave as (
      state: LiveEditorState | CodeEditorState
    ) => LiveEditorStateForSave | CodeEditorStateForSave, // I add explicit type here because TS cannot infer it correctly
    restoreStateFromSaved: _restoreStateFromSaved,
    setEditorState: _setEditorState,
    resetEditorState: _resetEditorState,
    addWidget,
    removeWidget,
    updateWidgetProps,
    updateWidgetData,
    setWidgetsPlacement: _setWidgetsPlacement,
    setGridSize: _setGridSize,
    removeAllWidgets: _removeAllWidgets,
    setPlayButtonIsBlocked: _setPlayButtonIsBlocked,
    setIsPlaying: _setIsPlaying,
    getUsedModulesIds: _getUsedModulesIds,
    getWidgetModulesIds,
    isWidgetModulesConnected,
    usedAndConnectedModulesIds,
    getWidgetsByModuleIds,
  };
};
