import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = ({ color = '#5A418B', ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.07959 2.07959L2.07959 46.0796" stroke={color} strokeWidth="3" strokeLinecap="round" />
        <path d="M16.0796 21.0796L2.07959 46.0796" stroke={color} strokeWidth="3" strokeLinecap="round" />
        <path d="M45.0796 21.0796L37.0796 29.0796" stroke={color} strokeWidth="3" strokeLinecap="round" />
        <path d="M22.0796 20.0796L31.0796 29.0796" stroke={color} strokeWidth="3" strokeLinecap="round" />
        <path
          d="M18.0796 21.0796C21.3933 21.0796 24.0796 18.3933 24.0796 15.0796C24.0796 11.7659 21.3933 9.07959 18.0796 9.07959C14.7659 9.07959 12.0796 11.7659 12.0796 15.0796C12.0796 18.3933 14.7659 21.0796 18.0796 21.0796Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0796 38.0796C36.841 38.0796 39.0796 35.841 39.0796 33.0796C39.0796 30.3182 36.841 28.0796 34.0796 28.0796C31.3182 28.0796 29.0796 30.3182 29.0796 33.0796C29.0796 35.841 31.3182 38.0796 34.0796 38.0796Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M46.0796 46.0797L2.07959 46.0797" stroke={color} strokeWidth="3" strokeLinecap="round" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
