import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#FFFFFF';

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="24" y="12" width="28" height="28" rx="5" stroke={defaultColor} strokeWidth="2" />
        <rect x="26" y="41" width="24" height="11" rx="3" fill={defaultColor} />
        <rect x="18" y="54" width="40" height="2" fill={defaultColor} />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
