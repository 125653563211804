import React from 'react';

import { Box, LinearProgress, linearProgressClasses, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';

import { colors } from '@themes/config/theme-colors';
import { fontFamily } from '@themes/config/theme-typography';
import { Logo } from '@components/Logo/Logo';

export const RoboLoader = ({ sx }: { sx?: SxProps<Theme> }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Typography
        color={colors.violet.main}
        variant="h5"
        sx={{
          marginBottom: '5px',
          fontFamily: fontFamily,
        }}
      >
        Loading
      </Typography>
      <LinearProgress
        sx={{
          width: '100%',
          marginBottom: '10px',
          color: colors.violet.main,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: colors.violet['100'],
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: colors.violet.main,
          },
        }}
      />
      <Logo variant="wunderkind" width="100" height="100" alt="RoboWunderkind" onClick={null} sx={{}} />
    </Box>
  );
};
