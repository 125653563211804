import { useSelector, useDispatch } from '@store/configureStore';
import { debounce, throttle } from 'lodash';

import { addEntry as addEntryAction, HistoryEntryPayload } from '@webapp/store/slices/live/history.slice';
import { useCallback, useMemo } from 'react';

export type AddHistoryEntryOptions = {
  throttle?: boolean;
  debounce?: boolean;
};

const ADD_HISTORY_ACTION_THROTTLE_TIMEOUT = 1000;

export const useActionsHistory = () => {
  const dispatch = useDispatch();
  const actionsHistory = useSelector(state => state.webapp.live.history);

  const addEntry = useCallback(
    (payload: HistoryEntryPayload) => {
      dispatch(addEntryAction(payload));
    },
    [dispatch]
  );

  const addHistoryEntryThrottled = useMemo(() => {
    return throttle((payload: HistoryEntryPayload) => {
      addEntry(payload);
    }, ADD_HISTORY_ACTION_THROTTLE_TIMEOUT);
  }, [addEntry]);

  const addHistoryEntryDebounced = useMemo(() => {
    return debounce((payload: HistoryEntryPayload) => {
      addEntry(payload);
    }, ADD_HISTORY_ACTION_THROTTLE_TIMEOUT);
  }, [addEntry]);

  const addHistoryEntry = useCallback(
    (payload: HistoryEntryPayload, options?: AddHistoryEntryOptions) => {
      if (options?.throttle) {
        addHistoryEntryThrottled(payload);
      } else if (options?.debounce) {
        addHistoryEntryDebounced(payload);
      } else {
        addEntry(payload);
      }
    },
    [addHistoryEntryThrottled, addHistoryEntryDebounced, addEntry]
  );

  return {
    addHistoryEntry,
    actionsHistory,
  };
};
