import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29" cy="29" r="14" fill="#fff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 0a2 2 0 00-2 2v6a2 2 0 104 0V2a2 2 0 00-2-2zm20.506 8.494a2 2 0 00-2.828 0l-4.243 4.242a2 2 0 002.828 2.829l4.243-4.243a2 2 0 000-2.828zM56 27a2 2 0 110 4h-6a2 2 0 110-4h6zm-6.494 22.506a2 2 0 000-2.828l-4.242-4.243a2 2 0 00-2.829 2.828l4.243 4.243a2 2 0 002.828 0zM31 56a2 2 0 11-4 0v-6a2 2 0 114 0v6zM8.494 49.506a2 2 0 002.828 0l4.243-4.242a2 2 0 00-2.829-2.829l-4.242 4.243a2 2 0 000 2.828zM2 31a2 2 0 110-4h6a2 2 0 110 4H2zM8.494 8.494a2 2 0 000 2.828l4.242 4.243a2 2 0 002.829-2.829l-4.243-4.242a2 2 0 00-2.828 0z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
