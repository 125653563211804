import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { primaryColor, secondaryColor, ...otherProps } = props;
  return (
    <SvgIcon {...otherProps}>
      <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Sound">
          <circle
            id="Ellipse 766"
            cx="29.889"
            cy="29.6336"
            r="28.3778"
            fill={primaryColor}
            stroke="#5A418B"
            strokeWidth="2"
          />
          <path
            id="Union"
            d="M25.4509 17.3424L19.1094 22.5485H14.8892C13.5955 22.5485 12.5248 23.5851 12.5248 24.8905V30.8362C12.5248 32.1417 13.5955 33.1782 14.8892 33.1782L19.1094 33.1782L25.4509 38.3844C26.9726 39.6337 29.3241 38.5889 29.3241 36.5812V19.1455C29.3241 17.1379 26.9726 16.0931 25.4509 17.3424Z"
            fill={secondaryColor}
            stroke="#5A418B"
            strokeWidth="1.71111"
            strokeLinecap="round"
          />
          <path
            id="Ellipse 168 (Stroke)"
            d="M33.3899 26.113L33.8811 25.4125L33.3899 26.113C34.1297 26.6317 34.6069 27.4846 34.6069 28.4491C34.6069 29.4136 34.1297 30.2664 33.3899 30.7851C32.4445 31.448 32.2155 32.7518 32.8784 33.6972C33.5413 34.6425 34.845 34.8716 35.7904 34.2087C37.5987 32.9408 38.7882 30.8341 38.7882 28.4491C38.7882 26.064 37.5987 23.9573 35.7904 22.6894C34.845 22.0265 33.5413 22.2556 32.8784 23.201C32.2155 24.1464 32.4445 25.4501 33.3899 26.113Z"
            fill={secondaryColor}
            stroke="#5A418B"
            strokeWidth="1.71111"
            strokeLinecap="round"
          />
          <path
            id="Ellipse 169 (Stroke)"
            d="M39.4168 37.3553L40.0711 36.804L39.4168 37.3553C40.1608 38.2383 41.4797 38.351 42.3627 37.607C44.965 35.4143 46.624 32.1243 46.624 28.4488C46.624 24.7733 44.965 21.4833 42.3627 19.2906C41.4797 18.5466 40.1608 18.6593 39.4168 19.5423C38.6729 20.4253 38.7855 21.7442 39.6685 22.4882C41.3683 23.9203 42.4427 26.058 42.4427 28.4488C42.4427 30.8396 41.3683 32.9773 39.6685 34.4095C38.7855 35.1534 38.6729 36.4723 39.4168 37.3553Z"
            fill={secondaryColor}
            stroke="#5A418B"
            strokeWidth="1.71111"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
