import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModuleState, AddModulePayload, RemoveModulePayload, UpdateModulePayload } from '@webapp/store/types';

const initialState: ModuleState = {
  motors: {},
  servos: {},
  ledRGBs: {},
  ledMatrices: {},
  IRs: {},
  cameras: {},
  buttons: {},
  lightSensors: {},
  meteos: {},
  systems: {},
  ultrasonics: {},
  motions: {},
  linetrackers: {},
  accelerometers: {},
  ledDisplays: {},
  hinges: {},
  claws: {},
  properServos: {},
  knobs: {},
  weathers: {},
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    addModule: (state, action: PayloadAction<AddModulePayload>) => {
      const { type, id, data } = action.payload;
      if (state[type]) {
        state[type][id] = data;
      }
    },
    removeModule: (state, action: PayloadAction<RemoveModulePayload>) => {
      const { type, id } = action.payload;
      if (state[type] && state[type][id]) {
        delete state[type][id];
      }
    },
    updateModule: (state, action: PayloadAction<UpdateModulePayload>) => {
      const { type, id, data } = action.payload;
      if (state[type] && state[type][id]) {
        state[type][id] = { ...state[type][id], ...data };
      }
    },

    reset: () => initialState,
  },
});

export const { addModule, removeModule, updateModule, reset } = modelSlice.actions;

export default modelSlice.reducer;
