import React, { useEffect, useRef } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { round } from 'lodash';

import { colors as themeColors } from '@themes/config/theme-colors';

import StyledBox from '@webapp/components/blocks/component/styled-box';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useSelector } from '@store/configureStore';
import { selectModuleById } from '@webapp/store/slices/model/model.selectors';
import { mapToRange, clamp } from '@lib/utils/math';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';
import { LiveWidgetProps } from '@webapp/types/types';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}));

const sliderStyle = {
  position: 'absolute',
  color: '#fff',
  boxShadow: 'none',
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '&:focus, &:hover, &$active': {
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    color: '#fff',
  },
  track: {
    boxShadow: 'none',
  },
};

// this values in measured in G: 1G = 9.8 m/s²
const MIN_ACCELERATION = -1.5;
const MAX_ACCELERATION = 1.5;

const defaultValue = {
  x: 0,
  y: 0,
  z: 0,
};

const calculateResultPoint = (x, y, z) => {
  const resX = x - z * Math.cos(Math.PI / 4);
  const resY = y + z * Math.cos(Math.PI / 4);

  return {
    x: resX,
    y: resY,
  };
};

const AccelerometerSensorWidget = ({ counter, data, disabled }: LiveWidgetProps) => {
  const { isPlaying } = useEditorContext();
  const sliderRef = useRef(null);
  const { addHistoryEntry } = useActionsHistory();
  const { editorType } = useEditorContext();

  const moduleId = data?.moduleIds?.[0];
  const moduleState = useSelector(state => selectModuleById(state, 'accelerometers', moduleId));
  let acceleration = isPlaying && !disabled ? moduleState?.sensorData?.acceleration ?? defaultValue : defaultValue;
  acceleration = {
    x: clamp(acceleration.x, MIN_ACCELERATION, MAX_ACCELERATION),
    y: clamp(acceleration.y, MIN_ACCELERATION, MAX_ACCELERATION),
    z: clamp(acceleration.z, MIN_ACCELERATION, MAX_ACCELERATION),
  };

  const sideLength = sliderRef.current ? sliderRef.current?.clientWidth / 2 : 0;

  let dotCoordinates = calculateResultPoint(acceleration.x, acceleration.y, acceleration.z);
  dotCoordinates = {
    x: clamp(dotCoordinates.x, MIN_ACCELERATION, MAX_ACCELERATION),
    y: clamp(dotCoordinates.y, MIN_ACCELERATION, MAX_ACCELERATION),
  };
  const dotX = mapToRange(dotCoordinates.x, [MIN_ACCELERATION, MAX_ACCELERATION], [-sideLength, sideLength]);
  const dotY = mapToRange(dotCoordinates.y, [MIN_ACCELERATION, MAX_ACCELERATION], [-sideLength, sideLength]);

  const roundedX = round(acceleration.x, 1);
  const roundedY = round(acceleration.y, 1);
  const roundedZ = round(acceleration.z, 1);

  useEffect(() => {
    const hasAcceleration = roundedX !== 0 || roundedY !== 0 || roundedZ !== 0;

    if (isPlaying && hasAcceleration) {
      addHistoryEntry(
        {
          action: 'interact:widget:gyro',
          scope: editorType,
          data: {
            dataType: 'RoboElement',
            elementType: 'gyro',
            property: 'acceleration',
            value: {
              x: roundedX,
              y: roundedY,
              z: roundedZ,
            },
          },
        },
        {
          throttle: true,
        }
      );
    }
  }, [roundedX, roundedY, roundedZ, editorType, isPlaying]);

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#E31871'}>
      {counter ? <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography> : null}
      <Box
        className="FAFAFAFAF"
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Slider
          sx={{
            ...sliderStyle,
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            width: '80%',
          }}
          min={MIN_ACCELERATION}
          max={MAX_ACCELERATION}
          value={[0, acceleration.x]}
          ref={sliderRef}
        />
        <Slider
          sx={{
            ...sliderStyle,
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%) rotate(90deg)',
            width: '80%',
          }}
          min={MIN_ACCELERATION}
          max={MAX_ACCELERATION}
          value={[0, acceleration.y]}
        />
        <Slider
          sx={{
            ...sliderStyle,
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%) rotate(135deg)',
            width: '80%',
          }}
          min={MIN_ACCELERATION}
          max={MAX_ACCELERATION}
          value={[0, acceleration.z]}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: 'white',
            left: 'calc(50% - 10px)',
            top: 'calc(50% - 10px)',
            transition: 'all 0.3s ease-out',
            transform: `translate(${dotX}px, ${dotY}px)`,
          }}
        ></Box>
      </Box>
    </StyledBox>
  );
};

AccelerometerSensorWidget.initialProps = {
  width: 2,
  height: 2,
};

AccelerometerSensorWidget.initialData = {};

export default AccelerometerSensorWidget;
