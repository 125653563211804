import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.986 28C4.854 28 4.746 27.958 4.662 27.874C4.59 27.79 4.554 27.688 4.554 27.568V18.244L1.818 20.35C1.71 20.422 1.602 20.452 1.494 20.44C1.386 20.416 1.29 20.35 1.206 20.242L0.45 19.27C0.378 19.162 0.348 19.048 0.36 18.928C0.384 18.808 0.45 18.712 0.558 18.64L4.59 15.526C4.662 15.466 4.734 15.43 4.806 15.418C4.878 15.406 4.956 15.4 5.04 15.4H6.624C6.744 15.4 6.846 15.442 6.93 15.526C7.014 15.61 7.056 15.712 7.056 15.832V27.568C7.056 27.688 7.014 27.79 6.93 27.874C6.846 27.958 6.744 28 6.624 28H4.986Z"
          fill={color}
        />
        <path
          d="M11.242 22C11.122 22 11.02 21.958 10.936 21.874C10.852 21.79 10.81 21.688 10.81 21.568V20.65C10.81 20.542 10.834 20.416 10.882 20.272C10.942 20.116 11.068 19.96 11.26 19.804L14.104 16.978C14.896 16.354 15.532 15.826 16.012 15.394C16.504 14.95 16.858 14.548 17.074 14.188C17.302 13.816 17.416 13.45 17.416 13.09C17.416 12.55 17.266 12.112 16.966 11.776C16.678 11.44 16.216 11.272 15.58 11.272C15.16 11.272 14.806 11.362 14.518 11.542C14.23 11.71 14.002 11.938 13.834 12.226C13.678 12.514 13.57 12.832 13.51 13.18C13.486 13.336 13.42 13.444 13.312 13.504C13.204 13.564 13.096 13.594 12.988 13.594H11.386C11.278 13.594 11.188 13.558 11.116 13.486C11.044 13.414 11.008 13.33 11.008 13.234C11.02 12.718 11.128 12.22 11.332 11.74C11.536 11.248 11.83 10.816 12.214 10.444C12.61 10.06 13.09 9.754 13.654 9.526C14.218 9.298 14.854 9.184 15.562 9.184C16.558 9.184 17.38 9.352 18.028 9.688C18.688 10.024 19.18 10.48 19.504 11.056C19.84 11.632 20.008 12.28 20.008 13C20.008 13.564 19.9 14.086 19.684 14.566C19.468 15.034 19.156 15.49 18.748 15.934C18.34 16.366 17.848 16.81 17.272 17.266L14.716 19.858H19.846C19.978 19.858 20.08 19.9 20.152 19.984C20.236 20.056 20.278 20.158 20.278 20.29V21.568C20.278 21.688 20.236 21.79 20.152 21.874C20.08 21.958 19.978 22 19.846 22H11.242Z"
          fill={color}
        />
        <path
          d="M26.598 17.18C25.758 17.18 25.032 17.078 24.42 16.874C23.808 16.67 23.298 16.406 22.89 16.082C22.494 15.746 22.194 15.386 21.99 15.002C21.786 14.606 21.678 14.216 21.666 13.832C21.666 13.724 21.702 13.64 21.774 13.58C21.858 13.508 21.954 13.472 22.062 13.472H23.682C23.802 13.472 23.904 13.496 23.988 13.544C24.072 13.592 24.144 13.688 24.204 13.832C24.312 14.156 24.486 14.414 24.726 14.606C24.978 14.798 25.266 14.936 25.59 15.02C25.914 15.092 26.256 15.128 26.616 15.128C27.336 15.128 27.906 14.96 28.326 14.624C28.758 14.276 28.974 13.784 28.974 13.148C28.974 12.512 28.776 12.056 28.38 11.78C27.984 11.492 27.432 11.348 26.724 11.348H24.87C24.738 11.348 24.63 11.312 24.546 11.24C24.462 11.156 24.42 11.048 24.42 10.916V10.16C24.42 10.04 24.444 9.938 24.492 9.854C24.552 9.758 24.612 9.68 24.672 9.62L27.894 6.47H22.746C22.614 6.47 22.506 6.428 22.422 6.344C22.35 6.26 22.314 6.158 22.314 6.038V4.85C22.314 4.718 22.35 4.61 22.422 4.526C22.506 4.442 22.614 4.4 22.746 4.4H30.468C30.6 4.4 30.708 4.442 30.792 4.526C30.876 4.61 30.918 4.718 30.918 4.85V5.93C30.918 6.038 30.894 6.134 30.846 6.218C30.798 6.302 30.744 6.374 30.684 6.434L27.57 9.638L27.786 9.656C28.518 9.716 29.166 9.884 29.73 10.16C30.306 10.424 30.75 10.814 31.062 11.33C31.386 11.846 31.548 12.494 31.548 13.274C31.548 14.09 31.332 14.792 30.9 15.38C30.48 15.956 29.898 16.4 29.154 16.712C28.41 17.024 27.558 17.18 26.598 17.18Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
