import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import Slider from '@webapp/components/ui/sliders/slider';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import {
  WidgetEntity,
  ExecutableActionWidgetComponent,
  WidgetExecutionType,
  ActionWidgetExecutionResult,
} from '@webapp/store/types';

const WaitWidget: ExecutableActionWidgetComponent<WaitWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();
  const widget = getWidgetById(id) as WidgetEntity & { data: WaitWidgetData };
  const { data } = widget;

  const [waitInterval, setWaitInterval] = useState<number>(data?.waitInterval ?? WaitWidget.initialData.waitInterval);
  const [isInfinite, setIsInfinite] = useState<boolean>(data?.isInfinite ?? WaitWidget.initialData.isInfinite);

  const handleWaitIntervalChange = (_: Event, newValue: number | number[]) => {
    const newWaitInterval = Array.isArray(newValue) ? newValue[0] : newValue;
    setWaitInterval(newWaitInterval);
    setIsInfinite(newWaitInterval > 60);
  };

  const onWaitIntervalChanged = () => {
    updateWidgetData(id, { waitInterval, isInfinite: waitInterval > 60 });
  };

  return (
    <Box sx={{ width: '360px', height: '185px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
          <Slider
            value={waitInterval}
            valueLabelDisplay="on"
            maxAsInfinite={true}
            onChange={handleWaitIntervalChange}
            onChangeCommitted={onWaitIntervalChanged}
            orientation="horizontal"
            sx={{ width: '75%' }}
            max={61}
            min={1}
            mainColor="#1CA543"
            railColor="#E9E9E9"
            labelColor="#5A418B"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Typography variant="x-headline5-bold" sx={{ color: '#5A418B' }}>
            {isInfinite ? '∞' : `${waitInterval} sec`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

WaitWidget.execute = async ({ signal, widgetId, getWidgetById }) => {
  const widget = getWidgetById(widgetId);

  if (!widget) {
    throw new Error('Widget not found');
  }

  const { data } = widget;

  const waitInterval = data?.waitInterval ?? WaitWidget.initialData.waitInterval;
  const isInfinite = data?.isInfinite ?? WaitWidget.initialData.isInfinite;

  if (isInfinite) {
    return AbortablePromise(signal, () => {
      // This promise will never resolve, effectively creating an infinite wait
      // It can only be interrupted by interrupting triggers
    });
  }

  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    setTimeout(() => {
      resolve({
        resolved: true,
        type: WidgetExecutionType.Action,
        widgetId: widget.id,
      });
    }, waitInterval * 1000);
  });
};

export type WaitWidgetData = {
  waitInterval: number;
  isInfinite: boolean;
};

WaitWidget.initialData = {
  waitInterval: 1,
  isInfinite: false,
};

export default WaitWidget;
