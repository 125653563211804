import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { primaryColor, secondaryColor, ...otherProps } = props;
  return (
    <SvgIcon {...otherProps}>
      <svg viewBox="0 0 60 60" fill="transparent" xmlns="http://www.w3.org/2000/svg">
        <g id="Visual">
          <circle
            id="Ellipse 766"
            cx="30.1112"
            cy="29.6336"
            r="28.3778"
            fill={primaryColor}
            stroke="#5A418B"
            strokeWidth="2"
          />
          <path
            id="Ellipse 324"
            d="M30.1115 42.4663C34.3641 42.4663 37.8115 39.0189 37.8115 34.7663C37.8115 30.5137 34.3641 27.0663 30.1115 27.0663C25.8589 27.0663 22.4115 30.5137 22.4115 34.7663C22.4115 39.0189 25.8589 42.4663 30.1115 42.4663Z"
            fill={secondaryColor}
            stroke="#5A418B"
            strokeWidth="1.71111"
          />
          <path
            id="Ellipse 325 (Stroke)"
            d="M16.2445 32.2842C16.2445 24.771 22.4396 18.6565 30.1113 18.6565C37.7829 18.6565 43.9781 24.771 43.9781 32.2842C43.9781 33.8837 45.2922 35.1565 46.8836 35.1565C48.475 35.1565 49.7891 33.8837 49.7891 32.2842C49.7891 21.5721 40.9658 12.912 30.1113 12.912C19.2568 12.912 10.4335 21.5721 10.4335 32.2842C10.4335 33.8837 11.7476 35.1565 13.339 35.1565C14.9304 35.1565 16.2445 33.8837 16.2445 32.2842Z"
            fill={secondaryColor}
            stroke="#5A418B"
            strokeWidth="1.71111"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
