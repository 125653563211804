import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.011 3.485a1.032 1.032 0 100 2.064 1.032 1.032 0 000-2.064zm-4.196 1.032a4.196 4.196 0 118.392 0 4.196 4.196 0 01-8.392 0zm15.83-1.963c.796.363 1.146 1.3.784 2.096l-3.136 6.886a1.582 1.582 0 11-2.88-1.312l3.137-6.886a1.582 1.582 0 012.095-.784zm13.39 4.088a1.582 1.582 0 10-2.132-2.338L31.06 13.28a1.582 1.582 0 002.132 2.339l9.843-8.977zm-23.408 6.14a3.164 3.164 0 00-5.06.804L1.024 40.253c-1.387 2.73 1.524 5.64 4.254 4.254l26.667-13.545a3.164 3.164 0 00.804-5.058L19.627 12.782zm-2.238 2.237l13.122 13.122L3.844 41.686 17.389 15.02zm25.478 4.032a1.582 1.582 0 10-1.194-2.93l-7.008 2.854a1.582 1.582 0 001.194 2.93l7.008-2.854zm-2.886 7.422a1.032 1.032 0 112.064 0 1.032 1.032 0 01-2.064 0zm1.032-4.196a4.196 4.196 0 100 8.392 4.196 4.196 0 000-8.392z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
