import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#D4D4D4';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="7" y="7" width="2" height="11" rx="1" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 2L12 9H4L8 2Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M8 25L12 18H4L8 25Z" fill={color} />
        <rect width="16" height="2" fill={color} />
        <rect y="24" width="16" height="2" fill={color} />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
