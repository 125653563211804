import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.59.064a.922.922 0 0 0-.328.197C4.054.469 4 .705 4 1.405v.59l-1.43.013c-1.386.012-1.435.015-1.595.1a.821.821 0 0 0-.413.483c-.056.199-.056 4.944 0 5.429.144 1.263.537 2.299 1.206 3.18.042.055.213.24.381.41.712.723 1.587 1.148 2.74 1.33.137.021.172.057.414.425a7.973 7.973 0 0 0 4.717 3.375c.286.073.623.146.75.161l.23.028v2.062l-1.05.02c-.88.017-1.102.033-1.37.102-1.202.307-2.128 1.053-2.663 2.147a3.45 3.45 0 0 0-.385 1.5c-.025.537.017.739.195.942.265.301-.266.278 6.273.278h5.86l.165-.088c.373-.2.473-.451.445-1.112a3.608 3.608 0 0 0-.388-1.52c-.53-1.091-1.459-1.84-2.662-2.147-.268-.069-.49-.085-1.37-.102l-1.05-.02v-2.062l.23-.028c.127-.015.464-.088.75-.161a7.984 7.984 0 0 0 4.717-3.375c.242-.368.277-.404.414-.425 1.153-.182 2.028-.607 2.74-1.33.168-.17.339-.355.381-.41.666-.877 1.063-1.922 1.206-3.18.056-.485.056-5.23 0-5.429a.821.821 0 0 0-.413-.483c-.16-.085-.209-.088-1.595-.1L20 1.995v-.59c0-.7-.054-.936-.261-1.144-.279-.278.236-.261-7.756-.258-6.195.002-7.23.011-7.393.061M18 5.694c0 2.432-.015 3.808-.044 4.03-.076.59-.278 1.292-.487 1.696-.034.066-.126.255-.203.42-.31.662-1.055 1.519-1.76 2.023a5.978 5.978 0 0 1-7.752-.641c-.46-.461-.822-.956-1.041-1.422a13.48 13.48 0 0 0-.213-.441c-.176-.33-.385-1.079-.459-1.652C6.016 9.514 6 7.98 6 5.697V2h12v3.694M4.012 6.93c.014 2.475.027 2.989.082 3.307.036.207.057.385.047.396-.036.035-.358-.209-.607-.459-.42-.421-.739-1.053-.897-1.777-.115-.528-.156-1.298-.157-2.927V4H3.995l.017 2.93M21.51 5.85c-.018 1.647-.029 1.901-.103 2.314-.188 1.046-.627 1.841-1.268 2.298-.145.103-.271.18-.281.17-.01-.01.012-.188.048-.395.055-.318.068-.832.082-3.307L20.005 4h1.524l-.019 1.85m-6.582 15.194c.45.094.947.428 1.226.826l.091.13h-8.49l.133-.185a2.07 2.07 0 0 1 1.13-.759c.273-.066 5.597-.077 5.91-.012" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
