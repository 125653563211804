import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="18.147" y="0.5" width="5.70588" height="41" rx="2.5" fill="white" stroke="#5A418B" />
        <rect
          x="41.5"
          y="18.147"
          width="5.70588"
          height="41"
          rx="2.5"
          transform="rotate(90 41.5 18.147)"
          fill="white"
          stroke="#5A418B"
        />
        <rect
          x="33.4785"
          y="4.48692"
          width="5.70588"
          height="41"
          rx="2.5"
          transform="rotate(45 33.4785 4.48692)"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="14.6177" y="14.6176" width="12.7647" height="12.7647" rx="6.38235" fill="white" stroke="#5A418B" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
