import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="7" stroke="white" strokeWidth="4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C10.8954 0 10 0.89543 10 2V4C10 5.10457 10.8954 6 12 6C13.1046 6 14 5.10457 14 4V2C14 0.895431 13.1046 0 12 0ZM20.4853 3.51472C19.7042 2.73367 18.4379 2.73367 17.6569 3.51472L16.2426 4.92893C15.4616 5.70998 15.4616 6.97631 16.2426 7.75736C17.0237 8.53841 18.29 8.53841 19.0711 7.75736L20.4853 6.34315C21.2663 5.5621 21.2663 4.29577 20.4853 3.51472ZM22 10C23.1046 10 24 10.8954 24 12C24 13.1046 23.1046 14 22 14H20C18.8954 14 18 13.1046 18 12C18 10.8954 18.8954 10 20 10H22ZM16.2426 19.0711L17.6569 20.4853C18.4379 21.2663 19.7042 21.2663 20.4853 20.4853C21.2663 19.7042 21.2663 18.4379 20.4853 17.6569L19.0711 16.2426C18.29 15.4616 17.0237 15.4616 16.2426 16.2426C15.4616 17.0237 15.4616 18.29 16.2426 19.0711ZM14 22C14 23.1046 13.1046 24 12 24C10.8954 24 10 23.1046 10 22V20C10 18.8954 10.8954 18 12 18C13.1046 18 14 18.8954 14 20V22ZM3.51472 17.6569C2.73367 18.4379 2.73367 19.7042 3.51472 20.4853C4.29577 21.2663 5.5621 21.2663 6.34315 20.4853L7.75736 19.0711C8.53841 18.29 8.53841 17.0237 7.75736 16.2426C6.97631 15.4616 5.70998 15.4616 4.92893 16.2426L3.51472 17.6569ZM2 14C0.89543 14 0 13.1046 0 12C0 10.8954 0.895431 10 2 10H4C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14H2ZM3.51472 3.51472C2.73367 4.29577 2.73367 5.5621 3.51472 6.34315L4.92893 7.75736C5.70998 8.53841 6.97631 8.53841 7.75736 7.75736C8.53841 6.97631 8.53841 5.70998 7.75736 4.92893L6.34315 3.51472C5.5621 2.73367 4.29577 2.73367 3.51472 3.51472Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
