import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

import { convertBytesArrayToInt } from '@lib/utils/hex';
import { isEqual } from 'lodash';

const ACCELEROMETER_COEFFICIENT = 1;

export enum AccelerometerEventType {
  PutDown = 'putDown',
  PickUp = 'pickUp',
  Move = 'move',
}

export class Accelerometer extends BaseModule<typeof Accelerometer> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.Accelerometer, store);

    this.client.registerHandler(
      HandlerType.OnAccelerometer,
      ({ data }) => {
        const acceleration = Accelerometer.parsePayload(data);

        const currentData = this.getDataState();
        const hasChanges = !currentData.sensorData || !isEqual(currentData.sensorData.acceleration, acceleration);
        if (!hasChanges) {
          return;
        }

        this.updateDataState({
          sensorData: {
            ...currentData.sensorData,
            acceleration,
          },
        });
      },
      id
    );

    this.client.registerHandler(
      HandlerType.OnAccelerometerState,

      payload => {
        const isPutDown = payload[0] > 0;
        const isPickUp = payload[1] > 0;
        const isMove = payload[2] > 0;

        const currentSensorData = this.getDataState().sensorData;

        const hasChanges =
          currentSensorData.activeEvents[AccelerometerEventType.PutDown] !== isPutDown ||
          currentSensorData.activeEvents[AccelerometerEventType.PickUp] !== isPickUp ||
          currentSensorData.activeEvents[AccelerometerEventType.Move] !== isMove;

        if (!hasChanges) return;

        this.updateDataState({
          sensorData: {
            ...currentSensorData,
            activeEvents: {
              [AccelerometerEventType.PutDown]: isPutDown,
              [AccelerometerEventType.PickUp]: isPickUp,
              [AccelerometerEventType.Move]: isMove,
            },
          },
        });
      },
      id
    );
  }

  requestAccelerometerState() {
    this.client.requestAccelerometerState(this.index);
  }

  createTrigger(eventType: AccelerometerEventType, onActionDone: onActionDoneType) {
    const triggerId = this.generateActionOrTriggerId();

    let triggerCondition;

    switch (eventType) {
      case AccelerometerEventType.PutDown:
        triggerCondition = 0;
        break;

      case AccelerometerEventType.PickUp:
        triggerCondition = 1;
        break;

      case AccelerometerEventType.Move:
        triggerCondition = 2;
        break;
    }

    this.subscribeToResponse(triggerId, onActionDone);

    this.client.setAccelerometerTrigger(triggerId, this.index, triggerCondition);

    return { triggerId };
  }

  /**
   * Convert raw number to gravity.
   *
   * @param {number} number - The raw number to be converted.
   * @returns {number} - The gravity value. 1 - is 1G, 2 is 2G, etc
   */
  static convertRawNumberToGravity(number: number) {
    let gravity;
    if (number > 32767) {
      gravity = (number - 65535 + 1) / ACCELEROMETER_COEFFICIENT;
    } else {
      gravity = number / ACCELEROMETER_COEFFICIENT;
    }

    return gravity / 100;
  }

  /**
   * Parse the accelerometer payload
   * @param data - The payload to parse
   * @returns The parsed payload
   */
  static parsePayload(data: Uint8Array) {
    const x = Accelerometer.convertRawNumberToGravity(convertBytesArrayToInt([data[1], data[0]]));
    const y = Accelerometer.convertRawNumberToGravity(convertBytesArrayToInt([data[3], data[2]]));
    const z = Accelerometer.convertRawNumberToGravity(convertBytesArrayToInt([data[5], data[4]]));

    return {
      x,
      y,
      z,
    };
  }

  /**
   * @returns {object} - The initial data state.
   */
  static initialDataState() {
    return {
      sensorData: {
        acceleration: {
          x: 0,
          y: 0,
          z: 0,
        },

        activeEvents: {
          [AccelerometerEventType.PutDown]: false,
          [AccelerometerEventType.PickUp]: false,
          [AccelerometerEventType.Move]: false,
        },
      },
    };
  }
}
