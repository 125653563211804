import { gql } from '@lib/store/strapi-graphql-query';

import { WITH_ACTION } from '@store/fragments/atoms/action';
import { WITH_FILE } from '@store/fragments/file';
import { WITH_DURATION } from '@store/fragments/atoms/time-selector';

import { WITH_PROJECTS_OVERVIEW } from '@store/fragments/entities/project';
import { WITH_LESSONS_OVERVIEW } from '@store/fragments/entities/lesson';
import { WITH_ACTIVITIES_OVERVIEW } from '@store/fragments/entities/activity';
import { WITH_RESOURCES_OVERVIEW } from '@store/fragments/entities/resource';

import { WITH_COLLECTION } from '@store/fragments/dashboard/collection';

export const FULL_DASHBOARD_QUERY = gql`
  ${WITH_ACTION}
  ${WITH_DURATION}
  ${WITH_FILE}

  ${WITH_PROJECTS_OVERVIEW}
  ${WITH_LESSONS_OVERVIEW}
  ${WITH_ACTIVITIES_OVERVIEW}
  ${WITH_RESOURCES_OVERVIEW}

  ${WITH_COLLECTION}
  query GetDashboard {
    dashboard {
      data {
        attributes {
          greeting {
            title
            content
            libraryAction {
              ...ACTION
            }

            communityAction {
              ...ACTION
            }

            pdAction {
              ...ACTION
            }

            helpAction {
              ...ACTION
            }
          }

          collections {
            ...COLLECTION
          }

          inspiration {
            title
            content
            inspirationAction {
              ...ACTION
            }
          }
        }
      }
    }
  }
`;
