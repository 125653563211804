export const defaultIconConfigs = {
  MotorIcon: { primaryColor: 'white', secondaryColor: '#5A418B', sx: { width: '30px', height: '30px' } },
  AngleIcon: { primaryColor: 'white', secondaryColor: '#5A418B', sx: { width: '28px', height: '38px' } },
  JoystickIcon: { primaryColor: 'white', secondaryColor: '#5A418B', sx: { width: '33px', height: '32px' } },
  DriveIcon: { sx: { width: '48px', height: '48px' } },
  TurnIcon: { sx: { width: '48px', height: '48px' } },
  LineFollowerIcon: { sx: { width: '48px', height: '48px' } },
  LightSensorIcon: { sx: { width: '34px', height: '34px' } },
  MotionSensorIcon: { primaryColor: 'white', sx: { width: '34px', height: '34px' } },
  DistanceSensorIcon: { sx: { width: '34px', height: '34px' } },
  LineFollowerSensorIcon: {
    enabledPositions: { left: true, middle: true, right: true },
    sx: {
      position: 'relative',
      width: '50px',
      height: '50px',
      marginBottom: '-41px',
      '.line-follower-background': { display: 'none' },
    },
  },
  RgbLightIcon: { sx: { width: '34px', height: '34px' } },
  LaughingFaceIcon: { sx: { width: '55px', height: '40px', paddingTop: '20px' } },
  SmilingFaceIcon: { sx: { width: '55px', height: '40px', paddingTop: '20px' } },
  LookingFaceIcon: { sx: { width: '55px', height: '40px', paddingTop: '20px' } },
  ShineIcon: { sx: { width: '48px', height: '48px' } },
  FlashIcon: { sx: { width: '48px', height: '48px' } },
  DrawIcon: { sx: { width: '48px', height: '48px' } },
  TextIcon: { sx: { width: '48px', height: '48px' } },
  AnimateIcon: { sx: { width: '48px', height: '48px' } },
  GeneralSoundIcon: { sx: { width: '48px', height: '48px' } },
  PartySoundIcon: { sx: { width: '35px', height: '36px' } },
  BellSoundIcon: { sx: { width: '35px', height: '36px' } },
  CarSoundIcon: { sx: { width: '35px', height: '36px' } },
  ChickSoundIcon: { sx: { width: '35px', height: '36px' } },
  ClockSoundIcon: { sx: { width: '35px', height: '36px' } },
  CatSoundIcon: { sx: { width: '35px', height: '36px' } },
  DogSoundIcon: { sx: { width: '35px', height: '36px' } },
  RobotSoundIcon: { sx: { width: '35px', height: '36px' } },
  OwlSoundIcon: { sx: { width: '35px', height: '36px' } },
  SpaceshipSoundIcon: { sx: { width: '35px', height: '36px' } },
  DinosaurSoundIcon: { sx: { width: '35px', height: '36px' } },
  HorseSoundIcon: { sx: { width: '35px', height: '36px' } },
  LedDisplayIcon: { sx: { width: '45px', height: '45px' } },
  ButtonOffSensorIcon: { sx: { position: 'relative', top: '10px', width: '35px', height: '35px' } },
  AccelerometerIcon: { sx: { position: 'relative', top: '10px', width: '35px', height: '35px' } },
  RepeatTriggerIcon: { sx: { width: '48px', height: '48px' } },
  ClockTriggerIcon: { sx: { width: '48px', height: '48px' } },
  TimerTriggerIcon: { sx: { width: '48px', height: '48px' } },
  AccelerometerTriggerIcon: { sx: { width: '35px', height: '35px' } },
  LightTriggerIcon: { sx: { width: '48px', height: '48px' } },
  DistanceTriggerIcon: { sx: { width: '48px', height: '48px' } },
  ButtonTriggerIcon: { sx: { width: '48px', height: '48px' } },
  ObstacleTriggerIcon: { sx: { width: '48px', height: '48px' } },
  MotionTriggerIcon: { sx: { width: '48px', height: '48px' } },
  WaitIcon: { sx: { width: '48px', height: '48px' } },
  MessageIcon: { sx: { width: '48px', height: '48px' } },

  // default size for section icons
  MotionIcon: { sx: { width: '55px', height: '55px' } },
  VisualIcon: { sx: { width: '55px', height: '55px' } },
  SensingIcon: { sx: { width: '55px', height: '55px' } },
  SoundIcon: { sx: { width: '55px', height: '55px' } },
  SpecialIcon: { sx: { width: '55px', height: '55px' } },
};

// Common
export { default as MainBlockIcon } from '@webapp/components/icons/common/main-block-icon';
export { default as BridgeIcon } from '@webapp/components/icons/common/bridge-icon';
export { default as CodeIcon } from '@webapp/components/icons/common/code-icon';
export { default as PlayIcon } from '@webapp/components/icons/common/play-icon';
export { default as PlayIconOutlined } from '@webapp/components/icons/common/play-icon-outlined';
export { default as ProfileIcon } from '@webapp/components/icons/common/profile-icon';
export { default as TutorialsIcon } from '@webapp/components/icons/common/tutorials-icon';
export { default as RecordSensorsIcon } from '@webapp/components/icons/common/record-sensors-icon';

// Toolbox
export { default as MotionIcon } from '@webapp/components/icons/toolbox/motion-icon';
export { default as VisualIcon } from '@webapp/components/icons/toolbox/visual-icon';
export { default as SensingIcon } from '@webapp/components/icons/toolbox/sensing-icon';
export { default as SoundIcon } from '@webapp/components/icons/toolbox/sound-icon';
export { default as SpecialIcon } from '@webapp/components/icons/toolbox/special-icon';

// Toolbox Sections - Motion
export { default as MotorIcon } from '@webapp/components/icons/toolbox-sections/motion/motor-icon';
export { default as AngleIcon } from '@webapp/components/icons/toolbox-sections/motion/angle-icon';
export { default as JoystickIcon } from '@webapp/components/icons/toolbox-sections/motion/joystick-icon';
export { default as DriveIcon } from '@webapp/components/icons/toolbox-sections/motion/drive-icon';
export { default as TurnIcon } from '@webapp/components/icons/toolbox-sections/motion/turn-icon';
export { default as LineFollowerIcon } from '@webapp/components/icons/toolbox-sections/motion/line-follower-icon';

// Toolbox Sections - Sound
export { default as GeneralSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/general-sound-icon';
export { default as BellSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/bell-sound-icon';
export { default as CarSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/car-sound-icon';
export { default as CatSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/cat-sound-icon';
export { default as ChickSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/chick-sound-icon';
export { default as ClockSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/clock-sound-icon';
export { default as DogSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/dog-sound-icon';
export { default as OwlSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/owl-sound-icon';
export { default as PartySoundIcon } from '@webapp/components/icons/toolbox-sections/sound/party-sound-icon';
export { default as RobotSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/robot-sound-icon';
export { default as SpaceshipSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/spaceship-sound-icon';
export { default as DinosaurSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/dinosaur-sound-icon';
export { default as HorseSoundIcon } from '@webapp/components/icons/toolbox-sections/sound/horse-sound-icon';

// Toolbox Sections - Sensor
export { default as MotionSensorIcon } from '@webapp/components/icons/toolbox-sections/sensor/motion-sensor-icon';
export { default as LightSensorIcon } from '@webapp/components/icons/toolbox-sections/sensor/light-sensor-icon';
export { default as LineFollowerSensorIcon } from '@webapp/components/icons/toolbox-sections/sensor/line-follower-sensor-icon';
export { default as SensorTestIcon } from '@webapp/components/icons/toolbox-sections/sensor/light-sensor-control-icon';
export { default as DistanceSensorIcon } from '@webapp/components/icons/toolbox-sections/sensor/distance-sensor-icon';
export { default as ButtonOnSensorIcon } from '@webapp/components/icons/toolbox-sections/sensor/button-on-sensor-icon';
export { default as ButtonOffSensorIcon } from '@webapp/components/icons/toolbox-sections/sensor/button-off-sensor-icon';
export { default as AccelerometerIcon } from '@webapp/components/icons/toolbox-sections/sensor/accelerometer-icon';
export { default as DistanceArrowIcon } from '@webapp/components/icons/toolbox-sections/sensor/distance-arrow-icon';

// Toolbox Sections - Visual
export { default as LaughingFaceIcon } from '@webapp/components/icons/toolbox-sections/visual/laughing-face-icon';
export { default as SmilingFaceIcon } from '@webapp/components/icons/toolbox-sections/visual/smiling-face-icon';
export { default as LookingFaceIcon } from '@webapp/components/icons/toolbox-sections/visual/looking-face-icon';
export { default as LedDisplayIcon } from '@webapp/components/icons/toolbox-sections/visual/led-display-icon';
export { default as ShineIcon } from '@webapp/components/icons/toolbox-sections/visual/shine-icon';
export { default as FlashIcon } from '@webapp/components/icons/toolbox-sections/visual/flash-icon';
export { default as DrawIcon } from '@webapp/components/icons/toolbox-sections/visual/draw-icon';
export { default as TextIcon } from '@webapp/components/icons/toolbox-sections/visual/text-icon';
export { default as AnimateIcon } from '@webapp/components/icons/toolbox-sections/visual/animate-icon';

// Toolbox Sections - Trigger
export { default as RepeatTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/repeat-icon';
export { default as TimerTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/timer-icon';
export { default as ClockTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/clock-icon';
export { default as AccelerometerTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/accelerometer-icon';
export { default as LightTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/light-icon';
export { default as DistanceTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/distance-icon';
export { default as ButtonTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/button-icon';
export { default as ObstacleTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/obstacle-icon';
export { default as MotionTriggerIcon } from '@webapp/components/icons/toolbox-sections/trigger/motion-icon';

// Toolbox Sections - Special
export { default as WaitIcon } from '@webapp/components/icons/toolbox-sections/special/wait-icon';
export { default as MessageIcon } from '@webapp/components/icons/toolbox-sections/special/message-icon';

// Connection Client
export { default as VolumeUpIcon } from '@webapp/components/icons/connection-client/volume-up-icon';
export { default as VolumeDownIcon } from '@webapp/components/icons/connection-client/volume-down-icon';
export { default as ProgressiveBatteryIcon } from '@webapp/components/icons/connection-client/progressive-battery-icon';

// Robo Live
export { default as SunIcon } from '@webapp/components/icons/robo-live/sun-icon';
export { default as FatCloseIcon } from '@webapp/components/icons/robo-live/fat-close-icon';
export { default as ZoomInIcon } from '@webapp/components/icons/robo-code/code-zoom-in-icon';
export { default as ZoomOutIcon } from '@webapp/components/icons/robo-code/code-zoom-out-icon';
export { default as RotateIcon } from '@webapp/components/icons/robo-live/rotate-icon';
export { default as WidgetSettingsIcon } from '@webapp/components/icons/robo-live/widget-settings-icon';
export { default as LiveAngleHandleIcon } from '@webapp/components/icons/robo-live/live-angle-handle-icon';
export { default as RgbLightIcon } from '@webapp/components/icons/robo-live/rgb-light-icon';

// Robo Code
export { default as ConnectionModeIcon } from '@webapp/components/icons/robo-code/code-connection-mode-icon';
export { default as RandomIcon } from '@webapp/components/icons/robo-code/code-random-icon';
export { default as DistanceIcon } from '@webapp/components/icons/robo-code/code-distance-icon';
export { default as SpeedIcon } from '@webapp/components/icons/robo-code/code-speed-icon';
export { default as ReverseIcon } from '@webapp/components/icons/robo-code/code-reverse-icon';
export { default as BrightnessIcon } from '@webapp/components/icons/robo-code/code-brightness-icon';
export { default as TimeIcon } from '@webapp/components/icons/robo-code/code-time-icon';
export { default as ColorIcon } from '@webapp/components/icons/robo-code/code-color-icon';
export { default as FrequencyIcon } from '@webapp/components/icons/robo-code/code-frequency-icon';
export { default as PlusIcon } from '@webapp/components/icons/robo-code/code-plus-icon';
export { default as CodeRotateIcon } from '@webapp/components/icons/robo-code/code-rotate-icon';
export { default as ClearIcon } from '@webapp/components/icons/robo-code/code-clear-icon';
export { default as LargeMotorIcon } from '@webapp/components/icons/robo-code/code-large-motor-icon';
export { default as LargeTurnIcon } from '@webapp/components/icons/robo-code/code-large-turn-icon';
export { default as LargeLineFollowerIcon } from '@webapp/components/icons/robo-code/code-large-linefollower-icon';
export { default as ArrowBackIcon } from '@webapp/components/icons/robo-code/code-arrow-back-icon';
export { default as ArrowForwardIcon } from '@webapp/components/icons/robo-code/code-arrow-forward-icon';
export { default as RepeatIcon } from '@webapp/components/icons/robo-code/code-repeat-icon';
export { default as LessThanIcon } from '@webapp/components/icons/robo-code/code-compare-lt-icon';
export { default as GreaterThanIcon } from '@webapp/components/icons/robo-code/code-compare-gt-icon';
export { default as LightLowIcon } from '@webapp/components/icons/robo-code/code-light-low-icon';
export { default as LightHighIcon } from '@webapp/components/icons/robo-code/code-light-high-icon';
export { default as MoveMotionIcon } from '@webapp/components/icons/robo-code/code-move-motion-icon';
export { default as MoveNoMotionIcon } from '@webapp/components/icons/robo-code/code-move-nomotion-icon';
export { default as ObstacleCloseIcon } from '@webapp/components/icons/robo-code/code-obstacle-close';
export { default as ObstacleFarIcon } from '@webapp/components/icons/robo-code/code-obstacle-far';
export { default as DistanceLowIcon } from '@webapp/components/icons/robo-code/code-distance-low-icon';
export { default as DistanceHighIcon } from '@webapp/components/icons/robo-code/code-distance-high-icon';
export { default as AccelPickIcon } from '@webapp/components/icons/robo-code/code-accel-pick-icon';
export { default as AccelMoveIcon } from '@webapp/components/icons/robo-code/code-accel-move-icon';
export { default as AccelPutIcon } from '@webapp/components/icons/robo-code/code-accel-put-icon';
export { default as SettingsIcon } from '@webapp/components/icons/robo-code/code-settings-icon';
export { default as BendRightIcon } from '@webapp/components/icons/robo-code/code-bend-right-icon';
export { default as BendLeftIcon } from '@webapp/components/icons/robo-code/code-bend-left-icon';
export { default as AccelerometerAssistedTurnIcon } from '@webapp/components/icons/robo-code/code-accel-assisted-turn-icon';

// Dynamic Icons
export { default as DynamicMotorIcon } from '@webapp/components/icons/dynamic-icons/dynamic-motor-icon';
export { default as DynamicAngleIcon } from '@webapp/components/icons/dynamic-icons/dynamic-angle-icon';
export { default as DynamicShineIcon } from '@webapp/components/icons/dynamic-icons/dynamic-shine-icon';
export { default as DynamicFlashIcon } from '@webapp/components/icons/dynamic-icons/dynamic-flash-icon';
export { default as DynamicRepeatIcon } from '@webapp/components/icons/dynamic-icons/dynamic-repeat-icon';
export { default as DynamicAccelerometerIcon } from '@webapp/components/icons/dynamic-icons/dynamic-accelerometer-icon';
export { default as DynamicDriveIcon } from '@webapp/components/icons/dynamic-icons/dynamic-drive-icon';
export { default as DynamicTurnIcon } from '@webapp/components/icons/dynamic-icons/dynamic-turn-icon';
export { default as DynamicObstacleIcon } from '@webapp/components/icons/dynamic-icons/dynamic-obstacle-icon';
