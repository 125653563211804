import { ElementEntity, ElementType, WidgetType } from '@webapp/store/types';
import { ModuleTypes } from '@lib/robo/types';
import { createElements, createElementsFromList } from '@webapp/store/state/utils/create-element';

const defaultToggles = {
  active: false,
  hidden: false,
  disabled: false,
  highlighted: false,
};

const elements: ElementEntity[] = [];

// Motion - Motor
for (const element of createElements(6, i => ({
  id: `code-motor-${i}`,
  name: 'Motor',
  type: 'motor',
  icon: 'MotorIcon',
  dynamicIcon: 'DynamicMotorIcon',
  sectionId: 'code-motion-section',
  template: 'motion-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Motor}_${i}`],

  widgetType: WidgetType.CodeMotorWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Motion - Angle
for (const element of createElements(6, i => ({
  id: `code-angle-${i}`,
  name: 'Angle',
  type: 'angle',
  icon: 'AngleIcon',
  dynamicIcon: 'DynamicAngleIcon',
  sectionId: 'code-motion-section',
  template: 'motion-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Motor}_${i}`],

  widgetType: WidgetType.CodeAngleWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Motion - Drive
for (const element of createElements(1, i => ({
  id: `code-drive`,
  name: 'Drive',
  type: 'drive',
  icon: 'DriveIcon',
  dynamicIcon: 'DynamicDriveIcon',
  sectionId: 'code-motion-section',
  template: 'motion-element',
  counter: i,
  requiredModuleTypes: [ModuleTypes.Motor, ModuleTypes.Motor],

  widgetType: WidgetType.CodeDriveWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Motion - Turn
for (const element of createElements(1, i => ({
  id: `code-turn`,
  name: 'Turn',
  type: 'turn',
  icon: 'TurnIcon',
  dynamicIcon: 'DynamicTurnIcon',
  sectionId: 'code-motion-section',
  template: 'motion-element',
  counter: i,
  requiredModuleTypes: [ModuleTypes.Motor, ModuleTypes.Motor],

  widgetType: WidgetType.CodeTurnWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Motion - Line Follower
for (const element of createElements(1, i => ({
  id: `code-line-follower`,
  name: 'Follow',
  type: 'line-follower',
  icon: 'LineFollowerIcon',
  sectionId: 'code-motion-section',
  template: 'motion-element',
  counter: i,
  requiredModules: [`${ModuleTypes.Linetracker}_${i}`],
  requiredModuleTypes: [ModuleTypes.Motor, ModuleTypes.Motor],

  widgetType: WidgetType.CodeLineFollowerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Shine
for (const element of createElements(6, i => ({
  id: `code-shine-${i}`,
  name: 'Shine',
  type: 'shine',
  icon: 'ShineIcon',
  dynamicIcon: 'DynamicShineIcon',
  sectionId: 'code-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedRGB}_${i}`],

  widgetType: WidgetType.CodeShineWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Flash
for (const element of createElements(6, i => ({
  id: `code-flash-${i}`,
  name: 'Flash',
  type: 'flash',
  icon: 'FlashIcon',
  dynamicIcon: 'DynamicFlashIcon',
  sectionId: 'code-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedRGB}_${i}`],

  widgetType: WidgetType.CodeFlashWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Draw
for (const element of createElements(4, i => ({
  id: `code-draw-${i}`,
  name: 'Draw',
  type: 'draw',
  icon: 'DrawIcon',
  sectionId: 'code-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: WidgetType.CodeDrawWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Text
for (const element of createElements(4, i => ({
  id: `code-text-${i}`,
  name: 'Text',
  type: 'text',
  icon: 'TextIcon',
  sectionId: 'code-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: WidgetType.CodeTextWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Animate
for (const element of createElements(4, i => ({
  id: `code-animate-${i}`,
  name: 'Animate',
  type: 'animate',
  icon: 'AnimateIcon',
  sectionId: 'code-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: WidgetType.CodeAnimateWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sounds
for (const element of createElementsFromList(
  1,
  [
    {
      name: 'Party',
      type: 'party-sound',
      icon: 'PartySoundIcon',
    },
    {
      name: 'Bell',
      type: 'bell-sound',
      icon: 'BellSoundIcon',
    },
    {
      name: 'Car',
      type: 'car-sound',
      icon: 'CarSoundIcon',
    },
    {
      name: 'Chick',
      type: 'chick-sound',
      icon: 'ChickSoundIcon',
    },
    {
      name: 'Clock',
      type: 'clock-sound',
      icon: 'ClockSoundIcon',
    },
    {
      name: 'Cat',
      type: 'cat-sound',
      icon: 'CatSoundIcon',
    },
    {
      name: 'Dog',
      type: 'dog-sound',
      icon: 'DogSoundIcon',
    },
    {
      name: 'Robot',
      type: 'robot-sound',
      icon: 'RobotSoundIcon',
    },
    {
      name: 'Owl',
      type: 'owl-sound',
      icon: 'OwlSoundIcon',
    },
    {
      name: 'Spaceship',
      type: 'spaceship-sound',
      icon: 'SpaceshipSoundIcon',
    },
    {
      name: 'Dinosaur',
      type: 'dinosaur-sound',
      icon: 'DinosaurSoundIcon',
    },
    {
      name: 'Horse',
      type: 'horse-sound',
      icon: 'HorseSoundIcon',
    },
  ],
  (i, { name, type, icon }) => ({
    id: `code-${type}-${i}`,
    name: `${name}`,
    type: `${type}` as ElementType,
    icon: `${icon}`,
    sectionId: 'code-sound-section',
    template: 'sound-element',
    counter: i,
    badge: false,

    requiredModules: [`${ModuleTypes.System}_1`],

    widgetType: WidgetType.CodeSoundWidget,
    widgetsPerGroup: 1, // per group
    widgetData: {
      sound: `${type}`,
    },

    ...defaultToggles,
  })
)) {
  elements.push(element);
}

// Sensing - Repeat trigger
for (const element of createElements(1, i => ({
  id: `code-repeat-trigger`,
  name: 'Repeat',
  type: 'repeat-trigger',
  icon: 'RepeatTriggerIcon',
  dynamicIcon: 'DynamicRepeatIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,

  widgetType: WidgetType.CodeRepeatTriggerWidget,
  widgetsPerGroup: 0, // unlimited

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Timer trigger
for (const element of createElements(1, i => ({
  id: `code-timer-trigger`,
  name: 'Timer',
  type: 'timer-trigger',
  icon: 'TimerTriggerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,

  widgetType: WidgetType.CodeTimerTriggerWidget,
  widgetsPerGroup: 0, // unlimited

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Clock trigger
for (const element of createElements(1, i => ({
  id: `code-clock-trigger`,
  name: 'Clock',
  type: 'clock-trigger',
  icon: 'ClockTriggerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,

  widgetType: WidgetType.CodeClockTriggerWidget,
  widgetsPerGroup: 0, // unlimited

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Button trigger
for (const element of createElements(4, i => ({
  id: `code-button-trigger-${i}`,
  name: 'Button',
  type: 'button-trigger',
  icon: 'ButtonTriggerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Button}_${i}`],
  widgetType: WidgetType.CodeButtonTriggerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Light trigger
for (const element of createElements(4, i => ({
  id: `code-light-trigger-${i}`,
  name: 'Light',
  type: 'light-trigger',
  icon: 'LightTriggerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.LightSensor}_${i}`],

  widgetType: WidgetType.CodeLightTriggerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Distance trigger
for (const element of createElements(4, i => ({
  id: `code-distance-trigger-${i}`,
  name: 'Distance',
  type: 'distance-trigger',
  icon: 'DistanceTriggerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Ultrasonic}_${i}`],
  widgetType: WidgetType.CodeDistanceTriggerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Motion trigger
for (const element of createElements(4, i => ({
  id: `code-motion-trigger-${i}`,
  name: 'Motion',
  type: 'motion-trigger',
  icon: 'MotionTriggerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Motion}_${i}`],
  widgetType: WidgetType.CodeMotionTriggerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Accelerometer trigger
for (const element of createElements(4, i => ({
  id: `code-accelerometer-trigger-${i}`,
  name: 'Accel',
  type: 'accelerometer-trigger',
  icon: 'AccelerometerTriggerIcon',
  dynamicIcon: 'DynamicAccelerometerIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Accelerometer}_${i}`],
  widgetType: WidgetType.CodeAccelerometerTriggerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Obstacle triggers
for (const element of createElements(4, i => ({
  id: `code-obstacle-trigger-${i}`,
  name: 'Obstacle',
  type: 'obstacle-trigger',
  icon: 'ObstacleTriggerIcon',
  dynamicIcon: 'DynamicObstacleIcon',
  sectionId: 'code-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Linetracker}_${i}`],
  widgetType: WidgetType.CodeObstacleTriggerWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Special - Wait
for (const element of createElements(1, i => ({
  id: `code-wait`,
  name: 'Wait',
  type: 'wait',
  icon: 'WaitIcon',
  sectionId: 'code-special-section',
  template: 'special-element',
  counter: i,

  widgetType: WidgetType.CodeWaitWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Special - Message
for (const element of createElements(1, i => ({
  id: `code-message`,
  name: 'Message',
  type: 'message',
  icon: 'MessageIcon',
  sectionId: 'code-special-section',
  template: 'special-element',
  counter: i,

  widgetType: WidgetType.CodeMessageWidget,
  widgetsPerGroup: 1, // per group

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Convert the array into the entities and ids structure
const codeElementsInitialState = {
  ids: elements.map(element => element.id),
  entities: elements.reduce(
    (entities, element) => {
      entities[element.id] = element;
      return entities;
    },
    {} as Record<string, ElementEntity>
  ),
};

export default codeElementsInitialState;
