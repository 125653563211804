import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#034FCC';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          opacity="0.507295"
          x="84.7059"
          y="58.4001"
          width="1.60001"
          height="72.605"
          transform="rotate(90 84.7059 58.4001)"
          fill="#D8D8D8"
        />
        <rect
          opacity="0.507295"
          x="84.7059"
          y="35.2001"
          width="1.60001"
          height="72.605"
          transform="rotate(90 84.7059 35.2001)"
          fill="#D8D8D8"
        />
        <rect opacity="0.507295" x="58.8908" y="12" width="1.61345" height="72.0002" fill="url(#paint0_linear_0_1)" />
        <rect opacity="0.507295" x="35.4958" y="12" width="1.61345" height="72.0002" fill="url(#paint1_linear_0_1)" />
        <rect opacity="0.507295" x="83.8992" y="12" width="1.61345" height="72.0002" fill="url(#paint2_linear_0_1)" />
        <rect opacity="0.507295" x="10.4874" y="12" width="1.61345" height="72.0002" fill="url(#paint3_linear_0_1)" />
        <mask id="mask0_0_1" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="2" y="26" width="43" height="43">
          <rect
            width="29.6023"
            height="29.6023"
            transform="matrix(0.710058 0.704143 -0.710058 0.704143 23.7983 26.7559)"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_1)">
          <rect x="11.3786" y="35.2839" width="24.4322" height="24.2287" fill="url(#paint4_linear_0_1)" />
        </g>
        <rect
          x="15.1363"
          y="39.0144"
          width="16.9169"
          height="16.7677"
          rx="5"
          fill="#5AA7FA"
          stroke="url(#paint5_linear_0_1)"
        />
        <path
          d="M23.5947 50.4749C25.3051 50.4749 26.6951 49.0994 26.6951 47.3982C26.6951 45.697 25.3051 44.3215 23.5947 44.3215C21.8844 44.3215 20.4943 45.697 20.4943 47.3982C20.4943 49.0994 21.8844 50.4749 23.5947 50.4749Z"
          fill="#D9C796"
          stroke="#B6A67A"
          strokeWidth="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9659 42.9563C22.6406 42.9563 23.1875 42.4139 23.1875 41.7449C23.1875 41.0758 22.6406 40.5334 21.9659 40.5334C21.2912 40.5334 20.7443 41.0758 20.7443 41.7449C20.7443 42.4139 21.2912 42.9563 21.9659 42.9563Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9659 54.263C22.6406 54.263 23.1875 53.7206 23.1875 53.0516C23.1875 52.3825 22.6406 51.8401 21.9659 51.8401C21.2912 51.8401 20.7443 52.3825 20.7443 53.0516C20.7443 53.7206 21.2912 54.263 21.9659 54.263Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2235 42.9563C25.8982 42.9563 26.4451 42.4139 26.4451 41.7449C26.4451 41.0758 25.8982 40.5334 25.2235 40.5334C24.5489 40.5334 24.0019 41.0758 24.0019 41.7449C24.0019 42.4139 24.5489 42.9563 25.2235 42.9563Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.2235 54.263C25.8982 54.263 26.4451 53.7206 26.4451 53.0516C26.4451 52.3825 25.8982 51.8401 25.2235 51.8401C24.5489 51.8401 24.0019 52.3825 24.0019 53.0516C24.0019 53.7206 24.5489 54.263 25.2235 54.263Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.074 45.783C28.074 46.452 28.6209 46.9944 29.2956 46.9944C29.9702 46.9944 30.5172 46.452 30.5172 45.783C30.5172 45.1139 29.9702 44.5715 29.2956 44.5715C28.6209 44.5715 28.074 45.1139 28.074 45.783Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6723 45.783C16.6723 46.452 17.2192 46.9944 17.8939 46.9944C18.5686 46.9944 19.1155 46.452 19.1155 45.783C19.1155 45.1139 18.5686 44.5715 17.8939 44.5715C17.2192 44.5715 16.6723 45.1139 16.6723 45.783Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.074 49.0135C28.074 49.6825 28.6209 50.2249 29.2956 50.2249C29.9702 50.2249 30.5172 49.6825 30.5172 49.0135C30.5172 48.3444 29.9702 47.802 29.2956 47.802C28.6209 47.802 28.074 48.3444 28.074 49.0135Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6723 49.0135C16.6723 49.6825 17.2192 50.2249 17.8939 50.2249C18.5686 50.2249 19.1155 49.6825 19.1155 49.0135C19.1155 48.3444 18.5686 47.802 17.8939 47.802C17.2192 47.802 16.6723 48.3444 16.6723 49.0135Z"
          fill="#326BA9"
        />
        <mask
          id="mask1_0_1"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="51"
          y="26"
          width="43"
          height="43"
        >
          <rect
            width="29.6023"
            height="29.6023"
            transform="matrix(0.710058 0.704143 -0.710058 0.704143 72.2017 26.7559)"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_0_1)">
          <rect x="59.782" y="35.2839" width="24.4322" height="24.2287" fill="url(#paint6_linear_0_1)" />
        </g>
        <rect
          x="63.5396"
          y="39.0144"
          width="16.9169"
          height="16.7677"
          rx="5"
          fill="#5AA7FA"
          stroke="url(#paint7_linear_0_1)"
        />
        <path
          d="M71.9981 50.4749C73.7084 50.4749 75.0985 49.0994 75.0985 47.3982C75.0985 45.697 73.7084 44.3215 71.9981 44.3215C70.2877 44.3215 68.8977 45.697 68.8977 47.3982C68.8977 49.0994 70.2877 50.4749 71.9981 50.4749Z"
          fill="#D9C796"
          stroke="#B6A67A"
          strokeWidth="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.3693 42.9563C71.0439 42.9563 71.5909 42.4139 71.5909 41.7449C71.5909 41.0758 71.0439 40.5334 70.3693 40.5334C69.6946 40.5334 69.1477 41.0758 69.1477 41.7449C69.1477 42.4139 69.6946 42.9563 70.3693 42.9563Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.3693 54.263C71.0439 54.263 71.5909 53.7206 71.5909 53.0516C71.5909 52.3825 71.0439 51.8401 70.3693 51.8401C69.6946 51.8401 69.1477 52.3825 69.1477 53.0516C69.1477 53.7206 69.6946 54.263 70.3693 54.263Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.6269 42.9563C74.3016 42.9563 74.8485 42.4139 74.8485 41.7449C74.8485 41.0758 74.3016 40.5334 73.6269 40.5334C72.9522 40.5334 72.4053 41.0758 72.4053 41.7449C72.4053 42.4139 72.9522 42.9563 73.6269 42.9563Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.6269 54.263C74.3016 54.263 74.8485 53.7206 74.8485 53.0516C74.8485 52.3825 74.3016 51.8401 73.6269 51.8401C72.9522 51.8401 72.4053 52.3825 72.4053 53.0516C72.4053 53.7206 72.9522 54.263 73.6269 54.263Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.4773 45.783C76.4773 46.452 77.0242 46.9944 77.6989 46.9944C78.3736 46.9944 78.9205 46.452 78.9205 45.783C78.9205 45.1139 78.3736 44.5715 77.6989 44.5715C77.0242 44.5715 76.4773 45.1139 76.4773 45.783Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.0756 45.783C65.0756 46.452 65.6226 46.9944 66.2972 46.9944C66.9719 46.9944 67.5189 46.452 67.5189 45.783C67.5189 45.1139 66.9719 44.5715 66.2972 44.5715C65.6226 44.5715 65.0756 45.1139 65.0756 45.783Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.4773 49.0135C76.4773 49.6825 77.0242 50.2249 77.6989 50.2249C78.3736 50.2249 78.9205 49.6825 78.9205 49.0135C78.9205 48.3444 78.3736 47.802 77.6989 47.802C77.0242 47.802 76.4773 48.3444 76.4773 49.0135Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.0756 49.0135C65.0756 49.6825 65.6226 50.2249 66.2972 50.2249C66.9719 50.2249 67.5189 49.6825 67.5189 49.0135C67.5189 48.3444 66.9719 47.802 66.2972 47.802C65.6226 47.802 65.0756 48.3444 65.0756 49.0135Z"
          fill="#326BA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.26051 66.0145L10.4407 56.2236L10.4407 39.3465L7.26051 28.8001L7.26051 66.0145Z"
          fill="#D8D8D8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.6928 66.0145L85.5126 56.2236L85.5126 39.3465L88.6928 28.8001L88.6928 66.0145Z"
          fill="#D8D8D8"
        />
        <rect x="1.14441e-05" y="26.4001" width="8.06723" height="41.6001" rx="2" fill="#BEBEBE" />
        <rect x="87.9328" y="26.4" width="8.06723" height="41.6001" rx="2" fill="#BEBEBE" />
        <defs>
          <linearGradient
            id="paint0_linear_0_1"
            x1="58.9036"
            y1="13.1426"
            x2="58.9036"
            y2="84.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.507045" stopColor="#B8B8B8" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_0_1"
            x1="35.5086"
            y1="13.1426"
            x2="35.5086"
            y2="84.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.507045" stopColor="#B8B8B8" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_0_1"
            x1="83.912"
            y1="13.1426"
            x2="83.912"
            y2="84.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.507045" stopColor="#B8B8B8" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_0_1"
            x1="10.5002"
            y1="13.1426"
            x2="10.5002"
            y2="84.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.507045" stopColor="#B8B8B8" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_0_1"
            x1="23.5239"
            y1="27.9893"
            x2="5.79357"
            y2="46.9893"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5AA7FA" />
            <stop offset="1" stopColor="#326BA9" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_0_1"
            x1="24.13"
            y1="32.719"
            x2="8.53295"
            y2="47.4231"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#336CA9" />
            <stop offset="1" stopColor="#5AA7FA" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_0_1"
            x1="71.9273"
            y1="27.9893"
            x2="54.1969"
            y2="46.9893"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5AA7FA" />
            <stop offset="1" stopColor="#326BA9" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_0_1"
            x1="72.5333"
            y1="32.719"
            x2="56.9363"
            y2="47.4231"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#336CA9" />
            <stop offset="1" stopColor="#5AA7FA" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
