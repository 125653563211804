import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 43 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.319 5.795A6.255 6.255 0 0113.422.91h14.904c2.61 0 4.945 1.62 5.859 4.065l2.863 7.662h1.261c2.59 0 4.691 2.1 4.691 4.691v8.6a4.69 4.69 0 01-4.69 4.691h-.175a7.039 7.039 0 01-13.724 0h-5.04a7.039 7.039 0 01-13.724 0h-.956A4.69 4.69 0 010 25.928v-8.6c0-2.591 2.1-4.692 4.69-4.692h1.095l1.534-6.84zm30.99 21.696h-.174a7.039 7.039 0 00-13.724 0h-5.04a7.039 7.039 0 00-13.724 0h-.956c-.864 0-1.564-.7-1.564-1.564v-8.6c0-.863.7-1.563 1.564-1.563h33.618c.864 0 1.564.7 1.564 1.563v8.6c0 .864-.7 1.564-1.564 1.564zM31.255 6.069l2.455 6.567H23.454v-8.6h4.872c1.305 0 2.473.81 2.93 2.033zM20.327 4.036v8.6H8.99l1.38-6.156a3.127 3.127 0 013.052-2.443h6.905zM8.6 29.055a3.91 3.91 0 117.818 0 3.91 3.91 0 01-7.818 0zm22.673-3.91a3.91 3.91 0 100 7.819 3.91 3.91 0 000-7.819z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
