const JoystickHandle = `
  <svg width="100px" height="100px" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <filter id="shadow" x="0" y="0" width="150%" height="150%" >
            <feDropShadow dx="3" dy="3" stdDeviation="3" flood-color="black" flood-opacity="0.35"/>
        </filter>
    </defs>
    <circle cx="50" cy="50" r="40" fill="white" filter="url(#shadow)"/>
</svg>
`;

export default JoystickHandle;
