import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = ({ htmlColor, ...props }) => {
  const defaultColor = 'white';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 56 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M51.4112 23.7453C56.7881 26.8673 56.7881 34.6327 51.4112 37.7548L12.8673 60.1352C7.46731 63.2706 0.699979 59.3746 0.699979 53.1304L0.699981 8.36973C0.699981 2.12546 7.4673 -1.77054 12.8673 1.36493L51.4112 23.7453Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
