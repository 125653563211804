import React, { useMemo } from 'react';
import { TrackProps, SliderGeometry, CustomStyle } from '../types';
import { describeArc, describeSpiralArc } from '../utils/svg';
import { normalizeAngle } from '../utils/math';

interface TrackComponentProps extends TrackProps {
  sliderGeometry: SliderGeometry;
  defaultWidth: number;
  defaultColor: string;
  defaultActiveColor: string;
  disabled: boolean;
  readOnly: boolean;
  onInteraction: (trackId: string, value: number) => void;
  customRender?: (props: TrackProps) => React.ReactNode;
}

const Track: React.FC<TrackComponentProps> = ({
  id,
  type,
  min,
  max,
  values,
  handles,
  startPoint,
  startAngle,
  endAngle,
  direction = 'clockwise',
  width,
  color,
  activeColor,
  sliderGeometry,
  defaultWidth,
  defaultColor,
  defaultActiveColor,
  disabled,
  readOnly,
  onInteraction,
  customRender,
  customStyle,
  spiralConfig,
  shouldRenderConnector: renderConnector,
  shouldRenderTrack: renderTrack,
  shouldRenderActiveTrack: renderActiveTrack,
}) => {
  startPoint = startPoint ?? startAngle;
  const shouldRenderConnector = renderConnector ?? true;
  const shouldRenderTrack = renderTrack ?? true;
  const shouldRenderActiveTrack = renderActiveTrack ?? true;

  /**
   * Track path
   */
  const trackPath = useMemo(() => {
    if (!shouldRenderTrack) return '';

    const { center, radius } = sliderGeometry;
    if (type === 'spiral' && spiralConfig) {
      return describeSpiralArc(
        center.x,
        center.y,
        radius,
        startAngle,
        endAngle,
        spiralConfig.expansionFactor,
        direction
      );
    } else {
      const trackStartAngle = normalizeAngle(startAngle);
      const trackEndAngle = normalizeAngle(endAngle);
      return describeArc(center.x, center.y, radius, trackStartAngle, trackEndAngle, direction);
    }
  }, [sliderGeometry, type, spiralConfig, startAngle, endAngle, direction, shouldRenderTrack]);

  /**
   * Active path
   */
  const activePaths = useMemo(() => {
    if (!shouldRenderActiveTrack) return [];
    if (values.length === 0 || !handles) return [];

    const { center, radius } = sliderGeometry;

    const valueAngle = sliderGeometry.getAngleByValue(id, values[0]);

    if (type === 'spiral' && spiralConfig) {
      return describeSpiralArc(
        center.x,
        center.y,
        radius,
        startAngle,
        valueAngle,
        spiralConfig.expansionFactor,
        direction
      );
    } else {
      const arcs = [];

      for (const singleHandle of handles) {
        const { activeTrackColor, value } = singleHandle;
        const valueAngle = sliderGeometry.getAngleByValue(id, value);

        const activeStartAngle = valueAngle;
        let activeEndAngle = startPoint!;

        const activeDirection = valueAngle > startPoint! ? 'counterclockwise' : 'clockwise';

        const isFullCircle = Math.abs(activeStartAngle - activeEndAngle) >= 360;

        if (isFullCircle) {
          if (activeDirection === 'clockwise') {
            activeEndAngle = activeStartAngle - 0.001;
          } else {
            activeEndAngle = activeStartAngle + 0.001;
          }
        }

        const arcPath = describeArc(center.x, center.y, radius, activeStartAngle, activeEndAngle, activeDirection);

        let customTrackStyle = {};

        if (activeTrackColor) {
          customTrackStyle = {
            stroke: activeTrackColor,
          };
        }

        arcs.push({
          path: arcPath,
          style: {
            ...customTrackStyle,
          },
        });
      }

      return arcs;
    }
  }, [
    sliderGeometry,
    id,
    values,
    startAngle,
    direction,
    type,
    spiralConfig,
    startPoint,
    handles,
    shouldRenderActiveTrack,
  ]);

  /**
   * Track style
   */
  const trackStyle: CustomStyle = {
    fill: 'none',
    stroke: color || defaultColor,
    strokeWidth: width || defaultWidth,
    ...customStyle,
  };

  /**
   * Active style
   */
  const activeStyle: CustomStyle = {
    fill: 'none',
    stroke: activeColor || defaultActiveColor,
    strokeWidth: width || defaultWidth,
    ...customStyle,
  };

  /**
   * Handle track click event
   * @param event
   */
  const handleTrackClick = (event: React.MouseEvent<SVGPathElement>) => {
    if (disabled || readOnly) return;
    const { clientX, clientY } = event;
    const { center } = sliderGeometry;
    const svgElement = event.currentTarget.ownerSVGElement;
    if (!svgElement) return;

    const point = svgElement.createSVGPoint();
    point.x = clientX;
    point.y = clientY;
    const svgPoint = point.matrixTransform(svgElement.getScreenCTM()?.inverse());

    const angle = Math.atan2(svgPoint.y - center.y, svgPoint.x - center.x) * (180 / Math.PI);
    const normalizedAngle = (angle + 360) % 360;
    const value = sliderGeometry.getValueByAngle(id, normalizedAngle);
    onInteraction(id, value);
  };

  if (customRender) {
    return customRender({
      id,
      type,
      min,
      max,
      values,
      startAngle,
      endAngle,
      direction,
      width,
      color,
      activeColor,
    });
  }

  return (
    <g>
      <path d={trackPath} style={trackStyle} onClick={handleTrackClick} />

      {activePaths.map((arc, index) => (
        <path key={index} d={arc.path} style={{ ...activeStyle, ...arc.style }} />
      ))}
    </g>
  );
};

export default React.memo(Track);
