import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_LINKABLE_LIST = gql`
  fragment LINKABLE_LIST on ComponentAtomsLinkableList {
    title
    link
    anchor
    description
  }
`;
