import { useState, useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { AbortablePromise } from '@lib/utils/abortable-promise';

import { LargeLineFollowerIcon, SpeedIcon, SettingsIcon, ReverseIcon } from '@webapp/components/icons';

import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';
import Slider from '@webapp/components/ui/sliders/slider';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { type ModuleId } from '@lib/robo/types';
import {
  type ExecutableActionWidgetComponent,
  type ActionWidgetExecutionResult,
  type CodeMotorId,
  WidgetExecutionType,
  CodeMotorAlignment,
} from '@webapp/store/types';

import MotorSettingsModal from '../common/motor-settings-modal';
import { Motor, ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';

import { getSharedMotorsConfig, useSharedMotorsConfig } from '../common/motor-alignment-helper';

const widgetConfig = {
  speed: {
    max: 100,
    min: 0,
    step: 5,
  },
};

/**
 * The Line Follower Action Widget
 * @param id The widget id
 * @returns The line follower widget
 */
const LineFollowerWidget: ExecutableActionWidgetComponent<LineFollowerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<LineFollowerWidgetData>(id);

  const { model: roboModel } = useRobo();

  const [speed, setSpeed] = useState(widget?.data.speed ?? LineFollowerWidget.initialData.speed);
  const [isReversed, setIsReversed] = useState(widget?.data.isReversed ?? LineFollowerWidget.initialData.isReversed);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  /**
   * Get the connected motors
   */
  const connectedMotors = useMemo(() => {
    if (!roboModel?.modules.motors) {
      return {};
    }

    return Object.entries(roboModel.modules.motors).reduce(
      (acc, [motorId, motor]) => {
        if (motor.isEnabled()) {
          acc[motorId] = motor;
        }
        return acc;
      },
      {} as Record<string, Motor>
    );
  }, [roboModel?.modules.motors]);

  const motorsConfig = useSharedMotorsConfig();

  /**
   * Handles the speed change
   */
  const handleSpeedChange = (newSpeed: number) => {
    setSpeed(newSpeed);
  };

  /**
   * Handles the speed changed event. Updates the widget data
   */
  const onSpeedChanged = (newSpeed: number) => {
    updateWidgetData(id, { speed: newSpeed });
  };

  /**
   * Handles the isReversed changed event. Updates the widget data
   */
  const onIsReversedChanged = () => {
    const newIsReversed = !isReversed;
    setIsReversed(newIsReversed);
    updateWidgetData(id, { isReversed: newIsReversed });
  };

  /**
   * Handle the settings click
   */
  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  /**
   * Handle the motor probe
   * @param motorId The motor id
   */
  const handleMotorProbe = (motorId: CodeMotorId) => {
    const MOTOR = connectedMotors[motorId];
    const probeAngle = 120;

    const configuredMotorAlignment = motorsConfig[motorId].alignment;

    let rotationDirection = ROTATION_DIRECTIONS.cw;

    switch (configuredMotorAlignment) {
      case CodeMotorAlignment.Left:
        rotationDirection = ROTATION_DIRECTIONS.ccw;
        break;
      case CodeMotorAlignment.Right:
        rotationDirection = ROTATION_DIRECTIONS.cw;
        break;
      default:
        return;
    }

    MOTOR.angle(probeAngle, rotationDirection);
  };

  return (
    <Box sx={{ width: '480px', height: '306px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0}>
        <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <RoundToggleIconButton
            icon={<ReverseIcon sx={{ width: 42, height: 42 }} />}
            onChange={onIsReversedChanged}
            value={isReversed}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Reverse
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconWithText
            icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B" textAlign="center">
                Speed
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={10}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '218px',
          }}
        >
          <LargeLineFollowerIcon sx={{ width: '368px', height: '246px', marginTop: '-48px', marginLeft: '36px' }} />

          {/* Add Settings button */}
          <Box sx={{ position: 'absolute', bottom: 8, left: '8px' }}>
            <RoundIconButton
              icon={<SettingsIcon sx={{ width: 42, height: 42 }} />}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Settings
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
              onClick={handleSettingsClick}
            />
          </Box>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
          <Slider
            value={speed}
            valueLabelDisplay="on"
            max={widgetConfig.speed.max}
            min={widgetConfig.speed.min}
            step={widgetConfig.speed.step}
            onChange={(_, newSpeed) => handleSpeedChange(newSpeed as number)}
            onChangeCommitted={(_, newSpeed) => onSpeedChanged(newSpeed as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="rainbow"
          />
        </Grid>
      </Grid>

      {/* Motor Settings Modal */}
      <MotorSettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        probeMotor={handleMotorProbe}
        connectedMotors={connectedMotors}
      />
    </Box>
  );
};

/**
 * Executes the line follower widget
 * @param signal The abort signal
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @param updateWidgetData The updateWidgetData function
 * @returns The execution result
 */
LineFollowerWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById, getProjectConfig }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const projectConfig = getProjectConfig();

    const widgetData = widget.data;
    const { speed, isReversed } = widgetData;

    if (!roboModel) {
      throw new Error('RoboModel not found');
    }

    const moduleId = widgetData?.moduleIds[0] as ModuleId;

    if (!moduleId) {
      throw new Error('Module or RoboModel not found');
    }

    const LINE_FOLLOWER = roboModel.modules.linetrackers[moduleId];

    if (!LINE_FOLLOWER) {
      throw new Error('Line Follower module not found');
    }

    // get all connected motors
    const connectedMotors = Object.values(roboModel.modules.motors).filter(motor => motor.isEnabled());

    // Get the shared motor configuration
    const motorsConfig = getSharedMotorsConfig(projectConfig.motorsConfig);

    // filter connected motors by motorsConfig. If alignment is not set, then skip it
    const filteredMotors = connectedMotors.filter(
      motor => motorsConfig[motor.id as CodeMotorId].alignment !== CodeMotorAlignment.None
    );

    // in case there are less than 2 motors, we can't drive
    if (filteredMotors.length < 2) {
      throw new Error('Less than 2 motors connected');
    }

    let motorsMap = 0b00000000;
    let motorsDirectionsMap = 0b00000000;

    const CW = 1;
    const CCW = 0;

    // go through all motors and set the motorsMap and motorsDirectionsMap
    filteredMotors.forEach(motor => {
      const motorConfig = motorsConfig[motor.id as CodeMotorId];
      const motorIndex = motor.index;

      let direction = motorConfig.alignment === CodeMotorAlignment.Left ? CW : CCW;
      direction = isReversed ? 1 - direction : direction;

      motorsMap = motorsMap | (1 << motorIndex);
      motorsDirectionsMap = motorsDirectionsMap | (direction << motorIndex);
    });

    LINE_FOLLOWER.setLineTrackingAction(LINE_FOLLOWER.index, motorsMap, motorsDirectionsMap, speed, ({ isError }) => {
      if (isError) {
        reject(new Error('Error setting line tracking action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    });
  });
};

/**
 * Interface for widget data
 */
export interface LineFollowerWidgetData {
  speed: number;
  isReversed: boolean;
}

/**
 * Initial data for the TurnWidget
 */
LineFollowerWidget.initialData = {
  speed: 100,
  isReversed: false,
};

export default LineFollowerWidget;
