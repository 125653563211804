import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#034FCC';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 19L18 18.25L18 18.25L18 19ZM13 19L20.5 23.3301L20.5 14.6699L13 19ZM24.25 27C24.25 27.4142 24.5858 27.75 25 27.75C25.4142 27.75 25.75 27.4142 25.75 27L24.25 27ZM24.25 26L24.25 27L25.75 27L25.75 26L24.25 26ZM18 19.75C21.4518 19.75 24.25 22.5482 24.25 26L25.75 26C25.75 21.7198 22.2802 18.25 18 18.25L18 19.75Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
