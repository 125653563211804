import React from 'react';
import BasicElement from './basic-element';

const SpecialElement = props => {
  return (
    <BasicElement
      sx={{
        backgroundColor: '#26882D',

        '&:hover': {
          backgroundColor: '#3A9A45',
        },

        '&:active': {
          backgroundColor: '#1A6620',
        },
      }}
      {...props}
    />
  );
};
export default SpecialElement;
