import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 53 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
          id="path-1-outside-1_1348_318"
          maskUnits="userSpaceOnUse"
          x="18"
          y="7.5"
          width="17"
          height="10"
          fill="black"
        >
          <rect fill="white" x="18" y="7.5" width="17" height="10" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 8.5C19 12.6421 22.3579 16 26.5 16C30.6421 16 34 12.6421 34 8.5H19Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 8.5C19 12.6421 22.3579 16 26.5 16C30.6421 16 34 12.6421 34 8.5H19Z"
          fill="white"
        />
        <path
          d="M19 8.5V7.5H18V8.5H19ZM34 8.5H35V7.5H34V8.5ZM26.5 15C22.9101 15 20 12.0899 20 8.5H18C18 13.1944 21.8056 17 26.5 17V15ZM33 8.5C33 12.0899 30.0899 15 26.5 15V17C31.1944 17 35 13.1944 35 8.5H33ZM34 7.5H19V9.5H34V7.5Z"
          fill="#5A418B"
          mask="url(#path-1-outside-1_1348_318)"
        />
        <circle cx="8.5" cy="8.5" r="8" fill="white" stroke="#5A418B" />
        <circle cx="44.5" cy="8.5" r="8" fill="white" stroke="#5A418B" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
