import React, { useMemo } from 'react';
import { SliderGeometry, CustomStyle } from '../types';
import { describeArc } from '../utils/svg';

interface ConnectorProps {
  trackId: string;
  startValue: number;
  endValue: number;
  sliderGeometry: SliderGeometry;
  width?: number;
  color?: string;
  customStyle?: CustomStyle;
  customRender?: (props: ConnectorProps) => React.ReactNode;
}

const Connector: React.FC<ConnectorProps> = ({
  trackId,
  startValue,
  endValue,
  sliderGeometry,
  width = 2,
  color = '#3f51b5',
  customStyle,
  customRender,
}) => {
  const connectorPath = useMemo(() => {
    const { center, radius } = sliderGeometry;
    const startAngle = sliderGeometry.getAngleByValue(trackId, startValue);
    const endAngle = sliderGeometry.getAngleByValue(trackId, endValue);

    const isFullCircle = Math.abs(startAngle - endAngle) >= 360;
    if (isFullCircle) {
      return describeArc(center.x, center.y, radius, 0, 359.999, 'clockwise');
    }

    return describeArc(center.x, center.y, radius, startAngle, endAngle, 'clockwise');
  }, [sliderGeometry, trackId, startValue, endValue]);

  const connectorStyle: CustomStyle = {
    fill: 'none',
    stroke: color,
    strokeWidth: width,
    ...customStyle,
  };

  if (customRender) {
    return customRender({
      trackId,
      startValue,
      endValue,
      sliderGeometry,
      width,
      color,
      customStyle,
    });
  }

  return <path d={connectorPath} style={connectorStyle} />;
};

export default React.memo(Connector);
