import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <mask
          id="path-2-outside-1_1862_1610"
          maskUnits="userSpaceOnUse"
          x="40.0791"
          y="16"
          width="21"
          height="41"
          fill="black"
        >
          <rect fill="white" x="40.0791" y="16" width="21" height="41" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.4868 27.7507L47.7716 30.9497L42.0791 18L57.1248 19.9299L53.7852 23.8646C61.9089 32.8617 61.7289 46.7079 53.1059 55.3309L49.5421 51.7671C56.0669 45.2422 56.3966 34.8023 50.4868 27.7507Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.4868 27.7507L47.7716 30.9497L42.0791 18L57.1248 19.9299L53.7852 23.8646C61.9089 32.8617 61.7289 46.7079 53.1059 55.3309L49.5421 51.7671C56.0669 45.2422 56.3966 34.8023 50.4868 27.7507Z"
          fill="white"
        />
        <path
          d="M47.7716 30.9497L46.8561 31.3521L47.4994 32.8157L48.534 31.5968L47.7716 30.9497ZM50.4868 27.7507L51.2532 27.1084L50.4916 26.1996L49.7244 27.1036L50.4868 27.7507ZM42.0791 18L42.2063 17.0081L40.4518 16.7831L41.1636 18.4024L42.0791 18ZM57.1248 19.9299L57.8872 20.577L59.0794 19.1724L57.252 18.938L57.1248 19.9299ZM53.7852 23.8646L53.0228 23.2175L52.4563 23.8849L53.043 24.5347L53.7852 23.8646ZM53.1059 55.3309L52.3988 56.038L53.1059 56.7451L53.813 56.038L53.1059 55.3309ZM49.5421 51.7671L48.835 51.06L48.1278 51.7671L48.835 52.4742L49.5421 51.7671ZM48.534 31.5968L51.2492 28.3978L49.7244 27.1036L47.0092 30.3026L48.534 31.5968ZM41.1636 18.4024L46.8561 31.3521L48.687 30.5473L42.9946 17.5976L41.1636 18.4024ZM57.252 18.938L42.2063 17.0081L41.9519 18.9919L56.9976 20.9217L57.252 18.938ZM54.5476 24.5117L57.8872 20.577L56.3624 19.2828L53.0228 23.2175L54.5476 24.5117ZM53.043 24.5347C60.8179 33.1456 60.6368 46.3858 52.3988 54.6238L53.813 56.038C62.8209 47.03 62.9999 32.5778 54.5274 23.1944L53.043 24.5347ZM53.813 54.6238L50.2492 51.06L48.835 52.4742L52.3988 56.038L53.813 54.6238ZM50.2492 52.4742C57.1493 45.574 57.4878 34.5475 51.2532 27.1084L49.7203 28.393C55.3054 35.0571 54.9844 44.9105 48.835 51.06L50.2492 52.4742Z"
          fill="#5A418B"
          mask="url(#path-2-outside-1_1862_1610)"
        />
        <mask
          id="path-4-outside-2_1862_1610"
          maskUnits="userSpaceOnUse"
          x="13"
          y="16"
          width="22"
          height="41"
          fill="black"
        >
          <rect fill="white" x="13" y="16" width="22" height="41" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.7159 26.9022L27.1514 30.9497L32.8438 18L17.7981 19.9299L20.4362 23.0381C11.7199 31.9586 11.8813 46.2947 20.7706 55.184L24.3344 51.6201C17.4794 44.7651 17.2895 33.8396 23.7159 26.9022Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7159 26.9022L27.1514 30.9497L32.8438 18L17.7981 19.9299L20.4362 23.0381C11.7199 31.9586 11.8813 46.2947 20.7706 55.184L24.3344 51.6201C17.4794 44.7651 17.2895 33.8396 23.7159 26.9022Z"
          fill="white"
        />
        <path
          d="M27.1514 30.9497L26.389 31.5968L27.4235 32.8157L28.0668 31.3521L27.1514 30.9497ZM23.7159 26.9022L24.4784 26.2551L23.7487 25.3953L22.9823 26.2226L23.7159 26.9022ZM32.8438 18L33.7593 18.4024L34.4711 16.7831L32.7166 17.0081L32.8438 18ZM17.7981 19.9299L17.6709 18.938L15.8435 19.1724L17.0357 20.577L17.7981 19.9299ZM20.4362 23.0381L21.1515 23.7369L21.7881 23.0854L21.1986 22.391L20.4362 23.0381ZM20.7706 55.184L20.0635 55.8911L20.7706 56.5982L21.4777 55.8911L20.7706 55.184ZM24.3344 51.6201L25.0415 52.3273L25.7486 51.6201L25.0415 50.913L24.3344 51.6201ZM27.9138 30.3026L24.4784 26.2551L22.9535 27.5493L26.389 31.5968L27.9138 30.3026ZM31.9284 17.5976L26.2359 30.5473L28.0668 31.3521L33.7593 18.4024L31.9284 17.5976ZM17.9253 20.9217L32.971 18.9919L32.7166 17.0081L17.6709 18.938L17.9253 20.9217ZM21.1986 22.391L18.5605 19.2828L17.0357 20.577L19.6738 23.6852L21.1986 22.391ZM21.4777 54.4769C12.9693 45.9684 12.8243 32.2591 21.1515 23.7369L19.721 22.3392C10.6154 31.658 10.7934 46.6209 20.0635 55.8911L21.4777 54.4769ZM23.6273 50.913L20.0635 54.4769L21.4777 55.8911L25.0415 52.3273L23.6273 50.913ZM22.9823 26.2226C16.1862 33.5591 16.3969 45.0969 23.6273 52.3273L25.0415 50.913C18.5618 44.4333 18.3928 34.1201 24.4496 27.5817L22.9823 26.2226Z"
          fill="#5A418B"
          mask="url(#path-4-outside-2_1862_1610)"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
