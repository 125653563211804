import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TopBarNavigationItem =
  | 'projects-link'
  | 'tutorials-link'
  | 'live-editor'
  | 'code-editor'
  | 'profile'
  | 'connect-block';

type TopBarState = {
  items: {
    [key in TopBarNavigationItem]: {
      enabled: boolean;
    };
  };
};

const initialState: TopBarState = {
  items: {
    'code-editor': {
      enabled: true,
    },
    'live-editor': {
      enabled: true,
    },
    profile: {
      enabled: true,
    },
    'connect-block': {
      enabled: true,
    },
    'projects-link': {
      enabled: true,
    },
    'tutorials-link': {
      enabled: true,
    },
  },
};

const topBarSlice = createSlice({
  name: 'webAppTopBar',
  initialState,
  reducers: {
    enableAll: state => {
      Object.keys(state.items).forEach(key => {
        state.items[key as TopBarNavigationItem].enabled = true;
      });
    },
    disableAll: state => {
      Object.keys(state.items).forEach(key => {
        state.items[key as TopBarNavigationItem].enabled = false;
      });
    },
    enable: (state, action: PayloadAction<TopBarNavigationItem>) => {
      state.items[action.payload].enabled = true;
    },
    disable: (state, action: PayloadAction<TopBarNavigationItem>) => {
      state.items[action.payload].enabled = false;
    },
  },
});

export const topBarActions = topBarSlice.actions;

export default topBarSlice.reducer;
