import { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { AbortablePromise } from '@lib/utils/abortable-promise';

import CircularSlider from '@webapp/components/ui/sliders/circular-slider';
import { LargeTurnIcon, SpeedIcon, AccelerometerAssistedTurnIcon, SettingsIcon } from '@webapp/components/icons';
import RandomButton from '@webapp/components/ui/buttons/random-button';
import Slider from '@webapp/components/ui/sliders/slider';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';

import MotorSettingsModal from '../common/motor-settings-modal';
import { CodeMotorAlignment, CodeMotorId } from '@webapp/store/types';
import { Motor, ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';

import { getSharedMotorsConfig, useSharedMotorsConfig } from '../common/motor-alignment-helper';

const widgetConfig = {
  angleValue: {
    min: -360,
    max: 360,
    step: 1,
    defaultValue: 0,
  },

  speed: {
    max: 100,
    min: 0,
    step: 5,
  },
};

// Type definitions for angle values
type SingleAngleValue = number;
type AngleValueRange = [SingleAngleValue, SingleAngleValue];
type AngleValue = SingleAngleValue | AngleValueRange;

/**
 * The Turn Action Widget
 * @param id The widget id
 * @returns The turn widget
 */
const TurnWidget: ExecutableActionWidgetComponent<TurnWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<TurnWidgetData>(id);

  const { model: roboModel } = useRobo();

  // take any accelerometer
  const ACCELEROMETER = roboModel?.modules.accelerometers
    ? Object.values(roboModel.modules.accelerometers)[0]
    : undefined;

  const defaultAngleValue = widgetConfig.angleValue.defaultValue;

  const [angleValue, setAngleValue] = useState<AngleValue>(widget?.data.angleValue || defaultAngleValue);
  const [speed, setSpeed] = useState(widget?.data.speed ?? TurnWidget.initialData.speed);
  const [isAccelerometerConnected, setIsAccelerometerConnected] = useState(ACCELEROMETER !== undefined);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  let connectedMotors: Record<string, Motor> = {};

  if (roboModel?.modules.motors) {
    connectedMotors = Object.entries(roboModel.modules.motors).reduce(
      (acc, [motorId, motor]) => {
        if (motor.isEnabled()) {
          acc[motorId] = motor;
        }
        return acc;
      },
      {} as Record<string, Motor>
    );
  }

  const motorsConfig = useSharedMotorsConfig();

  /**
   * Display the color and brightness changes on the LEDRGB
   */
  useEffect(() => {
    if (ACCELEROMETER) {
      setIsAccelerometerConnected(true);
    } else {
      setIsAccelerometerConnected(false);
    }
  }, [ACCELEROMETER]);

  /**
   * Handle the angle value change
   * @param newValue The new angle value
   */
  const handleAngleValueChange = (newValue: AngleValue) => {
    setAngleValue(newValue);
  };

  /**
   * Handle the angle value changed event
   */
  const onAngleValueChanged = (angleValue: AngleValue) => {
    updateWidgetData<TurnWidgetData>(id, { angleValue });
  };

  /**
   * Handles the speed change
   */
  const handleSpeedChange = (newSpeed: number) => {
    setSpeed(newSpeed);
  };

  /**
   * Handles the speed changed event. Updates the widget data
   */
  const onSpeedChanged = (newSpeed: number) => {
    updateWidgetData(id, { speed: newSpeed });
  };

  /**
   * Handle the random toggle
   */
  const handleRandomToggle = () => {
    let newAngleValue;

    if (Array.isArray(angleValue)) {
      newAngleValue = convertRangeToSingleValue(angleValue);
    } else {
      newAngleValue = convertSingleValueToRange(angleValue);
    }

    setAngleValue(newAngleValue);
    onAngleValueChanged(newAngleValue);
  };

  /**
   * Convert a range to a single value
   * @param range The range to convert
   * @returns The single value
   */
  const convertRangeToSingleValue = (range: AngleValueRange): SingleAngleValue => {
    const midValue = (range[0] + range[1]) / 2;
    const step = widgetConfig.angleValue.step;
    return Math.round(midValue / step) * step;
  };

  /**
   * Convert a single value to a range
   * @param value The single value to convert
   * @returns The range
   */
  const convertSingleValueToRange = (value: SingleAngleValue): AngleValueRange => {
    const range = 15;
    let rangeMin = value - range;
    let rangeMax = value + range;

    const unitMin = widgetConfig.angleValue.min;
    const unitMax = widgetConfig.angleValue.max;

    if (rangeMin < unitMin) {
      rangeMin = unitMin;
      rangeMax = unitMin + 2 * range;
    }

    if (rangeMax > unitMax) {
      rangeMax = unitMax;
      rangeMin = unitMax - 2 * range;
    }

    return [rangeMin, rangeMax];
  };

  /**
   * Calculate the rotation angle for the LargeMotorIcon
   * @returns The rotation angle in degrees
   */
  const calculateRotationAngle = (): number => {
    if (Array.isArray(angleValue)) {
      return (angleValue[0] + angleValue[1]) / 2;
    }
    return angleValue;
  };

  /**
   * Handle the settings click
   */
  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  /**
   * Handle the motor probe
   * @param motorId The motor id
   */
  const handleMotorProbe = (motorId: CodeMotorId) => {
    const MOTOR = connectedMotors[motorId];
    const probeAngle = 120;

    const configuredMotorAlignment = motorsConfig[motorId].alignment;

    let rotationDirection = ROTATION_DIRECTIONS.cw;

    switch (configuredMotorAlignment) {
      case CodeMotorAlignment.Left:
        rotationDirection = ROTATION_DIRECTIONS.ccw;
        break;
      case CodeMotorAlignment.Right:
        rotationDirection = ROTATION_DIRECTIONS.cw;
        break;
      default:
        return;
    }

    MOTOR.angle(probeAngle, rotationDirection);
  };

  return (
    <Box sx={{ width: '300px', height: '290px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0}>
        <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <RandomButton
            selected={Array.isArray(angleValue)}
            value={Array.isArray(angleValue)}
            onChange={handleRandomToggle}
            mainColor="#034FCC"
            secondaryColor="#E9E9E9"
          >
            Random
          </RandomButton>
        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconWithText
            icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B" textAlign="center">
                Speed
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '218px',
          }}
        >
          <CircularSlider
            value={angleValue}
            valueLabelDisplay="on"
            onChange={(_event, newValue) => handleAngleValueChange(newValue as AngleValue)}
            onChangeCommitted={(_event, newValue) => onAngleValueChanged(newValue as AngleValue)}
            min={widgetConfig.angleValue.min}
            max={widgetConfig.angleValue.max}
            step={widgetConfig.angleValue.step}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            radius={80}
            startAngle={-360}
            endAngle={360}
          >
            <Box>
              <LargeTurnIcon
                sx={{ width: '108px', height: '108px', transform: `rotate(${calculateRotationAngle()}deg)` }}
              />
            </Box>
          </CircularSlider>

          {isAccelerometerConnected && (
            <Box
              sx={{
                position: 'absolute',
                width: '48px',
                height: '48px',
                left: 0,
                bottom: 0,
                ml: 1,
                mb: 1,
              }}
              title="Accelerometer Assisted Turn"
            >
              <AccelerometerAssistedTurnIcon sx={{ width: '48px', height: '48px' }} />
            </Box>
          )}

          {/* Add Settings button */}
          <Box sx={{ position: 'absolute', bottom: 8, right: '72px' }}>
            <RoundIconButton
              icon={<SettingsIcon sx={{ width: 42, height: 42 }} />}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Settings
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
              onClick={handleSettingsClick}
            />
          </Box>
        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
          <Slider
            value={speed}
            valueLabelDisplay="on"
            max={widgetConfig.speed.max}
            min={widgetConfig.speed.min}
            step={widgetConfig.speed.step}
            onChange={(_, newSpeed) => handleSpeedChange(newSpeed as number)}
            onChangeCommitted={(_, newSpeed) => onSpeedChanged(newSpeed as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="rainbow"
          />
        </Grid>
      </Grid>

      {/* Motor Settings Modal */}
      <MotorSettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        probeMotor={handleMotorProbe}
        connectedMotors={connectedMotors}
      />
    </Box>
  );
};

/**
 * Executes the motor widget
 * @param signal The abort signal
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @param updateWidgetData The updateWidgetData function
 * @returns The execution result
 */
TurnWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById, getProjectConfig }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const projectConfig = getProjectConfig();

    const widgetData = widget.data;
    const { speed } = widgetData;

    if (!roboModel) {
      throw new Error('RoboModel not found');
    }

    // get all connected motors
    const connectedMotors = Object.values(roboModel.modules.motors).filter(motor => motor.isEnabled());

    // Get the shared motor configuration
    const motorsConfig = getSharedMotorsConfig(projectConfig.motorsConfig);

    // filter connected motors by motorsConfig. If alignment is not set, then skip it
    const filteredMotors = connectedMotors.filter(
      motor => motorsConfig[motor.id as CodeMotorId].alignment !== CodeMotorAlignment.None
    );

    // in case there are less than 2 motors, we can't drive
    if (filteredMotors.length < 2) {
      throw new Error('Less than 2 motors connected');
    }

    let motorsMap = 0b00000000;
    let motorsDirectionsMap = 0b00000000;

    const CW = 1;
    const CCW = 0;

    let sendAngle;
    if (Array.isArray(widgetData.angleValue)) {
      // Randomly select a duration between the range
      sendAngle = Math.floor(
        Math.random() * (widgetData.angleValue[1] - widgetData.angleValue[0] + 1) + widgetData.angleValue[0]
      );
    } else {
      sendAngle = widgetData.angleValue;
    }

    const isTurningRight = sendAngle > 0;

    // we don't need the sign of the angle anymore, because we will invert the direction of the motors in the map
    sendAngle = Math.abs(sendAngle);

    // go through all motors and set the motorsMap and motorsDirectionsMap
    filteredMotors.forEach(motor => {
      const motorIndex = motor.index;

      const direction = isTurningRight ? CW : CCW;

      motorsMap = motorsMap | (1 << motorIndex);
      motorsDirectionsMap = motorsDirectionsMap | (direction << motorIndex);
    });

    // take any of the connected motors as a proxy
    const MOTOR = filteredMotors[0];

    // take any accelerometer
    const ACCELEROMETER = roboModel?.modules.accelerometers
      ? Object.values(roboModel.modules.accelerometers)[0]
      : undefined;

    if (ACCELEROMETER) {
      // gyro-assisted turn
      MOTOR.setGyroAssistedTurnAction(
        ACCELEROMETER.index,
        motorsMap,
        motorsDirectionsMap,
        speed,
        sendAngle,
        ({ isError }) => {
          if (isError) {
            reject(new Error('Error setting gyro-assisted turn action'));
          } else {
            resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
          }
        }
      );
    } else {
      // regular turn
      MOTOR.setTurnAction(motorsMap, motorsDirectionsMap, speed, sendAngle, ({ isError }) => {
        if (isError) {
          reject(new Error('Error setting turn action'));
        } else {
          resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
        }
      });
    }
  });
};

/**
 * Interface for widget data
 */
export interface TurnWidgetData {
  angleValue: AngleValue;
  speed: number;
}

/**
 * Initial data for the TurnWidget
 */
TurnWidget.initialData = {
  angleValue: 0,
  speed: 100,
};

export default TurnWidget;
