import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg width="50" height="33" viewBox="0 0 50 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="26" width="50" height="7" rx="3.5" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 29C9 24.0294 12.5777 20 16.9924 20H33.0076C37.4217 20 41 24.0396 41 29"
          fill="white"
        />
        <rect x="22.6569" width="4" height="12" rx="2" fill="white" fillOpacity="0.91" />
        <rect
          x="43.1422"
          y="5"
          width="4"
          height="12"
          rx="2"
          transform="rotate(45 43.1422 5)"
          fill="white"
          fillOpacity="0.91"
        />
        <rect
          width="4"
          height="12"
          rx="2"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 5.82843 5)"
          fill="white"
          fillOpacity="0.91"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
