import { createSectionsSlice } from '@webapp/store/slices/shared/shared-sections-slice';
import sectionsInitialState from '@webapp/store/state/code/sections.state';
import { EditorType } from '@webapp/store/types';

const specificSelectors = {
  // Define specific selectors here
};

const specificReducers = {
  // Define specific reducers here
};

const { sectionsSlice, actions, selectors } = createSectionsSlice(
  sectionsInitialState,
  'code/sections',
  EditorType.Code,
  specificReducers
);
export const sectionsActions = actions;

export const sectionsSelectors = {
  ...selectors,
  ...specificSelectors,
};

export default sectionsSlice.reducer;
