import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_RELATED_GRADES = gql`
  fragment RELATED_GRADES on GradeRelationResponseCollection {
    data {
      id
      attributes {
        name
      }
    }
  }
`;
