import { ModuleMapping, ModuleTypes, ModulesCollectionTypes } from '@lib/robo/types';

export const MODULE_MAPPINGS: { [key in ModulesCollectionTypes]: ModuleMapping } = {
  accelerometers: {
    className: 'Accelerometer',
    modulePositions: [52, 53, 54, 55],
    idPrefix: `${ModuleTypes.Accelerometer}_`,
    enabled: true,
    triggerIdOffset: 10,
  },
  buttons: {
    className: 'Button',
    modulePositions: [11, 12, 37, 38],
    idPrefix: `${ModuleTypes.Button}_`,
    enabled: true,
    triggerIdOffset: 20,
  },
  cameras: {
    className: 'Camera',
    modulePositions: [10],
    idPrefix: `${ModuleTypes.Camera}_`,
    enabled: false,
    triggerIdOffset: 30,
  },
  claws: {
    className: 'Claw',
    modulePositions: [64, 65, 66, 67],
    idPrefix: `${ModuleTypes.Claw}_`,
    enabled: false,
    triggerIdOffset: 40,
  },
  hinges: {
    className: 'Hinge',
    modulePositions: [60, 61, 62, 63],
    idPrefix: `${ModuleTypes.Hinge}_`,
    enabled: false,
    triggerIdOffset: 50,
  },
  IRs: {
    className: 'IR',
    modulePositions: [9],
    idPrefix: `${ModuleTypes.IR}_`,
    enabled: false,
    triggerIdOffset: 60,
  },
  knobs: {
    className: 'Knob',
    modulePositions: [72, 73, 74, 75],
    idPrefix: `${ModuleTypes.Knob}_`,
    enabled: false,
    triggerIdOffset: 70,
  },
  ledDisplays: {
    className: 'LedDisplay',
    modulePositions: [56, 57, 58, 59],
    idPrefix: `${ModuleTypes.LedDisplay}_`,
    enabled: true,
    triggerIdOffset: 80,
  },
  ledMatrices: {
    className: 'LedMatrix',
    modulePositions: [8, 30, 31, 32, 33, 34, 35, 36],
    idPrefix: `${ModuleTypes.LedMatrix}_`,
    enabled: true,
    triggerIdOffset: 90,
  },
  ledRGBs: {
    className: 'LedRGB',
    modulePositions: [6, 7, 24, 25, 26, 27, 28, 29],
    idPrefix: `${ModuleTypes.LedRGB}_`,
    enabled: true,
    triggerIdOffset: 100,
  },
  lightSensors: {
    className: 'LightSensor',
    modulePositions: [13, 39, 40, 41],
    idPrefix: `${ModuleTypes.LightSensor}_`,
    enabled: true,
    triggerIdOffset: 110,
  },
  linetrackers: {
    className: 'Linetracker',
    modulePositions: [48, 49, 50, 51],
    idPrefix: `${ModuleTypes.Linetracker}_`,
    enabled: true,
    triggerIdOffset: 120,
  },
  meteos: {
    className: 'Meteo',
    modulePositions: [14],
    idPrefix: `${ModuleTypes.Meteo}_`,
    enabled: false,
    triggerIdOffset: 130,
  },
  motions: {
    className: 'Motion',
    modulePositions: [17, 45, 46, 47],
    idPrefix: `${ModuleTypes.Motion}_`,
    enabled: true,
    triggerIdOffset: 140,
  },
  motors: {
    className: 'Motor',
    modulePositions: [0, 1, 2, 3, 18, 19],
    idPrefix: `${ModuleTypes.Motor}_`,
    enabled: true,
    triggerIdOffset: 150,
  },
  properServos: {
    className: 'ProperServo',
    modulePositions: [68, 69, 70, 71],
    idPrefix: `${ModuleTypes.ProperServo}_`,
    enabled: false,
    triggerIdOffset: 160,
  },
  servos: {
    className: 'Servo',
    modulePositions: [4, 5, 20, 21, 22, 23],
    idPrefix: `${ModuleTypes.Servo}_`,
    enabled: false,
    triggerIdOffset: 170,
  },
  systems: {
    className: 'System',
    modulePositions: [15],
    idPrefix: `${ModuleTypes.System}_`,
    enabled: true,
    triggerIdOffset: 180,
  },
  ultrasonics: {
    className: 'Ultrasonic',
    modulePositions: [16, 42, 43, 44],
    idPrefix: `${ModuleTypes.Ultrasonic}_`,
    enabled: true,
    triggerIdOffset: 230,
  },
  weathers: {
    className: 'Weather',
    modulePositions: [76, 77],
    idPrefix: `${ModuleTypes.Weather}_`,
    enabled: false,
    triggerIdOffset: 240,
  },
};
