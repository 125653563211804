import { ElementEntity } from '@webapp/store/types';

// This function generates an individual element based on the index
function generateElement(index: number, template: (index: number) => ElementEntity): ElementEntity {
  return template(index);
}

// This generator function yields a sequence of elements
export function* createElements(count: number, template: (index: number) => ElementEntity) {
  for (let i = 1; i <= count; i++) {
    yield generateElement(i, template);
  }
}

// This function will use your existing generateElement function to create elements from a list of attributes
// This generator function yields a sequence of elements for each item in the attributes list
export function* createElementsFromList<T extends Record<string, string | number>>(
  count: number,
  attributesList: T[],
  template: (index: number, attributes: T) => ElementEntity
) {
  for (let i = 1; i <= count; i++) {
    for (const attributes of attributesList) {
      yield template(i, attributes);
    }
  }
}
