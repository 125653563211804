import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

export enum ButtonEventType {
  Clicked = 'clicked',
  Pressed = 'pressed',
  Released = 'released',
}

export class Button extends BaseModule<typeof Button> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.Button, store);

    this.client.registerHandler(
      HandlerType.OnButton,
      ({ data }) => {
        const pressed = Button.parsePayload(data);

        const currentData = this.getDataState();

        const hasChanges = !currentData.sensorData || currentData.sensorData.pressed !== pressed;
        if (!hasChanges) {
          return;
        }

        this.updateDataState({
          sensorData: {
            pressed,
          },
        });
      },
      id
    );
  }

  /**
   * Creates a trigger for the button.
   * @param {ButtonEventType} eventType - The event type to trigger on.
   * @param {number} eventsCount - The number of events to trigger on.
   * @param {onActionDoneType} onActionDone - The callback to call when the trigger is done.
   * @returns {Promise<{ triggerId: string }>} The promise that resolves when the trigger is created.
   */
  createTrigger(eventType: ButtonEventType, eventsCount: number, onActionDone: onActionDoneType) {
    const triggerId = this.generateActionOrTriggerId();

    let triggerCondition = 1;
    switch (eventType) {
      case ButtonEventType.Clicked:
        triggerCondition = eventsCount;
        break;
      case ButtonEventType.Pressed:
        triggerCondition = 0;
        break;
      case ButtonEventType.Released:
        triggerCondition = -1;
        break;
    }

    this.subscribeToResponse(triggerId, onActionDone);

    this.client.setButtonTrigger(triggerId, this.index, triggerCondition);

    return { triggerId };
  }

  /**
   * Parses the payload from the button.
   *
   * @param {Uint8Array} data - The data to parse.
   * @returns {boolean} The parsed payload.
   */
  static parsePayload(data: Uint8Array) {
    return data[0] === 1;
  }

  static initialDataState() {
    return {
      sensorData: {
        pressed: false,
      },
    };
  }
}
