import { RoboClient } from './robo-client';

class RoboClientManager {
  private clients: Record<string, RoboClient> = {};

  addClient(id: string, client: RoboClient): void {
    this.clients[id] = client;
  }

  getClient(id: string): RoboClient | undefined {
    return this.clients[id];
  }

  removeClient(id: string): void {
    delete this.clients[id];
  }
}

export const roboClientManager = new RoboClientManager();
