import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.346 37.8685C29.8784 40.3992 25.6037 41.8931 20.9796 41.8931C9.39374 41.8931 0.00152588 32.515 0.00152588 20.9465C0.00152588 9.37802 9.39374 -9.15527e-05 20.9796 -9.15527e-05C32.5655 -9.15527e-05 41.9578 9.37802 41.9578 20.9465C41.9578 25.6041 40.4353 29.9067 37.8602 33.3851L47.4368 42.9473C48.1808 43.6902 48.1808 44.8947 47.4368 45.6376L45.629 47.4427C44.885 48.1856 43.6787 48.1856 42.9346 47.4427L33.346 37.8685ZM11.8691 23.5798V18.3132H30.0901V23.5798H11.8691Z"
          fill="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
