import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="300 211.0555 29.521 27.7405"
        width="29.521"
        height="27.74"
        fill="rgb(255, 255, 255)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 309.075 226.089 C 308.564 225.451 307.632 225.347 306.994 225.858 L 300.556 231.008 C 300.205 231.289 300 231.715 300 232.165 C 300 232.615 300.205 233.04 300.556 233.321 L 306.994 238.471 C 307.632 238.982 308.564 238.878 309.075 238.24 C 309.586 237.601 309.482 236.67 308.844 236.159 L 305.702 233.645 L 320.794 233.645 C 321.612 233.645 322.275 232.982 322.275 232.165 C 322.275 231.347 321.612 230.684 320.794 230.684 L 305.702 230.684 L 308.844 228.171 C 309.482 227.66 309.586 226.728 309.075 226.089 Z"
          transform="matrix(1, 0, 0, 1, -2.842170943040401e-14, 0)"
        />

        <path
          d="M 320.204 234.934 C 320.911 235.724 321.939 236.221 323.083 236.221 C 325.216 236.221 326.945 234.492 326.945 232.359 C 326.945 230.225 325.216 228.496 323.083 228.496 C 321.939 228.496 320.911 228.993 320.204 229.783 L 317.181 229.783 C 318.174 227.51 320.443 225.921 323.083 225.921 C 326.638 225.921 329.521 228.803 329.521 232.359 C 329.521 235.914 326.638 238.796 323.083 238.796 C 320.443 238.796 318.174 237.207 317.181 234.934 L 320.204 234.934 Z"
          transform="matrix(1, 0, 0, 1, -2.842170943040401e-14, 0)"
        />

        <path
          d="M 316.321 211.612 C 315.81 210.974 314.878 210.87 314.24 211.381 L 307.802 216.531 C 307.451 216.812 307.246 217.238 307.246 217.688 C 307.246 218.138 307.451 218.563 307.802 218.844 L 314.24 223.994 C 314.878 224.505 315.81 224.401 316.321 223.763 C 316.832 223.124 316.728 222.193 316.09 221.682 L 312.948 219.168 L 328.04 219.168 C 328.858 219.168 329.521 218.505 329.521 217.688 C 329.521 216.87 328.858 216.207 328.04 216.207 L 312.948 216.207 L 316.09 213.694 C 316.728 213.183 316.832 212.251 316.321 211.612 Z"
          style={{ transformOrigin: '317.468px 217.688px' }}
          transform="matrix(-1, 0, 0, -1, 0.000080012302, 0.000012975187)"
        />

        <path
          d="M 303.023 220.07 C 303.73 220.86 304.758 221.357 305.902 221.357 C 308.035 221.357 309.764 219.628 309.764 217.495 C 309.764 215.361 308.035 213.632 305.902 213.632 C 304.758 213.632 303.73 214.129 303.023 214.919 L 300 214.919 C 300.993 212.646 303.262 211.057 305.902 211.057 C 309.457 211.057 312.34 213.939 312.34 217.495 C 312.34 221.05 309.457 223.932 305.902 223.932 C 303.262 223.932 300.993 222.343 300 220.07 L 303.023 220.07 Z"
          style={{ transformOrigin: '306.17px 217.495px' }}
          transform="matrix(-1, 0, 0, -1, 0.000079284298, 0.000012963684)"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
