const defaultContext = 'AbortablePromise';

/**
 * AbortablePromise
 *
 * This utility function creates a Promise that can be aborted using an AbortSignal.
 * It wraps the provided executor function with abort handling logic and context for better debugging.
 *
 * @param signal - An optional AbortSignal that can be used to abort the promise
 * @param executor - A function that defines the promise's execution. It receives two arguments:
 *   - resolve: A function to resolve the promise
 *   - reject: A function to reject the promise
 * @param context - An optional string providing context for where the promise was created. Useful for debugging.
 * @returns A Promise that can be aborted
 *
 * @example
 * const controller = new AbortController();
 * const signal = controller.signal;
 *
 * const promise = AbortablePromise(signal, (resolve, reject) => {
 *   // Async operation
 *   setTimeout(() => resolve('Done'), 5000);
 * }, 'MyContext');
 *
 * // To abort the promise:
 * controller.abort();
 *
 * @example
 * ButtonTriggerWidget.execute = async ({ roboModel, widget, signal }) => {
 *   return AbortablePromise(signal, async (resolve, reject) => {
 *     const { data } = widget;
 *     const buttonModuleId = data?.moduleIds[0];
 *     const buttonModule = roboModel.modules.buttons[buttonModuleId as ModuleId];
 *
 *     if (!buttonModule) {
 *       reject(new Error('Button module not found'));
 *       return;
 *     }
 *
 *     await buttonModule.createTrigger({
 *       eventType: data.eventType,
 *       eventsCount: data.eventsCount,
 *     });
 *
 *     resolve({ resolved: true, type: WidgetExecutionType.Trigger, widgetId: widget.id });
 *   });
 * };
 */
export function AbortablePromise<T>(
  signal: AbortSignal | undefined,
  executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
  context: string = defaultContext
): Promise<T> {
  const stack = new Error().stack;

  return new Promise<T>((resolve, reject) => {
    if (signal) {
      // Check if the signal is already aborted
      if (signal.aborted) {
        // Log the abort if it's not the default context
        // DEBUG:
        // context !== defaultContext && console.log('Promise aborted in initial check', context);
        reject(new AbortError('Promise aborted', context, stack));
        return;
      }

      // Define the abort handler
      const onAbort = () => {
        // Log the abort if it's not the default context
        // DEBUG:
        // context !== defaultContext && console.log('Promise aborted in event listener', context);
        reject(new AbortError('Promise aborted', context, stack));
      };

      // Add the abort listener
      signal.addEventListener('abort', onAbort);

      // Define a cleanup function to remove the abort listener
      const cleanup = () => {
        signal.removeEventListener('abort', onAbort);
      };

      // Wrap the resolve function to perform cleanup before resolving
      const wrappedResolve = (value: T | PromiseLike<T>) => {
        cleanup();
        resolve(value);
      };

      // Wrap the reject function to perform cleanup before rejecting
      const wrappedReject = (reason?: any) => {
        cleanup();
        reject(reason);
      };

      // Execute the promise with the wrapped resolve and reject functions
      executor(wrappedResolve, wrappedReject);
    } else {
      // If no signal is provided, execute the promise normally
      executor(resolve, reject);
    }
  });
}

/**
 * Custom error class for aborted promises.
 *
 * This class extends the built-in Error class to provide additional context
 * for errors related to aborted promises.
 */
export class AbortError extends Error {
  /**
   * Creates a new AbortError instance.
   *
   * @param message - The error message.
   * @param context - Additional context information about where the error occurred.
   * @param stack - Optional stack trace. If not provided, a new stack trace will be generated.
   */
  constructor(
    message: string,
    public context?: string,
    stack?: string
  ) {
    super(message);
    this.name = 'AbortError';
    this.context = context || defaultContext;
    if (stack) {
      this.stack = stack;
    } else {
      const error = new Error();
      this.stack = error.stack;
    }
  }
}
