import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';

import { colors as themeColors } from '@themes/config/theme-colors';

import { sectionColors } from './sections-appearance';

import IconLoader from '@webapp/components/icon-loader/icon-loader';

const Section = styled(Button, {
  shouldForwardProp: prop => prop !== 'backgroundImage',
})(({ type, colors, variant, backgroundImage }) => ({
  width: '96px',
  minWidth: '86px',
  height: '128px',

  marginLeft: '13px',

  borderWidth: '2px',
  borderColor: themeColors.violet['900'],
  borderStyle: 'solid',
  borderRadius: '8px 8px 0px 0px',
  borderBottom: 'none',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  backgroundColor: `${colors['tab'][variant].backgroundColor}`,
  backgroundImage: `url(${backgroundImage})`,

  '&:hover': {
    backgroundColor:
      variant === 'active' ? `${colors['tab']['active'].backgroundColor}` : `${colors['tab']['hover'].backgroundColor}`,
    backgroundImage: `url(${backgroundImage})`,
  },

  '&:after': {
    content: variant === 'active' ? '""' : 'none',
    position: 'absolute',
    top: '-11px', // Adjust as needed
    left: '50%',
    transform: 'translateX(-50%)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid ${themeColors.violet['900']}`,
  },
}));

const ToolboxSection = React.memo(({ section, onSectionClick }) => {
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const loadImage = async () => {
      const image = await import(
        `../../assets/section-backgrounds/${section.type}${section.active ? '-greyscale' : ''}.png`
      );
      setBackgroundImage(image.default);
    };
    loadImage();
  }, [section.type, section.active]);

  const colors = sectionColors[section.type];
  let variant = section.active ? 'active' : 'default';
  variant = section.disabled ? 'disabled' : variant;

  const SectionIcon = <IconLoader name={section.icon} props={{ ...colors['icon'][variant] }} />;

  return (
    <Section
      identifier={section.id}
      type={section.type}
      colors={colors}
      variant={variant}
      backgroundImage={backgroundImage}
      disabled={section.disabled}
      onClick={() => onSectionClick(section.id)}
    >
      {SectionIcon}

      <Typography
        variant="x-headline5-bold"
        color={
          section.active
            ? section.type === 'visual-section'
              ? themeColors.violet['900']
              : themeColors.white['500']
            : themeColors.violet['900']
        }
      >
        {section.name}
      </Typography>
    </Section>
  );
});

ToolboxSection.displayName = 'ToolboxSection';

ToolboxSection.propTypes = {
  section: PropTypes.object.isRequired,
  onSectionClick: PropTypes.func.isRequired,
};

export default ToolboxSection;
