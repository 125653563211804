import {
  WidgetType,
  WidgetEntity,
  WidgetData,
  WidgetProps,
  WidgetComponentType,
  ElementEntity,
} from '@webapp/store/types';

import availableWidgets from './index';

import { kebabToPascalCase } from '@lib/utils/string-utils';

const widgetsCache: {
  [key: string]: JSX.Element;
} = {};

/**
 * Returns a JSX element for the given widget type, ID, props, and data. If the widget has already been created, returns the cached element.
 * @param widgetType - The type of widget to create.
 * @param widgetId - The ID of the widget to create.
 * @returns A JSX element for the specified widget, or undefined if the widget type is not recognized.
 */
export const getOrCreateWidgetComponent = (widgetType: WidgetType, widgetId: string): JSX.Element => {
  const key = `${widgetType}-${widgetId}`;

  if (widgetsCache[key]) {
    return widgetsCache[key];
  }

  const WidgetComponent = getWidgetComponentByType(widgetType);

  widgetsCache[key] = WidgetComponent;

  return widgetsCache[key];
};

/**
 * Returns a new widget entity based on the provided element entity.
 * @param element - The element entity to create a widget entity for.
 * @param widgetId - ID of the widget
 * @returns A new widget entity.
 */
export const getWidgetEntityForElement = (element: ElementEntity, widgetId?: string): WidgetEntity => {
  const widgetType = element.widgetType!;
  const widgetData = element.widgetData || {};
  const elementType = element.type;
  const elementCounter = element.counter;

  widgetId = widgetId || `${widgetType}-${elementType}-${elementCounter}`;

  const initialWidgetProps = getInitialWidgetProps(widgetType) || {};

  const initialWidgetData = Object.assign({}, widgetData, getInitialWidgetData(widgetType));

  const widgetEntity = {
    id: widgetId,
    type: widgetType,

    props: {
      ...initialWidgetProps,

      active: false,
      hidden: false,
      disabled: false,
      highlighted: false,

      counter: elementCounter,
    },

    data: {
      ...initialWidgetData,

      id: widgetId,
      elementId: element.id,
      moduleIds: element.requiredModules || [],
      requiredModuleTypes: element.requiredModuleTypes || [],
    },
  };

  return widgetEntity;
};

/**
 * Returns the initial props for a given widget type.
 * @param widgetType - The type of widget to get initial props for.
 * @returns The initial props for the given widget type.
 * @throws An error if the widget type is unknown.
 */
export const getInitialWidgetProps = (widgetType: WidgetType): WidgetProps => {
  const WidgetComponent = getWidgetComponentByType(widgetType);
  return WidgetComponent.initialProps;
};

/**
 * Returns the initial data for a given widget type.
 * @param widgetType - The type of widget to get the initial data for.
 * @returns The initial data for the given widget type.
 * @throws An error if the widget type is unknown.
 */
export const getInitialWidgetData = (widgetType: WidgetType): WidgetData => {
  const WidgetComponent = getWidgetComponentByType(widgetType);
  return WidgetComponent.initialData;
};

/**
 * Returns the component name for a given widget type.
 * @param widgetType - The type of the widget.
 * @returns The component name in PascalCase.
 */
export const getWidgetComponentNameByType = (widgetType: WidgetType): string => {
  return kebabToPascalCase(widgetType);
};

/**
 * Returns the component for the given widget type.
 * @param widgetType - The type of widget to get the component for.
 * @returns The component for the given widget type.
 * @throws An error if the widget type is unknown.
 */
export const getWidgetComponentByType = (widgetType: WidgetType): WidgetComponentType => {
  const widgetComponentName = getWidgetComponentNameByType(widgetType);

  const WidgetComponent = availableWidgets[widgetComponentName] as WidgetComponentType;

  if (!WidgetComponent) {
    throw new Error(`Unknown widget type: ${widgetType}`);
  }

  return WidgetComponent;
};
