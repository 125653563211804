import React from 'react';
import BasicElement from './basic-element';

const VisualElement = props => {
  return (
    <BasicElement
      sx={{
        backgroundColor: '#FFB71C',

        '&:hover': {
          backgroundColor: '#FFCA3A',
        },

        '&:active': {
          backgroundColor: '#E6A300',
        },
      }}
      labelProps={{
        sx: {
          color: '#5A418B',
        },
      }}
      {...props}
    />
  );
};

export default VisualElement;
