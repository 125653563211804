import { ToggleButton as MuiToggleButton, ToggleButtonProps, styled } from '@mui/material';

const StyledToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '100px',
  border: 'none',
  height: '24px',
  fontSize: '12px',
  fontWeight: '700',
  padding: '0 20px',

  backgroundColor: '#E9E9E9',
  color: '#5A418B',
  '&:hover': {
    backgroundColor: '#E9E9E9',
  },

  '&.Mui-selected': {
    backgroundColor: '#DF1642',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#DF1642',
    },
  },
}));

const ToggleButton = (props: ToggleButtonProps) => {
  return <StyledToggleButton {...props} />;
};

export default ToggleButton;
