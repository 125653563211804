import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors as themeColors } from '@themes/config/theme-colors';

import StyledBox from '@webapp/components/blocks/component/styled-box';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useSelector } from '@store/configureStore';
import { selectModuleById } from '@webapp/store/slices/model/model.selectors';

import { ButtonOnSensorIcon, ButtonOffSensorIcon } from '@webapp/components/icons';

import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}));

const ButtonSensorWidget = ({ counter, data, disabled }) => {
  const { isPlaying, editorType } = useEditorContext();
  const { addHistoryEntry } = useActionsHistory();

  const moduleId = data?.moduleIds?.[0];
  const moduleState = useSelector(state => selectModuleById(state, 'buttons', moduleId));
  const pressed = isPlaying ? moduleState?.sensorData?.pressed ?? false : false;

  useEffect(() => {
    if (isPlaying && pressed) {
      addHistoryEntry({
        action: 'interact:widget:button',
        scope: editorType,
      });
    }
  }, [isPlaying, pressed, editorType]);

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#E31871'}>
      {counter ? <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography> : null}
      {pressed ? (
        <ButtonOnSensorIcon
          sx={{
            width: '50%',
            height: '50%',
          }}
        />
      ) : (
        <ButtonOffSensorIcon
          sx={{
            position: 'relative',
            top: '5px',
            width: '50%',
            height: '50%',
          }}
        />
      )}
    </StyledBox>
  );
};

ButtonSensorWidget.initialProps = {
  width: 1,
  height: 1,
};

ButtonSensorWidget.initialData = {};

export default ButtonSensorWidget;
