import { createEntityAdapter, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store/configureStore';
import { SectionsState, SectionEntity, EditorType } from '@webapp/store/types';

/**
 * Creates a sections slice with its actions, its reducer, its adapter, and its selectors.
 * @param sectionsInitialState The initial state of the sections slice.
 * @param sliceName The name of the slice.
 * @param editor The editor type.
 * @param specificReducers The specific reducers of the slice.
 * @returns The sections slice, its actions, its reducer, its adapter, and its selectors.
 */
export const createSectionsSlice = (
  sectionsInitialState: SectionsState,
  sliceName: string,
  editor: EditorType,
  specificReducers: Record<string, (state: SectionsState, action: PayloadAction) => void>
) => {
  const sectionsState = (state: RootState) => state.webapp[editor].toolbox.sections;

  const sectionsAdapter = createEntityAdapter<SectionEntity>();

  const sectionsSlice = createSlice({
    name: sliceName,
    initialState: sectionsInitialState,
    reducers: {
      addSection: sectionsAdapter.addOne,
      updateSection: sectionsAdapter.updateOne,
      removeSection: sectionsAdapter.removeOne,

      showAllSections: (state: SectionsState) => {
        Object.values(state.entities).forEach(section => {
          if (section) {
            section.hidden = false;
          }
        });
      },

      hideAllSections: (state: SectionsState) => {
        Object.values(state.entities).forEach(section => {
          if (section) {
            section.hidden = true;
          }
        });
      },

      showSection: (state: SectionsState, action: PayloadAction<string>) => {
        const id = action.payload;
        if (state.entities[id]) {
          state.entities[id].hidden = false;
        }
      },

      deactivateAllSections: (state: SectionsState) => {
        Object.values(state.entities).forEach(section => {
          if (section) {
            section.active = false;
          }
        });
      },

      activateAllSections: (state: SectionsState) => {
        Object.values(state.entities).forEach(section => {
          if (section) {
            section.active = true;
          }
        });
      },

      activateSection: (state: SectionsState, action: PayloadAction<string>) => {
        // First, deactivate all sections
        Object.values(state.entities).forEach(section => {
          if (section) {
            section.active = false;
          }
        });

        // Then, activate the section with the given id
        const id = action.payload;
        if (state.entities[id]) {
          state.entities[id].active = true;
        }
      },

      deactivateSection: (state: SectionsState, action: PayloadAction<string>) => {
        const id = action.payload;
        if (state.entities[id]) {
          state.entities[id].active = false;
        }
      },

      ...specificReducers,
    },
  });

  const adapterSelectors: ReturnType<typeof sectionsAdapter.getSelectors> = sectionsAdapter.getSelectors(sectionsState);

  /**
   * Selects the active section from an array of sections.
   * @returns The active section or null if none is found.
   */
  const selectActiveSection = createSelector([adapterSelectors.selectAll], sections => {
    return sections.find(section => section.active) || null;
  });

  return {
    sectionsSlice,
    actions: sectionsSlice.actions,
    reducer: sectionsSlice.reducer,
    sectionsAdapter: sectionsAdapter,
    selectors: { ...adapterSelectors, selectActiveSection },
  };
};
