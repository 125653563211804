import { createApi } from '@reduxjs/toolkit/query/react';

import { gql } from '@lib/store/strapi-graphql-query';
import protectedQuery from '@store/utils/protected-query.graphql';
import { AppSettingsEntityResponseCollection } from '@store/gql/graphql';

export const appSettingsApiGraphql = createApi({
  reducerPath: 'frontendSettingsApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getAppSettings: builder.query<{ appsSettings: AppSettingsEntityResponseCollection }, void>({
      query: () => ({
        document: gql`
          query GetAppSettings {
            appsSettings(filters: { key: { eq: "frontend_app" } }) {
              data {
                id
                attributes {
                  key
                  settings {
                    id
                    name
                    description
                    value
                  }
                }
              }
            }
          }
        `,
      }),
    }),
  }),
});

export const { useGetAppSettingsQuery } = appSettingsApiGraphql;
