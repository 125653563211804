import { createApi } from '@reduxjs/toolkit/query/react';

import protectedQuery from '@store/utils/protected-query.graphql';

import { TUTORIAL_QUERY, TUTORIALS_CATEGORIES_LIST_QUERY } from '@store/queries/tutorial';
import { Maybe, TutorialCategoryEntityResponseCollection, TutorialEntityResponse } from '@store/gql/graphql';

export const tutorialApiGraphql = createApi({
  reducerPath: 'tutorialApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getTutorialsCategoriesList: builder.query<
      { tutorialCategories?: Maybe<TutorialCategoryEntityResponseCollection> },
      void
    >({
      query: () => ({
        document: TUTORIALS_CATEGORIES_LIST_QUERY,
      }),
    }),

    getTutorial: builder.query<{ tutorial?: Maybe<TutorialEntityResponse> }, string>({
      query: (id: string) => {
        return {
          document: TUTORIAL_QUERY,
          variables: { id },
        };
      },
    }),
  }),
});

export const { useGetTutorialsCategoriesListQuery, useGetTutorialQuery } = tutorialApiGraphql;
