import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path d="M32.9474 22H23V51H32.9474V22Z" fill="white" />
        <path d="M50 22H40.0526V51H50V22Z" fill="white" />
        <path d="M32.9474 22H23V51H32.9474V22Z" stroke="#5A418B" />
        <path d="M50 22H40.0526V51H50V22Z" stroke="#5A418B" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
