import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes } from '@lib/robo/types';

export class LedRGB extends BaseModule<typeof LedRGB> {
  color: Uint8Array;
  active: boolean;

  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.LedRGB, store);

    this.color = new Uint8Array([0, 0, 0]);
    this.active = false;
  }

  /**
   * Sets the active state of the RGB LED module.
   * @param {boolean} state - The new active state.
   */
  setActive(state: boolean) {
    this.active = state;
    return this;
  }

  /**
   * Sets the color of the RGB LED.
   * @param {number} r - The red value (0-255).
   * @param {number} g - The green value (0-255).
   * @param {number} b - The blue value (0-255).
   */
  setColor(r: number, g: number, b: number) {
    this.color = new Uint8Array([r, g, b]);
    return this;
  }

  execute() {
    this.client.setLed(this.index, this.active ? 1 : 0, this.color);
  }

  /**
   * Sets the LED action.
   * @param red - The red value (0-255).
   * @param green - The green value (0-255).
   * @param blue - The blue value (0-255).
   * @param time
   * @param repeat
   * @param onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setLedAction(red: number, green: number, blue: number, time: number, repeat: number, onActionDone: onActionDoneType) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setLedAction(actionId, this.index, red, green, blue, time, repeat);

    return { actionId };
  }
}
