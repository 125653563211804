import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../configureStore';

import { UserRole, School, District, Tag, Subscription } from '@store/types/common';
import {
  SchoolEntityResponseCollection,
  DistrictEntityResponse,
  TagEntityResponseCollection,
  SubscriptionEntityResponseCollection,
} from '@store/gql/graphql';

type AuthState = {
  isLoggedIn: boolean;
  user: User | null;
  jwt: string | null;
};

type User = {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  role?: UserRole;
  isTest?: boolean;
  schools?: School[];
  district?: District;
  tags?: Tag[];
  subscriptions?: Subscription[];
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    jwt: null,
    isLoggedIn: false,
  } as AuthState,

  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, jwt },
      }: PayloadAction<{
        user: User;
        jwt: string;
      }>
    ) => {
      state.user = user;
      state.jwt = jwt;
      state.isLoggedIn = true;
    },

    setRole: (state, { payload: { role } }: PayloadAction<{ role: UserRole }>) => {
      if (state.user) {
        state.user.role = role;
      }
    },

    setSchools: (state, { payload: { schools } }: PayloadAction<{ schools: SchoolEntityResponseCollection }>) => {
      if (state.user) {
        state.user.schools = schools.data.map(school => ({
          id: school.id ?? '',
          name: school.attributes?.name ?? '',
        }));
      }
    },

    setDistrict: (state, { payload: { district } }: PayloadAction<{ district: DistrictEntityResponse }>) => {
      if (state.user) {
        state.user.district = {
          id: district.data?.id ?? '',
          name: district.data?.attributes?.name ?? '',
        };
      }
    },

    setTags: (state, { payload: { tags } }: PayloadAction<{ tags: TagEntityResponseCollection }>) => {
      if (state.user) {
        state.user.tags = tags.data.map(tag => ({
          id: tag.id ?? '',
          name: tag.attributes?.name ?? '',
        }));
      }
    },

    setSubscriptions: (
      state,
      { payload: { subscriptions } }: PayloadAction<{ subscriptions: SubscriptionEntityResponseCollection }>
    ) => {
      if (state.user) {
        state.user.subscriptions = subscriptions.data.map(subscription => ({
          id: subscription.id ?? '',
          name: subscription.attributes?.name ?? '',
        }));
      }
    },

    clearCredentials: state => {
      state.user = null;
      state.jwt = null;
      state.isLoggedIn = false;
    },
  },
});

export const { setCredentials, clearCredentials, setRole, setSchools, setDistrict, setTags, setSubscriptions } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState): User | object | null => state.auth.user;
export const selectToken = (state: RootState): string | null => state.auth.jwt;
export const selectAuth = (state: RootState): AuthState => state.auth;
