import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { FULL_SECTIONS_QUERY, SECTIONS_DETAILS_QUERY } from '@store/queries/section';

export const sectionApiGraphql = createApi({
  reducerPath: 'sectionApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getSectionsDetails: builder.query({
      query: () => ({
        document: SECTIONS_DETAILS_QUERY,
        variables: {},
      }),
    }),

    getSections: builder.query({
      query: filters => ({
        document: FULL_SECTIONS_QUERY,
        variables: { filters },
      }),
    }),

    getSection: builder.query({
      query: ({ id, studentsPagination }) => ({
        document: FULL_SECTIONS_QUERY,
        variables: {
          filters: { id: { eq: id } },
          studentsPagination,
        },
      }),
    }),
  }),
});

export const { useGetSectionsQuery, useGetSectionQuery, useGetSectionsDetailsQuery } = sectionApiGraphql;
