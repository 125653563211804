import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 59 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="prefix__a" maskUnits="userSpaceOnUse" x="21" y="8.5" width="17" height="10" fill="#000">
          <path fill="#fff" d="M21 8.5h17v10H21z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M22 9.5a7.5 7.5 0 0015 0H22z" />
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M22 9.5a7.5 7.5 0 0015 0H22z" fill="#fff" />
        <path
          d="M22 9.5v-1h-1v1h1zm15 0h1v-1h-1v1zM29.5 16A6.5 6.5 0 0123 9.5h-2a8.5 8.5 0 008.5 8.5v-2zM36 9.5a6.5 6.5 0 01-6.5 6.5v2A8.5 8.5 0 0038 9.5h-2zm1-1H22v2h15v-2z"
          fill="#5A418B"
          mask="url(#prefix__a)"
        />
        <path
          d="M15.5 9.5v.5h3v-.5a9 9 0 10-18 0v.5h3v-.5a6 6 0 1112 0zm40 0v.5h3v-.5a9 9 0 10-18 0v.5h3v-.5a6 6 0 0112 0z"
          fill="#fff"
          stroke="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
