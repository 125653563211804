import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { FULL_LESSON_QUERY } from '@store/queries/lesson';

export const lessonApiGraphql = createApi({
  reducerPath: 'lessonApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getLesson: builder.query({
      query: id => ({
        document: FULL_LESSON_QUERY,
        variables: { id },
      }),
    }),
  }),
});

export const { useGetLessonQuery } = lessonApiGraphql;
