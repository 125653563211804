import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { ModuleId } from '@lib/robo/types';
import { LinetrackerObstacleEventType } from '@lib/robo/modules/linetracker';

import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';
import { ObstacleCloseIcon, ObstacleFarIcon } from '@webapp/components/icons';
import ColoredDot from '@webapp/components/ui/colored-dot';

import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';

const ObstacleTriggerWidget: ExecutableTriggerWidgetComponent<ObstacleTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();
  const { model: roboModel } = useRobo();

  const widget = getWidgetById<ObstacleTriggerWidgetData>(id);
  const widgetData = widget?.data;

  const [eventType, setEventType] = useState<LinetrackerObstacleEventType>(
    widgetData?.eventType || ObstacleTriggerWidget.initialData.eventType
  );

  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const LINETRACKER = roboModel?.modules.linetrackers[moduleId];

  const activeEvents = LINETRACKER?.getDataState().sensorData.activeEvents;

  /**
   * Starts a batch sensors check for the linetracker when the widget is mounted and stops it when the widget is unmounted.
   */
  useEffect(() => {
    if (!roboModel) return;

    const isCheckRunning = roboModel.isRunningBatchSensorsCheck();

    if (LINETRACKER) {
      if (!isCheckRunning) {
        roboModel.startBatchSensorsCheck([LINETRACKER.id as ModuleId]);
      }
    } else {
      if (isCheckRunning) {
        roboModel.stopBatchSensorsCheck();
      }
    }

    return () => {
      if (roboModel.isRunningBatchSensorsCheck()) {
        roboModel.stopBatchSensorsCheck();
      }
    };
  }, [LINETRACKER, roboModel]);

  /**
   * Handles the event type change
   * @param newEventType - The new event type
   */
  const handleEventTypeChange = (newEventType: LinetrackerObstacleEventType) => {
    setEventType(newEventType);
    updateWidgetData(id, { eventType: newEventType });
  };

  return (
    <Box sx={{ width: '300px', height: '185px', display: 'flex' }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={6}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          {LINETRACKER && (
            <ColoredDot color={activeEvents?.[LinetrackerObstacleEventType.Close] ? '#DF1642' : '#D4D4D4'} />
          )}

          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<ObstacleCloseIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === LinetrackerObstacleEventType.Close}
            value={eventType === LinetrackerObstacleEventType.Close}
            onChange={() => handleEventTypeChange(LinetrackerObstacleEventType.Close)}
            sx={{ mt: 1 }}
            text={
              <Typography variant="x-xsmall-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                Near
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {LINETRACKER && (
            <ColoredDot color={activeEvents?.[LinetrackerObstacleEventType.Far] ? '#DF1642' : '#D4D4D4'} />
          )}

          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<ObstacleFarIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === LinetrackerObstacleEventType.Far}
            value={eventType === LinetrackerObstacleEventType.Far}
            onChange={() => handleEventTypeChange(LinetrackerObstacleEventType.Far)}
            sx={{ mt: 1 }}
            text={
              <Typography variant="x-xsmall-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                Far
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Execute the motion trigger widget
 * @param roboModel - The robo model
 * @param widgetId - The widget id
 * @param getWidgetById - The get widget by id function
 * @param signal - The signal
 * @returns The execution result
 */
ObstacleTriggerWidget.execute = async ({ roboModel, widgetId, getWidgetById, signal }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;
      const linetrackerModuleId = data?.moduleIds[0];
      const LINETRACKER = roboModel.modules.linetrackers[linetrackerModuleId as ModuleId];

      if (!LINETRACKER) {
        reject(new Error('Linetracker module not found'));
        return;
      }

      LINETRACKER.createTrigger(data.eventType, ({ isError }) => {
        if (isError) {
          reject(new Error('Error with obstacle/linetracker trigger'));
        } else {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
          });
        }
      });
    },
    `ObstacleTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

/**
 * The motion trigger widget data
 */
export type ObstacleTriggerWidgetData = {
  eventType: LinetrackerObstacleEventType;
};

/**
 * The initial data for the motion trigger widget
 */
ObstacleTriggerWidget.initialData = {
  eventType: LinetrackerObstacleEventType.Close,
};

export default ObstacleTriggerWidget;
