import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_RESOURCES_OVERVIEW = gql`
  fragment RESOURCES_OVERVIEW on ResourceRelationResponseCollection {
    data {
      id
      __typename
      attributes {
        name

        preview {
          ...FILE
        }
      }
    }
  }
`;
