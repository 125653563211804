import { createApi } from '@reduxjs/toolkit/query/react';

import protectedQuery from '@store/utils/protected-query.graphql';

import {
  EditorProjectEntityResponse,
  EditorProjectEntityResponseCollection,
  Maybe,
  MutationCreateEditorProjectArgs,
  MutationDeleteEditorProjectArgs,
  MutationUpdateEditorProjectArgs,
  QueryEditorProjectArgs,
  QueryEditorProjectsArgs,
  UserEditorProjectsCountResponse,
} from '@store/gql/graphql';
import {
  COUNT_USER_EDITOR_PROJECT_QUERY,
  CREATE_EDITOR_PROJECT_MUTATION,
  DELETE_EDITOR_PROJECT_MUTATION,
  FULL_EDITOR_PROJECT_QUERY,
  SEARCH_EDITOR_PROJECTS_QUERY,
  UPDATE_EDITOR_PROJECT_MUTATION,
} from '@store/queries/editor-project';

export const editorProjectApiGraphql = createApi({
  reducerPath: 'editorProjectApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    createEditorProject: builder.mutation<
      {
        createEditorProject?: Maybe<EditorProjectEntityResponse>;
      },
      MutationCreateEditorProjectArgs
    >({
      query: variables => ({
        document: CREATE_EDITOR_PROJECT_MUTATION,
        variables: variables,
      }),
    }),

    deleteEditorProject: builder.mutation<
      {
        deleteEditorProject?: Maybe<EditorProjectEntityResponse>;
      },
      MutationDeleteEditorProjectArgs
    >({
      query: variables => ({
        document: DELETE_EDITOR_PROJECT_MUTATION,
        variables: variables,
      }),
    }),

    updateEditorProject: builder.mutation<
      {
        updateEditorProject?: Maybe<EditorProjectEntityResponse>;
      },
      MutationUpdateEditorProjectArgs
    >({
      query: variables => ({
        document: UPDATE_EDITOR_PROJECT_MUTATION,
        variables: variables,
      }),
    }),

    getEditorProject: builder.query<{ editorProject?: Maybe<EditorProjectEntityResponse> }, QueryEditorProjectArgs>({
      query: variables => ({
        document: FULL_EDITOR_PROJECT_QUERY,
        variables: variables,
      }),
    }),

    searchEditorProject: builder.query<
      { editorProjects?: Maybe<EditorProjectEntityResponseCollection> },
      QueryEditorProjectsArgs
    >({
      query: variables => ({
        document: SEARCH_EDITOR_PROJECTS_QUERY,
        variables: variables,
      }),
    }),

    userEditorProjectsCount: builder.query<{ userEditorProjectsCount?: Maybe<UserEditorProjectsCountResponse> }, void>({
      query: variables => ({
        document: COUNT_USER_EDITOR_PROJECT_QUERY,
        variables: variables,
      }),
    }),
  }),
});

export const {
  useGetEditorProjectQuery,
  useSearchEditorProjectQuery,
  useDeleteEditorProjectMutation,
  useUpdateEditorProjectMutation,
  useLazyGetEditorProjectQuery,
  useLazySearchEditorProjectQuery,
  useCreateEditorProjectMutation,
  useUserEditorProjectsCountQuery,
  useLazyUserEditorProjectsCountQuery,
} = editorProjectApiGraphql;
