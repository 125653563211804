import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.7 11.1999L12.7 6.1999C12.3 5.7999 11.7 5.7999 11.3 6.1999C10.9 6.5999 10.9 7.1999 11.3 7.5999L14.6 10.8999H7C6.4 10.8999 6 11.2999 6 11.8999C6 12.4999 6.4 12.8999 7 12.8999H14.6L11.3 16.1999C10.9 16.5999 10.9 17.1999 11.3 17.5999C11.7 17.9999 12.3 17.9999 12.7 17.5999L17.7 12.5999C18.1 12.2999 18.1 11.5999 17.7 11.1999Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
