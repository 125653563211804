import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SettingsButton = styled(Box)(() => ({
  position: 'absolute',
  right: '10px',
  bottom: '10px',
  width: '24px',
  height: '24px',
  zIndex: 1000,
}));

export default SettingsButton;
