import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorType, ElementType } from '@webapp/store/types';

type HistoryEntryDataTypes = 'RoboElement' | 'HtmlElement';

type HistoryEntryDataRoboElement = {
  dataType: 'RoboElement';
  elementType: ElementType;
  property?: string;
  value?: string;
};

type HistoryEntryDataHtmlElement = {
  dataType: 'HtmlElement';
  classNames: string;
  dataAttributes: { [key: string]: string };
};

type HistoryEntryDataEmpty = {
  dataType: 'Empty';
};

export type HistoryEntry = {
  id: string;
  timestamp: number;
  action: string;
  scope: EditorType | 'common';
  data?: HistoryEntryDataRoboElement | HistoryEntryDataHtmlElement | HistoryEntryDataEmpty;
};

export type HistoryEntryPayload = Pick<HistoryEntry, 'action' | 'scope' | 'data'>;

type HistoryState = {
  entries: HistoryEntry[];
};

const initialState: HistoryState = {
  entries: [],
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addEntry: (state, action: PayloadAction<HistoryEntryPayload>) => {
      const entry = action.payload;
      state.entries.push({
        id: state.entries.length.toString(),
        timestamp: Date.now(),
        ...entry,
      });
    },
  },
});

export const { addEntry } = historySlice.actions;
export default historySlice.reducer;
