import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11" cy="11" r="5" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 4C12.1046 4 13 3.10457 13 2C13 0.895431 12.1046 0 11 0C9.89543 0 9 0.895431 9 2C9 3.10457 9.89543 4 11 4ZM15.9497 6.05025C16.7308 6.8313 17.9971 6.8313 18.7782 6.05025C19.5592 5.2692 19.5592 4.00287 18.7782 3.22183C17.9971 2.44078 16.7308 2.44078 15.9497 3.22183C15.1687 4.00287 15.1687 5.2692 15.9497 6.05025ZM20 13C18.8954 13 18 12.1046 18 11C18 9.89543 18.8954 9 20 9C21.1046 9 22 9.89543 22 11C22 12.1046 21.1046 13 20 13ZM15.9497 15.9497C15.1687 16.7308 15.1687 17.9971 15.9497 18.7782C16.7308 19.5592 17.9971 19.5592 18.7782 18.7782C19.5592 17.9971 19.5592 16.7308 18.7782 15.9497C17.9971 15.1687 16.7308 15.1687 15.9497 15.9497ZM9 20C9 18.8954 9.89543 18 11 18C12.1046 18 13 18.8954 13 20C13 21.1046 12.1046 22 11 22C9.89543 22 9 21.1046 9 20ZM6.05025 15.9497C5.2692 15.1687 4.00287 15.1687 3.22183 15.9497C2.44078 16.7308 2.44078 17.9971 3.22183 18.7782C4.00287 19.5592 5.2692 19.5592 6.05025 18.7782C6.8313 17.9971 6.8313 16.7308 6.05025 15.9497ZM2 9C3.10457 9 4 9.89543 4 11C4 12.1046 3.10457 13 2 13C0.895431 13 0 12.1046 0 11C0 9.89543 0.895431 9 2 9ZM6.05025 6.05025C6.8313 5.2692 6.8313 4.00287 6.05025 3.22183C5.2692 2.44078 4.00287 2.44078 3.22183 3.22183C2.44078 4.00287 2.44078 5.2692 3.22183 6.05025C4.00287 6.8313 5.2692 6.8313 6.05025 6.05025Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
