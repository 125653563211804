import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#034FCC';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 95 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="95" height="96" rx="14" fill="#034FCC" />
        <circle cx="47" cy="48" r="14" fill="white" />
        <circle cx="69.5" cy="39.5" r="5.5" transform="rotate(90 69.5 39.5)" fill="#013EA3" />
        <circle cx="24.5" cy="39.5" r="5.5" transform="rotate(90 24.5 39.5)" fill="#013EA3" />
        <circle cx="69.5" cy="56.5" r="5.5" transform="rotate(90 69.5 56.5)" fill="#013EA3" />
        <circle cx="24.5" cy="56.5" r="5.5" transform="rotate(90 24.5 56.5)" fill="#013EA3" />
        <circle cx="38.5" cy="25.5" r="5.5" fill="#013EA3" />
        <circle cx="38.5" cy="70.5" r="5.5" fill="#013EA3" />
        <circle cx="55.5" cy="25.5" r="5.5" fill="#013EA3" />
        <circle cx="55.5" cy="70.5" r="5.5" fill="#013EA3" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
