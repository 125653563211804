import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <rect opacity="0.3" x="20" y="19" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="20" y="24.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="20"
          y="30.3335"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="25.667" y="19" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="20" y="19" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="25.667" y="24.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="25.667"
          y="30.3335"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect
          opacity="0.3"
          x="31.333"
          y="19"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect
          opacity="0.3"
          x="31.333"
          y="24.6665"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="31.333" y="30.3335" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="37" y="19" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="37"
          y="24.6665"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="37" y="30.3335" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="42.667" y="19" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="42.667" y="24.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="42.667"
          y="30.3335"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="48.333" y="19" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="48.333" y="24.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="48.333"
          y="30.3335"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="37" y="36" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="37"
          y="41.6665"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect
          opacity="0.3"
          x="37"
          y="47.3335"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect
          opacity="0.3"
          x="42.667"
          y="36"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="42.667" y="41.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="42.667" y="47.3335" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="48.333"
          y="36"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="48.333" y="41.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="48.333" y="47.3335" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="20" y="36" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="20" y="41.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="20" y="47.3335" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="25.667"
          y="36"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect x="25.667" y="41.6665" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="25.667" y="47.3335" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect x="31.333" y="36" width="5.66667" height="5.66667" rx="2.83333" fill="white" stroke="#5A418B" />
        <rect
          opacity="0.3"
          x="31.333"
          y="41.6665"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
        <rect
          opacity="0.3"
          x="31.333"
          y="47.3335"
          width="5.66667"
          height="5.66667"
          rx="2.83333"
          fill="white"
          stroke="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
