import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="38" y="18" width="4" height="40" rx="2" fill="white" />
        <rect x="60" y="36" width="4" height="40" rx="2" transform="rotate(90 60 36)" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40 13L48 26H32L40 13Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40 63L48 50H32L40 63Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 38L28 46V30L15 38Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M65 38L52 30V46L65 38Z" fill="white" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
