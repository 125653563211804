/**
 * Normalizes an angle to a value between 0 and 360 degrees.
 *
 * This function takes any angle as input and returns its equivalent
 * angle within the range [0, 360). It handles positive and negative
 * angles, as well as angles greater than 360 degrees.
 *
 * Special cases:
 * - For exact multiples of 360 (except 0), it returns 359.999 for positive
 *   angles and 0.001 for negative angles to avoid ambiguity with full rotations.
 *
 * @param angle - The input angle in degrees to be normalized.
 * @returns The normalized angle between 0 and 360 degrees.
 */
export function normalizeAngle(angle: number): number {
  let normalizedAngle = angle % 360;

  // If it's a full rotation (positive or negative), return slightly more than 0 or less than 360
  if (normalizedAngle === 0 && angle !== 0) {
    normalizedAngle = angle > 0 ? 359.999 : 0.001;
  }

  // todo: check if this is correct
  // For negative angles, convert to positive
  if (normalizedAngle < 0) {
    normalizedAngle += 360;
  }

  return normalizedAngle;
}

/**
 * Calculates the sweep angle between two angles.
 *
 * @param startAngle - The start angle in degrees.
 * @param endAngle - The end angle in degrees.
 * @param direction - The direction of the sweep.
 * @returns The sweep angle between the two angles.
 */
export function calculateSweepAngle(
  startAngle: number,
  endAngle: number,
  direction: 'clockwise' | 'counterclockwise'
): number {
  const visualDistance = visualAnglesDistance(startAngle, endAngle, direction);

  // Adjust sweep angle for full rotations
  const fullRotations = Math.floor(Math.abs(endAngle - startAngle) / 360);
  const sweepAngle = visualDistance + fullRotations * 360;

  return sweepAngle;
}

/**
 * Calculates the visible angle difference between two angles.
 *
 * @param startAngle - The start angle in degrees.
 * @param endAngle - The end angle in degrees.
 * @param direction - The direction of the sweep.
 * @returns The visible angle difference between the two angles.
 */
export function visualAnglesDistance(
  startAngle: number,
  endAngle: number,
  direction: 'clockwise' | 'counterclockwise'
): number {
  const normalizedStart = normalizeAngle(startAngle);
  const normalizedEnd = normalizeAngle(endAngle);

  if (direction === 'clockwise') {
    return normalizedEnd >= normalizedStart ? normalizedEnd - normalizedStart : 360 - (normalizedStart - normalizedEnd);
  } else {
    return normalizedStart >= normalizedEnd ? normalizedStart - normalizedEnd : 360 - (normalizedEnd - normalizedStart);
  }
}

/**
 * Calculates the difference between two angles in degrees.
 * Returns a positive value if the second angle is greater than the first angle.
 *
 * @param firstAngle - The first angle in degrees.
 * @param secondAnfle - The second angle in degrees.
 * @returns The difference between the two angles in degrees.
 */
export function anglesDifference(firstAngle: number, secondAngle: number): number {
  return secondAngle - firstAngle;
}

/**
 * Calculates the radius of a spiral.
 *
 * @param startRadius - The start radius.
 * @param revolution - The number of revolutions.
 * @param expansionFactor - The expansion factor.
 * @returns The radius of the spiral.
 */
export function calculateSpiralRadius(startRadius: number, revolution: number, expansionFactor: number): number {
  return startRadius * (1 + revolution * (expansionFactor - 1));
}

/**
 * Calculates the angle of a spiral.
 *
 * @param normalizedValue - The normalized value.
 * @param startAngle - The start angle.
 * @param revolutions - The number of revolutions.
 * @returns The angle of the spiral.
 */
export function calculateSpiralAngle(normalizedValue: number, startAngle: number, revolutions: number): number {
  return startAngle + normalizedValue * revolutions * 360;
}

/**
 * Calculates the value of a spiral.
 *
 * @param angle - The angle.
 * @param startAngle - The start angle.
 * @param revolutions - The number of revolutions.
 * @returns The value of the spiral.
 */
export function calculateSpiralValue(angle: number, startAngle: number, revolutions: number): number {
  return (angle - startAngle) / (revolutions * 360);
}

/**
 * Solves a quadratic equation of the form ax^2 + bx + c = 0.
 *
 * @param a - The coefficient of x^2.
 * @param b - The coefficient of x.
 * @param c - The constant term.
 * @returns An array containing the solutions of the equation.
 * @throws {Error} If the equation has no solution or is not solvable.
 */
export function solveQuadraticEquation(a: number, b: number, c: number): number[] {
  if (a === 0) {
    if (b === 0) {
      if (c === 0) {
        return [0]; // Infinite solutions (0x = 0)
      }
      throw new Error('No solution exists');
    }
    // Linear equation (bx + c = 0)
    return [-c / b];
  }

  const discriminant = b * b - 4 * a * c;
  if (discriminant < 0) {
    throw new Error('No real solutions exist');
  }

  const sqrtDiscriminant = Math.sqrt(discriminant);
  const x1 = (-b + sqrtDiscriminant) / (2 * a);
  const x2 = (-b - sqrtDiscriminant) / (2 * a);

  return discriminant === 0 ? [x1] : [x1, x2];
}
