import { useState, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';

import { Box, Grid, Typography } from '@mui/material';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { adjustColorBrightness, hexToRgb } from '@lib/utils/color-utils';

import RandomButton from '@webapp/components/ui/buttons/random-button';
import ColorButton from '@webapp/components/ui/buttons/color-button';

import { BrightnessIcon, TimeIcon } from '@webapp/components/icons';

import Slider from '@webapp/components/ui/sliders/slider';
import IconWithText from '@webapp/components/ui/icon-with-text';
import ColorWheel from '@webapp/components/ui/color/color-wheel';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';
import { ModuleId } from '@lib/robo/types';

const widgetConfig = {
  brightness: {
    min: 0,
    max: 10,
    step: 1,
  },

  duration: {
    min: 1,
    max: 11,
    step: 1,
    maxAsInfinite: true,
  },
};

/**
 * The Shine Action Widget
 * @param id The widget id
 * @returns The shine widget
 */
const ShineWidget: ExecutableActionWidgetComponent<ShineWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<ShineWidgetData>(id);

  const widgetData = widget?.data;

  const { model: roboModel } = useRobo();
  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const LEDRGB = roboModel?.modules.ledRGBs[moduleId];

  const [color, setColor] = useState<string>(widget?.data.color ?? ShineWidget.initialData.color);
  const [isColorRandom, setIsColorRandom] = useState<boolean>(
    widget?.data.isColorRandom ?? ShineWidget.initialData.isColorRandom
  );
  const [brightness, setBrightness] = useState<number>(widget?.data.brightness ?? ShineWidget.initialData.brightness);

  const [duration, setDuration] = useState<number>(widget?.data.duration ?? ShineWidget.initialData.duration);
  const [isDurationInfinite, setIsDurationInfinite] = useState<boolean>(
    widget?.data.isDurationInfinite ?? ShineWidget.initialData.isDurationInfinite
  );

  /**
   * Handle the random toggle
   */
  const handleRandomToggle = () => {
    const newIsColorRandom = !isColorRandom;
    setIsColorRandom(newIsColorRandom);
    updateWidgetData<ShineWidgetData>(id, { isColorRandom: newIsColorRandom });
  };

  /**
   * Handles the brightness change
   * @param newBrightness The new brightness
   */
  const handleBrightnessChange = (newBrightness: number) => {
    setBrightness(newBrightness);
  };

  /**
   * Handle the brightness changed event. Updates the widget data
   */
  const onBrightnessChanged = (newBrightness: number) => {
    updateWidgetData<ShineWidgetData>(id, { brightness: newBrightness });
  };

  /**
   * Handles the duration change
   * @param newDuration The new duration
   */
  const handleDurationChange = (newDuration: number) => {
    if (newDuration >= widgetConfig.duration.max && widgetConfig.duration.maxAsInfinite) {
      setDuration(newDuration);
      setIsDurationInfinite(true);
    } else {
      setDuration(newDuration);
      setIsDurationInfinite(false);
    }
  };

  /**
   * Handle the duration changed event. Updates the widget data
   */
  const onDurationChanged = (newDuration: number) => {
    updateWidgetData<ShineWidgetData>(id, { duration: newDuration, isDurationInfinite });
  };

  /**
   * Handles the color change
   * @param newColor The new color
   */
  const handleColorChange = (newColor: string) => {
    setColor(newColor);
  };

  /**
   * Handle the color changed event. Updates the widget data
   */
  const onColorChanged = (newColor: string) => {
    updateWidgetData<ShineWidgetData>(id, { color: newColor });
  };

  /**
   * The view color - the color that is displayed on the screen
   */
  const viewColor = useMemo(() => {
    return adjustColorBrightness(color, brightness * 10);
  }, [color, brightness]);

  /**
   * The debounced set color function
   */
  const debouncedSetColor = useMemo(
    () =>
      debounce((color: string) => {
        if (LEDRGB) {
          const colorRgb = hexToRgb(color);
          LEDRGB.setColor(colorRgb.red, colorRgb.green, colorRgb.blue);
          LEDRGB.setActive(true);
          LEDRGB.execute();
        }
      }, 200),
    [LEDRGB]
  );

  /**
   * Display the color and brightness changes on the LEDRGB
   */
  useEffect(() => {
    if (LEDRGB) {
      debouncedSetColor(viewColor);
    }
  }, [LEDRGB, viewColor, debouncedSetColor]);

  /**
   * Deactivate the LEDRGB when the component unmounts
   */
  useEffect(() => {
    return () => {
      if (LEDRGB) {
        debouncedSetColor.cancel();
        LEDRGB.setActive(false);
        LEDRGB.execute();
      }
    };
  }, [LEDRGB, debouncedSetColor]);

  return (
    <Box sx={{ width: '300px', height: '290px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0}>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <RandomButton
            selected={isColorRandom}
            value={isColorRandom}
            onChange={handleRandomToggle}
            mainColor="#FEC84B"
            secondaryColor="#E9E9E9"
          >
            Random
          </RandomButton>

          {!isColorRandom && (
            <ColorButton mainColor={viewColor} sx={{ ml: 1 }}>
              Color
            </ColorButton>
          )}
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center  ', alignItems: 'center' }}>
          <IconWithText
            icon={<BrightnessIcon sx={{ fontSize: '2rem' }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Brightness
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconWithText
            icon={<TimeIcon sx={{ fontSize: '2rem' }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Time
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={8}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '218px',
          }}
        >
          <ColorWheel
            color={color}
            onChange={handleColorChange}
            onChangeCommitted={onColorChanged}
            disabled={isColorRandom}
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
          <Slider
            value={brightness}
            valueLabelDisplay="on"
            max={widgetConfig.brightness.max}
            min={widgetConfig.brightness.min}
            step={widgetConfig.brightness.step}
            onChange={(_, newBrightness) => handleBrightnessChange(newBrightness as number)}
            onChangeCommitted={(_, newBrightness) => onBrightnessChanged(newBrightness as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="default"
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
          <Slider
            value={duration}
            valueLabelDisplay="on"
            max={widgetConfig.duration.max}
            min={widgetConfig.duration.min}
            step={widgetConfig.duration.step}
            maxAsInfinite={widgetConfig.duration.maxAsInfinite}
            onChange={(_, newDuration) => handleDurationChange(newDuration as number)}
            onChangeCommitted={(_, newDuration) => onDurationChanged(newDuration as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="default"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Executes the motor widget
 * @param signal The abort signal
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @param updateWidgetData The updateWidgetData function
 * @returns The execution result
 */
ShineWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById, updateWidgetData }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData?.moduleIds[0] as ModuleId;

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    const LED = roboModel.modules.ledRGBs[moduleId];

    if (!LED) {
      throw new Error('LED not found');
    }

    const duration = widgetData.duration * 1000;
    const color = adjustColorBrightness(widgetData.color, widgetData.brightness * 10);
    const colorRgb = hexToRgb(color);

    LED.setLedAction(colorRgb.red, colorRgb.green, colorRgb.blue, duration, 0, ({ isError }) => {
      if (isError) {
        reject(new Error('Error setting shine action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    });
  });
};

// Interface for widget data
export interface ShineWidgetData {
  color: string;
  isColorRandom: boolean;
  brightness: number;
  duration: number;
  isDurationInfinite: boolean;
}

ShineWidget.initialData = {
  color: '#ffffff',
  isColorRandom: false,
  brightness: 10,
  duration: 1, // in seconds
  isDurationInfinite: false,
};

export default ShineWidget;
