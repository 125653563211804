import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="38" y="15" width="4" height="30" rx="2" fill="white" />
        <rect x="60" y="53" width="4" height="40" rx="2" transform="rotate(90 60 53)" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M40 50L48 37H32L40 50Z" fill="white" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
