import { Slider as MuiSlider, SliderProps as MuiSliderProps, styled } from '@mui/material';

interface CustomSliderProps {
  mainColor?: string;
  railColor?: string;
  labelColor?: string;
  maxAsInfinite?: boolean;
  minAsInfinite?: boolean;
  appearance?: SliderAppearance;
}

// Combine MUI SliderProps with CustomSliderProps
type SliderProps = MuiSliderProps & CustomSliderProps;

const StyledSlider = styled(MuiSlider, {
  shouldForwardProp: prop => prop !== 'custom',
})<{ custom: CustomSliderProps } & MuiSliderProps>(({ theme, custom, ...props }) => {
  const baseStyles = {
    color: props.color || custom.mainColor,

    '&.MuiSlider-vertical': {
      padding: '0 1px',
      background: undefined,
    },

    '& .MuiSlider-rail, .MuiSlider-track': {
      height: '6px',
      width: '100%',
      opacity: 1,
    },

    '& .MuiSlider-rail': {
      color: custom.railColor,
    },

    '& .MuiSlider-track': {
      border: 'none',
    },

    '&.MuiSlider-vertical .MuiSlider-rail, &.MuiSlider-vertical .MuiSlider-track': {
      height: '100%',
      width: '6px',
    },

    '& .MuiSlider-thumb': {
      width: 30,
      height: 30,
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      fontSize: '12px',
      fontWeight: 'bold',
    },

    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 700,
      top: '30px',

      width: '30px',
      height: '30px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'unset',
      position: 'absolute',

      '&::before': {
        display: 'none',
      },

      '& *': {
        background: 'transparent',
        color: custom.labelColor,
      },
    },

    '&.MuiSlider-vertical .MuiSlider-valueLabel': {
      top: '15px',
      transform: 'translate(100%, -50%)',
    },
  };

  if (custom.appearance === 'rainbow') {
    Object.assign(baseStyles, {
      padding: '1px 0',
      background: custom.railColor,

      '&.MuiSlider-vertical': {
        padding: '0 1px',
        background: custom.railColor,
      },

      '& .MuiSlider-rail': {
        background: 'linear-gradient(to right, green, yellow, red)',
        color: 'transparent',
        clipPath: `inset(0 ${100 - ((Number(props.value) ?? 0) / (Number(props.max) ?? 100)) * 100}% 0 0)`,
      },

      '&.MuiSlider-vertical .MuiSlider-rail': {
        background: 'linear-gradient(to top, green, yellow, red)',
        clipPath: `inset(${100 - ((Number(props.value) ?? 0) / (Number(props.max) ?? 100)) * 100}% 0 0 0)`,
      },

      '& .MuiSlider-track, &.MuiSlider-vertical .MuiSlider-track': {
        background: 'transparent',
      },
    });
  } else if (custom.appearance === 'middle') {
    const value = Number(props.value) ?? 0;
    const max = Number(props.max) ?? 100;
    const min = Number(props.min) ?? -100;
    const middle = (max + min) / 2;

    const trackStyle = {
      '& .MuiSlider-track': {
        background: custom.mainColor,
        transition: 'none !important',
      },
    };

    if (props.orientation === 'vertical') {
      if (value > middle) {
        Object.assign(trackStyle['& .MuiSlider-track'], {
          bottom: '50% !important',
          top: 'unset !important',
          height: `${((value - middle) / (max - middle)) * 50}% !important`,
        });
      } else if (value < middle) {
        Object.assign(trackStyle['& .MuiSlider-track'], {
          bottom: 'unset !important',
          top: '50% !important',
          height: `${((middle - value) / (middle - min)) * 50}% !important`,
        });
      } else {
        Object.assign(trackStyle['& .MuiSlider-track'], {
          display: 'none',
        });
      }
    } else {
      if (value > middle) {
        Object.assign(trackStyle['& .MuiSlider-track'], {
          left: '50% !important',
          right: 'unset !important',
          width: `${((value - middle) / (max - middle)) * 50}% !important`,
        });
      } else if (value < middle) {
        Object.assign(trackStyle['& .MuiSlider-track'], {
          left: 'unset !important',
          right: '50% !important',
          width: `${((middle - value) / (middle - min)) * 50}% !important`,
        });
      } else {
        Object.assign(trackStyle['& .MuiSlider-track'], {
          display: 'none',
        });
      }
    }

    Object.assign(baseStyles, trackStyle);
  }

  return baseStyles;
});

const formatValueLabel = (value: number, maxAsInfinite: boolean, max: number, minAsInfinite: boolean, min: number) => {
  if (maxAsInfinite && value === max) {
    return '∞';
  }
  if (minAsInfinite && value === min) {
    return '-∞';
  }
  return value;
};

export type SliderAppearance = 'default' | 'rainbow' | 'middle';

/**
 * Slider component that wraps the StyledSlider with additional props for customization.
 * Props include a props object containing mainColor, railColor, labelColor.
 */
const Slider: React.FunctionComponent<SliderProps> = ({
  mainColor = '#DF1642',
  railColor = '#E9E9E9',
  labelColor = '#5A418B',
  maxAsInfinite = false,
  minAsInfinite = false,
  appearance = 'default',
  ...props
}) => {
  const customProps = { mainColor, railColor, labelColor, appearance };

  return (
    <StyledSlider
      custom={customProps}
      {...props}
      valueLabelFormat={value =>
        formatValueLabel(value, maxAsInfinite, props.max ?? 100, minAsInfinite, props.min ?? -100)
      }
    />
  );
};

export default Slider;
