import { initMqttClient } from '@webapp/store/middlewares/devices.middleware';

const TEST_USERNAME = 'test';
const TEST_PASSWORD = 'password';

export async function isAllDNSResolverURLsReachable(): Promise<boolean> {
  try {
    const response = await (
      await fetch('https://devices.robowunderkind.com/health', {
        headers: {
          // check if authorization header could pass to server
          Authorization: `Basic ${btoa(`${TEST_USERNAME}:${TEST_PASSWORD}`)}`,
        },
      })
    ).json();
    return response.status === 'ok' && response.username === TEST_USERNAME;
  } catch (error) {
    return false;
  }
}

// check if local DNS are propagated
export async function checkIfDomainNameIsPropagetedToGlobalDNSServer(bridgeName: string): Promise<boolean> {
  const domain = getBridgeDomain(bridgeName);
  const deviceIP = await resolveDomain(domain);
  const devicesHubIP = await resolveDomain('devices.robowunderkind.com');

  // if IPs are the same, it means that the domain is not set
  if (devicesHubIP && deviceIP && deviceIP !== devicesHubIP) {
    return true;
  }

  return false;
}

export async function canConnectToBridge(bridgeName: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      console.log('Lan Routing not reachable by timeout');
      reject(new Error('Lan Routing not reachable by timeout'));
    }, 10000);

    initMqttClient(bridgeName)
      .then(mqttClient => {
        mqttClient.onConnected(() => {
          mqttClient.disconnect();
          clearTimeout(timeoutId);
          resolve(true);
        });
        mqttClient.connect();
      })
      .catch(error => {
        console.log('Lan Routing not reachable', error);
        reject(new Error('Lan Routing not reachable by error ' + error));
        clearTimeout(timeoutId);
      });
  });
}

export async function resolveDomain(domain: string): Promise<string | null> {
  const answer = (await (await fetch(`https://dns.google/resolve?name=${domain}`)).json()).Answer;
  return answer?.[0]?.data ?? null;
}

export const getBridgeDomain = (bridgeName: string) => `${bridgeName}.devices.robowunderkind.com`;
