import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <mask
          id="path-2-outside-1_1862_1602"
          maskUnits="userSpaceOnUse"
          x="23.2744"
          y="17.4784"
          width="25"
          height="39"
          fill="black"
        >
          <rect fill="white" x="23.2744" y="17.4784" width="25" height="39" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.8398 31.1291C46.7104 31.1291 47.1652 30.0938 46.5763 29.4526L36.7946 18.802C36.3984 18.3705 35.7178 18.3705 35.3216 18.802L25.5399 29.4526C24.951 30.0938 25.4058 31.1291 26.2764 31.1291H30.1616C30.7139 31.1291 31.1616 31.5768 31.1616 32.1291V53.9998C31.1616 54.5521 31.6093 54.9998 32.1616 54.9998H39.71C40.2623 54.9998 40.71 54.5521 40.71 53.9998V32.1291C40.71 31.5768 41.1577 31.1291 41.71 31.1291H45.8398Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.8398 31.1291C46.7104 31.1291 47.1652 30.0938 46.5763 29.4526L36.7946 18.802C36.3984 18.3705 35.7178 18.3705 35.3216 18.802L25.5399 29.4526C24.951 30.0938 25.4058 31.1291 26.2764 31.1291H30.1616C30.7139 31.1291 31.1616 31.5768 31.1616 32.1291V53.9998C31.1616 54.5521 31.6093 54.9998 32.1616 54.9998H39.71C40.2623 54.9998 40.71 54.5521 40.71 53.9998V32.1291C40.71 31.5768 41.1577 31.1291 41.71 31.1291H45.8398Z"
          fill="white"
        />
        <path
          d="M46.5763 29.4526L45.8398 30.1291L46.5763 29.4526ZM36.7946 18.802L36.0581 19.4784L36.7946 18.802ZM35.3216 18.802L34.5851 18.1256L35.3216 18.802ZM36.0581 19.4784L45.8398 30.1291L47.3129 28.7762L37.5311 18.1256L36.0581 19.4784ZM26.2764 30.1291L36.0581 19.4784L34.5851 18.1256L24.8033 28.7762L26.2764 30.1291ZM30.1616 30.1291H26.2764V32.1291H30.1616V30.1291ZM32.1616 53.9998V32.1291H30.1616V53.9998H32.1616ZM39.71 53.9998H32.1616V55.9998H39.71V53.9998ZM39.71 32.1291V53.9998H41.71V32.1291H39.71ZM45.8398 30.1291H41.71V32.1291H45.8398V30.1291ZM41.71 32.1291H41.71V30.1291C40.6054 30.1291 39.71 31.0245 39.71 32.1291H41.71ZM39.71 55.9998C40.8145 55.9998 41.71 55.1044 41.71 53.9998H39.71V55.9998ZM30.1616 53.9998C30.1616 55.1044 31.057 55.9998 32.1616 55.9998V53.9998H32.1616H30.1616ZM30.1616 32.1291H32.1616C32.1616 31.0245 31.2662 30.1291 30.1616 30.1291V32.1291ZM24.8033 28.7762C23.6256 30.0586 24.5353 32.1291 26.2764 32.1291V30.1291L24.8033 28.7762ZM45.8398 30.1291L45.8398 30.1291V32.1291C47.581 32.1291 48.4906 30.0586 47.3129 28.7762L45.8398 30.1291ZM37.5311 18.1256C36.7387 17.2627 35.3776 17.2627 34.5851 18.1256L36.0581 19.4784L36.0581 19.4784L37.5311 18.1256Z"
          fill="#5A418B"
          mask="url(#path-2-outside-1_1862_1602)"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
