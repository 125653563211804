import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors as themeColors } from '@themes/config/theme-colors';
import { DistanceArrowIcon } from '@webapp/components/icons';
import StyledBox from '@webapp/components/blocks/component/styled-box';
import StyledSlider from '@webapp/components/blocks/component/styled-slider';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useSelector } from '@store/configureStore';
import { selectModuleById } from '@webapp/store/slices/model/model.selectors';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}));

const DistanceSensorWidget = ({ counter, data, disabled }) => {
  const { isPlaying, editorType } = useEditorContext();
  const { addHistoryEntry } = useActionsHistory();

  const moduleId = data?.moduleIds?.[0];
  const moduleState = useSelector(state => selectModuleById(state, 'ultrasonics', moduleId));
  const distance = isPlaying ? moduleState?.sensorData?.distance ?? 0 : 0;

  useEffect(() => {
    if (isPlaying && distance !== 0) {
      addHistoryEntry({
        action: 'interact:widget:distance-sensor',
        scope: editorType,
      });
    }
  }, [isPlaying, distance, editorType]);

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#E31871'}>
      {!!counter && <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography>}
      <DistanceArrowIcon sx={{ width: '38px', height: '14px', marginBottom: '15px' }} />
      <StyledSlider orientation="vertical" min={0} max={255} value={distance} disabled={isPlaying} />
      <Typography variant="x-body-semibold">{`${distance} cm`} </Typography>
    </StyledBox>
  );
};

DistanceSensorWidget.initialProps = {
  width: 1,
  height: 3,
};

DistanceSensorWidget.initialData = {};

export default DistanceSensorWidget;
