import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#034FCC';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5 13.5C19.5 12.6716 20.1716 12 21 12C21.8284 12 22.5 12.6716 22.5 13.5V13.9054C23.3895 14.0925 24.2189 14.4427 24.9556 14.9231L25.2426 14.6361C25.8284 14.0503 26.7782 14.0503 27.3639 14.6361C27.9497 15.2219 27.9497 16.1716 27.3639 16.7574L27.0769 17.0445C27.5574 17.7811 27.9075 18.6105 28.0946 19.5H28.5C29.3284 19.5 30 20.1716 30 21C30 21.8284 29.3284 22.5 28.5 22.5H28.0946C27.9075 23.3895 27.5573 24.219 27.0768 24.9557L27.3639 25.2428C27.9497 25.8286 27.9497 26.7783 27.3639 27.3641C26.7782 27.9499 25.8284 27.9499 25.2426 27.3641L24.9554 27.0769C24.2188 27.5574 23.3894 27.9075 22.5 28.0946V28.5C22.5 29.3284 21.8284 30 21 30C20.1716 30 19.5 29.3284 19.5 28.5V28.0946C18.6105 27.9075 17.7811 27.5574 17.0444 27.0769L16.7574 27.3639C16.1716 27.9497 15.2218 27.9497 14.6361 27.3639C14.0503 26.7781 14.0503 25.8284 14.6361 25.2426L14.9231 24.9555C14.4426 24.2189 14.0925 23.3895 13.9054 22.5H13.5C12.6716 22.5 12 21.8284 12 21C12 20.1716 12.6716 19.5 13.5 19.5H13.9054C14.0925 18.6105 14.4427 17.781 14.9232 17.0443L14.6361 16.7572C14.0503 16.1714 14.0503 15.2217 14.6361 14.6359C15.2218 14.0501 16.1716 14.0501 16.7574 14.6359L17.0446 14.9231C17.7812 14.4426 18.6106 14.0925 19.5 13.9054V13.5ZM17.75 21C17.75 19.2051 19.2051 17.75 21 17.75C22.7949 17.75 24.25 19.2051 24.25 21C24.25 22.7949 22.7949 24.25 21 24.25C19.2051 24.25 17.75 22.7949 17.75 21Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
