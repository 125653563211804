import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <mask
          id="path-2-outside-1_1862_3772"
          maskUnits="userSpaceOnUse"
          x="17.3335"
          y="22"
          width="40"
          height="30"
          fill="black"
        >
          <rect fill="white" x="17.3335" y="22" width="40" height="30" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3335 23C20.5721 23 18.3335 25.2386 18.3335 28V51H50.6668C53.4283 51 55.6668 48.7614 55.6668 46V28C55.6668 25.2386 53.4283 23 50.6668 23H23.3335ZM34.6667 28.6665C34.6667 28.1142 35.1145 27.6665 35.6667 27.6665H38.3334C38.8857 27.6665 39.3334 28.1142 39.3334 28.6665V38.3332C39.3334 38.8855 38.8857 39.3332 38.3334 39.3332H35.6667C35.1145 39.3332 34.6667 38.8855 34.6667 38.3332V28.6665ZM34.6669 43.6665C34.6669 42.5619 35.5624 41.6665 36.6669 41.6665H37.3336C38.4382 41.6665 39.3336 42.5619 39.3336 43.6665V44.3332C39.3336 45.4377 38.4382 46.3332 37.3336 46.3332H36.6669C35.5624 46.3332 34.6669 45.4377 34.6669 44.3332V43.6665Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3335 23C20.5721 23 18.3335 25.2386 18.3335 28V51H50.6668C53.4283 51 55.6668 48.7614 55.6668 46V28C55.6668 25.2386 53.4283 23 50.6668 23H23.3335ZM34.6667 28.6665C34.6667 28.1142 35.1145 27.6665 35.6667 27.6665H38.3334C38.8857 27.6665 39.3334 28.1142 39.3334 28.6665V38.3332C39.3334 38.8855 38.8857 39.3332 38.3334 39.3332H35.6667C35.1145 39.3332 34.6667 38.8855 34.6667 38.3332V28.6665ZM34.6669 43.6665C34.6669 42.5619 35.5624 41.6665 36.6669 41.6665H37.3336C38.4382 41.6665 39.3336 42.5619 39.3336 43.6665V44.3332C39.3336 45.4377 38.4382 46.3332 37.3336 46.3332H36.6669C35.5624 46.3332 34.6669 45.4377 34.6669 44.3332V43.6665Z"
          fill="white"
        />
        <path
          d="M18.3335 51H17.3335V52H18.3335V51ZM19.3335 28C19.3335 25.7909 21.1244 24 23.3335 24V22C20.0198 22 17.3335 24.6863 17.3335 28H19.3335ZM19.3335 51V28H17.3335V51H19.3335ZM50.6668 50H18.3335V52H50.6668V50ZM54.6668 46C54.6668 48.2091 52.876 50 50.6668 50V52C53.9805 52 56.6668 49.3137 56.6668 46H54.6668ZM54.6668 28V46H56.6668V28H54.6668ZM50.6668 24C52.876 24 54.6668 25.7909 54.6668 28H56.6668C56.6668 24.6863 53.9805 22 50.6668 22V24ZM23.3335 24H50.6668V22H23.3335V24ZM35.6667 26.6665C34.5622 26.6665 33.6667 27.5619 33.6667 28.6665H35.6667V28.6665V26.6665ZM38.3334 26.6665H35.6667V28.6665H38.3334V26.6665ZM40.3334 28.6665C40.3334 27.5619 39.438 26.6665 38.3334 26.6665V28.6665H38.3334H40.3334ZM40.3334 38.3332V28.6665H38.3334V38.3332H40.3334ZM38.3334 40.3332C39.438 40.3332 40.3334 39.4377 40.3334 38.3332H38.3334V38.3332V40.3332ZM35.6667 40.3332H38.3334V38.3332H35.6667V40.3332ZM33.6667 38.3332C33.6667 39.4377 34.5622 40.3332 35.6667 40.3332V38.3332V38.3332H33.6667ZM33.6667 28.6665V38.3332H35.6667V28.6665H33.6667ZM36.6669 40.6665C35.0101 40.6665 33.6669 42.0097 33.6669 43.6665H35.6669C35.6669 43.1142 36.1146 42.6665 36.6669 42.6665V40.6665ZM37.3336 40.6665H36.6669V42.6665H37.3336V40.6665ZM40.3336 43.6665C40.3336 42.0097 38.9905 40.6665 37.3336 40.6665V42.6665C37.8859 42.6665 38.3336 43.1142 38.3336 43.6665H40.3336ZM40.3336 44.3332V43.6665H38.3336V44.3332H40.3336ZM37.3336 47.3332C38.9905 47.3332 40.3336 45.99 40.3336 44.3332H38.3336C38.3336 44.8855 37.8859 45.3332 37.3336 45.3332V47.3332ZM36.6669 47.3332H37.3336V45.3332H36.6669V47.3332ZM33.6669 44.3332C33.6669 45.99 35.0101 47.3332 36.6669 47.3332V45.3332C36.1146 45.3332 35.6669 44.8855 35.6669 44.3332H33.6669ZM33.6669 43.6665V44.3332H35.6669V43.6665H33.6669Z"
          fill="#5A418B"
          mask="url(#path-2-outside-1_1862_3772)"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
