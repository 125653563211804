/**
 * Converts a kebab-case string to PascalCase.
 *
 * @param {string} str - The kebab-case string to convert.
 * @returns {string} The PascalCase version of the input string.
 */
export const kebabToPascalCase = (str: string): string => {
  return str
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

/**
 * Converts a PascalCase string to kebab-case.
 *
 * @param {string} str - The PascalCase string to convert.
 * @returns {string} The kebab-case version of the input string.
 */
export const pascalToKebabCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('-');
};

/**
 * Decodes Unicode escape sequences in a string.
 *
 * This function replaces Unicode escape sequences of the form `\uXXXX` in the input string
 * with their corresponding Unicode characters. The `XXXX` can be any valid hexadecimal number
 * between 1 and 5 digits.
 *
 * @param {string} data - The input string containing Unicode escape sequences.
 * @returns {string} - The string with Unicode escape sequences decoded.
 *
 * @example
 * const input = '\\u0041\\u0062\\u03A9';
 * const output = decodeUnicodeEscapes(input);
 * console.log(output); // Outputs: "AbΩ"
 */
export const decodeUnicodeEscapes = (data: string): string => {
  return data.replace(/\\u([0-9a-fA-F]{1,4})/g, (_, hex) => String.fromCharCode(parseInt(hex, 16)));
};
