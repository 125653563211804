import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 15C20 17.7614 17.7614 20 15 20C12.2386 20 10 17.7614 10 15C10 12.2386 12.2386 10 15 10C17.7614 10 20 12.2386 20 15Z"
          fill={color}
          stroke={color}
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0C14.4477 0 14 0.447715 14 1V6C14 6.55228 14.4477 7 15 7C15.5523 7 16 6.55228 16 6V1C16 0.447715 15.5523 0 15 0ZM25.6066 4.39339C25.2161 4.00286 24.5829 4.00286 24.1924 4.39339L20.6569 7.92892C20.2663 8.31944 20.2663 8.95261 20.6569 9.34313C21.0474 9.73366 21.6806 9.73366 22.0711 9.34313L25.6066 5.8076C25.9971 5.41708 25.9971 4.78391 25.6066 4.39339ZM29 14C29.5523 14 30 14.4477 30 15C30 15.5523 29.5523 16 29 16H24C23.4477 16 23 15.5523 23 15C23 14.4477 23.4477 14 24 14H29ZM25.6066 25.6065C25.9971 25.216 25.9971 24.5828 25.6066 24.1923L22.0711 20.6568C21.6806 20.2662 21.0474 20.2662 20.6569 20.6568C20.2663 21.0473 20.2663 21.6805 20.6569 22.071L24.1924 25.6065C24.5829 25.997 25.2161 25.997 25.6066 25.6065ZM16 29C16 29.5523 15.5523 30 15 30C14.4477 30 14 29.5523 14 29V24C14 23.4477 14.4477 23 15 23C15.5523 23 16 23.4477 16 24V29ZM4.39339 25.6066C4.78391 25.9971 5.41708 25.9971 5.8076 25.6066L9.34313 22.0711C9.73366 21.6806 9.73366 21.0474 9.34313 20.6569C8.95261 20.2663 8.31945 20.2663 7.92892 20.6569L4.39339 24.1924C4.00286 24.5829 4.00286 25.2161 4.39339 25.6066ZM1 16C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H6C6.55228 14 7 14.4477 7 15C7 15.5523 6.55228 16 6 16H1ZM4.39339 4.39336C4.00286 4.78388 4.00286 5.41705 4.39339 5.80757L7.92892 9.3431C8.31944 9.73363 8.95261 9.73363 9.34313 9.3431C9.73366 8.95258 9.73366 8.31942 9.34313 7.92889L5.8076 4.39336C5.41708 4.00283 4.78391 4.00283 4.39339 4.39336Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
