import { styled } from '@mui/material/styles';
import { Paper, Box } from '@mui/material';

export const ToolboxContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  height: '100%',
}));

export const ToolboxElementsContainer = styled(Paper)(() => ({
  position: 'absolute',

  height: '90px',
  top: '-96px',

  display: 'flex',

  maxWidth: '100%',
  minWidth: '530px',
  marginLeft: '13px',

  border: '1px solid #5A418B',
  borderRadius: '10px',

  overflow: 'hidden',

  '&.toolbox-elements-empty': {
    width: '530px',
    marginLeft: '13px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
