import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DeviceType = 'robo' | 'bridge';
export type UpdateStatus = 'in_progress' | 'finished' | 'error' | 'stuck';
export type RenameStatus = 'in_progress' | 'finished' | 'error' | 'stuck';

export interface UpdateManagerState {
  updatingDevices: Array<{
    deviceType: DeviceType;
    status: UpdateStatus;
    id: string;
  }>;
  updatingStatusModal: {
    deviceType: DeviceType;
    status: UpdateStatus;
  } | null;
  renamingModal: {
    status: RenameStatus;
  } | null;
  showWiredUpdateModal: boolean;
  firmware: {
    robo: {
      [key: string]: {
        lastVersion: string;
      };
    };
    bridge: {
      [key: string]: {
        lastVersion: string;
      };
    };
  };
}

const initialState: UpdateManagerState = {
  updatingDevices: [],
  updatingStatusModal: null,
  renamingModal: null,
  showWiredUpdateModal: false,
  firmware: {
    robo: {},
    bridge: {},
  },
};

const updateManagerSlice = createSlice({
  name: 'updateManager',
  initialState,
  reducers: {
    startUpdate: (state, { payload: { id, deviceType } }: PayloadAction<{ id: string; deviceType: DeviceType }>) => {
      const updatingDevice = state.updatingDevices.find(d => d.id === id && d.deviceType === deviceType);
      if (updatingDevice) {
        updatingDevice.status = 'in_progress';
      } else {
        state.updatingDevices.push({
          id,
          deviceType,
          status: 'in_progress',
        });
      }
    },
    endUpdate: (state, { payload: { id, deviceType } }: PayloadAction<{ id: string; deviceType: DeviceType }>) => {
      const updatingDevice = state.updatingDevices.find(d => d.id === id && d.deviceType === deviceType);
      if (updatingDevice) {
        updatingDevice.status = 'finished';
      }
    },
    updateIsStuck: (state, { payload: { id, deviceType } }: PayloadAction<{ id: string; deviceType: DeviceType }>) => {
      const updatingDevice = state.updatingDevices.find(d => d.id === id && d.deviceType === deviceType);
      if (updatingDevice) {
        updatingDevice.status = 'stuck';
      }
    },
    setUpdateStatusModal: (
      state,
      {
        payload,
      }: PayloadAction<{
        deviceType: DeviceType;
        status: UpdateStatus;
      } | null>
    ) => {
      state.updatingStatusModal = payload;
    },
    setShowWiredUpdateModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showWiredUpdateModal = payload;
    },
    setRenamingModal: (
      state,
      {
        payload,
      }: PayloadAction<{
        status: RenameStatus;
      } | null>
    ) => {
      if (payload) {
        state.renamingModal = { status: payload.status };
      } else {
        state.renamingModal = null;
      }
    },
    setFirmwareLastVersion: (
      state,
      {
        payload: { version, deviceType, deviceVersion },
      }: PayloadAction<{
        version: string;
        deviceType: DeviceType;
        deviceVersion: string;
      }>
    ) => {
      if (!state.firmware[deviceType][deviceVersion]) {
        state.firmware[deviceType][deviceVersion] = {
          lastVersion: '',
        };
      }
      state.firmware[deviceType][deviceVersion].lastVersion = version;
    },
  },
});
export const {
  startUpdate,
  endUpdate,
  setFirmwareLastVersion,
  updateIsStuck,
  setUpdateStatusModal,
  setRenamingModal,
  setShowWiredUpdateModal,
} = updateManagerSlice.actions;

export default updateManagerSlice.reducer;
