import { AnalyticsEvent, EVENT_PAYLOAD } from './events';

interface DataLayerEvent<T = Record<string, unknown>> {
  event: string;
  timestamp: number;
  [key: string]: T[keyof T] | string | number | boolean | undefined;
}

declare global {
  interface Window {
    dataLayer?: Array<DataLayerEvent<EVENT_PAYLOAD<AnalyticsEvent>>>;
  }
}

// Type-safe track event
export function track<E extends AnalyticsEvent>(event: E, params: EVENT_PAYLOAD<E>): void {
  if (typeof window === 'undefined' || !window.dataLayer) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('DataLayer is not available');
    }
    return;
  }

  const eventName = event.toString();

  window.dataLayer.push({
    event: eventName,
    ...params,
    timestamp: Date.now(),
  });
}

// Re-export all event enums for convenience
export * from './events';
