import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 27 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.872 3.54a.708.708 0 100 1.415.708.708 0 000-1.415zm-3.54.708a3.54 3.54 0 116.11 2.433 11.809 11.809 0 019.043 10.226l1.49 13.785a4.248 4.248 0 01-4.223 4.704h-3.217a5.663 5.663 0 01-11.327 0H4.37A4.248 4.248 0 01.16 30.58l1.89-13.985a11.808 11.808 0 019.21-9.96 3.527 3.527 0 01-.927-2.387zm3.416 4.955a8.973 8.973 0 00-8.892 7.771L2.966 30.96a1.416 1.416 0 001.403 1.606h18.383c.842 0 1.498-.731 1.408-1.568l-1.49-13.785a8.973 8.973 0 00-8.922-8.009zm2.126 28.196a2.832 2.832 0 01-4.455-.587h4.905c-.123.213-.274.41-.45.587z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
