import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors as themeColors } from '@themes/config/theme-colors';
import { MotionSensorIcon } from '@webapp/components/icons';

import StyledBox from '@webapp/components/blocks/component/styled-box';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useSelector } from '@store/configureStore';
import { selectModuleById } from '@webapp/store/slices/model/model.selectors';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}));

const MotionSensorWidget = ({ counter, data, disabled }) => {
  const { isPlaying, editorType } = useEditorContext();
  const { addHistoryEntry } = useActionsHistory();

  const moduleId = data?.moduleIds?.[0];
  const moduleState = useSelector(state => selectModuleById(state, 'motions', moduleId));
  const motion = isPlaying ? moduleState?.sensorData?.motion ?? false : false;

  useEffect(() => {
    if (isPlaying && motion) {
      addHistoryEntry({
        action: 'interact:widget:motion-sensor',
        scope: editorType,
      });
    }
  }, [isPlaying, motion, editorType]);

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#E31871'}>
      {counter ? <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography> : null}
      <MotionSensorIcon
        primaryColor={motion ? 'white' : 'rgba(255,255,255,0.4)'}
        sx={{
          width: '50%',
          height: '50%',
        }}
      />
    </StyledBox>
  );
};

MotionSensorWidget.initialProps = {
  width: 1,
  height: 1,
};

MotionSensorWidget.initialData = {};

export default MotionSensorWidget;
