import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';
import { MoveMotionIcon, MoveNoMotionIcon } from '@webapp/components/icons';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { ModuleId } from '@lib/robo/types';
import { MotionEventType } from '@lib/robo/modules/motion';

import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';

const MotionTriggerWidget: ExecutableTriggerWidgetComponent<MotionTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<MotionTriggerWidgetData>(id);

  const [eventType, setEventType] = useState<MotionEventType>(
    widget?.data.eventType || MotionTriggerWidget.initialData.eventType
  );

  const handleEventTypeChange = (newEventType: MotionEventType) => {
    setEventType(newEventType);
    updateWidgetData(id, { eventType: newEventType });
  };

  return (
    <Box sx={{ width: '300px', height: '185px', display: 'flex' }}>
      <Grid container spacing={0}>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<MoveMotionIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === MotionEventType.Motion}
            value={eventType === MotionEventType.Motion}
            onChange={() => handleEventTypeChange(MotionEventType.Motion)}
            text={
              <Typography variant="x-small-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                Motion
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<MoveNoMotionIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === MotionEventType.NoMotion}
            value={eventType === MotionEventType.NoMotion}
            onChange={() => handleEventTypeChange(MotionEventType.NoMotion)}
            text={
              <Typography variant="x-small-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                No Motion
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Execute the motion trigger widget
 * @param roboModel - The robo model
 * @param widgetId - The widget id
 * @param getWidgetById - The get widget by id function
 * @param signal - The signal
 * @returns The execution result
 */
MotionTriggerWidget.execute = async ({ roboModel, widgetId, getWidgetById, signal }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;
      const motionModuleId = data?.moduleIds[0];
      const MOTION = roboModel.modules.motions[motionModuleId as ModuleId];

      if (!MOTION) {
        reject(new Error('Motion module not found'));
        return;
      }

      // Create trigger for motion sensor module
      MOTION.createTrigger(data.eventType, ({ isError }) => {
        if (isError) {
          reject(new Error('Error with motion trigger'));
        } else {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
            // persistenceDuration: 2000,
          });
        }
      });
    },
    `MotionTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

/**
 * The motion trigger widget data
 */
export type MotionTriggerWidgetData = {
  eventType: MotionEventType;
};

/**
 * The initial data for the motion trigger widget
 */
MotionTriggerWidget.initialData = {
  eventType: MotionEventType.Motion,
};

export default MotionTriggerWidget;
