import { Slider as MuiSlider, SliderProps as MuiSliderProps, styled } from '@mui/material';

interface CustomSliderProps {
  secondValue: number;
  mainColor?: string;
  railColor?: string;
  labelColor?: string;
  maxAsInfinite?: boolean;
  minAsInfinite?: boolean;
  appearance?: SliderAppearance;
}

// Combine MUI SliderProps with CustomSliderProps
type SliderProps = MuiSliderProps & CustomSliderProps;

const StyledSlider = styled(MuiSlider, {
  shouldForwardProp: prop => prop !== 'custom',
})<{ custom: CustomSliderProps } & MuiSliderProps>(({ theme, custom, ...props }) => {
  const max = props.max ?? 100;
  const min = props.min ?? 0;
  const value = (props.value ?? 0) as number;
  const secondValue = (custom.secondValue ?? -1) as number;
  const isReversed = custom.appearance === 'reversed';

  const mainColor = custom.mainColor;
  const railColor = custom.railColor;
  const labelColor = custom.labelColor;

  const baseStyles = {
    color: props.color || mainColor,

    '& .MuiSlider-rail, .MuiSlider-track': {
      height: '6px',
      width: '100%',
      opacity: 1,
      boxSizing: 'border-box',
    },

    '& .MuiSlider-rail': {
      color: railColor,
    },

    '& .MuiSlider-track': {
      boxShadow: `inset 0 0 0 1px ${mainColor}`,
      color: mainColor,

      left: isReversed ? 'auto !important' : '0 !important',
      right: isReversed ? '0 !important' : 'auto !important',
      width: isReversed
        ? `${100 - ((value as number) / ((max - min) as number)) * 100}% !important`
        : `${(((value - min) as number) / (max - min)) * 100}% !important`,
    },

    // Add the second track
    '&::before': {
      content: '""',
      position: 'absolute',

      left: 1,

      top: '50%',
      transform: 'translateY(-50%)',
      height: '4px',
      borderRadius: secondValue ? '12px 0 0 12px' : '12px',
      // When second value specified, use it, otherwise full width
      width: secondValue === -1 ? 'calc(100% - 2px)' : `${(secondValue / (max - min)) * 100}%`,
      backgroundColor: '#FFFFFF',
      zIndex: 5,
      boxSizing: 'border-box',
      maxWidth: '99%',
    },

    '& .MuiSlider-thumb': {
      marginTop: '40px',
      width: 30,
      height: 30,
      zIndex: 10,
      backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      fontSize: '12px',
      fontWeight: 'bold',

      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 4,
        height: 55,
        backgroundColor: props.color || mainColor,
        borderRadius: 2,
        zIndex: -1,
      },
    },

    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 700,
      top: '30px',

      width: '30px',
      height: '30px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'unset',
      position: 'absolute',

      '&::before': {
        display: 'none',
      },

      '& *': {
        background: 'transparent',
        color: labelColor,
      },
    },
  };

  return baseStyles;
});

const formatValueLabel = (value: number, maxAsInfinite: boolean, max: number, minAsInfinite: boolean, min: number) => {
  if (maxAsInfinite && value === max) {
    return '∞';
  }
  if (minAsInfinite && value === min) {
    return '-∞';
  }
  return value;
};

export type SliderAppearance = 'default' | 'reversed';

/**
 * Slider component that wraps the StyledSlider with additional props for customization.
 * Props include a props object containing mainColor, railColor, labelColor.
 */
const Slider: React.FunctionComponent<SliderProps> = ({
  secondValue = 0,
  mainColor = '#DF1642',
  railColor = '#E9E9E9',
  labelColor = '#5A418B',
  maxAsInfinite = false,
  minAsInfinite = false,
  appearance = 'default',
  ...props
}) => {
  const customProps = { mainColor, railColor, labelColor, appearance, secondValue };

  return (
    <StyledSlider
      custom={customProps}
      {...props}
      valueLabelFormat={value =>
        formatValueLabel(value, maxAsInfinite, props.max ?? 100, minAsInfinite, props.min ?? -100)
      }
    />
  );
};

export default Slider;
