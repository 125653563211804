import { Button as MuiButton, ButtonProps as MuiButtonProps, Box, styled, Typography } from '@mui/material';

import { ColorIcon } from '@webapp/components/icons';

interface CustomButtonProps {
  mainColor?: string;
  secondaryColor?: string;
  selected?: boolean;
}

export type ColorButtonProps = MuiButtonProps & CustomButtonProps;

const StyledButton = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'custom', // Prevents custom props from being forwarded to the underlying component
})<{ custom: CustomButtonProps } & MuiButtonProps>(({ theme, custom }) => ({
  minWidth: '42px',
  minHeight: '42px',
  textTransform: 'none',
  borderRadius: '50%',
  border: 'none',
  padding: '0',

  backgroundColor: custom.mainColor,
}));

const ColorButton: React.FunctionComponent<ColorButtonProps> = ({
  mainColor = '#FFFFFF',

  ...props
}) => {
  const { children, sx, ...rest } = props;

  return (
    <Box sx={sx}>
      <StyledButton custom={{ mainColor }} {...rest}>
        <ColorIcon sx={{ fontSize: '42px' }} htmlColor={mainColor} />
      </StyledButton>

      {children && (
        <Box sx={{ lineHeight: '1rem', textAlign: 'center' }}>
          {typeof children === 'string' ? (
            <Typography variant="x-tiny-bold" color={'#5A418B'}>
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
      )}
    </Box>
  );
};

export default ColorButton;
