import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { primaryColor, secondaryColor, ...otherProps } = props;

  return (
    <SvgIcon {...otherProps}>
      <svg viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.133 12.5h-.5V19a1.5 1.5 0 01-1.5 1.5h-7a1.5 1.5 0 01-1.5-1.5v-6.5h-4.965a1.5 1.5 0 00-1.248.668l-8.666 13c-.665.997.05 2.332 1.248 2.332h36.263c1.198 0 1.912-1.335 1.248-2.332l-8.667-13a1.5 1.5 0 00-1.248-.668h-3.465zm-14.662 3h1.162V19a4.5 4.5 0 004.5 4.5h7a4.5 4.5 0 004.5-4.5v-3.5h.162l6.667 10H5.804l6.667-10zM2.133 30.5h-.5V39a2.5 2.5 0 002.5 2.5h35a2.5 2.5 0 002.5-2.5v-8.5h-39.5zm2.5 8v-5h34v5h-34z"
          fill={primaryColor}
          stroke={secondaryColor}
        />
        <path
          d="M22.613 9.702l-.345.112v6.023a.228.228 0 01-.234.22h-1.068a.228.228 0 01-.233-.22V9.813l-.346-.112c-1.411-.46-2.42-1.764-2.42-3.292 0-1.907 1.574-3.467 3.533-3.467 1.96 0 3.533 1.56 3.533 3.467 0 1.528-1.009 2.833-2.42 3.292zm2.121 1.686C26.394 10.34 27.5 8.505 27.5 6.41c0-3.272-2.694-5.91-6-5.91s-6 2.638-6 5.91c0 2.095 1.107 3.93 2.766 4.978v4.448c0 1.48 1.217 2.664 2.7 2.664h1.068c1.484 0 2.7-1.185 2.7-2.664v-4.448z"
          fill={primaryColor}
          stroke={secondaryColor}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
