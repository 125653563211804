import { gql } from '@lib/store/strapi-graphql-query';

export const SECTIONS_DETAILS_QUERY = gql`
  query GetSectionsDetails {
    sectionsDetails {
      data {
        id
        attributes {
          name
          externalId
          studentsCount
          createdAt
          enabled
        }
      }
    }
  }
`;

export const FULL_SECTIONS_QUERY = gql`
  query GetSections($filters: SectionFiltersInput, $studentsPagination: PaginationArg) {
    sections(filters: $filters) {
      data {
        id
        attributes {
          name
          enabled
          externalId

          parentSection {
            data {
              id
              attributes {
                name
                externalId
              }
            }
          }

          students(pagination: $studentsPagination) {
            data {
              id
              attributes {
                name
                externalId
                email
                username
              }
            }
          }

          teachers {
            data {
              id
              attributes {
                name
                externalId
                email
                username
              }
            }
          }

          grades {
            data {
              id
              attributes {
                name
              }
            }
          }

          subjects {
            data {
              id
              attributes {
                name
              }
            }
          }

          owner {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
