import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import ToggleButton from '@webapp/components/ui/buttons/toggle-button';
import Slider from '@webapp/components/ui/sliders/slider';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { ModuleId } from '@lib/robo/types';
import { ButtonEventType } from '@lib/robo/modules/button';

import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';

import { AbortablePromise } from '@lib/utils/abortable-promise';

const ButtonTriggerWidget: ExecutableTriggerWidgetComponent<ButtonTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<ButtonTriggerWidgetData>(id);

  const [eventsCount, setEventsCount] = useState<number>(
    widget?.data.eventsCount || ButtonTriggerWidget.initialData.eventsCount
  );

  const [eventType, setEventType] = useState<ButtonEventType>(
    widget?.data.eventType || ButtonTriggerWidget.initialData.eventType
  );

  const handleEventsCountChange = (newEventsCount: number) => {
    setEventsCount(newEventsCount);
  };

  const onEventsCountChanged = () => {
    setEventsCount(eventsCount);
    updateWidgetData(id, { eventsCount });
  };

  const handleEventTypeChange = (newEventType: ButtonEventType) => {
    setEventType(newEventType);
    updateWidgetData(id, { eventType: newEventType });
  };

  return (
    <Box sx={{ width: '360px', height: '185px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0} sx={{ height: '150px' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* We show slider only for clicked event type */}
          {eventType === ButtonEventType.Clicked ? (
            <Slider
              value={eventsCount}
              onChange={(e, newValue) => handleEventsCountChange(newValue as number)}
              onChangeCommitted={onEventsCountChanged}
              orientation="horizontal"
              sx={{ width: '75%' }}
              max={3}
              min={1}
              mainColor="#DF1642"
              railColor="#E9E9E9"
              labelColor="#5A418B"
              valueLabelDisplay="on"
            />
          ) : (
            <Typography variant="x-headline5-bold" sx={{ color: '#C7C7C7' }}>
              No adjustments available
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={0} sx={{ height: '35px' }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <ToggleButton
            selected={eventType === ButtonEventType.Clicked}
            value={ButtonEventType.Clicked}
            onChange={() => handleEventTypeChange(ButtonEventType.Clicked)}
          >
            clicked
          </ToggleButton>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ToggleButton
            selected={eventType === ButtonEventType.Pressed}
            value={ButtonEventType.Pressed}
            onChange={() => handleEventTypeChange(ButtonEventType.Pressed)}
          >
            pressed
          </ToggleButton>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <ToggleButton
            selected={eventType === ButtonEventType.Released}
            value={ButtonEventType.Released}
            onChange={() => handleEventTypeChange(ButtonEventType.Released)}
          >
            released
          </ToggleButton>
        </Grid>
      </Grid>
    </Box>
  );
};

ButtonTriggerWidget.execute = async ({ roboModel, widgetId, getWidgetById, signal }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;
      const buttonModuleId = data?.moduleIds[0];
      const BUTTON = roboModel.modules.buttons[buttonModuleId as ModuleId];

      if (!BUTTON) {
        reject(new Error('Button module not found'));
        return;
      }

      // Create trigger for button module
      BUTTON.createTrigger(data.eventType, data.eventsCount, ({ isError }) => {
        if (isError) {
          reject(new Error('Error with button trigger'));
        } else {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
            persistenceDuration: 2000,
          });
        }
      });
    },
    `ButtonTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

export type ButtonTriggerWidgetData = {
  eventsCount: number;
  eventType: ButtonEventType;
};

ButtonTriggerWidget.initialData = {
  eventsCount: 1,
  eventType: ButtonEventType.Clicked,
};

export default ButtonTriggerWidget;
