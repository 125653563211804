import React from 'react';
import { Box } from '@mui/material';
import { RoboLoader } from '@components/Loader/robo-loader';

export const AppLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        background: '#7e63b630',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <RoboLoader />
    </Box>
  );
};
