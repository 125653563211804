import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { primaryColor, secondaryColor, ...otherProps } = props;

  return (
    <SvgIcon {...otherProps}>
      <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Motion">
          <circle
            id="Ellipse 766"
            cx="30.3336"
            cy="29.6336"
            r="28.3778"
            fill={primaryColor}
            stroke="#5A418B"
            strokeWidth="2"
          />
          <path
            id="Union"
            d="M32.3563 10.9731C31.3269 9.61564 29.3401 9.61564 28.3107 10.9731L18.0523 24.5006C16.7781 26.1808 17.8475 28.8075 20.0751 28.8075H23.9864V43.13C23.9864 45.5563 25.8651 47.5994 28.2742 47.5994H32.3929C34.802 47.5994 36.6807 45.5563 36.6807 43.13V28.8075H40.5919C42.8195 28.8075 43.8889 26.1808 42.6147 24.5006L32.3563 10.9731Z"
            fill={secondaryColor}
            stroke="#06204B"
            strokeWidth="1.71111"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
