import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.06.041C6.859.387 3.127 2.905 1.278 6.64a11.982 11.982 0 0 0 5.355 16.077c3.41 1.694 7.271 1.703 10.667.024.418-.206.958-.505 1.2-.663a12.25 12.25 0 0 0 3.578-3.578c.383-.586.992-1.832 1.22-2.496.492-1.433.678-2.529.678-4.004 0-1.475-.186-2.571-.678-4.004-.228-.665-.837-1.911-1.22-2.496-1.576-2.411-3.815-4.098-6.598-4.973-1.339-.421-2.996-.603-4.42-.486M13.48 2.1c.57.087 1.325.277 1.877.472.863.305 2.096.978 2.758 1.506l.215.172-1.429 1.429-1.429 1.43-.377-.23a6.132 6.132 0 0 0-2.59-.842c-1.211-.114-2.543.198-3.6.842l-.377.23-1.429-1.43L5.67 4.25l.215-.172c.662-.528 1.892-1.199 2.763-1.509a10.92 10.92 0 0 1 2.512-.544c.397-.036 1.915.013 2.32.075M6.879 8.905a6.132 6.132 0 0 0-.842 2.59c-.114 1.211.198 2.543.842 3.6l.23.377-1.43 1.429L4.25 18.33l-.172-.215c-.528-.662-1.199-1.892-1.509-2.763a11.4 11.4 0 0 1-.476-1.912c-.101-.648-.101-2.232 0-2.88a11.4 11.4 0 0 1 .476-1.912c.31-.871.981-2.101 1.509-2.763l.172-.215 1.429 1.429 1.43 1.429-.23.377m13.445-2.449a12.14 12.14 0 0 1 1.012 1.935c.881 2.247.881 4.971 0 7.218-.317.807-.94 1.911-1.414 2.506l-.172.215-1.428-1.428-1.429-1.429.244-.407c1.122-1.868 1.123-4.284.001-6.125l-.245-.401 1.424-1.432 1.423-1.432.134.152c.074.084.277.366.45.628m-7.357 1.658a3.986 3.986 0 0 1 1.856 1.063 4.017 4.017 0 0 1 1.066 1.856c.123.445.123 1.489 0 1.934a4.017 4.017 0 0 1-1.066 1.856 4.017 4.017 0 0 1-1.856 1.066c-.445.123-1.489.123-1.934 0a4.017 4.017 0 0 1-1.856-1.066 4.017 4.017 0 0 1-1.066-1.856c-.123-.445-.123-1.489 0-1.934.428-1.556 1.625-2.681 3.163-2.973.427-.081 1.285-.054 1.693.054m-3.276 9.407a6.013 6.013 0 0 0 5.375-.384l.407-.244 1.429 1.429 1.428 1.428-.215.172c-.662.528-1.895 1.201-2.758 1.506-.564.199-1.31.386-1.917.479-.648.1-2.233.1-2.88 0a11.351 11.351 0 0 1-1.912-.476c-.871-.31-2.101-.981-2.763-1.509l-.215-.172 1.429-1.429 1.429-1.43.377.23c.208.127.561.306.786.4" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
