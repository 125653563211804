import { IconType } from '@webapp/components/icons/types';

import { ObstacleTriggerWidgetData } from '@webapp/components/blocks/widgets/code/obstacle-trigger/code-obstacle-trigger-widget';
import { LinetrackerObstacleEventType } from '@lib/robo/modules/linetracker';
import { ObstacleCloseIcon, ObstacleFarIcon } from '@webapp/components/icons';

type IconProps = {
  value: ObstacleTriggerWidgetData;
};

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { eventType } = value;

  if (eventType === LinetrackerObstacleEventType.Close) {
    return <ObstacleCloseIcon htmlColor="#FFFFFF" sx={{ fontSize: '40px', stroke: '#5A418B' }} />;
  } else if (eventType === LinetrackerObstacleEventType.Far) {
    return <ObstacleFarIcon htmlColor="#FFFFFF" sx={{ fontSize: '40px', stroke: '#5A418B' }} />;
  }
};

export default Icon;
