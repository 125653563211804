import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.3 11.1999L11.3 6.1999C11.7 5.7999 12.3 5.7999 12.7 6.1999C13.1 6.5999 13.1 7.1999 12.7 7.5999L9.4 10.8999H17C17.6 10.8999 18 11.2999 18 11.8999C18 12.4999 17.6 12.8999 17 12.8999H9.4L12.7 16.1999C13.1 16.5999 13.1 17.1999 12.7 17.5999C12.3 17.9999 11.7 17.9999 11.3 17.5999L6.3 12.5999C5.9 12.2999 5.9 11.5999 6.3 11.1999Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
