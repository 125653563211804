import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { AbortablePromise } from '@lib/utils/abortable-promise';

import CircularSlider from '@webapp/components/ui/sliders/circular-slider';
import ToggleButton from '@webapp/components/ui/buttons/toggle-button';
import RandomButton from '@webapp/components/ui/buttons/random-button';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';

// Enum for time units
enum TimeUnit {
  Milliseconds = 'ms',
  Seconds = 'sec',
  Minutes = 'min',
}

// Configuration for different time units
const widgetConfig = {
  [TimeUnit.Milliseconds]: { min: 0, max: 1000, step: 100, defaultValue: 0 },
  [TimeUnit.Seconds]: { min: 0, max: 60, step: 1, defaultValue: 0 },
  [TimeUnit.Minutes]: { min: 0, max: 60, step: 1, defaultValue: 0 },
};

// Type definitions for time values
type SingleTimeValue = number;
type TimeValueRange = [SingleTimeValue, SingleTimeValue];
type TimeValue = SingleTimeValue | TimeValueRange;

/**
 * Timer trigger widget
 * @param id - The id of the widget
 * @returns The timer trigger widget
 */
const TimerTriggerWidget: ExecutableTriggerWidgetComponent<TimerTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<TimerTriggerWidgetData>(id);

  const defaultTimeValue = widgetConfig[TimeUnit.Seconds].defaultValue;
  const defaultTimeUnit = TimeUnit.Seconds;

  const [timeValue, setTimeValue] = useState<TimeValue>(widget?.data.timeValue || defaultTimeValue);
  const [timeUnit, setTimeUnit] = useState<TimeUnit>(widget?.data.timeUnit || defaultTimeUnit);

  const handleTimeValueChange = (newValue: TimeValue) => {
    setTimeValue(newValue);
  };

  const handleTimeUnitChange = (newUnit: TimeUnit) => {
    let newTimeValue;

    if (Array.isArray(timeValue)) {
      newTimeValue = convertSingleValueToRange(widgetConfig[newUnit].defaultValue, newUnit);
    } else {
      newTimeValue = widgetConfig[newUnit].defaultValue;
    }

    setTimeValue(newTimeValue);
    setTimeUnit(newUnit);
  };

  /**
   * Handle the random toggle
   */
  const handleRandomToggle = () => {
    let newTimeValue;

    if (Array.isArray(timeValue)) {
      newTimeValue = convertRangeToSingleValue(timeValue, timeUnit);
    } else {
      newTimeValue = convertSingleValueToRange(timeValue, timeUnit);
    }

    setTimeValue(newTimeValue);
    onTimeOrUnitChanged({ timeValue: newTimeValue, timeUnit: timeUnit });
  };

  /**
   * Handle the time or unit changed
   * @param timeValue - The time value
   * @param timeUnit - The time unit
   */
  const onTimeOrUnitChanged = ({ timeValue, timeUnit }: { timeValue: TimeValue; timeUnit: TimeUnit }) => {
    updateWidgetData<TimerTriggerWidgetData>(id, { timeValue, timeUnit });
  };

  useEffect(() => {
    onTimeOrUnitChanged({ timeValue, timeUnit });
  }, [timeUnit]);

  const getDisplayText = () => {
    if (Array.isArray(timeValue)) {
      const sortedTimeValue = [...timeValue].sort((a, b) => a - b);
      return `${sortedTimeValue[0]} - ${sortedTimeValue[1]} ${timeUnit}`;
    } else {
      switch (timeUnit) {
        case TimeUnit.Milliseconds:
          return `${timeValue} ms`;
        case TimeUnit.Seconds:
          return `${timeValue} sec`;
        case TimeUnit.Minutes:
          return `${timeValue} min`;
      }
    }
  };

  const convertRangeToSingleValue = (range: TimeValueRange, unit: TimeUnit): SingleTimeValue => {
    const midValue = (range[0] + range[1]) / 2;
    const step = widgetConfig[unit].step;
    return Math.round(midValue / step) * step;
  };

  const convertSingleValueToRange = (value: SingleTimeValue, unit: TimeUnit): TimeValueRange => {
    const step = widgetConfig[unit].step;
    let rangeMin = value - 2 * step;
    let rangeMax = value + 2 * step;

    const unitMin = widgetConfig[unit].min;
    const unitMax = widgetConfig[unit].max;

    if (rangeMin < unitMin) {
      rangeMin = unitMin;
      rangeMax = unitMin + 4 * step;
    }

    if (rangeMax > unitMax) {
      rangeMax = unitMax;
      rangeMin = unitMax - 4 * step;
    }

    return [rangeMin, rangeMax];
  };

  return (
    <Box sx={{ width: '200px', height: '290px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '-20px' }}>
          <RandomButton
            selected={Array.isArray(timeValue)}
            value={Array.isArray(timeValue)}
            onChange={handleRandomToggle}
          >
            Random
          </RandomButton>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '218px' }}>
          <CircularSlider
            value={timeValue}
            valueLabelDisplay="on"
            onChange={(_event, newValue) => handleTimeValueChange(newValue as TimeValue)}
            onChangeCommitted={(_event, newValue) =>
              onTimeOrUnitChanged({ timeValue: newValue as TimeValue, timeUnit: timeUnit })
            }
            min={widgetConfig[timeUnit].min}
            max={widgetConfig[timeUnit].max}
            step={widgetConfig[timeUnit].step}
            mainColor="#DF1642"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            radius={80}
          >
            <Typography variant="x-headline5-semibold" color="#5A418B">
              {getDisplayText()}
            </Typography>
          </CircularSlider>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '35px' }}>
          <ToggleButton
            selected={timeUnit === TimeUnit.Milliseconds}
            value={TimeUnit.Milliseconds}
            onChange={() => handleTimeUnitChange(TimeUnit.Milliseconds)}
          >
            ms
          </ToggleButton>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35px' }}>
          <ToggleButton
            selected={timeUnit === TimeUnit.Seconds}
            value={TimeUnit.Seconds}
            onChange={() => handleTimeUnitChange(TimeUnit.Seconds)}
          >
            sec
          </ToggleButton>
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', height: '35px' }}>
          <ToggleButton
            selected={timeUnit === TimeUnit.Minutes}
            value={TimeUnit.Minutes}
            onChange={() => handleTimeUnitChange(TimeUnit.Minutes)}
          >
            min
          </ToggleButton>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Get the duration of the timer in milliseconds
 * @param timeValue - The time value
 * @param timeUnit - The time unit
 * @returns The duration in milliseconds
 */
const getDuration = (timeValue: SingleTimeValue, timeUnit: TimeUnit) => {
  if (timeUnit === TimeUnit.Milliseconds) {
    return timeValue;
  } else if (timeUnit === TimeUnit.Seconds) {
    return timeValue * 1000;
  } else {
    return timeValue * 60_000;
  }
};

/**
 * Execute the timer trigger widget
 * @param signal - The signal to abort the execution
 * @param widgetId - The id of the widget
 * @param getWidgetById - The function to get the widget by id
 * @param updateWidgetData - The function to update the widget data
 * @returns A promise that resolves when the widget is executed
 */
TimerTriggerWidget.execute = async ({ signal, widgetId, getWidgetById, updateWidgetData }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Timer trigger widget not found');
      }

      const { data } = widget;

      const runtime = data.runtime || TimerTriggerWidget.initialData.runtime;

      const startTime = runtime.startTime || Date.now();

      let duration;

      if (Array.isArray(data.timeValue)) {
        // Randomly select a duration between the range
        duration = Math.floor(Math.random() * (data.timeValue[1] - data.timeValue[0]) + data.timeValue[0]);
      } else {
        duration = getDuration(data.timeValue, data.timeUnit);
      }

      updateWidgetData(widget.id, {
        ...data,
        runtime: {
          ...data.runtime,
          startTime: startTime,
        },
      });

      setTimeout(() => {
        resolve({
          type: WidgetExecutionType.Trigger,
          widgetId: widget.id,
          resolved: true,
          persistenceDuration: 7_200_000, // save result for 2 hours
        });
      }, duration);
    },
    `TimerTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

/**
 * Reset the timer trigger widget
 * @param widgetId - The id of the widget
 * @param getWidgetById - The function to get the widget by id
 * @param updateWidgetData - The function to update the widget data
 */
TimerTriggerWidget.reset = async ({ widgetId, getWidgetById, updateWidgetData }) => {
  const widget = getWidgetById(widgetId);

  if (!widget) {
    console.error('Timer trigger widget not found');
    return;
  }

  try {
    updateWidgetData(widget.id, {
      ...widget.data,
      runtime: {
        ...widget.data.runtime,
        startTime: null,
      },
    });
  } catch (error) {
    console.error('Error resetting timer trigger:', error);
  }
};

// Interface for widget data
interface TimerTriggerWidgetData {
  timeValue: TimeValue;
  timeUnit: TimeUnit;
  runtime: {
    startTime: number | null;
  };
}

TimerTriggerWidget.initialData = {
  timeValue: 30,
  timeUnit: TimeUnit.Seconds,
  runtime: {
    startTime: null,
  },
};

export default TimerTriggerWidget;
