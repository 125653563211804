import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill={color}
        />
        <path
          d="M17.4522 9.51617C17.8982 9.95217 17.9332 10.5592 17.4522 11.0922L13.7052 15.0002L17.4522 18.9082C17.9332 19.4412 17.8982 20.0492 17.4522 20.4822C17.0072 20.9182 16.2552 20.8902 15.8372 20.4822C15.4192 20.0762 11.3352 15.7872 11.3352 15.7872C11.2293 15.685 11.1451 15.5626 11.0876 15.4272C11.0301 15.2919 11.0005 15.1463 11.0005 14.9992C11.0005 14.8521 11.0301 14.7065 11.0876 14.5711C11.1451 14.4357 11.2293 14.3133 11.3352 14.2112C11.3352 14.2112 15.4192 9.92417 15.8372 9.51617C16.2552 9.10717 17.0072 9.08017 17.4522 9.51617Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
