import { defaultMemoize } from 'reselect';
import { shallowEqual } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { ModuleState } from '@webapp/store/types';
import { isEqual, round } from 'lodash';
import { ModuleId } from '@lib/robo/types';

const selectorOptions = {
  memoize: defaultMemoize,
  memoizeOptions: {
    equalityCheck: shallowEqual,
    resultEqualityCheck: isEqual,
    maxSize: 10,
  },
  argsMemoize: defaultMemoize,
  argsMemoizeOptions: {
    equalityCheck: shallowEqual,
    resultEqualityCheck: isEqual,
    maxSize: 10,
  },
};

const selectModuleIds = (_: ModuleState, moduleIds?: Array<ModuleId>) => moduleIds;

export const selectUltrasonics = createSelector(
  [(state: ModuleState) => state.ultrasonics, selectModuleIds],
  (modules, moduleIds) => {
    const result: {
      [key: ModuleId]: string;
    } = {};

    (Object.keys(modules) as ModuleId[]).forEach(moduleId => {
      if (moduleIds && !moduleIds.includes(moduleId)) {
        return;
      }
      result[moduleId] = modules[moduleId].sensorData?.distance ?? null;
    });

    return result;
  },
  selectorOptions
);

export const selectButtons = createSelector(
  [(state: ModuleState) => state.buttons, selectModuleIds],
  (modules, moduleIds) => {
    const result: {
      [key: ModuleId]: string;
    } = {};

    (Object.keys(modules) as ModuleId[]).forEach(moduleId => {
      if (moduleIds && !moduleIds.includes(moduleId)) {
        return;
      }
      result[moduleId] = modules[moduleId].sensorData?.pressed ?? null;
    });

    return result;
  },
  selectorOptions
);

export const selectAccelerometers = createSelector(
  [(state: ModuleState) => state.accelerometers, selectModuleIds],
  (modules, moduleIds) => {
    const result: {
      [key: ModuleId]: {
        x: number;
        y: number;
        z: number;
      } | null;
    } = {};

    (Object.keys(modules) as ModuleId[]).forEach(moduleId => {
      if (moduleIds && !moduleIds.includes(moduleId)) {
        return;
      }
      result[moduleId] = modules[moduleId].sensorData?.acceleration
        ? {
            x: round(modules[moduleId].sensorData?.acceleration.x, 1),
            y: round(modules[moduleId].sensorData?.acceleration.y, 1),
            z: round(modules[moduleId].sensorData?.acceleration.z, 1),
          }
        : null;
    });

    return result;
  },
  selectorOptions
);

export const selectLightSensors = createSelector(
  [(state: ModuleState) => state.lightSensors, selectModuleIds],
  (modules, moduleIds) => {
    const result: {
      [key: ModuleId]: any;
    } = {};

    (Object.keys(modules) as ModuleId[]).forEach(moduleId => {
      if (moduleIds && !moduleIds.includes(moduleId)) {
        return;
      }
      result[moduleId] = modules[moduleId].sensorData?.intensity ?? null;
    });

    return result;
  },
  selectorOptions
);

export const selectLineTrackers = createSelector(
  [(state: ModuleState) => state.linetrackers, selectModuleIds],
  (modules, moduleIds) => {
    const result: {
      [key: ModuleId]: any;
    } = {};

    (Object.keys(modules) as ModuleId[]).forEach(moduleId => {
      if (moduleIds && !moduleIds.includes(moduleId)) {
        return;
      }
      result[moduleId] = modules[moduleId].sensorData?.positions ?? null;
    });

    return result;
  },
  selectorOptions
);

export const selectMotions = createSelector(
  [(state: ModuleState) => state.motions, selectModuleIds],
  (modules, moduleIds) => {
    const result: {
      [key: ModuleId]: any;
    } = {};

    (Object.keys(modules) as ModuleId[]).forEach(moduleId => {
      if (moduleIds && !moduleIds.includes(moduleId)) {
        return;
      }
      result[moduleId] = modules[moduleId].sensorData?.motion ?? null;
    });

    return result;
  },
  selectorOptions
);
