import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PlayArrow } from '@mui/icons-material';

import { AbortablePromise } from '@lib/utils/abortable-promise';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { ExecutableActionWidgetComponent, WidgetExecutionType, ActionWidgetExecutionResult } from '@webapp/store/types';

import SoundsMap from './sounds-map';

const PlayButton = styled(Button)({
  color: 'white',
  width: '70px',
  height: '70px',
  borderRadius: '50%',
});

const CodeSoundWidget: ExecutableActionWidgetComponent<CodeSoundWidgetData> = ({ id }) => {
  const { getWidgetById } = useCodeEditor();
  const { isPlaying } = useEditorContext();

  const { model: roboModel, connected } = useRobo();
  const widget = getWidgetById<CodeSoundWidgetData>(id);

  if (!widget) {
    return null;
  }

  const widgetData = widget.data;
  const moduleId = widgetData?.moduleIds[0];

  const handlePlayClick = () => {
    if (!roboModel || isPlaying || !widgetData) {
      return;
    }

    const SYSTEM = roboModel.modules.systems[moduleId as keyof typeof roboModel.modules.systems];
    const soundName = SoundsMap[widgetData.sound as keyof typeof SoundsMap];
    SYSTEM.playSound(soundName as any);
  };

  return (
    <Box sx={{ width: '200px', height: '150px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <PlayButton variant="contained" color="primary" onClick={handlePlayClick} disabled={!connected}>
        <PlayArrow sx={{ fontSize: '36px' }} />
      </PlayButton>
    </Box>
  );
};

/**
 * Executes the sound widget
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @param signal The abort signal
 * @returns The execution result
 */
CodeSoundWidget.execute = async ({ roboModel, widgetId, getWidgetById, signal }) => {
  const widget = getWidgetById(widgetId);

  if (!widget) {
    throw new Error('Widget not found');
  }

  const widgetData = widget.data;
  const moduleId = widgetData?.moduleIds[0];

  if (!moduleId || !roboModel) {
    throw new Error('Module or RoboModel not found');
  }

  const SYSTEM = roboModel.modules.systems[moduleId as keyof typeof roboModel.modules.systems];
  const soundName = SoundsMap[widgetData.sound as keyof typeof SoundsMap];

  if (!soundName || typeof soundName !== 'string') {
    throw new Error('Invalid sound name or type');
  }

  // Return a promise that can be aborted
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    SYSTEM.setPlaySoundAction(soundName as any, ({ isError }) => {
      resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
    });
  });
};

export type CodeSoundWidgetData = Record<string, never>;

CodeSoundWidget.initialData = {};

export default CodeSoundWidget;
