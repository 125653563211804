import { Box } from '@mui/material';

import PlotExampleImage from '@webapp/assets/plot.jpg';

export const PlotExample = () => {
  return (
    <Box
      sx={{
        padding: '10px 50px 0',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img src={PlotExampleImage} alt="" style={{ width: '100%' }} />
    </Box>
  );
};
