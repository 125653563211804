import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

import { convertBytesArrayToInt } from '@lib/utils/hex';

// Max light intensity in lumen
export const LUMEN_MAX_LIGHT = 65535;

export enum LightSensorCondition {
  LessThan = 'lessThan',
  GreaterThan = 'greaterThan',
}

export class LightSensor extends BaseModule<typeof LightSensor> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.LightSensor, store);

    this.client.registerHandler(
      HandlerType.OnLightLevel,
      ({ data }) => {
        const intensity = LightSensor.parsePayload(data);

        const currentData = this.getDataState();
        const hasChanges = !currentData.sensorData || currentData.sensorData.intensity !== intensity;
        if (!hasChanges) {
          return;
        }

        this.updateDataState({
          sensorData: {
            intensity,
          },
        });
      },
      id
    );
  }

  /**
   * Sets the light level trigger.
   * @param lightLevel - The light level to trigger.
   * @param onActionDone - The callback to call when the action is done.
   * @returns The trigger id.
   */
  createTrigger(condition: LightSensorCondition, lightLevel: number, onActionDone: onActionDoneType) {
    const triggerId = this.generateActionOrTriggerId();
    const triggerCondition = condition === LightSensorCondition.LessThan ? 0 : 1;
    const desiredLightLevel = LightSensor.convertPercentagesToLumen(lightLevel);

    this.subscribeToResponse(triggerId, onActionDone);

    this.client.setLightTrigger(triggerId, this.index, triggerCondition, desiredLightLevel);

    return { triggerId };
  }

  /**
   * Parses the payload from the light level handler.
   * @param {number[]} data - The data to parse.
   * @returns {number} The parsed light level.
   */
  static parsePayload(data: number[]) {
    return convertBytesArrayToInt(data);
  }

  /**
   * Converts a lumen value to a percentage.
   * @param {number} lumen - The lumen value to convert.
   * @returns {number} The equivalent percentage of the lumen value.
   */
  static convertLumenToPercentages(lumen: number) {
    return (100 * Math.log10(lumen + 1)) / Math.log10(LUMEN_MAX_LIGHT + 1);
  }

  /**
   * Converts a percentage to a lumen value.
   * @param {number} percentage - The percentage to convert.
   * @returns {number} The equivalent lumen value.
   */
  static convertPercentagesToLumen(percentage: number) {
    return Math.round(Math.pow(10, (percentage * Math.log10(LUMEN_MAX_LIGHT + 1)) / 100) - 1);
  }

  static initialDataState() {
    return {
      sensorData: {
        intensity: 0,
      },
    };
  }
}
