export const config = {
  maxActionsPerGroup: 7,
  actionSize: 71,

  labelWidth: 100,
  labelHeight: 36,

  edgesOffset: 24,
  edgeWithNodeOverlap: 30,
  triggerZoneWidth: 120,
  triggerZoneHeight: 56,

  triggerHeight: 36,
  triggerWidth: 96,
  triggerOverlap: 30,

  throttleDragEvents: 50, // ms
  performAdditionalDrawCallAfter: 100, // ms

  overlappingGroupSafeZone: 96, // px

  colors: {
    sourceDummyColor: '#FF000022',
    sourceDummyBorderColor: '#00000055',
    targetDummyColor: '#00FF0022',
    targetDummyBorderColor: '#00000055',
  },

  groups: [
    {
      // actions in group: 0
      groupSize: 116,
      actions: [{ x: 23, y: 23 }],
    },
    {
      // actions in group: 1
      groupSize: 116,
      actions: [{ x: 23, y: 23 }],
    },
    {
      // actions in group: 2
      groupSize: 188,
      actions: [
        { x: 18, y: 59 },
        { x: 99, y: 59 },
      ],
    },

    {
      // actions in group: 3
      groupSize: 213,
      actions: [
        { x: 71, y: 16 },
        { x: 30, y: 97 },
        { x: 111, y: 97 },
      ],
    },

    {
      // actions in group: 4
      groupSize: 233,
      actions: [
        { x: 41, y: 40 },
        { x: 122, y: 40 },
        { x: 41, y: 121 },
        { x: 122, y: 121 },
      ],
    },

    {
      // actions in group: 5
      groupSize: 282,
      actions: [
        { x: 66, y: 50 },
        { x: 147, y: 50 },
        { x: 25, y: 131 },
        { x: 106, y: 131 },
        { x: 187, y: 131 },
      ],
    },

    {
      // actions in group: 6
      groupSize: 297,
      actions: [
        { x: 32, y: 73 },
        { x: 113, y: 73 },
        { x: 194, y: 73 },
        { x: 32, y: 154 },
        { x: 113, y: 154 },
        { x: 194, y: 154 },
      ],
    },

    {
      // actions in group: 7
      groupSize: 323,
      actions: [
        { x: 127, y: 26 },
        { x: 46, y: 107 },
        { x: 127, y: 107 },
        { x: 208, y: 107 },
        { x: 46, y: 188 },
        { x: 127, y: 188 },
        { x: 208, y: 188 },
      ],
    },
  ],
};
