import { Fragment } from 'react';

import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';
import { TurnWidgetData } from '@webapp/components/blocks/widgets/code/turn-action/code-turn-widget';

// Extracted for future purposes
const fillColor = 'white';
const strokeColor = '#5A418B';

type IconProps = {
  value: TurnWidgetData;
};

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { angleValue } = value;

  let minAngle, maxAngle;

  if (Array.isArray(angleValue)) {
    [minAngle, maxAngle] = [...angleValue].sort((a, b) => a - b);
  } else {
    minAngle = angleValue;
    maxAngle = undefined;
  }

  let arrowType;
  if (maxAngle === undefined) {
    arrowType = minAngle >= 0 ? 'CW' : 'CCW';
  } else {
    arrowType = (minAngle + maxAngle) / 2 >= 0 ? 'CW' : 'CCW';
  }

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '73px' }}>
        {arrows[arrowType as keyof typeof arrows]}

        {maxAngle === undefined ? (
          <text x="35" y="35" fill="white" fontSize="10" textAnchor="middle">
            {minAngle}
          </text>
        ) : (
          <text x="35" y="33" fill="white" fontSize="10" textAnchor="middle">
            {minAngle} -{' '}
            <tspan x={35} dy={9}>
              {maxAngle}
            </tspan>
          </text>
        )}
      </svg>
    </SvgIcon>
  );
};

const arrows = {
  // Arrow pointing from South to North
  CCW: (
    <Fragment>
      <path
        d="M25.9842 16.9747C33.3294 12.0822 43.3745 12.6397 50.1352 18.8437C58.0225 26.0816 58.4579 38.2661 51.0974 46.0346C43.7624 53.7749 31.4641 54.1939 23.6045 46.9814C22.3837 45.8612 22.3022 43.9635 23.4224 42.7427C24.5427 41.5219 26.4404 41.4405 27.6612 42.5607C33.1116 47.5623 41.6652 47.2652 46.7422 41.9075C51.7936 36.576 51.501 28.2405 46.0785 23.2644C41.383 18.9556 34.3845 18.5793 29.2898 21.9838L31.0162 24.6L19.0002 24.744L24.0212 14L25.9842 16.9747Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  CW: (
    <Fragment>
      <path
        d="M45.0158 16.9747C37.6706 12.0822 27.6255 12.6397 20.8648 18.8437C12.9775 26.0816 12.5421 38.2661 19.9026 46.0346C27.2376 53.7749 39.5359 54.1939 47.3955 46.9814C48.6163 45.8612 48.6978 43.9635 47.5776 42.7427C46.4573 41.5219 44.5596 41.4405 43.3388 42.5607C37.8884 47.5623 29.3348 47.2652 24.2578 41.9075C19.2064 36.576 19.499 28.2405 24.9215 23.2644C29.617 18.9556 36.6155 18.5793 41.7102 21.9838L39.9838 24.6L51.9998 24.744L46.9788 14L45.0158 16.9747Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),
};
export default Icon;
