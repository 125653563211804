import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="32.5" y="6.5" width="3" height="25" rx="1.5" transform="rotate(90 32.5 6.5)" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M37 8l-8 4.572V3.429L37 8zM3 8l8 4.572V3.429L3 8z" fill="#fff" />
        <rect x="40" width="16" height="3" rx="1.5" transform="rotate(90 40 0)" fill="#fff" />
        <rect x="3" width="16" height="3" rx="1.5" transform="rotate(90 3 0)" fill="#fff" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
