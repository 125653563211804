import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 28 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.393.546C4.87.802 3.646 1.911 3.21 3.43l-.107.373V15.05l.127.24c.246.466.644.672 1.304.674.928.004 1.365-.347 1.482-1.188.034-.242.051-2.062.051-5.459V4.22l.105-.206c.057-.113.185-.269.284-.348l.179-.143 7.474-.011 7.474-.011.185.105c.109.062.244.2.327.334l.142.229.024 5.394c.018 4.115.038 5.434.082 5.562.095.277.305.523.574.672.243.134.276.14.836.14.567 0 .591-.005.84-.145.31-.175.487-.414.583-.787.105-.413.107-10.587.001-11.193-.151-.866-.495-1.526-1.12-2.154-.445-.446-.743-.648-1.281-.867-.718-.292-.283-.278-8.646-.271-4.191.003-7.672.015-7.737.026m6.044 6.245a3.453 3.453 0 01-.177.043c-.194.043-.476.324-.589.587-.1.231-.1.257-.087 3.021.012 2.716.015 2.792.109 2.951.125.213.35.361.607.4.455.068.496.048 2.69-1.32 1.137-.709 2.146-1.351 2.243-1.426.203-.158.36-.434.36-.633 0-.188-.119-.508-.257-.689-.114-.15-4.128-2.7-4.464-2.836-.188-.076-.372-.118-.435-.098m1.642 3.155c.252.157.485.311.519.342.05.047-.041.122-.473.392a7.874 7.874 0 01-.563.333c-.016 0-.029-.304-.029-.676 0-.395.019-.677.045-.677.024 0 .25.128.501.286M1.631 16.397c-.305.082-.511.215-.684.442-.21.276-.266.51-.262 1.104.014 2.123 1.288 4.121 3.197 5.016a5.878 5.878 0 001.559.466c.266.038 2.768.048 8.932.037 8.068-.014 8.584-.02 8.912-.099 2.101-.508 3.615-2.017 4.188-4.173.104-.389.122-.556.126-1.177.004-.7 0-.731-.125-.987-.155-.316-.368-.489-.744-.606-.268-.084-.718-.087-12.582-.084-9.581.003-12.352.016-12.517.061m22.545 3.012a2.89 2.89 0 01-.324.365c-.312.311-.789.583-1.163.661-.175.037-2.649.052-8.563.051-9.085 0-8.5.017-9.085-.279-.294-.148-.934-.721-.934-.836 0-.037 2.755-.051 10.058-.051 9.874 0 10.057.002 10.011.089"
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
