type ESPErrorReason =
  | 'user_not_grant_access'
  | 'device_lost'
  | 'port_open_error'
  | 'port_is_closed'
  | 'cannot_connect_to_port'
  | 'uart_is_not_configured';

export class ESPError extends Error {
  public readonly reason?: ESPErrorReason;
  public readonly errorType = 'ESPError';

  constructor(message: string, reason?: ESPErrorReason) {
    super(message);

    this.message = message;
    this.reason = reason;
  }

  static createFromError(e: Error, reason?: ESPErrorReason) {
    const espError = new ESPError(e.message, reason);
    espError.stack = e.stack;

    return espError;
  }
}

export const loadESPToolPackage = async () => {
  return await import('esptool-js');
};

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const supported = () => {
  return 'serial' in navigator;
};

export { sleep, supported };
