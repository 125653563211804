import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.1916 17.3866C33.1916 16.5475 33.8718 15.8672 34.711 15.8672H37.7499C38.5891 15.8672 39.2693 16.5475 39.2693 17.3866C39.2693 18.2258 38.5891 18.9061 37.7499 18.9061H34.711C33.8718 18.9061 33.1916 18.2258 33.1916 17.3866Z"
          fill="white"
        />
        <path
          d="M29.0123 33.2491C28.4507 32.6255 28.5011 31.6648 29.1247 31.1033C29.7483 30.5418 30.7091 30.5921 31.2706 31.2157L36.6298 37.1677C36.7472 37.1491 36.8675 37.1394 36.9902 37.1394C38.2489 37.1394 39.2693 38.1598 39.2693 39.4186C39.2693 40.6773 38.2489 41.6977 36.9902 41.6977C35.7873 41.6977 34.8021 40.766 34.717 39.5849L29.0123 33.2491Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7568 38.6589C18.7568 29.0084 26.58 21.1852 36.2304 21.1852C45.8809 21.1852 53.7041 29.0084 53.7041 38.6589C53.7041 48.3093 45.8809 56.1325 36.2304 56.1325C26.58 56.1325 18.7568 48.3093 18.7568 38.6589ZM36.2304 25.7436C29.0975 25.7436 23.3152 31.5259 23.3152 38.6589C23.3152 45.7918 29.0975 51.5741 36.2304 51.5741C43.3634 51.5741 49.1457 45.7918 49.1457 38.6589C49.1457 31.5259 43.3634 25.7436 36.2304 25.7436Z"
          fill="white"
        />
        <path
          d="M51.4624 22.8353C50.8794 22.2317 49.9175 22.2149 49.3139 22.7978C48.7102 23.3808 48.6935 24.3427 49.2764 24.9463L51.3874 27.1323C51.9703 27.736 52.9322 27.7527 53.5359 27.1698C54.1395 26.5869 54.1563 25.625 53.5734 25.0213L51.4624 22.8353Z"
          fill="white"
        />
        <path
          d="M33.1916 17.3866C33.1916 16.5475 33.8718 15.8672 34.711 15.8672H37.7499C38.5891 15.8672 39.2693 16.5475 39.2693 17.3866C39.2693 18.2258 38.5891 18.9061 37.7499 18.9061H34.711C33.8718 18.9061 33.1916 18.2258 33.1916 17.3866Z"
          stroke="#5A418B"
          strokeWidth="2.01326"
        />
        <path
          d="M29.0123 33.2491C28.4507 32.6255 28.5011 31.6648 29.1247 31.1033C29.7483 30.5418 30.7091 30.5921 31.2706 31.2157L36.6298 37.1677C36.7472 37.1491 36.8675 37.1394 36.9902 37.1394C38.2489 37.1394 39.2693 38.1598 39.2693 39.4186C39.2693 40.6773 38.2489 41.6977 36.9902 41.6977C35.7873 41.6977 34.8021 40.766 34.717 39.5849L29.0123 33.2491Z"
          stroke="#5A418B"
          strokeWidth="2.01326"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7568 38.6589C18.7568 29.0084 26.58 21.1852 36.2304 21.1852C45.8809 21.1852 53.7041 29.0084 53.7041 38.6589C53.7041 48.3093 45.8809 56.1325 36.2304 56.1325C26.58 56.1325 18.7568 48.3093 18.7568 38.6589ZM36.2304 25.7436C29.0975 25.7436 23.3152 31.5259 23.3152 38.6589C23.3152 45.7918 29.0975 51.5741 36.2304 51.5741C43.3634 51.5741 49.1457 45.7918 49.1457 38.6589C49.1457 31.5259 43.3634 25.7436 36.2304 25.7436Z"
          stroke="#5A418B"
          strokeWidth="2.01326"
        />
        <path
          d="M51.4624 22.8353C50.8794 22.2317 49.9175 22.2149 49.3139 22.7978C48.7102 23.3808 48.6935 24.3427 49.2764 24.9463L51.3874 27.1323C51.9703 27.736 52.9322 27.7527 53.5359 27.1698C54.1395 26.5869 54.1563 25.625 53.5734 25.0213L51.4624 22.8353Z"
          stroke="#5A418B"
          strokeWidth="2.01326"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
