import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.855769"
          y="0.730769"
          width="16"
          height="28"
          rx="2.50923"
          fill="#FF9445"
          stroke="#E06002"
          strokeWidth="0.538462"
        />
        <circle cx="9" cy="23.75" r="1.75" fill="#E06002" />
        <path
          d="M8.15967 3.71669C8.21837 3.65798 8.298 3.625 8.38102 3.625H9.6869C9.76992 3.625 9.84955 3.65798 9.90825 3.71669L10.0648 3.87321C10.262 4.07041 10.1223 4.40761 9.84342 4.40761H8.2245C7.94561 4.40761 7.80594 4.07041 8.00315 3.87321L8.15967 3.71669Z"
          fill="#E06002"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
