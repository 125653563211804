import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { ModuleId } from '@lib/robo/types';
import { AccelerometerEventType } from '@lib/robo/modules/accelerometer';

import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';
import { AccelPickIcon, AccelPutIcon, AccelMoveIcon } from '@webapp/components/icons';
import ColoredDot from '@webapp/components/ui/colored-dot';

import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';

// Constants
const CHECK_INTERVAL = 200; // ms

const AccelerometerTriggerWidget: ExecutableTriggerWidgetComponent<AccelerometerTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();
  const { model: roboModel } = useRobo();

  const widget = getWidgetById<AccelerometerTriggerWidgetData>(id);
  const widgetData = widget?.data;

  const [eventType, setEventType] = useState<AccelerometerEventType>(
    widgetData?.eventType || AccelerometerTriggerWidget.initialData.eventType
  );

  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const ACCELEROMETER = roboModel?.modules.accelerometers[moduleId];
  const activeEvents = ACCELEROMETER?.getDataState().sensorData.activeEvents;

  useEffect(() => {
    if (!ACCELEROMETER) return;

    const checkAccelerometer = () => {
      ACCELEROMETER?.requestAccelerometerState();
    };

    const interval = setInterval(checkAccelerometer, CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [ACCELEROMETER]);

  const handleEventTypeChange = (newEventType: AccelerometerEventType) => {
    setEventType(newEventType);
    updateWidgetData(id, { eventType: newEventType });
  };

  return (
    <Box sx={{ width: '300px', height: '185px', display: 'flex' }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          {ACCELEROMETER && (
            <ColoredDot color={activeEvents?.[AccelerometerEventType.PutDown] ? '#DF1642' : '#D4D4D4'} />
          )}

          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<AccelPutIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === AccelerometerEventType.PutDown}
            value={eventType === AccelerometerEventType.PutDown}
            onChange={() => handleEventTypeChange(AccelerometerEventType.PutDown)}
            sx={{ mt: 1 }}
            text={
              <Typography variant="x-xsmall-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                Put Down
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {ACCELEROMETER && (
            <ColoredDot color={activeEvents?.[AccelerometerEventType.PickUp] ? '#DF1642' : '#D4D4D4'} />
          )}

          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<AccelPickIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === AccelerometerEventType.PickUp}
            value={eventType === AccelerometerEventType.PickUp}
            onChange={() => handleEventTypeChange(AccelerometerEventType.PickUp)}
            sx={{ mt: 1 }}
            text={
              <Typography variant="x-xsmall-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                Pick Up
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          {ACCELEROMETER && <ColoredDot color={activeEvents?.[AccelerometerEventType.Move] ? '#DF1642' : '#D4D4D4'} />}

          <RoundToggleIconButton
            mainColor="#DF1642"
            secondaryColor="#D4D4D4"
            tertiaryColor="#FFFFFF"
            buttonSize={80}
            icon={<AccelMoveIcon sx={{ fontSize: '68px' }} />}
            selected={eventType === AccelerometerEventType.Move}
            value={eventType === AccelerometerEventType.Move}
            onChange={() => handleEventTypeChange(AccelerometerEventType.Move)}
            sx={{ mt: 1 }}
            text={
              <Typography variant="x-xsmall-semibold" sx={{ color: '#5A418B', mt: 1 }}>
                In Motion
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Execute the motion trigger widget
 * @param roboModel - The robo model
 * @param widgetId - The widget id
 * @param getWidgetById - The get widget by id function
 * @param signal - The signal
 * @returns The execution result
 */
AccelerometerTriggerWidget.execute = async ({ roboModel, widgetId, getWidgetById, signal }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;
      const accelerometerModuleId = data?.moduleIds[0];
      const ACCELEROMETER = roboModel.modules.accelerometers[accelerometerModuleId as ModuleId];

      if (!ACCELEROMETER) {
        reject(new Error('Accelerometer module not found'));
        return;
      }

      ACCELEROMETER.createTrigger(data.eventType, ({ isError }) => {
        if (isError) {
          reject(new Error('Error with accelerometer trigger'));
        } else {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
          });
        }
      });
    },
    `AccelerometerTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

/**
 * The motion trigger widget data
 */
export type AccelerometerTriggerWidgetData = {
  eventType: AccelerometerEventType;
};

/**
 * The initial data for the motion trigger widget
 */
AccelerometerTriggerWidget.initialData = {
  eventType: AccelerometerEventType.PickUp,
};

export default AccelerometerTriggerWidget;
