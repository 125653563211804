import { ModuleType } from '@webapp/store/types';
import { RoboModel } from '@lib/robo/robo-model';
import { SensorsStore } from '@webapp/components/record-sensors/sensors-recorder-provider';
import { formatMilliseconds } from '@lib/utils/time';
import { ModuleId, ModulesCollectionTypes } from '@lib/robo/types';

export const valuesTransformers: {
  [key in ModuleType]?: {
    headers: (index: number | string) => Array<string>;
    values: (value: any) => Array<string | number>;
  };
} = {
  accelerometers: {
    headers: index => {
      return [`Accelerometer x ${index} (G)`, `Accelerometer y ${index} (G)`, `Accelerometer z ${index} (G)`];
    },
    values: (value: { x: number; y: number; z: number } | null) => {
      if (value === null) {
        return ['', '', ''];
      }
      return [value.x, value.y, value.z];
    },
  },
  buttons: {
    headers: index => {
      return [`Button ${index} (Binary)`];
    },
    values: (value: boolean | null) => {
      if (value === null) {
        return [''];
      }
      return [Number(value)];
    },
  },
  ultrasonics: {
    headers: index => {
      return [`Distance ${index} (cm)`];
    },
    values: (value: number | null) => {
      if (value === null) {
        return [''];
      }
      return [value];
    },
  },
  lightSensors: {
    headers: index => {
      return [`Light ${index} (LUX)`];
    },
    values: (value: number | null) => {
      if (value === null) {
        return [''];
      }
      return [value];
    },
  },
  linetrackers: {
    headers: index => {
      return [
        `Line follower left ${index} (Boolean)`,
        `Line follower center ${index} (Boolean)`,
        `Line follower right ${index} (Boolean)`,
      ];
    },
    values: (value: { left: boolean; middle: boolean; right: boolean } | null) => {
      if (value === null) {
        return ['', '', ''];
      }
      return [Number(value.left), Number(value.middle), Number(value.right)];
    },
  },
  motions: {
    headers: index => {
      return [`Motion ${index} (Boolean)`];
    },
    values: (value: boolean | null) => {
      if (value === null) {
        return [''];
      }
      return [Number(value)];
    },
  },
};

const sensorsOrderInTable: Array<ModuleType> = [
  'accelerometers',
  'buttons',
  'ultrasonics',
  'lightSensors',
  'linetrackers',
  'motions',
];

export const convertSensorRecordsToTable = (sensorRecords: SensorsStore) => {
  // Converting is implemented in two stages
  // 1. Prepare data for iterating over it
  // 2. Iterate over data and generate result table

  // 1. Prepare data
  const timestamps = new Set<number>();
  const timestampsMap = new Map<number, any>();
  const modulesList: Array<{
    moduleType: ModulesCollectionTypes;
    moduleIndex: number;
  }> = [];

  for (const moduleType in sensorRecords) {
    for (const moduleId in sensorRecords[moduleType as ModuleType]) {
      modulesList.push({
        moduleType: moduleType as ModulesCollectionTypes,
        moduleIndex: parseInt(moduleId),
      });
      sensorRecords[moduleType as ModulesCollectionTypes]?.[moduleId].forEach(sensorRecord => {
        const ts = sensorRecord[0];
        const value = sensorRecord[1];
        timestamps.add(sensorRecord[0]);
        if (!timestampsMap.has(ts)) {
          timestampsMap.set(ts, {});
        }
        if (!timestampsMap.get(ts)[moduleType]) {
          timestampsMap.get(ts)[moduleType] = {};
        }
        if (!timestampsMap.get(ts)[moduleType][moduleId]) {
          timestampsMap.get(ts)[moduleType][moduleId] = value;
        }
      });
    }
  }

  if (timestamps.size === 0) {
    return [];
  }

  const timestampsMapEntries = Array.from(timestampsMap.entries()).sort((a, b) => {
    return a[0] - b[0];
  });

  const orderedModulesList = modulesList.sort((a, b) => {
    const diff =
      sensorsOrderInTable.indexOf(a.moduleType as ModuleType) - sensorsOrderInTable.indexOf(b.moduleType as ModuleType);
    if (diff === 0) {
      return a.moduleIndex - b.moduleIndex;
    }
    return diff;
  });

  // 2. Iterate over data and generate result table
  const tableHeader: Array<string> = ['Time'];
  for (const module of orderedModulesList) {
    const transformer = valuesTransformers[module.moduleType];
    if (!transformer) {
      throw new Error(`Transform ${module.moduleType} not found`);
    }
    tableHeader.push(...transformer.headers(module.moduleIndex));
  }

  const tableData: Array<Array<string | number>> = [tableHeader];
  timestampsMapEntries.forEach(entry => {
    const [ts, modulesData] = entry;
    const rowData: Array<number | string> = [ts];
    orderedModulesList.forEach(module => {
      const transformer = valuesTransformers[module.moduleType];
      if (!transformer) {
        throw new Error(`Transform ${module.moduleType} not found`);
      }
      rowData.push(...transformer.values(modulesData[module.moduleType]?.[module.moduleIndex] ?? null));
    });
    tableData.push(rowData);
  });

  return tableData;
};

export const saveSensorValues = (
  sensorsStore: SensorsStore,
  sensorsData: {
    [key: string]: any;
  },
  sensorType: ModulesCollectionTypes,
  timestamp: number
) => {
  Object.keys(sensorsData).forEach(moduleId => {
    if (!sensorsStore[sensorType]) {
      sensorsStore[sensorType] = {};
    }
    const { position } = RoboModel.parseModuleId(moduleId as ModuleId);
    const sensorStore = sensorsStore[sensorType];
    if (sensorStore) {
      if (!sensorStore[position]) {
        sensorStore[position] = [];
      }
      sensorStore[position].push([timestamp, sensorsData[moduleId]]);
    }
  });
};

export const createCSVFromTable = (table: (string | number)[][], csvDelimiter = ','): string => {
  if (table.length === 0) {
    return '';
  }

  if (table.length === 1) {
    return table[0].join(csvDelimiter);
  }

  const minTimestamp = table[1][0] as number;
  let csvContent = '';

  table.forEach((row, index) => {
    if (index === 0) {
      csvContent += row.join(csvDelimiter);
    } else {
      const elapsedMs = (row[0] as number) - minTimestamp;
      csvContent += `\n${formatMilliseconds(elapsedMs)}${csvDelimiter}${row.slice(1).join(csvDelimiter)}`;
    }
  });

  return csvContent;
};
