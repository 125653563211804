import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { PlayIcon } from '@webapp/components/icons';

const MotorPlayButton = ({ motorId, probeMotor, disabled }) => {
  return (
    <Button
      sx={{
        width: '38px',
        minWidth: '38px',
        height: '38px',
        minHeight: '38px',
        borderRadius: '50%',
        padding: '0',
      }}
      variant="contained"
      color="quaternary"
      disabled={disabled}
      onClick={() => probeMotor(motorId)}
    >
      <PlayIcon sx={{ width: '12px' }} />
    </Button>
  );
};

MotorPlayButton.propTypes = {
  motorId: PropTypes.string.isRequired,
  probeMotor: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MotorPlayButton.defaultProps = {
  disabled: false,
};

export default MotorPlayButton;
