import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

import { ALIGNMENT } from '../joystick-widget';

const MotorAlignmentToggle = ({ currentAlignment, motorId, setMotorAlignment }) => {
  const handleToggleClick = () => {
    let nextAvailableAlignment = ALIGNMENT.none;
    switch (currentAlignment) {
      case ALIGNMENT.left:
        nextAvailableAlignment = ALIGNMENT.none;
        break;
      case ALIGNMENT.none:
        nextAvailableAlignment = ALIGNMENT.right;
        break;
      case ALIGNMENT.right:
        nextAvailableAlignment = ALIGNMENT.left;
        break;
    }

    setMotorAlignment(motorId, nextAvailableAlignment);
  };

  return (
    <Button
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        height: '38px',
        borderRadius: '19px',
      }}
      variant="contained"
      color="quaternary"
      fullWidth
      onClick={handleToggleClick}
    >
      <Typography
        variant="x-button1-semibold"
        sx={{
          opacity: currentAlignment === ALIGNMENT.left ? '1' : '0.20',
        }}
      >
        L
      </Typography>

      <Typography
        variant="x-button1-semibold"
        sx={{
          opacity: currentAlignment === ALIGNMENT.none ? '1' : '0.20',
          ml: 1,
          mr: 1,
        }}
      >
        -
      </Typography>

      <Typography
        variant="x-button1-semibold"
        sx={{
          opacity: currentAlignment === ALIGNMENT.right ? '1' : '0.20',
        }}
      >
        R
      </Typography>
    </Button>
  );
};

MotorAlignmentToggle.propTypes = {
  motorId: PropTypes.string.isRequired,
  currentAlignment: PropTypes.oneOf(['left', 'none', 'right']).isRequired,
  setMotorAlignment: PropTypes.func.isRequired,
};

MotorAlignmentToggle.defaultProps = {
  currentAlignment: 'none',
};

export default MotorAlignmentToggle;
