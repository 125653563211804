import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop'; // todo: Take from our icons

import { PlayIcon } from '@webapp/components/icons';
import { colors as themeColors } from '@themes/config/theme-colors';
import { AnimateGlobal } from '@components/Animation/animate';
import { ElementsIds } from '@constants/elements-ids';

export const BUTTON_TYPES = {
  play: 'play',
  stop: 'stop',
} as const;

export const PlayStopButton = ({
  type,
  disabled,
  onClick,
  tooltip,
}: {
  type: keyof typeof BUTTON_TYPES;
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
}) => {
  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{
          marginBottom: '10px',
        }}
      >
        <AnimateGlobal
          id={ElementsIds.WebappPlayStopButton}
          animations={{
            strobe: {
              color: themeColors.violet['900'],
            },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              width: '120px',
              height: '120px',
              background: disabled ? '#AAAAAA' : type === BUTTON_TYPES.stop ? '#DD383F' : '#26882D',
              borderRadius: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: themeColors.white['500'],
            }}
            disabled={disabled}
            onClick={onClick}
          >
            {type === BUTTON_TYPES.play ? (
              <PlayIcon sx={{ width: '67px', height: '58px' }} />
            ) : (
              <StopIcon sx={{ width: '67px', height: '67px' }} />
            )}
            <Typography variant="x-headline5-bold">{type}</Typography>
          </Button>
        </AnimateGlobal>
      </Box>
    </Tooltip>
  );
};

PlayStopButton.propTypes = {
  type: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PlayStopButton.defaultProps = {
  type: BUTTON_TYPES.play,
  disabled: false,
};
