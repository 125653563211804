import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#FEC84B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.3335 9.66683H12.3335V1.66683C12.3335 1.31321 12.193 0.974069 11.943 0.724021C11.6929 0.473972 11.3538 0.333496 11.0002 0.333496C10.6465 0.333496 10.3074 0.473972 10.0574 0.724021C9.80731 0.974069 9.66683 1.31321 9.66683 1.66683V9.66683H1.66683C1.31321 9.66683 0.974069 9.80731 0.724021 10.0574C0.473972 10.3074 0.333496 10.6465 0.333496 11.0002C0.333496 11.3538 0.473972 11.6929 0.724021 11.943C0.974069 12.193 1.31321 12.3335 1.66683 12.3335H9.66683V20.3335C9.66683 20.6871 9.80731 21.0263 10.0574 21.2763C10.3074 21.5264 10.6465 21.6668 11.0002 21.6668C11.3538 21.6668 11.6929 21.5264 11.943 21.2763C12.193 21.0263 12.3335 20.6871 12.3335 20.3335V12.3335H20.3335C20.6871 12.3335 21.0263 12.193 21.2763 11.943C21.5264 11.6929 21.6668 11.3538 21.6668 11.0002C21.6668 10.6465 21.5264 10.3074 21.2763 10.0574C21.0263 9.80731 20.6871 9.66683 20.3335 9.66683Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
