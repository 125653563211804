import { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { WidgetId, ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';
import { debounce } from 'lodash';

// Define an interface for the widget data
interface ClockTriggerWidgetData {
  hours: number;
  minutes: number;
}

interface NumberSelectorProps {
  value: number;
  onChange: (value: number) => void;
  range: number[];
}

interface DragState {
  isDragging: boolean;
  startY: number;
  startScrollTop: number;
}

const NUMBER_HEIGHT = 55; // Height of each number in pixels
const CONTAINER_HEIGHT = NUMBER_HEIGHT * 3; // Container shows 3 numbers at a time
const FADE_HEIGHT = Math.floor(NUMBER_HEIGHT * 1.36); // Height of fade effect (~75px when NUMBER_HEIGHT is 55)

const NumberSelector: React.FC<NumberSelectorProps> = ({ value, onChange, range }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dragState = useRef<DragState>({
    isDragging: false,
    startY: 0,
    startScrollTop: 0,
  });

  /**
   * Scroll to the selected value
   */
  useEffect(() => {
    // Ensure the initial scroll happens after mount
    const scrollToValue = () => {
      if (containerRef.current) {
        const index = range.indexOf(value);
        containerRef.current.scrollTop = index * NUMBER_HEIGHT;
      }
    };

    // Execute immediately and after a short delay to ensure proper positioning
    scrollToValue();
    const timeoutId = setTimeout(scrollToValue, 50);

    return () => clearTimeout(timeoutId);
  }, [value, range]);

  /**
   * Debounce the scroll event to prevent excessive re-renders
   */
  const handleScroll = useCallback(
    debounce(() => {
      if (!dragState.current.isDragging && containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        const newIndex = Math.round(scrollTop / NUMBER_HEIGHT);
        if (range[newIndex] !== value && newIndex >= 0 && newIndex < range.length) {
          onChange(range[newIndex]);
        }
      }
    }, 200),
    [onChange, range, value]
  );

  /**
   * Handle the start of a drag event
   */
  const handleDragStart = (clientY: number) => {
    if (containerRef.current) {
      dragState.current = {
        isDragging: true,
        startY: clientY,
        startScrollTop: containerRef.current.scrollTop,
      };
    }
  };

  /**
   * Handle the movement of a drag event
   */
  const handleDragMove = (clientY: number) => {
    if (dragState.current.isDragging && containerRef.current) {
      const delta = dragState.current.startY - clientY;
      containerRef.current.scrollTop = dragState.current.startScrollTop + delta;
    }
  };

  /**
   * Handle the end of a drag event
   */
  const handleDragEnd = () => {
    if (dragState.current.isDragging && containerRef.current) {
      dragState.current.isDragging = false;
      const scrollTop = containerRef.current.scrollTop;
      const newIndex = Math.round(scrollTop / NUMBER_HEIGHT);
      if (range[newIndex] !== value && newIndex >= 0 && newIndex < range.length) {
        onChange(range[newIndex]);
      }
    }
  };

  /**
   * Handle the start of a mouse down event
   */
  const handleMouseDown = (e: React.MouseEvent) => {
    handleDragStart(e.clientY);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  /**
   * Handle the movement of a mouse event
   */
  const handleMouseMove = (e: MouseEvent) => {
    handleDragMove(e.clientY);
  };

  /**
   * Handle the end of a mouse event
   */
  const handleMouseUp = () => {
    handleDragEnd();
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  /**
   * Handle the start of a touch event
   */
  const handleTouchStart = (e: React.TouchEvent) => {
    handleDragStart(e.touches[0].clientY);
  };

  /**
   * Handle the movement of a touch event
   */
  const handleTouchMove = (e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleDragMove(e.touches[0].clientY);
  };

  /**
   * Handle the end of a touch event
   */
  const handleTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleDragEnd();
  };

  return (
    <Box sx={{ position: 'relative', height: CONTAINER_HEIGHT, backgroundColor: '#fafafa' }}>
      {/* Fixed fade overlays */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: FADE_HEIGHT,
          background: 'linear-gradient(to bottom, #fafafa 20%, rgba(250, 250, 250, 0.8) 40%, transparent 100%)',
          zIndex: 3,
          pointerEvents: 'none',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: FADE_HEIGHT,
          background: 'linear-gradient(to top, #fafafa 20%, rgba(250, 250, 250, 0.8) 40%, transparent 100%)',
          zIndex: 3,
          pointerEvents: 'none',
        }}
      />

      {/* Scrollable content */}
      <Box
        ref={containerRef}
        onScroll={handleScroll}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        sx={{
          height: '100%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          scrollbarWidth: 'none',
          userSelect: 'none',
          touchAction: 'none',
          backgroundColor: '#fafafa',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Typography sx={{ height: NUMBER_HEIGHT, lineHeight: `${NUMBER_HEIGHT}px` }}>&nbsp;</Typography>

        {range.map(num => (
          <Typography
            key={num}
            onClick={() => onChange(num)}
            sx={{
              height: NUMBER_HEIGHT,
              lineHeight: `${NUMBER_HEIGHT}px`,
              fontSize: NUMBER_HEIGHT,
              fontWeight: 600,
              color: num === value ? '#DF1642' : 'text.secondary',
              transition: 'color 0.3s',
              cursor: 'pointer',
            }}
          >
            {num < 10 ? `0${num}` : num}
          </Typography>
        ))}

        <Typography sx={{ height: NUMBER_HEIGHT, lineHeight: `${NUMBER_HEIGHT}px` }}>&nbsp;</Typography>
      </Box>
    </Box>
  );
};

const ClockTriggerWidget: ExecutableTriggerWidgetComponent<ClockTriggerWidgetData> = ({ id }: { id: WidgetId }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();
  const widget = getWidgetById<ClockTriggerWidgetData>(id);

  // Get the next minute from now if no saved data
  const getInitialTime = useCallback((): ClockTriggerWidgetData => {
    // If we have saved data and both hours and minutes are defined, use it
    if (widget?.data?.hours !== undefined && widget?.data?.minutes !== undefined) {
      return widget.data;
    }

    const now = new Date();
    // Add one minute to current time
    now.setMinutes(now.getMinutes() + 1);
    now.setSeconds(0);

    const initialTime = {
      hours: now.getHours(),
      minutes: now.getMinutes(),
    };

    // Update widget data with initial time
    updateWidgetData(id, initialTime);

    return initialTime;
  }, [widget?.data, id, updateWidgetData]);

  // Initialize state with the result of getInitialTime
  const initialTime = getInitialTime();
  const [hours, setHours] = useState<number>(initialTime.hours);
  const [minutes, setMinutes] = useState<number>(initialTime.minutes);

  const handleHoursChange = (newHours: number) => {
    setHours(newHours);
    updateWidgetData(id, { hours: newHours });
  };

  const handleMinutesChange = (newMinutes: number) => {
    setMinutes(newMinutes);
    updateWidgetData(id, { minutes: newMinutes });
  };

  return (
    <Box sx={{ width: '350px', height: '165px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} columns={11} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
          <NumberSelector value={hours} onChange={handleHoursChange} range={Array.from({ length: 24 }, (_, i) => i)} />
        </Grid>

        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            sx={{
              height: NUMBER_HEIGHT,
              lineHeight: `${NUMBER_HEIGHT}px`,
              fontSize: NUMBER_HEIGHT,
              fontWeight: 600,
              color: '#DF1642',
            }}
          >
            :
          </Typography>
        </Grid>

        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <NumberSelector
            value={minutes}
            onChange={handleMinutesChange}
            range={Array.from({ length: 60 }, (_, i) => i)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

ClockTriggerWidget.execute = async ({ signal, widgetId, getWidgetById }) => {
  return AbortablePromise(
    signal,
    (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;

      const getTargetTime = () => {
        const now = new Date();
        const target = new Date();
        target.setHours(data.hours);
        target.setMinutes(data.minutes);
        target.setSeconds(0);
        target.setMilliseconds(0);

        // If target time is earlier than now, set it to tomorrow
        if (target.getTime() <= now.getTime()) {
          target.setDate(target.getDate() + 1);
        }

        return target;
      };

      const targetTime = getTargetTime();

      const checkTime = () => {
        const now = new Date();
        if (
          now.getHours() === targetTime.getHours() &&
          now.getMinutes() === targetTime.getMinutes() &&
          now.getDate() === targetTime.getDate()
        ) {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
          });
        } else {
          setTimeout(checkTime, 1000); // Check every second
        }
      };

      console.debug(`[EXEC] Clock trigger will execute at ${targetTime.toLocaleString()}`);
      checkTime();
    },
    `ClockTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

// Define a proper initial data structure instead of null
ClockTriggerWidget.initialData = {
  hours: new Date().getHours(),
  minutes: new Date().getMinutes() + 1,
};

export default ClockTriggerWidget;
