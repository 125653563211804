import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

import { FlashWidgetData } from '@webapp/components/blocks/widgets/code/flash-action/code-flash-widget';

type IconProps = {
  value: FlashWidgetData;
};

import { getContrastColor } from '@lib/utils/color-utils';

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { color, blinks, isBlinksInfinite, isColorRandom } = value;

  const numberOfBlinks = isBlinksInfinite ? '∞' : blinks;

  const gradientColor = isColorRandom ? 'url(#rainbow-gradient)' : color;
  const contrastColor = isColorRandom ? '#FFFFFF' : getContrastColor(color);

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '71px' }}>
        <defs>
          <linearGradient id="rainbow-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#FF6B6B" />
            <stop offset="16.67%" stopColor="#FFD93D" />
            <stop offset="33.33%" stopColor="#6BCB77" />
            <stop offset="50%" stopColor="#4D96FF" />
            <stop offset="66.67%" stopColor="#9B59B6" />
            <stop offset="83.33%" stopColor="#FF6B6B" />
            <stop offset="100%" stopColor="#FFD93D" />
          </linearGradient>
        </defs>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 35C57 46.598 47.598 56 36 56C24.402 56 15 46.598 15 35C15 23.402 24.402 14 36 14C47.598 14 57 23.402 57 35ZM56 35C56 46.0457 47.0457 55 36 55C24.9543 55 16 46.0457 16 35C16 23.9543 24.9543 15 36 15C47.0457 15 56 23.9543 56 35Z"
          fill="#5A418B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36 51C44.8366 51 52 43.8366 52 35C52 26.1634 44.8366 19 36 19C27.1634 19 20 26.1634 20 35C20 43.8366 27.1634 51 36 51ZM56 35C56 46.0457 47.0457 55 36 55C24.9543 55 16 46.0457 16 35C16 23.9543 24.9543 15 36 15C47.0457 15 56 23.9543 56 35ZM36 45C41.5228 45 46 40.5228 46 35C46 29.4772 41.5228 25 36 25C30.4772 25 26 29.4772 26 35C26 40.5228 30.4772 45 36 45ZM50 35C50 42.732 43.732 49 36 49C28.268 49 22 42.732 22 35C22 27.268 28.268 21 36 21C43.732 21 50 27.268 50 35Z"
          fill="white"
        />
        <path
          d="M44 35C44 39.4183 40.4183 43 36 43C31.5817 43 28 39.4183 28 35C28 30.5817 31.5817 27 36 27C40.4183 27 44 30.5817 44 35Z"
          fill={gradientColor}
        />

        <text x="50%" y="50%" fill={contrastColor} fontSize="10" textAnchor="middle" dominantBaseline="middle">
          <tspan dy={0}>{numberOfBlinks}</tspan>
        </text>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
