import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg width="299" height="297" viewBox="0 0 299 297" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="299" height="297" rx="71" fill="#FAEDCD" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33 74C33 50.804 51.804 32 75 32H226C249.196 32 268 50.804 268 74V224C268 247.196 249.196 266 226 266H75C51.804 266 33 247.196 33 224V74ZM75 35H226C247.539 35 265 52.4609 265 74V224C265 245.539 247.539 263 226 263H75C53.4609 263 36 245.539 36 224V74C36 52.4609 53.4609 35 75 35Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 72C1 32.2355 33.2355 0 73 0H227C266.764 0 299 32.2355 299 72V225C299 264.764 266.764 297 227 297H73C33.2355 297 1 264.764 1 225V72ZM73 3H227C265.108 3 296 33.8924 296 72V225C296 263.108 265.108 294 227 294H73C34.8924 294 4 263.108 4 225V72C4 33.8924 34.8924 3 73 3Z"
          fill="black"
        />
        <path
          d="M96 81.5H191.487C196.968 81.5 202.131 84.0672 205.439 88.4364L214.952 101C217.254 104.041 218.5 107.75 218.5 111.564V150.007V186.033C218.5 189.79 217.291 193.447 215.052 196.463L205.426 209.431C202.125 213.878 196.913 216.5 191.374 216.5H96C86.335 216.5 78.5 208.665 78.5 199V99C78.5 89.335 86.335 81.5 96 81.5Z"
          stroke="black"
          strokeWidth="3"
        />
        <circle cx="180" cy="181" r="27.5" stroke="black" strokeWidth="3" />
        <rect x="92.5" y="155.5" width="51" height="50" rx="4.5" stroke="black" strokeWidth="3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183 144V143.995C197.405 143.729 209 131.969 209 117.5C209 103.031 197.405 91.2712 183 91.0046V91H94V144H183ZM183 140.995C198 140.995 206 127.712 206 117.5C206 107.288 198.5 95 183 94.1314L97 94V141L183 140.995Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
