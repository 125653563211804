import { useState } from 'react';

import { Box, Grid } from '@mui/material';

import { ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';

import CircularSlider from '@webapp/components/ui/sliders/circular-slider';
import { LargeMotorIcon } from '@webapp/components/icons';
import RandomButton from '@webapp/components/ui/buttons/random-button';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';

const widgetConfig = {
  angleValue: {
    min: -360,
    max: 360,
    step: 1,
    defaultValue: 0,
  },
};

// Type definitions for angle values
type SingleAngleValue = number;
type AngleValueRange = [SingleAngleValue, SingleAngleValue];
type AngleValue = SingleAngleValue | AngleValueRange;

/**
 * The Angle Action Widget
 * @param id The widget id
 * @returns The angle widget
 */
const AngleWidget: ExecutableActionWidgetComponent<AngleWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<AngleWidgetData>(id);

  const defaultAngleValue = widgetConfig.angleValue.defaultValue;

  const [angleValue, setAngleValue] = useState<AngleValue>(widget?.data.angleValue || defaultAngleValue);

  /**
   * Handle the angle value change
   * @param newValue The new angle value
   */
  const handleAngleValueChange = (newValue: AngleValue) => {
    setAngleValue(newValue);
  };

  /**
   * Handle the angle value changed event
   */
  const onAngleValueChanged = (angleValue: AngleValue) => {
    updateWidgetData<AngleWidgetData>(id, { angleValue });
  };

  /**
   * Handle the random toggle
   */
  const handleRandomToggle = () => {
    let newAngleValue;

    if (Array.isArray(angleValue)) {
      newAngleValue = convertRangeToSingleValue(angleValue);
    } else {
      newAngleValue = convertSingleValueToRange(angleValue);
    }

    setAngleValue(newAngleValue);
    onAngleValueChanged(newAngleValue);
  };

  /**
   * Convert a range to a single value
   * @param range The range to convert
   * @returns The single value
   */
  const convertRangeToSingleValue = (range: AngleValueRange): SingleAngleValue => {
    const midValue = (range[0] + range[1]) / 2;
    const step = widgetConfig.angleValue.step;
    return Math.round(midValue / step) * step;
  };

  /**
   * Convert a single value to a range
   * @param value The single value to convert
   * @returns The range
   */
  const convertSingleValueToRange = (value: SingleAngleValue): AngleValueRange => {
    const range = 15;
    let rangeMin = value - range;
    let rangeMax = value + range;

    const unitMin = widgetConfig.angleValue.min;
    const unitMax = widgetConfig.angleValue.max;

    if (rangeMin < unitMin) {
      rangeMin = unitMin;
      rangeMax = unitMin + 2 * range;
    }

    if (rangeMax > unitMax) {
      rangeMax = unitMax;
      rangeMin = unitMax - 2 * range;
    }

    return [rangeMin, rangeMax];
  };

  /**
   * Calculate the rotation angle for the LargeMotorIcon
   * @returns The rotation angle in degrees
   */
  const calculateRotationAngle = (): number => {
    if (Array.isArray(angleValue)) {
      return (angleValue[0] + angleValue[1]) / 2;
    }
    return angleValue;
  };

  return (
    <Box sx={{ width: '200px', height: '280px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '-20px' }}>
          <RandomButton
            selected={Array.isArray(angleValue)}
            value={Array.isArray(angleValue)}
            onChange={handleRandomToggle}
            mainColor="#034FCC"
            secondaryColor="#E9E9E9"
          >
            Random
          </RandomButton>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '218px' }}>
          <CircularSlider
            value={angleValue}
            valueLabelDisplay="on"
            onChange={(_event, newValue) => handleAngleValueChange(newValue as AngleValue)}
            onChangeCommitted={(_event, newValue) => onAngleValueChanged(newValue as AngleValue)}
            min={widgetConfig.angleValue.min}
            max={widgetConfig.angleValue.max}
            step={widgetConfig.angleValue.step}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            radius={80}
            startAngle={-360}
            endAngle={360}
          >
            <Box>
              <LargeMotorIcon
                sx={{ width: '96px', height: '96px', transform: `rotate(${calculateRotationAngle()}deg)` }}
              />
            </Box>
          </CircularSlider>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Executes the motor widget
 * @param signal The abort signal
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @returns The execution result
 */
AngleWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData?.moduleIds[0];

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    const MOTOR = roboModel.modules.motors[moduleId as keyof typeof roboModel.modules.motors];

    if (!MOTOR) {
      throw new Error('Motor not found');
    }

    let angleValue;
    if (Array.isArray(widgetData.angleValue)) {
      // Randomly select a duration between the range
      angleValue = Math.floor(
        Math.random() * (widgetData.angleValue[1] - widgetData.angleValue[0]) + widgetData.angleValue[0]
      );
    } else {
      angleValue = widgetData.angleValue;
    }

    const angleSign = angleValue > 0 ? 1 : -1;

    const sendAngleValue = Math.abs(angleValue);
    const sendAngleDirection = angleSign > 0 ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw;

    MOTOR.setAngleAction(sendAngleValue, sendAngleDirection, ({ isError }) => {
      if (isError) {
        reject(new Error('Error setting angle action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    });
  });
};

// Interface for widget data
export interface AngleWidgetData {
  angleValue: AngleValue;
}

AngleWidget.initialData = {
  angleValue: 0,
};

export default AngleWidget;
