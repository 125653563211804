import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M21.1848 45.1287C19.6465 45.1287 18.3354 44.8927 17.2515 44.4207C16.1852 43.9487 15.381 43.3586 14.8391 42.6506C14.3147 41.9257 14.035 41.1839 14 40.4253C14 40.2736 14.0524 40.1556 14.1573 40.0713C14.2797 39.9701 14.4196 39.9195 14.5769 39.9195H16.9369C17.3215 39.9195 17.5749 40.0881 17.6973 40.4253C18.0819 41.6391 19.2531 42.246 21.2111 42.246C22.2774 42.246 23.1165 42.01 23.7284 41.5379C24.3402 41.049 24.6461 40.3579 24.6461 39.4644C24.6461 37.7785 23.5536 36.9356 21.3684 36.9356H18.6675C18.4752 36.9356 18.3179 36.8766 18.1955 36.7586C18.0732 36.6406 18.012 36.4973 18.012 36.3287V35.2667C18.012 34.9969 18.1343 34.7441 18.3791 34.508L23.0728 30.0828H15.5733C15.3985 30.0828 15.2499 30.0238 15.1275 29.9057C15.0052 29.7877 14.944 29.6444 14.944 29.4759V27.8069C14.944 27.6215 14.9964 27.4697 15.1013 27.3517C15.2237 27.2337 15.381 27.1747 15.5733 27.1747H26.8226C27.0149 27.1747 27.1722 27.2337 27.2946 27.3517C27.4169 27.4697 27.4781 27.6215 27.4781 27.8069V29.3241C27.4781 29.577 27.3645 29.813 27.1372 30.0322L22.6008 34.5333L22.9155 34.5586C24.5937 34.6935 25.9223 35.1824 26.9012 36.0253C27.8977 36.8682 28.3959 38.0736 28.3959 39.6414C28.3959 40.754 28.0812 41.7234 27.4519 42.5494C26.8401 43.3755 25.9835 44.0161 24.8821 44.4713C23.7983 44.9096 22.5659 45.1287 21.1848 45.1287Z"
          fill="white"
        />
        <path
          d="M30.4971 47C30.3572 47 30.2348 46.9494 30.13 46.8483C30.0251 46.7471 29.9726 46.6207 29.9726 46.469C29.9726 46.3678 29.9901 46.2751 30.0251 46.1908L38.9668 25.531C39.0192 25.3962 39.1066 25.2782 39.229 25.177C39.3688 25.059 39.5437 25 39.7534 25H41.4579C41.5977 25 41.7201 25.0506 41.825 25.1517C41.9473 25.2529 42.0085 25.3793 42.0085 25.531C42.0085 25.5816 41.9823 25.6743 41.9299 25.8092L32.9882 46.469C32.8133 46.823 32.5424 47 32.1753 47H30.4971Z"
          fill="white"
        />
        <path
          d="M50.7889 45.1287C49.2506 45.1287 47.9395 44.8927 46.8556 44.4207C45.7893 43.9487 44.9851 43.3586 44.4432 42.6506C43.9188 41.9257 43.6391 41.1839 43.6041 40.4253C43.6041 40.2736 43.6566 40.1556 43.7614 40.0713C43.8838 39.9701 44.0237 39.9195 44.181 39.9195H46.541C46.9256 39.9195 47.179 40.0881 47.3014 40.4253C47.686 41.6391 48.8573 42.246 50.8152 42.246C51.8815 42.246 52.7206 42.01 53.3325 41.5379C53.9443 41.049 54.2503 40.3579 54.2503 39.4644C54.2503 37.7785 53.1577 36.9356 50.9725 36.9356H48.2716C48.0793 36.9356 47.922 36.8766 47.7996 36.7586C47.6773 36.6406 47.6161 36.4973 47.6161 36.3287V35.2667C47.6161 34.9969 47.7384 34.7441 47.9832 34.508L52.6769 30.0828H45.1774C45.0026 30.0828 44.854 30.0238 44.7317 29.9057C44.6093 29.7877 44.5481 29.6444 44.5481 29.4759V27.8069C44.5481 27.6215 44.6005 27.4697 44.7054 27.3517C44.8278 27.2337 44.9851 27.1747 45.1774 27.1747H56.4267C56.619 27.1747 56.7763 27.2337 56.8987 27.3517C57.021 27.4697 57.0822 27.6215 57.0822 27.8069V29.3241C57.0822 29.577 56.9686 29.813 56.7413 30.0322L52.2049 34.5333L52.5196 34.5586C54.1978 34.6935 55.5264 35.1824 56.5053 36.0253C57.5018 36.8682 58 38.0736 58 39.6414C58 40.754 57.6853 41.7234 57.056 42.5494C56.4442 43.3755 55.5876 44.0161 54.4862 44.4713C53.4024 44.9096 52.17 45.1287 50.7889 45.1287Z"
          fill="white"
        />
        <path
          d="M21.1848 45.1287C19.6465 45.1287 18.3354 44.8927 17.2515 44.4207C16.1852 43.9487 15.381 43.3586 14.8391 42.6506C14.3147 41.9257 14.035 41.1839 14 40.4253C14 40.2736 14.0524 40.1556 14.1573 40.0713C14.2797 39.9701 14.4196 39.9195 14.5769 39.9195H16.9369C17.3215 39.9195 17.5749 40.0881 17.6973 40.4253C18.0819 41.6391 19.2531 42.246 21.2111 42.246C22.2774 42.246 23.1165 42.01 23.7284 41.5379C24.3402 41.049 24.6461 40.3579 24.6461 39.4644C24.6461 37.7785 23.5536 36.9356 21.3684 36.9356H18.6675C18.4752 36.9356 18.3179 36.8766 18.1955 36.7586C18.0732 36.6406 18.012 36.4973 18.012 36.3287V35.2667C18.012 34.9969 18.1343 34.7441 18.3791 34.508L23.0728 30.0828H15.5733C15.3985 30.0828 15.2499 30.0238 15.1275 29.9057C15.0052 29.7877 14.944 29.6444 14.944 29.4759V27.8069C14.944 27.6215 14.9964 27.4697 15.1013 27.3517C15.2237 27.2337 15.381 27.1747 15.5733 27.1747H26.8226C27.0149 27.1747 27.1722 27.2337 27.2946 27.3517C27.4169 27.4697 27.4781 27.6215 27.4781 27.8069V29.3241C27.4781 29.577 27.3645 29.813 27.1372 30.0322L22.6008 34.5333L22.9155 34.5586C24.5937 34.6935 25.9223 35.1824 26.9012 36.0253C27.8977 36.8682 28.3959 38.0736 28.3959 39.6414C28.3959 40.754 28.0812 41.7234 27.4519 42.5494C26.8401 43.3755 25.9835 44.0161 24.8821 44.4713C23.7983 44.9096 22.5659 45.1287 21.1848 45.1287Z"
          stroke="#5A418B"
          strokeWidth="1.57143"
        />
        <path
          d="M30.4971 47C30.3572 47 30.2348 46.9494 30.13 46.8483C30.0251 46.7471 29.9726 46.6207 29.9726 46.469C29.9726 46.3678 29.9901 46.2751 30.0251 46.1908L38.9668 25.531C39.0192 25.3962 39.1066 25.2782 39.229 25.177C39.3688 25.059 39.5437 25 39.7534 25H41.4579C41.5977 25 41.7201 25.0506 41.825 25.1517C41.9473 25.2529 42.0085 25.3793 42.0085 25.531C42.0085 25.5816 41.9823 25.6743 41.9299 25.8092L32.9882 46.469C32.8133 46.823 32.5424 47 32.1753 47H30.4971Z"
          stroke="#5A418B"
          strokeWidth="1.57143"
        />
        <path
          d="M50.7889 45.1287C49.2506 45.1287 47.9395 44.8927 46.8556 44.4207C45.7893 43.9487 44.9851 43.3586 44.4432 42.6506C43.9188 41.9257 43.6391 41.1839 43.6041 40.4253C43.6041 40.2736 43.6566 40.1556 43.7614 40.0713C43.8838 39.9701 44.0237 39.9195 44.181 39.9195H46.541C46.9256 39.9195 47.179 40.0881 47.3014 40.4253C47.686 41.6391 48.8573 42.246 50.8152 42.246C51.8815 42.246 52.7206 42.01 53.3325 41.5379C53.9443 41.049 54.2503 40.3579 54.2503 39.4644C54.2503 37.7785 53.1577 36.9356 50.9725 36.9356H48.2716C48.0793 36.9356 47.922 36.8766 47.7996 36.7586C47.6773 36.6406 47.6161 36.4973 47.6161 36.3287V35.2667C47.6161 34.9969 47.7384 34.7441 47.9832 34.508L52.6769 30.0828H45.1774C45.0026 30.0828 44.854 30.0238 44.7317 29.9057C44.6093 29.7877 44.5481 29.6444 44.5481 29.4759V27.8069C44.5481 27.6215 44.6005 27.4697 44.7054 27.3517C44.8278 27.2337 44.9851 27.1747 45.1774 27.1747H56.4267C56.619 27.1747 56.7763 27.2337 56.8987 27.3517C57.021 27.4697 57.0822 27.6215 57.0822 27.8069V29.3241C57.0822 29.577 56.9686 29.813 56.7413 30.0322L52.2049 34.5333L52.5196 34.5586C54.1978 34.6935 55.5264 35.1824 56.5053 36.0253C57.5018 36.8682 58 38.0736 58 39.6414C58 40.754 57.6853 41.7234 57.056 42.5494C56.4442 43.3755 55.5876 44.0161 54.4862 44.4713C53.4024 44.9096 52.17 45.1287 50.7889 45.1287Z"
          stroke="#5A418B"
          strokeWidth="1.57143"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
