import React, { useCallback, useContext, useState } from 'react';
import { ElementsIdType } from '@constants/elements-ids';
import { AnimationComponentName } from '@components/Animation/animations';

export type ElementId = ElementsIdType;
export type AnimationName = AnimationComponentName;
export type AnimationProps = any;
export type Animations = {
  [key: string]: AnimationProps;
};

export type AnimationsContextType = {
  registerElement: (id: ElementId, animations: Animations) => void;
  unregisterElement: (id: ElementId) => void;
  activateAnimations: (id: ElementId, animations: AnimationName[]) => void;
  deactivateAnimations: (id: ElementId, animations?: AnimationName[]) => void;
  getActiveAnimations: (id: ElementId) => Animations;
  elementsAnimations: {
    [key: string]: {
      animations: Animations;
      activeAnimations: AnimationName[];
    };
  };
};

export const AnimationsProvider = React.createContext<AnimationsContextType>({
  registerElement: () => {
    throw Error('not implemented!');
  },
  unregisterElement: () => {
    throw Error('not implemented!');
  },
  activateAnimations: () => {
    throw Error('not implemented!');
  },
  deactivateAnimations: () => {
    throw Error('not implemented!');
  },
  getActiveAnimations: () => {
    throw Error('not implemented!');
  },
  elementsAnimations: {},
});

export const useAnimations = () => {
  return useContext(AnimationsProvider);
};

export const AnimationsManager = ({ children }: { children: React.ReactNode }) => {
  const [elementsAnimations, setElementsAnimations] = useState<AnimationsContextType['elementsAnimations']>({});

  const registerElement = (id: ElementId, animations: any) => {
    animations[id] = animations;
    setElementsAnimations(prev => ({
      ...prev,
      [id]: {
        animations,
        activeAnimations: [],
      },
    }));
  };

  const unregisterElement = (id: ElementId) => {
    setElementsAnimations(prev => {
      const newAnimations = { ...prev };
      delete newAnimations[id];

      return newAnimations;
    });
  };

  const activateAnimations = (id: ElementId, animations: AnimationName[]) => {
    setElementsAnimations(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          activeAnimations: animations,
        },
      };
    });
  };

  const deactivateAnimations = (id: ElementId, animations?: AnimationName[]) => {
    setElementsAnimations(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          activeAnimations: animations ? prev[id].activeAnimations.filter(a => !animations.includes(a)) : [],
        },
      };
    });
  };

  const getActiveAnimations = useCallback(
    (id: ElementId) => {
      return elementsAnimations[id]?.activeAnimations.reduce((acc, name) => {
        return {
          ...acc,
          [name]: elementsAnimations[id].animations[name],
        };
      }, {});
    },
    [elementsAnimations]
  );

  return (
    <AnimationsProvider.Provider
      value={{
        elementsAnimations,
        registerElement,
        unregisterElement,
        activateAnimations,
        deactivateAnimations,
        getActiveAnimations,
      }}
    >
      {children}
    </AnimationsProvider.Provider>
  );
};
