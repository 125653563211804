import { Button, Typography } from '@mui/material';
import { CodeMotorAlignment, CodeMotorId } from '@webapp/store/types';

interface MotorAlignmentToggleProps {
  motorId: CodeMotorId;
  currentAlignment: CodeMotorAlignment;
  setMotorAlignment: (motorId: CodeMotorId, alignment: CodeMotorAlignment) => void;
}

const MotorAlignmentToggle: React.FunctionComponent<MotorAlignmentToggleProps> = ({
  motorId,
  currentAlignment,
  setMotorAlignment,
}) => {
  const handleToggleClick = () => {
    let nextAlignment: CodeMotorAlignment;
    switch (currentAlignment) {
      case CodeMotorAlignment.Left:
        nextAlignment = CodeMotorAlignment.None;
        break;
      case CodeMotorAlignment.None:
        nextAlignment = CodeMotorAlignment.Right;
        break;
      case CodeMotorAlignment.Right:
        nextAlignment = CodeMotorAlignment.Left;
        break;
      default:
        nextAlignment = CodeMotorAlignment.None;
    }
    setMotorAlignment(motorId, nextAlignment);
  };

  return (
    <Button
      variant="contained"
      color="quaternary"
      onClick={handleToggleClick}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '38px',
        width: '90px',
        borderRadius: '19px',
      }}
      aria-label="Toggle alignment"
    >
      <Typography
        variant="button"
        sx={{
          opacity: currentAlignment === CodeMotorAlignment.Left ? 1 : 0.2,
          marginRight: '8px',
        }}
      >
        L
      </Typography>
      <Typography
        variant="button"
        sx={{
          opacity: currentAlignment === CodeMotorAlignment.None ? 1 : 0.2,
          marginRight: '8px',
        }}
      >
        -
      </Typography>
      <Typography
        variant="button"
        sx={{
          opacity: currentAlignment === CodeMotorAlignment.Right ? 1 : 0.2,
        }}
      >
        R
      </Typography>
    </Button>
  );
};

export default MotorAlignmentToggle;
