import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#FFFFFF';

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="24" y="9" width="28" height="28" rx="5" stroke={defaultColor} strokeWidth="2" />
        <rect x="26" y="38" width="24" height="11" rx="3" fill={defaultColor} />
        <path
          d="M30.2218 52.7782V52.7782C34.5176 57.0739 41.4824 57.0739 45.7782 52.7782"
          stroke={defaultColor}
          strokeWidth="2"
        />
        <path
          d="M25.9792 57.0208V57.0208C32.6181 63.6597 43.3819 63.6597 50.0208 57.0208"
          stroke={defaultColor}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
