import { gql } from '@lib/store/strapi-graphql-query';

import { WITH_FILE } from '@store/fragments/file';

export const FULL_SEARCH_QUERY = gql`
  ${WITH_FILE}
  query Search($query: String!) {
    search(query: $query) {
      lessons {
        data {
          id
          __typename
          attributes {
            name
            description
            preview {
              ...FILE
            }
          }
        }
      }

      projects {
        data {
          id
          __typename
          attributes {
            name
            description
            preview {
              ...FILE
            }
          }
        }
      }

      activities {
        data {
          id
          __typename
          attributes {
            name
            description
            preview {
              ...FILE
            }
          }
        }
      }

      resources {
        data {
          id
          __typename
          attributes {
            name
            description
            preview {
              ...FILE
            }
          }
        }
      }
    }
  }
`;
