import { Box, SxProps } from '@mui/material';

interface IconWithTextProps {
  icon: React.ReactNode;
  text: React.ReactNode | string;
  sx?: SxProps;
}

const IconWithText: React.FunctionComponent<IconWithTextProps> = ({ icon, text, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        ...sx,
      }}
    >
      <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</Box>
      {text}
    </Box>
  );
};

export default IconWithText;
