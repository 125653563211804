import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from '../configureStore';
import { AppLoader } from '@components/Loader/app-loader';
import { SentryWrapper } from '@components/Sentry/sentry-wrapper';

const WrapWithProvider = ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<AppLoader />}>
        <SentryWrapper>{element}</SentryWrapper>
      </PersistGate>
    </Provider>
  );
};

export default WrapWithProvider;
