import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 157 156" fill="none">
        <path
          d="M8431.46 3852.48C8430.5 3852.48 8429.86 3853.12 8429.86 3854.08C8429.86 3855.04 8430.5 3855.68 8431.46 3855.68H8438.35C8439.31 3855.68 8439.95 3855.04 8439.95 3854.08C8439.95 3853.12 8439.31 3852.48 8438.35 3852.48H8431.46ZM8438.35 3857.92H8431.46C8430.5 3857.92 8429.86 3858.56 8429.86 3859.52C8429.86 3860.48 8430.5 3861.12 8431.46 3861.12H8438.35C8439.31 3861.12 8439.95 3860.48 8439.95 3859.52C8439.95 3858.56 8439.31 3857.92 8438.35 3857.92ZM8438.35 3863.2H8431.46C8430.5 3863.2 8429.86 3863.84 8429.86 3864.8C8429.86 3865.76 8430.5 3866.4 8431.46 3866.4H8438.35C8439.31 3866.4 8439.95 3865.76 8439.95 3864.8C8439.95 3864 8439.31 3863.2 8438.35 3863.2ZM76.4995 0C34.5518 0 0.449219 34.08 0.449219 76C0.449219 117.92 34.5518 152 76.4995 152C118.447 152 152.55 117.92 152.55 76C152.55 34.08 118.447 0 76.4995 0ZM104.038 80.48L63.0506 107.68C59.5283 110.08 54.8852 107.52 54.8852 103.2V48.64C54.8852 44.48 59.5283 41.92 63.0506 44.16L104.038 71.52C107.24 73.76 107.24 78.24 104.038 80.48Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
