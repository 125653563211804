const generateColorScheme = (primary, secondary) => {
  return {
    icon: {
      default: {
        primaryColor: primary,
        secondaryColor: secondary,
      },

      hover: {
        primaryColor: primary,
        secondaryColor: secondary,
      },

      active: {
        primaryColor: secondary,
        secondaryColor: primary,
      },

      disabled: {
        primaryColor: '#AAAAAA',
        secondaryColor: '#AAAAAA',
        opacity: 0.3,
      },
    },

    tab: {
      default: {
        backgroundColor: secondary,
      },

      hover: {
        backgroundColor: secondary,
      },

      active: {
        backgroundColor: primary,
      },

      disabled: {
        backgroundColor: '#AAAAAA',
      },
    },
  };
};

export const sectionColors = {
  'motion-section': generateColorScheme('#156BFB', '#FFFFFF'),
  'visual-section': generateColorScheme('#FFBD2E', '#FFFFFF'),
  'sound-section': generateColorScheme('#F27D27', '#FFFFFF'),
  'sensing-section': generateColorScheme('#E31871', '#FFFFFF'),
  'special-section': generateColorScheme('#36A958', '#FFFFFF'),
};
