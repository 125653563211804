import { useMemo } from 'react';
import { SliderGeometry, SliderGeometryProps } from '../types';
import { anglesDifference } from '../utils/math';

export const useSliderGeometry = ({ radius, width, height, tracks }: SliderGeometryProps): SliderGeometry => {
  return useMemo(() => {
    const center = {
      x: width ? width / 2 : radius,
      y: height ? height / 2 : radius,
    };

    /**
     * Converts a value to the angle to the startAngle of the track.
     *
     * @param trackId - The id of the track to convert the value to an angle for.
     * @param value - The value to convert to an angle.
     * @returns The angle corresponding to the value.
     */
    const getAngleByValue = (trackId: string, value: number): number => {
      const track = tracks.find(t => t.id === trackId);
      if (!track) throw new Error(`Track with id ${trackId} not found`);

      const { min, max, startAngle, endAngle, direction = 'clockwise', type, spiralConfig, step = 1 } = track;

      // Ensure the value is within the track's value range
      value = Math.max(min, Math.min(max, value));

      // Calculate the normalized value
      const normalizedValue = (value - min) / (max - min);

      // Calculate the total angle sweep
      const totalAngle = anglesDifference(startAngle, endAngle);

      // Calculate the angle from the start
      const angleFromStart = normalizedValue * totalAngle;

      return startAngle + angleFromStart;
    };

    /**
     * Converts an angle from the startAngle to a value.
     *
     *
     * @param trackId - The id of the track to convert the angle to a value for.
     * @param angle - The angle to convert to a value.
     * @returns The value corresponding to the angle.
     */
    const getValueByAngle = (trackId: string, angle: number): number => {
      const track = tracks.find(t => t.id === trackId);
      if (!track) throw new Error(`Track with id ${trackId} not found`);

      const { min, max, startAngle, endAngle, step = 1 } = track;

      // Ensure the angle is within the track's angle range
      angle = Math.max(startAngle, Math.min(endAngle, angle));

      // Calculate the fraction of the total angle
      const totalAngle = endAngle - startAngle;
      const fraction = (angle - startAngle) / totalAngle;

      // Calculate the raw value
      const rawValue = min + fraction * (max - min);

      // Apply step if necessary
      const steppedValue = Math.round((rawValue - min) / step) * step + min;

      // Clamp the value to the min-max range
      return Math.max(min, Math.min(max, steppedValue));
    };

    /**
     * Calculates the position of a handle for a given track and value.
     *
     * @param trackId - The id of the track to calculate the handle position for.
     * @param value - The value to calculate the handle position for.
     * @returns The position of the handle.
     */
    const getHandlePosition = (trackId: string, value: number): { x: number; y: number } => {
      const track = tracks.find(t => t.id === trackId);
      if (!track) throw new Error(`Track with id ${trackId} not found`);

      const angle = getAngleByValue(trackId, value);
      const radians = (angle - 90) * (Math.PI / 180);

      if (track.type === 'spiral' && track.spiralConfig) {
        const { expansionFactor, revolutions = (track.endAngle - track.startAngle) / 360 } = track.spiralConfig;
        const normalizedValue = (value - track.min) / (track.max - track.min);
        const revolution = normalizedValue * revolutions;
        const spiralRadius = radius * (1 + revolution * (expansionFactor - 1));
        return {
          x: center.x + Math.cos(radians) * spiralRadius,
          y: center.y + Math.sin(radians) * spiralRadius,
        };
      } else {
        return {
          x: center.x + Math.cos(radians) * radius,
          y: center.y + Math.sin(radians) * radius,
        };
      }
    };

    return {
      center,
      radius,
      getValueByAngle,
      getAngleByValue,
      getHandlePosition,
    };
  }, [radius, width, height, tracks]);
};
