import { gql } from '@lib/store/strapi-graphql-query';

export const BRIDGE_QUERY = gql`
  query GetBridge($id: ID!) {
    bridge(id: $id) {
      data {
        id
        attributes {
          name
          ip
          mac
        }
      }
    }
  }
`;

export const USER_BRIDGES_QUERY = gql`
  query GetUserBridges {
    userBridges {
      data {
        id
        attributes {
          name
          ip
          mac
        }
      }
    }
  }
`;

export const ASSIGN_BRIDGE_TO_USER_MUTATION = gql`
  mutation AssignBridgeToUser($userId: ID!, $bridgeId: Int, $bridgeMac: String) {
    assignBridgeToUser(userId: $userId, bridgeId: $bridgeId, bridgeMac: $bridgeMac) {
      data {
        id
        attributes {
          name
          ip
          mac
          users {
            data {
              id
            }
          }
        }
      }
    }
  }
`;
