import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)(({ theme, color, width, height }) => ({
  position: 'relative',
  borderColor: '#5A418B',
  borderWidth: '1px',
  borderStyle: 'solid',
  width: width || '61px',
  height: height || '61px',
  borderRadius: '8px',
  backgroundColor: color,
  display: 'flex',
  flexDirection: 'column',

  '&:hover': {
    backgroundColor: color,
  },

  '&:focus': {
    backgroundColor: color,
    outline: 'none',
  },

  '&:active': {
    backgroundColor: color,
  },
}));

export default StyledIconButton;
