import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS = gql`
  fragment WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS on ComponentItemsTutorialStepAvailableActions {
    id
    action
    data
  }
`;
export const WITH_TUTORIAL_STEP_NEEDED_ACTIONS = gql`
  fragment WITH_TUTORIAL_STEP_NEEDED_ACTIONS on ComponentItemsTutorialStepNeededActions {
    id
    action
    data
  }
`;
