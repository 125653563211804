import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { ApiError, ApiResponse } from '@common-types/api';
import { type BaseQueryApi } from '@reduxjs/toolkit/query';

export const strapiRestQuery = ({
  baseUrl,
  prepareHeaders,
  ...restOptions
}: {
  baseUrl: string;
  prepareHeaders: (headers: Headers, { getState }: { getState: () => any }) => Headers;
  [key: string]: any;
}) => {
  return async (args: any, opts: BaseQueryApi, extraOptions: object = {}) => {
    try {
      const result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders,
        ...restOptions,
      })(args, opts, extraOptions);

      return processResponse(result);
    } catch (error: any) {
      return processError(error);
    }
  };
};

const processResponse = (response: any): ApiResponse => {
  const processedResponse: ApiResponse = {};

  if (!response) {
    return { error: { status: 400, data: { message: 'Malformed response' } } };
  }

  // store and return error
  if (response.error) {
    // store error meta
    if (response.meta) {
      processedResponse.meta = response.meta;
    }

    processedResponse.error = {
      status: response.error.status || 400,
      data: { message: response.error.data?.error?.message || response.error.message || 'Unexpected error' },
    };

    return processedResponse;
  }

  processedResponse.data = response.data.data || response.data;
  processedResponse.meta = response.data.meta || response.meta;

  return processedResponse;
};

const processError = (error: { status: number; message: string }): { error: ApiError } => {
  return {
    error: {
      status: error.status || 500,
      data: { message: error.message || 'Unexpected error' },
    },
  };
};
