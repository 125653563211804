import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M48.7 49.8V49.1H48H24H23.3V49.8V51.8V52.5H24H48H48.7V51.8V49.8ZM31.5 20.3C28.6281 20.3 26.3 22.6281 26.3 25.5V34.5C26.3 36.4976 27.4264 38.2321 29.0787 39.103C28.5972 39.5907 28.3 40.2606 28.3 41V43C28.3 44.4912 29.5088 45.7 31 45.7H41C42.4912 45.7 43.7 44.4912 43.7 43V41C43.7 40.2606 43.4028 39.5907 42.9213 39.103C44.5736 38.2321 45.7 36.4976 45.7 34.5V25.5C45.7 22.6281 43.3719 20.3 40.5 20.3H31.5ZM31.5 23.7H40.5C41.4941 23.7 42.3 24.5059 42.3 25.5V34.5C42.3 35.4941 41.4941 36.3 40.5 36.3H31.5C30.5059 36.3 29.7 35.4941 29.7 34.5V25.5C29.7 24.5059 30.5059 23.7 31.5 23.7Z"
          fill="white"
          stroke="#5A418B"
          strokeWidth="1.4"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
