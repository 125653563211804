import React, { useContext } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors as themeColors } from '@themes/config/theme-colors';
import { LookingFaceIcon } from '@webapp/components/icons';
import StyledIconButton from '../../component/styled-icon-button';

import { EditorContext, useEditorContext } from '@webapp/components/hoc/with-editor';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import { LedDisplay } from '@lib/robo/modules';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';
import { useLedDisplayWidgetOrientation } from '@webapp/components/blocks/widgets/led-pixel-display-widget/led-pixel-display-widget';

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-xsmall-semibold',
  color: themeColors.white['500'],
}));

const LookingFaceWidget = ({ counter, data, disabled }) => {
  const { isPlaying } = useContext(EditorContext);
  const { model: roboModel } = useRobo();

  const { addHistoryEntry } = useActionsHistory();
  const { editorType } = useEditorContext();

  const moduleId = data?.moduleIds?.[0];
  const orientation = useLedDisplayWidgetOrientation(data?.moduleIds);

  const handleClick = () => {
    const displayBlock = roboModel?.ledDisplays[moduleId];

    displayBlock.setAnimation(LedDisplay.Animations.LookingFace, orientation);
    addHistoryEntry({
      action: 'interact:widget:looking',
      scope: editorType,
    });
  };

  return (
    <StyledIconButton
      color={disabled ? WidgetColors.disabledBackground : '#FFB71C'}
      width="100%"
      height="100%"
      onClick={isPlaying && !disabled ? handleClick : null}
      size="large"
    >
      {!!counter && <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography>}
      <LookingFaceIcon sx={{ width: '79px', height: '58px', paddingTop: '15px' }} />
    </StyledIconButton>
  );
};

LookingFaceWidget.initialProps = {
  width: 1,
  height: 1,
};

LookingFaceWidget.initialData = {};

export default LookingFaceWidget;
