import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Slider from '@webapp/components/ui/sliders/slider';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';

const RepeatTriggerWidget: ExecutableTriggerWidgetComponent<RepeatTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<RepeatTriggerWidgetData>(id);

  const [repeats, setRepeats] = useState<number>(widget?.data.repeats ?? RepeatTriggerWidget.initialData.repeats);

  const handleRepeatsChange = (newRepeats: number) => {
    setRepeats(newRepeats);
  };

  const onRepeatsChanged = (repeats: number) => {
    updateWidgetData<RepeatTriggerWidgetData>(id, { repeats });
  };

  return (
    <Box sx={{ width: '360px', height: '185px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            value={repeats}
            onChange={(e, newValue) => handleRepeatsChange(newValue as number)}
            onChangeCommitted={(e, newValue) => onRepeatsChanged(newValue as number)}
            orientation="horizontal"
            sx={{ width: '75%' }}
            max={10}
            min={1}
            valueLabelDisplay="on"
            mainColor="#DF1642"
            railColor="#E9E9E9"
            labelColor="#5A418B"
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="x-headline5-semibold" color="#5A418B">
            Repeat {repeats > 1 ? `${repeats} times` : 'once'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

RepeatTriggerWidget.execute = async ({ widgetId, getWidgetById, updateWidgetData }) => {
  const widget = getWidgetById(widgetId);

  if (!widget) {
    throw new Error('Repeat trigger widget not found');
  }

  const { data } = widget;

  const runtime = data.runtime || RepeatTriggerWidget.initialData.runtime;

  const repeated = runtime.repeated;
  const repeats = data.repeats;

  if (repeated < repeats) {
    return {
      type: WidgetExecutionType.Trigger,
      widgetId: widget.id,
      resolved: true,
      passed: () => {
        console.debug('[EXEC] Repeat trigger PASSED');
        const newRepeated = repeated + 1;
        updateWidgetData(widget.id, {
          ...data,

          runtime: {
            ...data.runtime,
            repeated: newRepeated,
          },
        });

        console.debug(`[EXEC] Updated repeat count: ${newRepeated} of ${repeats}`);
      },
    };
  } else {
    return {
      type: WidgetExecutionType.Trigger,
      widgetId: widget.id,
      resolved: false,
    };
  }
};

RepeatTriggerWidget.reset = async ({ widgetId, getWidgetById, updateWidgetData }) => {
  const widget = getWidgetById(widgetId);

  if (!widget) {
    console.error('Repeat trigger widget not found');
    return;
  }

  try {
    updateWidgetData(widget.id, {
      ...widget.data,
      runtime: {
        ...widget.data.runtime,
        repeated: 0,
      },
    });
    console.debug('[EXEC] Repeat trigger reset');
  } catch (error) {
    console.error('Error resetting repeat trigger:', error);
  }
};

export type RepeatTriggerWidgetData = {
  repeats: number;
  runtime: {
    repeated: number;
  };
};

RepeatTriggerWidget.initialData = {
  repeats: 3,
  runtime: {
    repeated: 0,
  },
};

export default RepeatTriggerWidget;
