import { gql } from '@lib/store/strapi-graphql-query';
import { WITH_FILE } from '@store/fragments/file';

export const FULL_EDITOR_PROJECT = gql`
  ${WITH_FILE}

  fragment FULL_EDITOR_PROJECT on EditorProjectEntity {
    id
    attributes {
      uuid
      title
      type
      version
      createdAt
      updatedAt
      editorState
      previewImage {
        ...FILE
      }
    }
  }
`;

export const SHORT_EDITOR_PROJECT = gql`
  ${WITH_FILE}

  fragment SHORT_EDITOR_PROJECT on EditorProjectEntity {
    id
    attributes {
      uuid
      title
      type
      version
      createdAt
      updatedAt
      previewImage {
        ...FILE
      }
    }
  }
`;

export const FULL_EDITOR_PROJECT_QUERY = gql`
  ${FULL_EDITOR_PROJECT}

  query EditorProject($id: ID) {
    editorProject(id: $id) {
      data {
        ...FULL_EDITOR_PROJECT
      }
    }
  }
`;

export const SEARCH_EDITOR_PROJECTS_QUERY = gql`
  ${SHORT_EDITOR_PROJECT}

  query SearchEditorProjects($filters: EditorProjectFiltersInput, $pagination: PaginationArg, $sort: [String]) {
    editorProjects(filters: $filters, pagination: $pagination, sort: $sort) {
      data {
        ...SHORT_EDITOR_PROJECT
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

export const CREATE_EDITOR_PROJECT_MUTATION = gql`
  ${SHORT_EDITOR_PROJECT}

  mutation CreateEditorProject($data: EditorProjectInput!) {
    createEditorProject(data: $data) {
      data {
        ...SHORT_EDITOR_PROJECT
      }
    }
  }
`;

export const UPDATE_EDITOR_PROJECT_MUTATION = gql`
  ${SHORT_EDITOR_PROJECT}

  mutation UpdateEditorProject($data: EditorProjectInput!, $id: ID!) {
    updateEditorProject(data: $data, id: $id) {
      data {
        ...SHORT_EDITOR_PROJECT
      }
    }
  }
`;

export const DELETE_EDITOR_PROJECT_MUTATION = gql`
  ${SHORT_EDITOR_PROJECT}

  mutation DeleteEditorProject($id: ID!) {
    deleteEditorProject(id: $id) {
      data {
        ...SHORT_EDITOR_PROJECT
      }
    }
  }
`;

export const COUNT_USER_EDITOR_PROJECT_QUERY = gql`
  query UserEditorProjectsCount {
    userEditorProjectsCount {
      data
    }
  }
`;
