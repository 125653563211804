import { gql } from '@lib/store/strapi-graphql-query';
import { WITH_FILE, WITH_FILES } from '@store/fragments/file';
import { WITH_TUTORIAL_STEP_COMMON_FIELDS } from '@store/fragments/items/tutorial-step-common-field';
import {
  WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS,
  WITH_TUTORIAL_STEP_NEEDED_ACTIONS,
} from '@store/fragments/items/tutorial-step-actions';

export const TUTORIAL_QUERY = gql`
  ${WITH_FILE}
  ${WITH_FILES}
  ${WITH_TUTORIAL_STEP_COMMON_FIELDS}
  ${WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS}
  ${WITH_TUTORIAL_STEP_NEEDED_ACTIONS}

  query GetTutorial($id: ID!) {
    tutorial(id: $id) {
      data {
        id
        attributes {
          title
          collapsible
          editorType
          mainImage {
            ...FILE
          }
          steps {
            __typename
            ... on ComponentItemsTutorialTextStep {
              id
              baseFields {
                ...TUTORIAL_STEP_COMMON_FIELDS
              }
              tutorialStepAvailableActions {
                ...WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS
              }
              tutorialStepNeededActions {
                ...WITH_TUTORIAL_STEP_NEEDED_ACTIONS
              }
            }
            ... on ComponentItemsTutorialImageStep {
              id
              baseFields {
                ...TUTORIAL_STEP_COMMON_FIELDS
              }
              image {
                ...FILE
              }
              tutorialStepAvailableActions {
                ...WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS
              }
              tutorialStepNeededActions {
                ...WITH_TUTORIAL_STEP_NEEDED_ACTIONS
              }
            }
            ... on ComponentItemsTutorialVideoStep {
              id
              baseFields {
                ...TUTORIAL_STEP_COMMON_FIELDS
              }
              startImage {
                ...FILE
              }
              endTitle
              endText
              endImage {
                ...FILE
              }
              videos {
                ...FILES
              }
              tutorialStepAvailableActions {
                ...WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS
              }
              tutorialStepNeededActions {
                ...WITH_TUTORIAL_STEP_NEEDED_ACTIONS
              }
            }
            ... on Error {
              code
              message
            }
          }
        }
      }
    }
  }
`;

export const TUTORIALS_CATEGORIES_LIST_QUERY = gql`
  ${WITH_FILE}

  query GetTutorialsCategoriesList {
    tutorialCategories {
      data {
        id
        attributes {
          title
          order
          description
          tutorials {
            data {
              id
              attributes {
                title
                editorType
                mainImage {
                  ...FILE
                }
              }
            }
          }
        }
      }
    }
  }
`;
