import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Slider from '@webapp/components/ui/sliders/slider';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';

import { DistanceIcon, SpeedIcon, ReverseIcon } from '@webapp/components/icons';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';

const widgetConfig = {
  distance: {
    max: 105,
    min: -105,
    step: 5,
    maxAsInfinite: true,
    minAsInfinite: true,
  },

  speed: {
    max: 100,
    min: 0,
    step: 5,
  },
};

/**
 * The motor widget
 * @param id The widget id
 * @returns The motor widget
 */
const MotorWidget: ExecutableActionWidgetComponent<MotorWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<MotorWidgetData>(id);

  const widgetData = widget?.data;

  const [distance, setDistance] = useState(widgetData?.distance ?? MotorWidget.initialData.distance);
  const [speed, setSpeed] = useState(widgetData?.speed ?? MotorWidget.initialData.speed);
  const [isReversed, setIsReversed] = useState(widgetData?.isReversed ?? MotorWidget.initialData.isReversed);

  const [isDistanceInfinite, setIsDistanceInfinite] = useState(
    widgetData?.isDistanceInfinite ?? MotorWidget.initialData.isDistanceInfinite
  );

  const handleDistanceChange = (newDistance: number) => {
    if (newDistance >= widgetConfig.distance.max && widgetConfig.distance.maxAsInfinite) {
      setDistance(newDistance);
      setIsDistanceInfinite(true);
    } else if (newDistance <= widgetConfig.distance.min && widgetConfig.distance.minAsInfinite) {
      setDistance(newDistance);
      setIsDistanceInfinite(true);
    } else {
      setDistance(newDistance);
      setIsDistanceInfinite(false);
    }
  };

  /**
   * Handles the distance change
   */
  const onDistanceChanged = (newDistance: number) => {
    updateWidgetData<MotorWidgetData>(id, { distance: newDistance, isDistanceInfinite });
  };

  /**
   * Handles the speed change
   * @param newSpeed The new speed
   */
  const handleSpeedChange = (newSpeed: number) => {
    setSpeed(newSpeed);
  };

  /**
   * Handle the speed changed event. Updates the widget data
   */
  const onSpeedChanged = (newSpeed: number) => {
    updateWidgetData<MotorWidgetData>(id, { speed: newSpeed });
  };

  /**
   * Handles the isReversed change
   */
  const onIsReversedChanged = () => {
    setIsReversed(!isReversed);
    updateWidgetData<MotorWidgetData>(id, { isReversed: !isReversed });
  };

  return (
    <Box sx={{ width: '200px', height: '305px', display: 'flex', flexDirection: 'column' }}>
      {/* First row */}
      <Grid container spacing={0} sx={{ height: '64px' }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
          <IconWithText
            icon={<DistanceIcon sx={{ fontSize: 'inherit', width: 38 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Distance
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={4} />

        <Grid
          item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '10px' }}
        >
          <IconWithText
            icon={<SpeedIcon sx={{ fontSize: 'inherit', width: 42 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Speed
              </Typography>
            }
          />
        </Grid>
      </Grid>

      {/* Second row */}
      <Grid container spacing={2} sx={{ flexGrow: 1, pb: 3, pt: 2 }}>
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            value={distance}
            valueLabelDisplay="on"
            maxAsInfinite={widgetConfig.distance.maxAsInfinite}
            minAsInfinite={widgetConfig.distance.minAsInfinite}
            max={widgetConfig.distance.max}
            min={widgetConfig.distance.min}
            step={widgetConfig.distance.step}
            onChange={(_, newDistance) => handleDistanceChange(newDistance as number)}
            onChangeCommitted={(_, newDistance) => onDistanceChanged(newDistance as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="middle"
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
          <RoundToggleIconButton
            icon={<ReverseIcon sx={{ width: 42, height: 42 }} />}
            onChange={onIsReversedChanged}
            value={isReversed}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Reverse
              </Typography>
            }
            mainColor="#034FCC"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
        </Grid>

        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            value={speed}
            valueLabelDisplay="on"
            max={widgetConfig.speed.max}
            min={widgetConfig.speed.min}
            step={widgetConfig.speed.step}
            onChange={(_, newSpeed) => handleSpeedChange(newSpeed as number)}
            onChangeCommitted={(_, newSpeed) => onSpeedChanged(newSpeed as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="rainbow"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Executes the motor widget
 * @param signal The abort signal
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @param updateWidgetData The updateWidgetData function
 * @returns The execution result
 */
MotorWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById, updateWidgetData }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData?.moduleIds[0];

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    const MOTOR = roboModel.modules.motors[moduleId as keyof typeof roboModel.modules.motors];

    if (!MOTOR) {
      throw new Error('Motor not found');
    }

    const speed = widgetData.speed;
    const distance = widgetData.distance * 10; // convert to mm
    const isReversed = widgetData.isReversed;
    const isDistanceInfinite = widgetData.isDistanceInfinite;

    // First aff all we need to calculate sign for distance
    let distanceSign = distance > 0 ? 1 : -1;
    if (isReversed) {
      distanceSign *= -1;
    }

    // we apply the sign to the speed because only it can be sent as a signed value
    const sendSpeed = distanceSign * speed;

    // if distance is infinite, we set it maximum value allowed by bytes
    let sendDistance = 0;
    if (isDistanceInfinite) {
      sendDistance = 65535;
    } else {
      sendDistance = Math.abs(distance);
    }

    MOTOR.setMotorAction(sendSpeed, sendDistance, ({ isError }) => {
      if (isError) {
        reject(new Error('Error setting motor action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    });
  });
};

// Interface for widget data
export interface MotorWidgetData {
  distance: number;
  isDistanceInfinite: boolean;
  speed: number;
  isReversed: boolean;
}

MotorWidget.initialData = {
  distance: 20,
  isDistanceInfinite: false,
  speed: 100,
  isReversed: false,
};

export default MotorWidget;
