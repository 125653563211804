import PropTypes from 'prop-types';

import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ElementWrapper = styled(Button)(({ width, height, sx }) => ({
  width: width,
  height: height,
  border: '1px solid #5A418B',
  backgroundColor: 'green',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',

  '&:hover': {
    backgroundColor: '#00CC00',
  },

  '&:active': {
    backgroundColor: '#009900',
  },

  '&[disabled]': {
    backgroundColor: '#aaa',
    border: '1px solid #aaa',
  },

  marginTop: '10px',
  marginLeft: '10px',
  marginBottom: '10px',

  '&:last-child': {
    marginRight: '10px',
  },

  ...sx,
}));

const ElementBadge = styled(Box)(({ sx }) => ({
  backgroundColor: '#5A418B',
  color: 'white',
  fontSize: '12px',
  lineHeight: 'normal',
  fontWeight: '700',
  borderRadius: '50%',
  border: '2px solid white',
  height: '20px',
  width: '20px',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '10px',
  left: '10px',
  boxSizing: 'border-box',
  paddingBottom: '2px',

  '[disabled] &': {
    backgroundColor: '#555',
    border: '2px solid #aaa',
  },

  ...sx,
}));

const ElementIcon = styled(Box)(({ sx }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',

  ...sx,
}));

const ElementLabel = styled(Box)(({ sx }) => ({
  color: 'white',
  fontSize: '10px',
  fontWeight: '700',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ...sx,
}));

const BasicElement = ({
  icon,
  label,
  badge,
  width,
  height,
  onClick,
  onDragStart,
  iconProps,
  labelProps,
  badgeProps,
  ...props
}) => {
  return (
    <ElementWrapper width={width} height={height} onClick={onClick} onDragStart={onDragStart} {...props}>
      <ElementIcon {...iconProps}>{icon}</ElementIcon>
      {!!badge && <ElementBadge {...badgeProps}>{badge}</ElementBadge>}
      {!!label && <ElementLabel {...labelProps}>{label}</ElementLabel>}
    </ElementWrapper>
  );
};

BasicElement.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  width: PropTypes.string,
  height: PropTypes.string,
  iconProps: PropTypes.object,
  labelProps: PropTypes.object,
  badgeProps: PropTypes.object,

  onClick: PropTypes.func,
  onDragStart: PropTypes.func,
};

BasicElement.defaultProps = {
  width: '71px',
  height: '71px',
  iconProps: {},
  labelProps: {},
  badgeProps: {},
};

export default BasicElement;
