import { StrobeAnimation } from '@components/Animation/animations/strobe';
import { ShakeAnimation } from '@components/Animation/animations/shake';

const animations = {
  strobe: StrobeAnimation,
  shake: ShakeAnimation,
};

export type AnimationComponentName = keyof typeof animations;

export default animations;
