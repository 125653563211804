import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="prefix__a" maskUnits="userSpaceOnUse" x="6.31" y="28.448" width="14" height="7" fill="#000">
          <path fill="#fff" d="M6.31 28.448h14v7h-14z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.88 29.448a.569.569 0 100 1.138h10.24a.569.569 0 100-1.138H7.88zM13 34a3.414 3.414 0 01-3.414-3.414h6.828A3.414 3.414 0 0113 34z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.88 29.448a.569.569 0 100 1.138h10.24a.569.569 0 100-1.138H7.88zM13 34a3.414 3.414 0 01-3.414-3.414h6.828A3.414 3.414 0 0113 34z"
          fill="#fff"
        />
        <path
          d="M9.586 30.586v-1h-1v1h1zm6.828 0h1v-1h-1v1zm-8.104-.569a.431.431 0 01-.43.431v-2a1.57 1.57 0 00-1.57 1.57h2zm-.43-.43a.43.43 0 01.43.43h-2c0 .867.703 1.57 1.57 1.57v-2zm10.24 0H7.88v2h10.24v-2zm-.43.43a.43.43 0 01.43-.43v2a1.57 1.57 0 001.57-1.57h-2zm.43.431a.431.431 0 01-.43-.43h2a1.57 1.57 0 00-1.57-1.57v2zm-10.24 0h10.24v-2H7.88v2zm.706.138A4.414 4.414 0 0013 35v-2a2.414 2.414 0 01-2.414-2.414h-2zm7.828-1H9.586v2h6.828v-2zM13 35a4.414 4.414 0 004.414-4.414h-2A2.414 2.414 0 0113 33v2z"
          fill="#5A418B"
          mask="url(#prefix__a)"
        />
        <mask id="prefix__b" maskUnits="userSpaceOnUse" x=".621" y="0" width="25" height="28" fill="#000">
          <path fill="#fff" d="M.621 0h25v28h-25z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.69 22.236a11.375 11.375 0 005.69-9.857C24.38 6.095 19.284 1 13 1 6.715 1 1.62 6.095 1.62 12.38c0 4.211 2.289 7.889 5.69 9.856v3.799h11.38v-3.799z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.69 22.236a11.375 11.375 0 005.69-9.857C24.38 6.095 19.284 1 13 1 6.715 1 1.62 6.095 1.62 12.38c0 4.211 2.289 7.889 5.69 9.856v3.799h11.38v-3.799z"
          fill="#fff"
        />
        <path
          d="M18.69 22.236l-.501-.865-.5.289v.576h1zm-11.38 0h1v-.576l-.499-.29-.5.866zm0 3.799h-1v1h1v-1zm11.38 0v1h1v-1h-1zm4.69-13.656c0 3.84-2.086 7.196-5.191 8.992l1.001 1.73a12.375 12.375 0 006.19-10.722h-2zM13 2c5.732 0 10.38 4.647 10.38 10.38h2C25.38 5.541 19.836 0 13 0v2zM2.62 12.38C2.62 6.646 7.269 2 13 2V0C6.163 0 .62 5.542.62 12.38h2zm5.191 8.99a10.375 10.375 0 01-5.19-8.99h-2c0 4.583 2.491 8.583 6.189 10.722l1-1.732zm.5 4.665v-3.799h-2v3.799h2zm10.379-1H7.31v2h11.38v-2zm-1-2.799v3.799h2v-3.799h-2z"
          fill="#5A418B"
          mask="url(#prefix__b)"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
