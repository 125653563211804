import { gql } from '@lib/store/strapi-graphql-query';

import { WITH_FILE, WITH_FILES } from '../file';

export const WITH_GALLERY = gql`
  ${WITH_FILE}
  ${WITH_FILES}

  fragment GALLERY on ComponentSharedGallery {
    main {
      ...FILE
    }
    files {
      ...FILES
    }
  }
`;
