import axios from 'axios';
import { sleep } from '@tools/lib/esp';

/**
 * Converts a File object to a binary string representation. This function reads the content of the provided File object
 * and returns a Promise that resolves with its content as a binary string. This can be useful when you need to process
 * or analyze the file's binary data directly in JavaScript.
 *
 * @async
 * @function fileToString
 * @param file - The file to be converted to a string.
 * @returns A Promise that resolves with the content of the file as a binary string.
 * @throws Throws an error if the file cannot be read.
 * @example
 * const inputElement = document.createElement('input');
 * inputElement.type = 'file';
 * inputElement.onchange = async event => {
 *   const file = event.target.files[0];
 *   try {
 *     const binaryString = await fileToString(file);
 *     console.log('File content as binary string:', binaryString);
 *   } catch (error) {
 *     console.error('Error reading file:', error);
 *   }
 * };
 * document.body.appendChild(inputElement);
 */
export const fileToString = async (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      if (e.target && e.target.result) {
        // Convert ArrayBuffer to binary string
        const arrayBuffer = e.target.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        let binaryString = '';
        for (let i = 0; i < uint8Array.byteLength; i++) {
          binaryString += String.fromCharCode(uint8Array[i]);
        }
        resolve(binaryString);
      } else {
        reject('Could not read file');
      }
    };
    reader.onerror = err => {
      reject(err.toString());
    };
    // Use readAsArrayBuffer instead of readAsBinaryString
    reader.readAsArrayBuffer(file);
  });
};

export type FileChunk = {
  index: number;
  start: number;
  end: number;
  data: ArrayBuffer;
  contentLength: number;
};

/**
 * Asynchronously fetches a file in specified chunks from a URL and yields smaller chunks of the file.
 * This method is useful for processing large files in manageable pieces without loading the entire file into memory.
 *
 * @async
 * @generator
 * @function fetchFileInChunksAndSplitInChunks
 * @param {string} url - The URL of the file to fetch.
 * @param {Object} options - Configuration options for downloading and yielding chunks.
 * @param {number} options.downloadChunkSize - The size (in bytes) of each chunk to be downloaded.
 * @param {number} options.returnChunkSize - The size (in bytes) of each chunk to be yielded to the caller.
 * @param {number} [options.timeout] - An optional timeout (in milliseconds) for the fetch operation.
 * @yields {Object} An object containing the chunk's metadata and data:
 *                  - `index` (number): The index of the chunk.
 *                  - `start` (number): The starting byte of the chunk within the full content.
 *                  - `end` (number): The ending byte of the chunk within the full content.
 *                  - `data` (ArrayBuffer): The chunk data as an ArrayBuffer.
 *                  - `contentLength` (number): The total content length of the file.
 * @throws {Error} Throws an error if fetching chunks fails or the operation times out.
 * @example
 * const url = 'https://example.com/largefile.zip';
 * const options = { downloadChunkSize: 1024 * 1024, returnChunkSize: 1024, timeout: 15000 };
 * for await (const chunk of fetchFileInChunksAndSplitInChunks(url, options)) {
 *   // Process each chunk here
 *   console.log(`Received chunk: ${chunk.index}`);
 * }
 */
export async function* fetchFileInChunksAndSplitInChunks(
  url: string,
  options: {
    downloadChunkSize: number;
    returnChunkSize: number;
    timeout?: number;
  }
): AsyncGenerator<FileChunk> {
  const { downloadChunkSize, returnChunkSize, timeout } = options;
  let fetchError;

  let timeoutId: null | number = null;

  const response = await axios.head(url);
  const contentLength = parseInt(response.headers['content-length'], 10);

  const dataBuffer = new ArrayBuffer(contentLength);
  const dataBufferView = new Uint8Array(dataBuffer);

  let bytesDownloaded = 0;

  if (timeout) {
    timeoutId = setTimeout(() => {
      if (bytesDownloaded !== contentLength) {
        fetchError = new Error(`Timeout ${timeout} exceed. Downloaded ${bytesDownloaded} from ${contentLength}`);
      }
      timeoutId = null;
    }, timeout) as unknown as number;
  }

  (async () => {
    const numberOfChunksToDownload = Math.ceil(contentLength / downloadChunkSize);
    const retriesCount = 5;
    for (let i = 0; i < numberOfChunksToDownload; i++) {
      const start = i * downloadChunkSize;
      const end = Math.min((i + 1) * downloadChunkSize - 1, contentLength);

      let retries = 0;
      let response = null;
      do {
        try {
          response = await axios.get(url, {
            headers: {
              Range: `bytes=${start}-${end}`,
            },
            responseType: 'arraybuffer',
          });
        } catch {
          retries++;
          if (retries >= retriesCount) {
            throw new Error(`Cannot fetch chunk ${start}-${end}`);
          }
        }
      } while (!response);
      dataBufferView.set(new Uint8Array(response.data), bytesDownloaded);
      bytesDownloaded += response.data.byteLength;
    }
  })().catch(e => {
    fetchError = e;
    console.log(e);
  });

  let returnedBytes = 0;
  let index = 0;

  while (returnedBytes !== contentLength && !fetchError) {
    const start = returnedBytes;
    const end = Math.min(returnedBytes + returnChunkSize, contentLength);

    if (end <= bytesDownloaded) {
      yield {
        index,
        start,
        end,
        data: dataBuffer.slice(start, end),
        contentLength,
      };
      returnedBytes += end - start;
      index++;
    } else {
      await sleep(500);
    }
  }

  if (timeoutId !== null) {
    clearTimeout(timeoutId);
  }

  if (fetchError) {
    throw fetchError;
  }
}

/**
 * Splits a File object into smaller chunks of a specified size.
 * This function is a generator, yielding each chunk as an ArrayBuffer, allowing for processing of large files in parts.
 *
 * @async
 * @generator
 * @function splitFileInChunks
 * @param {File} file - The file to be split into chunks.
 * @param {number} chunkSize - The size of each chunk in bytes.
 * @yields {Object} An object containing the chunk's metadata and data:
 *                  - `index` (number): The index of the chunk.
 *                  - `start` (number): The starting byte position of the chunk within the full file.
 *                  - `end` (number): The ending byte position of the chunk within the full file.
 *                  - `data` (ArrayBuffer): The chunk data as an ArrayBuffer.
 *                  - `contentLength` (number): The total size of the file in bytes.
 * @example
 * const file = new File([data], "filename.txt");
 * const chunkSize = 1024; // 1KB
 * for await (const chunk of splitFileInChunks(file, chunkSize)) {
 *   // Process each chunk here
 *   console.log(`Chunk ${chunk.index} processed`);
 * }
 */
export async function* splitFileInChunks(file: File, chunkSize: number) {
  const data = await file.arrayBuffer();
  const contentLength = data.byteLength;
  const numberOfChunks = Math.ceil(contentLength / chunkSize);

  for (let i = 0; i < numberOfChunks; i++) {
    const start = i * chunkSize;
    const end = Math.min((i + 1) * chunkSize, contentLength);

    yield {
      index: i,
      start,
      end,
      data: data.slice(start, end),
      contentLength,
    };
  }
  return;
}

/**
 * Converts an array of ArrayBuffer chunks into a File object.
 * This function is useful for reassembling chunks of file data into a single File object,
 * particularly after processing or transferring individual chunks.
 *
 * @function convertChunksIntoFile
 * @param chunks - An array of ArrayBuffer objects representing file chunks.
 * @param name - The name of the resulting File object.
 * @returns A File object containing the combined data from the chunks.
 * @example
 * const chunks = [chunk1, chunk2, chunk3]; // ArrayBuffers obtained from processing or fetching
 * const file = convertChunksIntoFile(chunks, 'combined.txt');
 * console.log(file.name); // 'combined.txt'
 */
export const convertChunksIntoFile = (chunks: ArrayBuffer[], name = 'no_name'): File => {
  const combinedBlob = new Blob(chunks, {
    type: 'application/octet-stream',
  });

  return new File([combinedBlob], name, {
    type: combinedBlob.type,
    lastModified: new Date().getTime(),
  });
};

/*
 * Initiates a download of the provided data as a file with the specified MIME type and file name.
 */
export const downloadFile = (data: string | ArrayBuffer, mimeType: string, fileName: string) => {
  // Convert string data to ArrayBuffer if necessary
  const arrayBuffer = typeof data === 'string' ? new TextEncoder().encode(data).buffer : data;

  // Create a Blob from the ArrayBuffer
  const blob = new Blob([arrayBuffer], { type: mimeType });

  // Generate a download link and click it programmatically
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.setAttribute('download', fileName);
  downloadLink.click();

  // Clean up the URL object after download
  URL.revokeObjectURL(downloadLink.href);
};
