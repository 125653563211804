import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_PROJECT_OVERVIEW = gql`
  fragment PROJECT_OVERVIEW on ProjectEntityResponse {
    data {
      id
      __typename
      attributes {
        name

        preview {
          ...FILE
        }
        preparationDuration {
          ...DURATION
        }
        learningDuration {
          ...DURATION
        }
      }
    }
  }
`;

export const WITH_PROJECTS_OVERVIEW = gql`
  fragment PROJECTS_OVERVIEW on ProjectRelationResponseCollection {
    data {
      id
      __typename
      attributes {
        name

        preview {
          ...FILE
        }
        preparationDuration {
          ...DURATION
        }
        learningDuration {
          ...DURATION
        }
      }
    }
  }
`;
