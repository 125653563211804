export type RGBColor = {
  red: number;
  green: number;
  blue: number;
};

export type HSLAColor = {
  hue: number;
  saturation: number;
  lightness: number;
  alpha: number;
};

export type HexColor = string;

/**
 * Converts HSLA to RGB
 * @param hue - The hue to convert
 * @param saturation - The saturation to convert
 * @param lightness - The lightness to convert
 * @returns {RGBColor} The RGB color
 */
export const hslaToRgb = (hue: number, saturation: number, lightness: number): RGBColor => {
  // Convert saturation and lightness to the range of 0 to 1
  const s = saturation / 100;
  const l = lightness / 100;

  // Calculate the chroma value (colorfulness) based on lightness and saturation
  const c = (1 - Math.abs(2 * l - 1)) * s;

  // Convert hue to the range of 0 to 6 (for easier calculation)
  const h60 = hue / 60;

  // Calculate the intermediate value for determining the RGB components
  const x = c * (1 - Math.abs((h60 % 2) - 1));

  // Calculate the offset needed for each RGB component
  const m = l - c / 2;

  // Determine the RGB values based on the hue value
  let r, g, b;
  switch (Math.floor(h60)) {
    case 0:
      r = c;
      g = x;
      b = 0;
      break;
    case 1:
      r = x;
      g = c;
      b = 0;
      break;
    case 2:
      r = 0;
      g = c;
      b = x;
      break;
    case 3:
      r = 0;
      g = x;
      b = c;
      break;
    case 4:
      r = x;
      g = 0;
      b = c;
      break;
    case 5:
    default:
      r = c;
      g = 0;
      b = x;
  }

  // Scale the RGB values to the 0-255 range and round them to integers
  return {
    red: Math.round((r + m) * 255),
    green: Math.round((g + m) * 255),
    blue: Math.round((b + m) * 255),
  };
};

/**
 * Converts RGB to hex
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {HexColor} The hex color
 */
export const rgbToHex = (r: number, g: number, b: number): HexColor => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).toUpperCase().slice(1)}`;
};

/**
 * Converts hex to RGB
 * @param {string} hexColor - The hex color to convert
 * @returns {RGBColor} The RGB color
 */
export const hexToRgb = (hexColor: HexColor): RGBColor => {
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);
  return { red, green, blue };
};

/**
 * Adjusts the brightness of a color
 * @param {string} hexColor - The hex color to adjust
 * @param {number} brightness - The brightness to adjust the color to (0-100)
 * @returns {string} The adjusted color
 */
export const adjustColorBrightness = (hexColor: HexColor, brightness: number): HexColor => {
  // Parse the hex color
  let red = parseInt(hexColor.substring(1, 3), 16);
  let green = parseInt(hexColor.substring(3, 5), 16);
  let blue = parseInt(hexColor.substring(5, 7), 16);

  // Adjust the brightness
  red = Math.floor((red * brightness) / 100);
  green = Math.floor((green * brightness) / 100);
  blue = Math.floor((blue * brightness) / 100);

  // Convert back to hex and return
  return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}`;
};

/**
 * Get the contrast color for a given hex color
 * @param hexColor - The hex color to get the contrast color for
 * @returns {HexColor} The contrast color
 */
export const getContrastColor = (hexColor: HexColor): HexColor => {
  if (!/^#[0-9A-F]{6}$/i.test(hexColor)) {
    throw new Error(`invalid hex value ${hexColor}`);
  }
  // Parse the hex color to RGB
  const red = parseInt(hexColor.substring(1, 3), 16);
  const green = parseInt(hexColor.substring(3, 5), 16);
  const blue = parseInt(hexColor.substring(5, 7), 16);

  // Calculate luminance
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  // Determine contrast color (black or white)
  if (luminance > 0.5) {
    return '#000000'; // Dark color for light backgrounds
  } else {
    return '#FFFFFF'; // Light color for dark backgrounds
  }
};
