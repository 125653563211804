import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.986 24C4.854 24 4.746 23.958 4.662 23.874C4.59 23.79 4.554 23.688 4.554 23.568V14.244L1.818 16.35C1.71 16.422 1.602 16.452 1.494 16.44C1.386 16.416 1.29 16.35 1.206 16.242L0.45 15.27C0.378 15.162 0.348 15.048 0.36 14.928C0.384 14.808 0.45 14.712 0.558 14.64L4.59 11.526C4.662 11.466 4.734 11.43 4.806 11.418C4.878 11.406 4.956 11.4 5.04 11.4H6.624C6.744 11.4 6.846 11.442 6.93 11.526C7.014 11.61 7.056 11.712 7.056 11.832V23.568C7.056 23.688 7.014 23.79 6.93 23.874C6.846 23.958 6.744 24 6.624 24H4.986Z"
          fill={color}
        />
        <path
          d="M11.242 18C11.122 18 11.02 17.958 10.936 17.874C10.852 17.79 10.81 17.688 10.81 17.568V16.65C10.81 16.542 10.834 16.416 10.882 16.272C10.942 16.116 11.068 15.96 11.26 15.804L14.104 12.978C14.896 12.354 15.532 11.826 16.012 11.394C16.504 10.95 16.858 10.548 17.074 10.188C17.302 9.816 17.416 9.45 17.416 9.09C17.416 8.55 17.266 8.112 16.966 7.776C16.678 7.44 16.216 7.272 15.58 7.272C15.16 7.272 14.806 7.362 14.518 7.542C14.23 7.71 14.002 7.938 13.834 8.226C13.678 8.514 13.57 8.832 13.51 9.18C13.486 9.336 13.42 9.444 13.312 9.504C13.204 9.564 13.096 9.594 12.988 9.594H11.386C11.278 9.594 11.188 9.558 11.116 9.486C11.044 9.414 11.008 9.33 11.008 9.234C11.02 8.718 11.128 8.22 11.332 7.74C11.536 7.248 11.83 6.816 12.214 6.444C12.61 6.06 13.09 5.754 13.654 5.526C14.218 5.298 14.854 5.184 15.562 5.184C16.558 5.184 17.38 5.352 18.028 5.688C18.688 6.024 19.18 6.48 19.504 7.056C19.84 7.632 20.008 8.28 20.008 9C20.008 9.564 19.9 10.086 19.684 10.566C19.468 11.034 19.156 11.49 18.748 11.934C18.34 12.366 17.848 12.81 17.272 13.266L14.716 15.858H19.846C19.978 15.858 20.08 15.9 20.152 15.984C20.236 16.056 20.278 16.158 20.278 16.29V17.568C20.278 17.688 20.236 17.79 20.152 17.874C20.08 17.958 19.978 18 19.846 18H11.242Z"
          fill={color}
        />
        <path
          d="M26.598 13.18C25.758 13.18 25.032 13.078 24.42 12.874C23.808 12.67 23.298 12.406 22.89 12.082C22.494 11.746 22.194 11.386 21.99 11.002C21.786 10.606 21.678 10.216 21.666 9.832C21.666 9.724 21.702 9.64 21.774 9.58C21.858 9.508 21.954 9.472 22.062 9.472H23.682C23.802 9.472 23.904 9.496 23.988 9.544C24.072 9.592 24.144 9.688 24.204 9.832C24.312 10.156 24.486 10.414 24.726 10.606C24.978 10.798 25.266 10.936 25.59 11.02C25.914 11.092 26.256 11.128 26.616 11.128C27.336 11.128 27.906 10.96 28.326 10.624C28.758 10.276 28.974 9.784 28.974 9.148C28.974 8.512 28.776 8.056 28.38 7.78C27.984 7.492 27.432 7.348 26.724 7.348H24.87C24.738 7.348 24.63 7.312 24.546 7.24C24.462 7.156 24.42 7.048 24.42 6.916V6.16C24.42 6.04 24.444 5.938 24.492 5.854C24.552 5.758 24.612 5.68 24.672 5.62L27.894 2.47H22.746C22.614 2.47 22.506 2.428 22.422 2.344C22.35 2.26 22.314 2.158 22.314 2.038V0.85C22.314 0.717999 22.35 0.609999 22.422 0.526C22.506 0.441999 22.614 0.399999 22.746 0.399999H30.468C30.6 0.399999 30.708 0.441999 30.792 0.526C30.876 0.609999 30.918 0.717999 30.918 0.85V1.93C30.918 2.038 30.894 2.134 30.846 2.218C30.798 2.302 30.744 2.374 30.684 2.434L27.57 5.638L27.786 5.656C28.518 5.716 29.166 5.884 29.73 6.16C30.306 6.424 30.75 6.814 31.062 7.33C31.386 7.846 31.548 8.494 31.548 9.274C31.548 10.09 31.332 10.792 30.9 11.38C30.48 11.956 29.898 12.4 29.154 12.712C28.41 13.024 27.558 13.18 26.598 13.18Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
