import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { FULL_RESOURCE_QUERY } from '@store/queries/resource';

export const resourceApiGraphql = createApi({
  reducerPath: 'resourceApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getResource: builder.query({
      query: id => ({
        document: FULL_RESOURCE_QUERY,
        variables: { id },
      }),
    }),
  }),
});

export const { useGetResourceQuery } = resourceApiGraphql;
