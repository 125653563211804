import { createApi } from '@reduxjs/toolkit/query/react';

import protectedQuery from '@store/utils/protected-query.graphql';

import { DEFAULT_LIBRARY_QUERY, LIBRARY_FILTERS_QUERY } from '@store/queries/library';
import {
  LessonFiltersInput,
  ProjectFiltersInput,
  ActivityFiltersInput,
  ResourceFiltersInput,
} from '@store/gql/graphql';

export const libraryApiGraphql = createApi({
  reducerPath: 'libraryApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getLibraryData: builder.query({
      query: filters => {
        let includeProjects = true;
        let includeLessons = true;
        let includeChallenges = true;
        let includeActivities = true;
        let includeResources = true;

        const projectFilters: ProjectFiltersInput = {};
        const lessonFilters: LessonFiltersInput = {};
        const activityFilters: ActivityFiltersInput = {};
        const resourceFilters: ResourceFiltersInput = {};

        if (filters.type) {
          includeProjects = filters.type === 'project';
          includeLessons = filters.type === 'lesson';
          includeChallenges = filters.type === 'challenge';
          includeActivities = filters.type === 'activity';
          includeResources = filters.type === 'resource';
        }

        if (filters.subject) {
          projectFilters.subjects = { code: { in: [filters.subject] } };
          lessonFilters.subjects = { code: { in: [filters.subject] } };
          activityFilters.subjects = { code: { in: [filters.subject] } };
          resourceFilters.subjects = { code: { in: [filters.subject] } };
        }

        if (filters.grade) {
          projectFilters.grades = { name: { in: [filters.grade] } };
          lessonFilters.grades = { name: { in: [filters.grade] } };
          activityFilters.grades = { name: { in: [filters.grade] } };

          includeResources = false;
        }

        if (filters.level) {
          projectFilters.level = { body: { eq: filters.level } };
          lessonFilters.level = { body: { eq: filters.level } };
          activityFilters.level = { body: { eq: filters.level } };
          resourceFilters.level = { body: { eq: filters.level } };
        }

        if (filters.duration) {
          includeResources = false;

          switch (filters.duration) {
            case 'lt-1hr':
              projectFilters.learningDuration = { hours: { eq: 0 } };
              lessonFilters.learningDuration = { hours: { eq: 0 } };
              activityFilters.learningDuration = { hours: { eq: 0 } };
              break;
            case '1-to-3hrs':
              projectFilters.learningDuration = {
                or: [
                  { hours: { gte: 1, lt: 3 } },
                  {
                    hours: { eq: 3 },
                    minutes: { eq: 0 },
                  },
                ],
              };
              lessonFilters.learningDuration = {
                or: [
                  { hours: { gte: 1, lt: 3 } },
                  {
                    hours: { eq: 3 },
                    minutes: { eq: 0 },
                  },
                ],
              };
              activityFilters.learningDuration = {
                or: [
                  { hours: { gte: 1, lt: 3 } },
                  {
                    hours: { eq: 3 },
                    minutes: { eq: 0 },
                  },
                ],
              };
              break;
            case 'gt-3hrs':
              projectFilters.learningDuration = {
                or: [{ hours: { gt: 3 } }, { hours: { eq: 3 }, minutes: { gt: 0 } }],
              };
              lessonFilters.learningDuration = { or: [{ hours: { gt: 3 } }, { hours: { eq: 3 }, minutes: { gt: 0 } }] };
              activityFilters.learningDuration = {
                or: [
                  { hours: { gt: 3 } },
                  {
                    hours: { eq: 3 },
                    minutes: { gt: 0 },
                  },
                ],
              };
              break;
          }
        }

        return {
          document: DEFAULT_LIBRARY_QUERY,
          variables: {
            includeProjects,
            includeLessons,
            includeChallenges,
            includeActivities,
            includeResources,
            projectFilters,
            lessonFilters,
            activityFilters,
            resourceFilters,
          },
        };
      },
    }),

    getLibraryFilters: builder.query({
      query: () => ({
        document: LIBRARY_FILTERS_QUERY,
      }),
    }),
  }),
});

export const { useGetLibraryDataQuery, useGetLibraryFiltersQuery } = libraryApiGraphql;
