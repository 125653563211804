import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { round } from 'lodash';

import { colors as themeColors } from '@themes/config/theme-colors';
import { RgbLightIcon, SensorTestIcon } from '@webapp/components/icons';

import StyledIconButton from '@webapp/components/blocks/component/styled-icon-button';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useSelector } from '@store/configureStore';
import { selectModuleById } from '@webapp/store/slices/model/model.selectors';
import StyledSlider from '@webapp/components/blocks/component/styled-slider';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';
import { LightSensor } from '@lib/robo/modules';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-xsmall-semibold',
  color: themeColors.white['500'],
}));

const LightSensorWidget = ({ counter, data, disabled }) => {
  const { isPlaying, editorType } = useEditorContext();
  const { addHistoryEntry } = useActionsHistory();

  const moduleId = data?.moduleIds?.[0];
  const moduleState = useSelector(state => selectModuleById(state, 'lightSensors', moduleId));

  const sliderValue =
    isPlaying && moduleState?.sensorData?.intensity
      ? LightSensor.convertLumenToPercentages(moduleState.sensorData.intensity)
      : 0;

  const roundedSliderValue = round(sliderValue, 1);

  useEffect(() => {
    if (isPlaying && roundedSliderValue) {
      addHistoryEntry({
        action: 'interact:widget:light-sensor',
        scope: editorType,
      });
    }
  }, [isPlaying, roundedSliderValue, editorType]);

  return (
    <StyledIconButton
      color={disabled ? WidgetColors.disabledBackground : '#E31871'}
      width="100%"
      height="100%"
      size="large"
    >
      {!!counter && <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography>}
      <RgbLightIcon sx={{ width: '25px', height: '25px', marginBottom: '15px' }} />
      <StyledSlider orientation="vertical" min={0} max={100} value={sliderValue} />
      <SensorTestIcon sx={{ width: '15px', height: '15px' }} />
    </StyledIconButton>
  );
};

LightSensorWidget.initialProps = {
  width: 1,
  height: 3,
};

LightSensorWidget.initialData = {};

export default LightSensorWidget;
