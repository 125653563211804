import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21" cy="21" r="9" stroke={color} strokeWidth="2" />
        <path
          d="M34 21C34 21.2818 33.8428 21.6855 33.2547 22.1822C32.6711 22.6749 31.7674 23.1637 30.5632 23.5938C28.1634 24.4509 24.7824 25 21 25C17.2176 25 13.8366 24.4509 11.4368 23.5938C10.2326 23.1637 9.32889 22.6749 8.74534 22.1822C8.15715 21.6855 8 21.2818 8 21C8 20.7182 8.15715 20.3145 8.74534 19.8178C9.32889 19.3251 10.2326 18.8363 11.4368 18.4062C13.8366 17.5491 17.2176 17 21 17C24.7824 17 28.1634 17.5491 30.5632 18.4062C31.7674 18.8363 32.6711 19.3251 33.2547 19.8178C33.8428 20.3145 34 20.7182 34 21Z"
          stroke={color}
          strokeWidth="2"
        />
        <path d="M21 9V33" stroke={color} strokeWidth="2" strokeLinecap="round" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
