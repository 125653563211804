import React, { useMemo } from 'react';

// Import all icon components statically
import * as IconComponents from '@webapp/components/icons';

// Import only the default icon configs
import { defaultIconConfigs } from '@webapp/components/icons';

// Create a type that represents any icon component
type IconComponent = React.ComponentType<any>;

// Generic interface for IconLoaderProps
interface IconLoaderProps {
  name: string;
  props?: React.ComponentProps<IconComponent>;
}

/**
 * IconLoader is a component that dynamically loads and renders icons based on the provided name.
 * It uses a centralized icon registry (imported from '@webapp/components/icons') to access all available icons.
 *
 * The component also applies default styling and properties for each icon type, which can be overridden
 * by the props passed to it.
 *
 * Usage:
 * <IconLoader name="MotorIcon" props={{ color: 'red' }} />
 *
 * @param {string} name - The name of the icon to load. This should match an exported icon name in the icon registry.
 * @param {Object} [props] - Optional properties to pass to the icon component. These will override default properties.
 * @returns {React.ReactElement | null} - The rendered icon component or null if the icon is not found.
 */
function IconLoader({ name, props }: IconLoaderProps) {
  const IconComponent = useMemo(() => {
    const Component = (IconComponents as any)[name];

    if (!Component) {
      console.warn(`Icon not found: ${name}`);
      return () => null;
    }

    return Component;
  }, [name]);

  const defaultProps = (defaultIconConfigs as any)?.[name] || {};
  const mergedProps = { ...defaultProps, ...props };

  return <IconComponent {...mergedProps} />;
}

export default React.memo(IconLoader);
