import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoboClient } from '@lib/robo/robo-client';

export type RoboState = {
  id: string;
  name: string;
  alias: string;
  displayAlias: string;
  connected: boolean;
  batchCheckTimeout: number | null;
};

const initialState: RoboState = {
  id: '',
  name: '',
  alias: '',
  displayAlias: '',
  connected: false,
  batchCheckTimeout: null,
};

const roboSlice = createSlice({
  name: 'robo',
  initialState,
  reducers: {
    connect: (
      state,
      action: PayloadAction<{
        id: string;
        alias: string;
        batchCheckTimeout: number | null;
      }>
    ) => {
      const { id, alias, batchCheckTimeout } = action.payload;
      state.id = id;
      state.name = id;
      state.alias = alias;
      state.displayAlias = RoboClient.getDisplayAlias(alias);
      state.batchCheckTimeout = batchCheckTimeout;
    },

    connected: state => {
      state.connected = true;
    },

    disconnect: state => {
      state.id = initialState.id;
      state.name = initialState.name;
      state.alias = initialState.alias;
    },

    disconnected: state => {
      state.connected = false;
    },
  },
});

export const { connect, connected, disconnect, disconnected } = roboSlice.actions;

export default roboSlice.reducer;
