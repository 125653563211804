import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <mask
          id="path-2-outside-1_1862_1622"
          maskUnits="userSpaceOnUse"
          x="20"
          y="24"
          width="32"
          height="32"
          fill="black"
        >
          <rect fill="white" x="20" y="24" width="32" height="32" />
          <path d="M27.25 32.5C27.25 31.8096 27.8096 31.25 28.5 31.25C29.1904 31.25 29.75 31.8096 29.75 32.5V36.25C29.75 36.9404 29.1904 37.5 28.5 37.5C27.8096 37.5 27.25 36.9404 27.25 36.25V32.5Z" />
          <path d="M43.5 31.25C42.8096 31.25 42.25 31.8096 42.25 32.5V36.25C42.25 36.9404 42.8096 37.5 43.5 37.5C44.1904 37.5 44.75 36.9404 44.75 36.25V32.5C44.75 31.8096 44.1904 31.25 43.5 31.25Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.75 25H27.25C23.7982 25 21 27.7982 21 31.25V48.75C21 52.2018 23.7982 55 27.25 55H44.75C48.2018 55 51 52.2018 51 48.75V31.25C51 27.7982 48.2018 25 44.75 25ZM23.5 31.25C23.5 29.1789 25.1789 27.5 27.25 27.5H44.75C46.8211 27.5 48.5 29.1789 48.5 31.25V48.75C48.5 50.8211 46.8211 52.5 44.75 52.5H27.25C25.1789 52.5 23.5 50.8211 23.5 48.75V31.25Z"
          />
        </mask>
        <path
          d="M27.25 32.5C27.25 31.8096 27.8096 31.25 28.5 31.25C29.1904 31.25 29.75 31.8096 29.75 32.5V36.25C29.75 36.9404 29.1904 37.5 28.5 37.5C27.8096 37.5 27.25 36.9404 27.25 36.25V32.5Z"
          fill="white"
        />
        <path
          d="M43.5 31.25C42.8096 31.25 42.25 31.8096 42.25 32.5V36.25C42.25 36.9404 42.8096 37.5 43.5 37.5C44.1904 37.5 44.75 36.9404 44.75 36.25V32.5C44.75 31.8096 44.1904 31.25 43.5 31.25Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.75 25H27.25C23.7982 25 21 27.7982 21 31.25V48.75C21 52.2018 23.7982 55 27.25 55H44.75C48.2018 55 51 52.2018 51 48.75V31.25C51 27.7982 48.2018 25 44.75 25ZM23.5 31.25C23.5 29.1789 25.1789 27.5 27.25 27.5H44.75C46.8211 27.5 48.5 29.1789 48.5 31.25V48.75C48.5 50.8211 46.8211 52.5 44.75 52.5H27.25C25.1789 52.5 23.5 50.8211 23.5 48.75V31.25Z"
          fill="white"
        />
        <path
          d="M27.25 26H44.75V24H27.25V26ZM22 31.25C22 28.3505 24.3505 26 27.25 26V24C23.2459 24 20 27.2459 20 31.25H22ZM22 48.75V31.25H20V48.75H22ZM27.25 54C24.3505 54 22 51.6495 22 48.75H20C20 52.7541 23.2459 56 27.25 56V54ZM44.75 54H27.25V56H44.75V54ZM50 48.75C50 51.6495 47.6495 54 44.75 54V56C48.7541 56 52 52.7541 52 48.75H50ZM50 31.25V48.75H52V31.25H50ZM44.75 26C47.6495 26 50 28.3505 50 31.25H52C52 27.2459 48.7541 24 44.75 24V26ZM27.25 26.5C24.6266 26.5 22.5 28.6266 22.5 31.25H24.5C24.5 29.7312 25.7312 28.5 27.25 28.5V26.5ZM44.75 26.5H27.25V28.5H44.75V26.5ZM49.5 31.25C49.5 28.6266 47.3734 26.5 44.75 26.5V28.5C46.2688 28.5 47.5 29.7312 47.5 31.25H49.5ZM49.5 48.75V31.25H47.5V48.75H49.5ZM44.75 53.5C47.3734 53.5 49.5 51.3734 49.5 48.75H47.5C47.5 50.2688 46.2688 51.5 44.75 51.5V53.5ZM27.25 53.5H44.75V51.5H27.25V53.5ZM22.5 48.75C22.5 51.3734 24.6266 53.5 27.25 53.5V51.5C25.7312 51.5 24.5 50.2688 24.5 48.75H22.5ZM22.5 31.25V48.75H24.5V31.25H22.5ZM28.5 30.25C27.2574 30.25 26.25 31.2574 26.25 32.5H28.25C28.25 32.3619 28.3619 32.25 28.5 32.25V30.25ZM30.75 32.5C30.75 31.2574 29.7426 30.25 28.5 30.25V32.25C28.6381 32.25 28.75 32.3619 28.75 32.5H30.75ZM30.75 36.25V32.5H28.75V36.25H30.75ZM28.5 38.5C29.7426 38.5 30.75 37.4926 30.75 36.25H28.75C28.75 36.3881 28.6381 36.5 28.5 36.5V38.5ZM26.25 36.25C26.25 37.4926 27.2574 38.5 28.5 38.5V36.5C28.3619 36.5 28.25 36.3881 28.25 36.25H26.25ZM26.25 32.5V36.25H28.25V32.5H26.25ZM43.25 32.5C43.25 32.3619 43.3619 32.25 43.5 32.25V30.25C42.2574 30.25 41.25 31.2574 41.25 32.5H43.25ZM43.25 36.25V32.5H41.25V36.25H43.25ZM43.5 36.5C43.3619 36.5 43.25 36.3881 43.25 36.25H41.25C41.25 37.4926 42.2574 38.5 43.5 38.5V36.5ZM43.75 36.25C43.75 36.3881 43.6381 36.5 43.5 36.5V38.5C44.7426 38.5 45.75 37.4926 45.75 36.25H43.75ZM43.75 32.5V36.25H45.75V32.5H43.75ZM43.5 32.25C43.6381 32.25 43.75 32.3619 43.75 32.5H45.75C45.75 31.2574 44.7426 30.25 43.5 30.25V32.25Z"
          fill="#5A418B"
          mask="url(#path-2-outside-1_1862_1622)"
        />
        <path
          d="M32 19.75C32 17.6789 33.6789 16 35.75 16H36.25C38.3211 16 40 17.6789 40 19.75V53.25C40 55.3211 38.3211 57 36.25 57H35.75C33.6789 57 32 55.3211 32 53.25V19.75Z"
          fill="white"
          stroke="#5A418B"
          strokeWidth="0.8"
        />
        <path
          d="M36 30.75C35.0335 30.75 34.25 31.5335 34.25 32.5V36.25C34.25 37.2165 35.0335 38 36 38C36.9665 38 37.75 37.2165 37.75 36.25V32.5C37.75 31.5335 36.9665 30.75 36 30.75Z"
          fill="white"
          stroke="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
