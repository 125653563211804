import React, { useMemo } from 'react';
import { keyframes, css, Box } from '@mui/material';

export const StrobeAnimation = ({
  borderRadius = '50%',
  color = 'rgba(255, 0, 0, 0.5)',
  animationDuration = 1.5,
  children,
}: {
  borderRadius?: string;
  color?: string;
  animationDuration?: number;
  children: React.ReactNode;
}) => {
  // separate keyframes is used here because sx={{...}} approach caches "pulse"
  // keyframe and displayed animation color was not related
  const pulse = useMemo(() => {
    return keyframes`
      from {
        box-shadow: 0 0 0 0 ${color};
      }

      50% {
        box-shadow: 0 0 14px 15px ${color};
      }

      to {
        box-shadow: 0 0 0 0 ${color};
      }
    `;
  }, [color]);

  return (
    <Box
      data-test-id="animation-strobe"
      css={css`
        border-radius: ${borderRadius};
        animation: ${pulse} ${animationDuration}s infinite;
      `}
    >
      {children}
    </Box>
  );
};
