import { createTheme } from '@mui/material/styles';
import { colors as themeColors } from './config/theme-colors';
import { shadows as themeShadows } from './config/theme-shadows';
import { overrides as typographyOverrides } from './config/theme-typography';

import { MuiButtonOverrides } from './components/MuiButton.overrides';
import { MuiTextFieldOverrides } from './components/MuiTextField.overrides';
import { MuiSelectOverrides } from './components/MuiSelect.overrides';
import { MuiPaperOverrides } from './components/MuiPaper.overrides';

let theme = createTheme({
  root: {
    color: themeColors.black['500'],
  },
  themeName: 'General RW theme',
  typography: {
    ...typographyOverrides.font,
    ...typographyOverrides.elements,
    ...typographyOverrides.extra,
  },

  shadows: [...themeShadows],

  components: {
    MuiButton: { ...MuiButtonOverrides },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },

    MuiPaper: { ...MuiPaperOverrides },

    MuiSelect: { ...MuiSelectOverrides },

    MuiTextField: { ...MuiTextFieldOverrides },

    MuiAlert: {
      styleOverrides: {
        outlined: {
          backgroundColor: themeColors.white.main,
        },
      },
    },
  },

  palette: {
    mode: 'light',
    primary: {
      light: themeColors.violet['100'],
      main: themeColors.violet.main,
      dark: themeColors.violet['300'],
      contrastText: themeColors.white.main,
    },

    secondary: {
      light: themeColors.black['200'],
      main: themeColors.black['200'],
      dark: themeColors.black['300'],
      contrastText: themeColors.white.main,
    },

    tertiary: {
      light: themeColors.black['200'],
      main: themeColors.black.main,
      dark: themeColors.black['400'],
      contrastText: themeColors.white.main,
    },

    quaternary: {
      light: '#1D95FF ',
      main: '#156BFB',
      dark: '#1048db ',
      contrastText: themeColors.white.main,
    },

    quinary: {
      light: '#69aeff ',
      main: '#5C98FC',
      dark: '#4b7bcc ',
      contrastText: themeColors.white.main,
    },
  },
  breakpoints: {
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // "lg" is greater than Chromebook screen width 1366px
      lg: 1367,
      // extra-large
      xl: 1536,
    },
  },
});

export default theme;
