import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.0758 47.5795C48.9712 47.424 48.8458 47.2734 48.6919 47.1279C46.672 45.2168 44.0116 43.9481 41.4088 42.8809C42.6215 39.931 43.7799 36.9552 44.827 33.9426C45.0495 33.9301 45.2795 33.8874 45.5246 33.803C49.9272 32.285 54.3273 30.7678 58.7299 29.2498C61.7258 28.2169 60.4386 23.4379 57.4151 24.48C53.0485 25.9846 48.6827 27.4901 44.3177 28.9964C43.1183 27.869 41.1638 27.4692 39.5379 28.0814C36.5847 24.7711 33.6315 21.4599 30.6774 18.1496C28.5506 15.7643 25.0646 19.2753 27.1806 21.6473C30.1346 24.9593 33.0878 28.2696 36.0427 31.5816C36.2208 31.7806 36.4074 31.9345 36.5997 32.055C35.4823 35.2398 34.2462 38.3846 32.9548 41.5009C32.8511 41.7526 32.7876 42.006 32.755 42.2653C32.7307 42.272 32.7064 42.2787 32.6839 42.2854C29.642 43.4855 26.3292 44.8505 23.9255 47.1262C23.483 47.5444 23.2539 48.0119 23.1853 48.4736C22.788 49.1444 22.6609 49.9874 23.0439 50.8665C24.3729 53.9133 25.6743 56.9727 26.9598 60.0389C27.4817 61.28 28.5765 62.1574 30.0025 61.7651C31.1776 61.4414 32.2523 59.9686 31.7296 58.7216C30.521 55.8395 29.2991 52.9632 28.0579 50.0953C29.1 49.305 30.3429 48.7287 31.4971 48.1667C32.1369 47.8555 32.7867 47.5662 33.4399 47.2835C33.4458 47.2835 33.8983 47.0928 33.9978 47.056C34.4252 46.8879 35.8545 45.7429 37.958 46.7106C38.1612 46.8469 38.382 46.9631 38.6187 47.056C38.7065 47.0903 39.0695 47.2408 39.1582 47.2776C39.8197 47.5603 40.4712 47.8589 41.1194 48.1667C42.4083 48.7797 43.8083 49.4388 44.9107 50.3772C45.0922 53.5244 45.2962 56.6675 45.5137 59.8114C45.7353 62.9737 50.6841 62.9971 50.4599 59.8114C50.2132 56.2827 49.9824 52.7533 49.7867 49.2197C49.7474 48.5179 49.4747 47.9718 49.0758 47.5795Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.355 27.2355C45.6087 27.2355 47.4357 25.4085 47.4357 23.1548C47.4357 20.9012 45.6087 19.0742 43.355 19.0742C41.1014 19.0742 39.2744 20.9012 39.2744 23.1548C39.2744 25.4085 41.1014 27.2355 43.355 27.2355Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.5469 33.1523L64.2106 31.707L47.4365 36.3865L64.5469 33.1523Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8138 26.0479L19 27.2873L33.9441 36.2239L19.8138 26.0479Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.48 27.7936L33.2074 33.9551L28.6526 26.8828L27.48 27.7936Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.8139 41.4489L55.2438 40.0296L47.0073 38.3125L54.8139 41.4489Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
