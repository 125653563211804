import BasicElement from './basic-element';

const MotionElement = props => {
  return (
    <BasicElement
      sx={{
        backgroundColor: '#034FCC',

        '&:hover': {
          backgroundColor: '#0366FF',
        },

        '&:active': {
          backgroundColor: '#033BA9',
        },
      }}
      {...props}
    />
  );
};

export default MotionElement;
