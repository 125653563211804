import { isEqual } from 'lodash';

import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

export enum LinetrackerObstacleEventType {
  Close = 'close',
  Far = 'far',
}

export class Linetracker extends BaseModule<typeof Linetracker> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.Linetracker, store);

    this.client.registerHandler(
      HandlerType.OnLinetracker,
      ({ data }) => {
        const positions = Linetracker.parsePayload(data);

        const activeEvents = {
          [LinetrackerObstacleEventType.Close]: positions.left || positions.middle || positions.right,
          [LinetrackerObstacleEventType.Far]: !(positions.left || positions.middle || positions.right),
        };

        const currentData = this.getDataState();

        const hasChanges =
          !currentData.sensorData ||
          !isEqual(currentData.sensorData.positions, positions) ||
          !isEqual(currentData.sensorData.activeEvents, activeEvents);

        if (!hasChanges) {
          return;
        }

        this.updateDataState({
          sensorData: {
            positions,
            activeEvents,
          },
        });
      },
      id
    );
  }

  requestLinetrackerState() {
    this.client.requestLinetracker(this.index);
  }

  /**
   * Create a trigger for the linetracker
   * @param eventType - The type of event to trigger on
   * @param onActionDone - The callback function to call when the action is done
   * @returns The trigger ID
   */
  createTrigger(eventType: LinetrackerObstacleEventType, onActionDone: onActionDoneType) {
    const triggerId = this.generateActionOrTriggerId();

    let triggerCondition;

    switch (eventType) {
      case LinetrackerObstacleEventType.Close:
        triggerCondition = 0;
        break;

      case LinetrackerObstacleEventType.Far:
        triggerCondition = 1;
        break;
    }

    this.subscribeToResponse(triggerId, onActionDone);

    this.client.setLinetrackerTrigger(triggerId, this.index, triggerCondition);

    return { triggerId };
  }

  /**
   * Get the initial data state
   * @returns The initial data state
   */
  static initialDataState() {
    return {
      sensorData: {
        positions: {
          left: false,
          middle: false,
          right: false,
        },
        activeEvents: {
          [LinetrackerObstacleEventType.Close]: false,
          [LinetrackerObstacleEventType.Far]: false,
        },
      },
    };
  }

  /**
   * Parse the linetracker payload
   * @param data - The payload to parse
   * @returns The parsed payload
   */
  static parsePayload(data: Uint8Array) {
    const state = data[0].toString(2).padStart(3, '0').split('').reverse().join('');

    return {
      left: state[0] === '1',
      middle: state[1] === '1',
      right: state[2] === '1',
    };
  }

  /**
   * Sets the line tracking action.
   * @param {number} accelerometerModuleIndex - The accelerometer module index.
   * @param {number} motorsMap - The motors bitfield. LSB is the first motor.
   * @param {number} motorsDirectionsMap - The motors directions bitfield. LSB is the first motor. 0 - ccw, 1 - cw.
   * @param {number} speed - The speed value to set. 0-100%
   * @param {onActionDoneType} onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setLineTrackingAction(
    accelerometerModuleIndex: number,
    motorsMap: number,
    motorsDirectionsMap: number,
    speed: number,
    onActionDone: onActionDoneType
  ) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    const kp = 0;
    const kd = 0;

    this.client.setLineTrackerAction(actionId, accelerometerModuleIndex, motorsMap, motorsDirectionsMap, speed, kp, kd);

    return { actionId };
  }
}
