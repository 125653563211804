import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

import { RepeatTriggerWidgetData } from '@webapp/components/blocks/widgets/code/repeat-trigger/code-repeat-trigger-widget';

type IconProps = {
  value: RepeatTriggerWidgetData;
};

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { repeats, runtime } = value;

  const allowedRepeats = repeats;
  const repeatCount = allowedRepeats - (runtime?.repeated ?? 0);

  return (
    <SvgIcon {...rest} width="45" height="30" viewBox="0 0 45 30">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none">
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={allowedRepeats === 10 ? '20' : '26'} // fit number "10" in the icon
          fontWeight="bold"
          fill="white"
          stroke="#5A418B"
          strokeWidth="1.5"
          letterSpacing="-3"
        >
          {`${repeatCount}/${allowedRepeats}`}
        </text>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
