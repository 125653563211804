import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import Slider from '@webapp/components/ui/sliders/slider';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';

import {
  DistanceIcon,
  SpeedIcon,
  ReverseIcon,
  SettingsIcon,
  BendRightIcon,
  BendLeftIcon,
} from '@webapp/components/icons';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { Motor, ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { ExecutableActionWidgetComponent, WidgetExecutionType, ActionWidgetExecutionResult } from '@webapp/store/types';

import MotorSettingsModal from '../common/motor-settings-modal';
import { CodeMotorAlignment, CodeMotorId } from '@webapp/store/types';

import { getSharedMotorsConfig, useSharedMotorsConfig } from '../common/motor-alignment-helper';

const widgetConfig = {
  distance: {
    max: 105,
    min: -105,
    step: 5,
    maxAsInfinite: true,
    minAsInfinite: true,
  },

  speed: {
    max: 100,
    min: 0,
    step: 5,
  },

  bend: {
    max: 100,
    min: -100,
    step: 5,
  },
};

const DriveWidget: ExecutableActionWidgetComponent<DriveWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();
  const widget = getWidgetById<DriveWidgetData>(id);
  const widgetData = widget?.data;

  const [distance, setDistance] = useState(widgetData?.distance ?? DriveWidget.initialData.distance);
  const [isDistanceInfinite, setIsDistanceInfinite] = useState(
    widgetData?.isDistanceInfinite ?? DriveWidget.initialData.isDistanceInfinite
  );

  const [speed, setSpeed] = useState(widgetData?.speed ?? DriveWidget.initialData.speed);
  const [isReversed, setIsReversed] = useState(widgetData?.isReversed ?? DriveWidget.initialData.isReversed);
  const [bend, setBend] = useState(widgetData?.bend ?? DriveWidget.initialData.bend);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const { model: roboModel } = useRobo();

  let connectedMotors: Record<string, Motor> = {};

  if (roboModel?.modules.motors) {
    connectedMotors = Object.entries(roboModel.modules.motors).reduce(
      (acc, [motorId, motor]) => {
        if (motor.isEnabled()) {
          acc[motorId] = motor;
        }
        return acc;
      },
      {} as Record<string, Motor>
    );
  }

  const motorsConfig = useSharedMotorsConfig();

  /**
   * Handles the distance change
   */
  const handleDistanceChange = (newDistance: number) => {
    if (newDistance >= widgetConfig.distance.max && widgetConfig.distance.maxAsInfinite) {
      setDistance(newDistance);
      setIsDistanceInfinite(true);
    } else if (newDistance <= widgetConfig.distance.min && widgetConfig.distance.minAsInfinite) {
      setDistance(newDistance);
      setIsDistanceInfinite(true);
    } else {
      setDistance(newDistance);
      setIsDistanceInfinite(false);
    }
  };

  /**
   * Handles the distance changed event. Updates the widget data
   */
  const onDistanceChanged = (newDistance: number) => {
    updateWidgetData(id, { distance: newDistance, isDistanceInfinite });
  };

  /**
   * Handles the speed change
   */
  const handleSpeedChange = (newSpeed: number) => {
    setSpeed(newSpeed);
  };

  /**
   * Handles the speed changed event. Updates the widget data
   */
  const onSpeedChanged = (newSpeed: number) => {
    updateWidgetData(id, { speed: newSpeed });
  };

  /**
   * Handles the isReversed changed event. Updates the widget data
   */
  const onIsReversedChanged = () => {
    const newIsReversed = !isReversed;
    setIsReversed(newIsReversed);
    updateWidgetData(id, { isReversed: newIsReversed });
  };

  /**
   * Handles the bend change
   */
  const handleBendChange = (newBend: number) => {
    setBend(newBend);
  };

  /**
   * Handles the bend changed event. Updates the widget data
   */
  const onBendChanged = (newBend: number) => {
    updateWidgetData(id, { bend: newBend });
  };

  /**
   * Handles the settings button click to open the modal
   */
  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  /**
   * Probes a motor by rotating it to a specific angle
   */
  const handleMotorProbe = (motorId: CodeMotorId) => {
    const MOTOR = connectedMotors[motorId];
    const probeAngle = 120;

    const configuredMotorAlignment = motorsConfig[motorId].alignment;

    let rotationDirection = ROTATION_DIRECTIONS.cw;

    switch (configuredMotorAlignment) {
      case CodeMotorAlignment.Left:
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw;
        break;
      case CodeMotorAlignment.Right:
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.ccw : ROTATION_DIRECTIONS.cw;
        break;
      default:
        // Do nothing
        return;
    }

    MOTOR.angle(probeAngle, rotationDirection);
  };

  return (
    <Box sx={{ width: '200px', height: '305px', display: 'flex', flexDirection: 'column' }}>
      {/* First row - Distance and Speed icons */}
      <Grid container spacing={0} sx={{ height: '64px' }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
          <IconWithText
            icon={<DistanceIcon sx={{ fontSize: 'inherit', width: 38 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Distance
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={4} />

        <Grid
          item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: '10px' }}
        >
          <IconWithText
            icon={<SpeedIcon sx={{ fontSize: 'inherit', width: 42 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Speed
              </Typography>
            }
          />
        </Grid>
      </Grid>

      {/* Second row - Distance and Speed sliders */}
      <Grid container spacing={2} sx={{ flexGrow: 1, pb: 3, pt: 2 }}>
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120px' }}>
          <Slider
            value={distance}
            valueLabelDisplay="on"
            maxAsInfinite={widgetConfig.distance.maxAsInfinite}
            minAsInfinite={widgetConfig.distance.minAsInfinite}
            max={widgetConfig.distance.max}
            min={widgetConfig.distance.min}
            step={widgetConfig.distance.step}
            onChange={(_, newDistance) => handleDistanceChange(newDistance as number)}
            onChangeCommitted={(_, newDistance) => onDistanceChanged(newDistance as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="middle"
          />
        </Grid>

        <Grid item xs={2}></Grid>

        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120px' }}>
          <Slider
            value={speed}
            valueLabelDisplay="on"
            max={widgetConfig.speed.max}
            min={widgetConfig.speed.min}
            step={widgetConfig.speed.step}
            onChange={(_, newSpeed) => handleSpeedChange(newSpeed as number)}
            onChangeCommitted={(_, newSpeed) => onSpeedChanged(newSpeed as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="rainbow"
          />
        </Grid>

        {/* Third row - Bend */}
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <BendLeftIcon htmlColor="#5A418B" sx={{ fontSize: '48px' }} />
        </Grid>

        <Grid
          item
          xs={8}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              position: 'absolute',
              top: '6px',
            }}
          >
            <Typography variant="x-tiny-bold" color="#5A418B">
              Bend
            </Typography>
          </Box>

          <Slider
            value={bend}
            valueLabelDisplay="on"
            max={widgetConfig.bend.max}
            min={widgetConfig.bend.min}
            step={widgetConfig.bend.step}
            onChange={(_, newBend) => handleBendChange(newBend as number)}
            onChangeCommitted={(_, newBend) => onBendChanged(newBend as number)}
            orientation="horizontal"
            sx={{ width: '100%' }}
            mainColor="#034FCC"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="middle"
          />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <BendRightIcon htmlColor="#5A418B" sx={{ fontSize: '48px' }} />
        </Grid>

        <Grid item xs={12} sx={{ height: '64px' }}></Grid>
      </Grid>

      {/* Fourth row - Settings and Reverse */}
      <Grid
        container
        spacing={0}
        sx={{
          height: '76px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          pl: 1,
          pr: 1,
          boxShadow: '0px 2px 19px rgba(0, 0, 0, 0.30)',
        }}
      >
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RoundIconButton
            icon={<SettingsIcon sx={{ width: 42, height: 42 }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Settings
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
            onClick={handleSettingsClick}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <RoundToggleIconButton
            icon={<ReverseIcon sx={{ width: 42, height: 42 }} />}
            onChange={onIsReversedChanged}
            value={isReversed}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Reverse
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
        </Grid>
      </Grid>

      {/* Drive Settings Modal */}
      <MotorSettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        probeMotor={handleMotorProbe}
        connectedMotors={connectedMotors}
      />
    </Box>
  );
};

/**
 * Executes the drive steering action
 */
DriveWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById, getProjectConfig }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, async (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const projectConfig = getProjectConfig();

    const widgetData = widget.data;
    const { distance, speed, isReversed, bend, isDistanceInfinite } = widgetData;

    if (!roboModel) {
      throw new Error('RoboModel not found');
    }

    // get all connected motors
    const connectedMotors = Object.values(roboModel.modules.motors).filter(motor => motor.isEnabled());

    // Get the shared motor configuration
    const motorsConfig = getSharedMotorsConfig(projectConfig.motorsConfig);

    // filter connected motors by motorsConfig. If alignment is not set, then skip it
    const filteredMotors = connectedMotors.filter(
      motor => motorsConfig[motor.id as CodeMotorId].alignment !== CodeMotorAlignment.None
    );

    // in case there are less than 2 motors, we can't drive
    if (filteredMotors.length < 2) {
      throw new Error('Less than 2 motors connected');
    }

    let motorsMap = 0b00000000;
    let motorsDirectionsMap = 0b00000000;

    const CW = 1;
    const CCW = 0;

    // First aff all we need to calculate sign for distance
    let distanceSign = distance >= 0 ? 1 : -1;
    if (isReversed) {
      distanceSign *= -1;
    }

    // go through all motors and set the motorsMap and motorsDirectionsMap
    filteredMotors.forEach(motor => {
      const motorConfig = motorsConfig[motor.id as CodeMotorId];
      const motorIndex = motor.index;

      let direction = motorConfig.alignment === CodeMotorAlignment.Left ? CW : CCW;
      direction = distanceSign === 1 ? direction : 1 - direction;

      motorsMap = motorsMap | (1 << motorIndex);
      motorsDirectionsMap = motorsDirectionsMap | (direction << motorIndex);
    });

    // if distance is infinite, we set it maximum value allowed by bytes
    let sendDistance = 0;
    if (isDistanceInfinite) {
      sendDistance = 65535;
    } else {
      sendDistance = Math.abs(distance * 10);
    }

    // take any of the connected motors
    const MOTOR = filteredMotors[0];

    if (bend) {
      MOTOR.setDriveSteeringAction(motorsMap, motorsDirectionsMap, speed, sendDistance, bend, ({ isError }) => {
        if (isError) {
          reject(new Error('Error setting drive steering action'));
        } else {
          resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
        }
      });
    } else {
      MOTOR.setDriveAction(motorsMap, motorsDirectionsMap, speed, sendDistance, ({ isError }) => {
        if (isError) {
          reject(new Error('Error setting drive action'));
        } else {
          resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
        }
      });
    }
  });
};

export interface DriveWidgetData {
  distance: number;
  isDistanceInfinite: boolean;
  speed: number;
  isReversed: boolean;
  bend: number;
}

/**
 * Initial data for the DriveWidget
 */
DriveWidget.initialData = {
  distance: 20,
  isDistanceInfinite: false,
  speed: 100,
  isReversed: false,
  bend: 0,
};

export default DriveWidget;
