export enum WEBAPP_EVENTS {
  // Bridge events
  BRIDGE_CONNECTED = 'webapp.bridge_connected',

  // Mainblock events
  MAINBLOCK_CONNECTED = 'webapp.mainblock_connected',

  // Editor events
  LIVE_EDITOR_OPENED = 'webapp.live_editor_opened',
  CODE_EDITOR_OPENED = 'webapp.code_editor_opened',

  // Tutorial events
  LIVE_TUTORIAL_STARTED = 'webapp.live_tutorial_started',
  CODE_TUTORIAL_STARTED = 'webapp.code_tutorial_started',
  LIVE_TUTORIAL_FINISHED = 'webapp.live_tutorial_finished',
  CODE_TUTORIAL_FINISHED = 'webapp.code_tutorial_finished',
}

export interface WEBAPP_EVENTS_PAYLOAD {
  [WEBAPP_EVENTS.BRIDGE_CONNECTED]: Record<string, never>;
  [WEBAPP_EVENTS.MAINBLOCK_CONNECTED]: Record<string, never>;
  [WEBAPP_EVENTS.LIVE_EDITOR_OPENED]: Record<string, never>;
  [WEBAPP_EVENTS.CODE_EDITOR_OPENED]: Record<string, never>;
  [WEBAPP_EVENTS.LIVE_TUTORIAL_STARTED]: {
    tutorialId?: string;
  };
  [WEBAPP_EVENTS.CODE_TUTORIAL_STARTED]: {
    tutorialId?: string;
  };
  [WEBAPP_EVENTS.LIVE_TUTORIAL_FINISHED]: {
    tutorialId?: string;
  };
  [WEBAPP_EVENTS.CODE_TUTORIAL_FINISHED]: {
    tutorialId?: string;
  };
}
