import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
  Box,
  styled,
  Typography,
} from '@mui/material';

import { RandomIcon } from '@webapp/components/icons';

interface CustomButtonProps {
  mainColor?: string;
  secondaryColor?: string;
  selected?: boolean;
}

export type RandomButtonProps = MuiToggleButtonProps & CustomButtonProps;

const StyledToggleButton = styled(MuiToggleButton, {
  shouldForwardProp: prop => prop !== 'custom', // Prevents custom props from being forwarded to the underlying component
})<{ custom: CustomButtonProps } & MuiToggleButtonProps>(({ theme, custom }) => ({
  textTransform: 'none',
  borderRadius: '50%',
  border: 'none',
  height: '42px',
  width: '42px',
  padding: '0',

  backgroundColor: custom.selected ? custom.mainColor : custom.secondaryColor,

  '&:hover': {
    backgroundColor: custom.selected ? custom.mainColor : custom.secondaryColor,
    opacity: '0.8',
  },
}));

const RandomButton: React.FunctionComponent<RandomButtonProps> = ({
  mainColor = '#DF1642',
  secondaryColor = '#E9E9E9',

  ...props
}) => {
  const { children, selected, sx, ...rest } = props;

  return (
    <Box sx={sx}>
      <StyledToggleButton custom={{ mainColor, secondaryColor, selected }} {...rest}>
        <RandomIcon sx={{ width: 'auto', height: 'auto' }} htmlColor={selected ? '#fefefe' : mainColor} />
      </StyledToggleButton>

      {children && (
        <Box sx={{ lineHeight: '1rem', textAlign: 'center' }}>
          {typeof children === 'string' ? (
            <Typography variant="x-tiny-bold" color={'#5A418B'}>
              {children}
            </Typography>
          ) : (
            children
          )}
        </Box>
      )}
    </Box>
  );
};

export default RandomButton;
