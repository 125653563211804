import { createContext, useContext, useMemo } from 'react';
import {
  ItemSource,
  ItemSourceValue,
  useLocallyOverridableKeyValueStorage,
} from '@lib/hooks/use-locally-overridable-key-value-store';

export type StrapiFeature = {
  name: string;
  enabled: boolean;
  description?: string;
};

export const StrapiFeaturesContext = createContext<Array<StrapiFeature>>([]);

const parseStringValue = (value: string) => {
  return value.toLowerCase() === 'true';
};

export const useFeatures = () => {
  const strapiFeatures = useContext(StrapiFeaturesContext);

  const { clearValue, getValue, setValue } = useLocallyOverridableKeyValueStorage({
    initialItems: useMemo(
      () =>
        strapiFeatures.map(f => ({
          key: f.name,
          value: f.enabled,
        })),
      [strapiFeatures]
    ),
    valueDecoders: useMemo(
      () => ({
        [ItemSource.URL]: value => parseStringValue(value),
        [ItemSource.Cookies]: value => parseStringValue(value),
        [ItemSource.API]: (value: boolean) => value,
      }),
      []
    ),
    valueEncoders: useMemo(
      () => ({
        [ItemSource.Cookies]: (value: boolean) => value.toString(),
      }),
      []
    ),
    storePrefix: 'feature',
  });

  return {
    isFeatureEnabled: (name: string) => {
      return getValue(name).value;
    },
    getFeatureValue: (name: string) => {
      return getValue(name);
    },
    setFeatureValue: (name: string, value: boolean, source: ItemSourceValue) => {
      setValue(name, value, source);
    },
    clearFeatureValue: (name: string, source: ItemSourceValue) => {
      clearValue(name, source);
    },
  };
};
