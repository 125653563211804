import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.762.028C12.598.072.5 6.028.363 6.133.145 6.299 0 6.636 0 6.974c0 .338.086.507.341.676.095.062.822.435 1.616.827l1.445.714.023 4.147c.026 4.598.007 4.296.32 4.927.718 1.447 2.718 2.59 5.528 3.16 2.218.449 5.194.459 7.328.024.573-.117.559-.119.559.088 0 .158-.088.295-.972 1.51a56.453 56.453 0 0 0-1.072 1.503c-.079.135-.098.23-.096.478.004.419.124.67.4.832l.202.118 2.405.013c2.176.011 2.423.006 2.597-.061.234-.089.557-.378.637-.568a.922.922 0 0 0-.022-.684c-.044-.095-.531-.793-1.083-1.551l-1.003-1.379V20.709l.141-.048a7.91 7.91 0 0 0 .618-.292c1.362-.699 2.181-1.521 2.525-2.537l.118-.347.022-4.145.021-4.145 1.17-.583c.644-.32 1.301-.644 1.461-.72.16-.076.353-.19.429-.254.208-.175.341-.498.342-.83 0-.324-.086-.496-.336-.668-.089-.062-2.908-1.46-6.263-3.108C14.104.431 13.27.035 13.074.021a1.327 1.327 0 0 0-.312.007m5.072 4.458c2.643 1.299 4.821 2.378 4.84 2.397.036.036-1.39.744-1.499.744-.033 0-.135.052-.228.115-.19.13-2.89 1.464-3.159 1.559a1.859 1.859 0 0 0-.325.162c-.176.115-4.405 2.194-4.464 2.194-.068 0-7.769-3.794-7.946-3.915a.698.698 0 0 0-.238-.117c-.039-.001-.402-.164-.807-.363l-.736-.361 2.426-1.198 4.832-2.385c1.323-.653 2.426-1.189 2.452-1.191.026-.002 2.209 1.059 4.852 2.359m-8.447 7.637c1.743.858 3.242 1.584 3.331 1.614.348.114.47.069 2.475-.915a279.62 279.62 0 0 1 1.928-.941c.023-.009.039 1.521.039 3.692v3.707l-.184.05c-.326.087-1.211.251-1.725.318-2.97.39-6.338-.05-8.307-1.083-.529-.277-.821-.487-1.121-.806-.421-.447-.383-.059-.396-4.054l-.012-3.52.402.189c.221.104 1.827.891 3.57 1.749m11.127 5.135c-.11.333-.62.839-1.157 1.15a1.392 1.392 0 0 1-.184.095c-.011 0-.02-1.717-.02-3.816v-3.816l.705-.341.704-.341.011 3.43c.01 3.05.003 3.454-.059 3.639m-2.228 6.716c0 .018-.068.033-.151.033-.174 0-.179-.011-.076-.152l.076-.103.075.095a.41.41 0 0 1 .076.127" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
