import { IconType } from '@webapp/components/icons/types';

import { AccelerometerTriggerWidgetData } from '@webapp/components/blocks/widgets/code/accelerometer-trigger/code-accelerometer-trigger-widget';
import { AccelerometerEventType } from '@lib/robo/modules/accelerometer';

import { AccelPickIcon, AccelPutIcon, AccelMoveIcon } from '@webapp/components/icons';

type IconProps = {
  value: AccelerometerTriggerWidgetData;
};

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { eventType } = value;

  if (eventType === AccelerometerEventType.PickUp) {
    return <AccelPickIcon sx={{ fontSize: '40px', stroke: '#5A418B' }} />;
  } else if (eventType === AccelerometerEventType.PutDown) {
    return <AccelPutIcon sx={{ fontSize: '40px', stroke: '#5A418B' }} />;
  } else if (eventType === AccelerometerEventType.Move) {
    return <AccelMoveIcon sx={{ fontSize: '40px', stroke: '#5A418B' }} />;
  }
};

export default Icon;
