import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 38C8.50659 38 0 29.4934 0 19C0 17.5009 1.21523 16.2857 2.71429 16.2857C4.21334 16.2857 5.42857 17.5009 5.42857 19C5.42857 26.4953 11.5047 32.5714 19 32.5714C20.4991 32.5714 21.7143 33.7867 21.7143 35.2857C21.7143 36.7848 20.4991 38 19 38ZM16.2857 2.71429C16.2857 1.21523 17.5009 0 19 0C29.4934 0 38 8.50659 38 19C38 20.4991 36.7848 21.7143 35.2857 21.7143C33.7867 21.7143 32.5714 20.4991 32.5714 19C32.5714 11.5047 26.4953 5.42857 19 5.42857C17.5009 5.42857 16.2857 4.21334 16.2857 2.71429Z"
          fill="url(#paint0_angular_1468_4854)"
        />
        <rect x="12.2144" y="12.2142" width="13.5714" height="13.5714" rx="2.71429" fill="white" />
        <defs>
          <radialGradient
            id="paint0_angular_1468_4854"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(19 19) rotate(10.1247) scale(19.3006)"
          >
            <stop stopColor="white" />
            <stop offset="0.0496453" stopColor="white" stopOpacity="0.904993" />
            <stop offset="0.255827" stopColor="white" />
            <stop offset="0.500967" stopColor="white" stopOpacity="0" />
            <stop offset="0.705086" stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
