export enum COMMON_EVENTS {
  SET_USER_PROPERTIES = 'set_user_properties', // we don't use prefix for this event
  PAGE_VIEW = 'common.page_view', // Also used for route change events in `gatsby-plugin-google-tagmanager`. @see gatsby-config.js
  ERROR_OCCURRED = 'common.error_occurred',
}

export interface COMMON_EVENTS_PAYLOAD {
  [COMMON_EVENTS.PAGE_VIEW]: {
    path: string;
    title?: string;
  };

  [COMMON_EVENTS.ERROR_OCCURRED]: {
    error: string;
    location: string;
    stack?: string;
  };

  [COMMON_EVENTS.SET_USER_PROPERTIES]: {
    user_district?: string;
    user_school?: string;
    user_role?: string;
    user_subscription?: string;
  };
}
