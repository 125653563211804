import Command from '@lib/robo/types/commands';
import {
  convertHexToStringRepresentation as hexToString,
  convertStringRepresentationToHex as stringToHex,
} from '@lib/utils';
import Commands from '@lib/robo/types/commands';
import { Commands as CommandsConfig } from '@lib/robo/commands';

import { format } from 'date-fns';

export type CommandsQueueConfig = {
  commands: {
    [key in Commands]?: {
      interval: number; // if interval is 0 - we just send immediate request
      history?: boolean; // it will enable persisting actions history for specific command
      persistInQueueAfterCleanup?: boolean;
    };
  };
  history?: boolean;
  defaultInterval: number;
};

type CommandQueueData = {
  activeTimeoutId: ReturnType<typeof setTimeout> | null;
  lastExecutedTimestamp: number | null;
  payloadsQueue: Array<{
    payload: Uint8Array;
  }>;
  // for debugging purposes
  executionHistory: Array<{
    payload: Uint8Array;
    timestamp: number;
    executedFromQueue: boolean;
  }>;
};

/**
 * A class to manage a queue of commands that handles timing, execution history,
 * and conditional execution based on configured intervals and conditions.
 */
export class CommandsQueue {
  private commandsQueueMap = new Map<string, CommandQueueData>();

  private readonly sendCommandImmediately: (command: Command, payload: Uint8Array) => void;
  private readonly config: CommandsQueueConfig;

  constructor({
    sendCommandImmediately,
    config,
  }: {
    sendCommandImmediately: (command: Command, payload: Uint8Array) => void;
    config: CommandsQueueConfig;
  }) {
    this.sendCommandImmediately = sendCommandImmediately;
    this.config = config;
  }

  /**
   * Sends a command either immediately or adds it to the queue based on the last execution time and configured intervals.
   */
  public send(command: Command, payload: Uint8Array) {
    const now = Date.now();
    const lastExecutionTime = this.getCommandLastExecutionTime(command);
    const delta = lastExecutionTime !== null ? now - lastExecutionTime : null;

    const minimumIntervalBetweenCommands = this.config.commands[command]?.interval ?? this.config.defaultInterval;

    const canRunImmediately =
      minimumIntervalBetweenCommands === 0 || !lastExecutionTime || (delta && delta > minimumIntervalBetweenCommands);

    if (canRunImmediately) {
      this.sendCommandImmediately(command, payload);
      this.setCommandLastExecutionTime(command, payload, now, false);
      return;
    }

    this.addCommandToQueue(command, payload);
  }

  /**
   * Adds a command to the queue.
   */
  private addCommandToQueue(command: Command, payload: Uint8Array) {
    const queue = this.getCommandQueue(command);

    const minimumIntervalBetweenCommands = this.config.commands[command]?.interval ?? this.config.defaultInterval;

    queue.payloadsQueue.push({
      payload,
    });
    if (!queue.activeTimeoutId) {
      queue.activeTimeoutId = setTimeout(() => {
        this.checkCommandQueueAndExecute(command);
      }, minimumIntervalBetweenCommands);
    }
  }

  /**
   * Retrieves the last execution time of a command from the queue.
   */
  private getCommandLastExecutionTime(command: Command) {
    return this.getCommandQueue(command).lastExecutedTimestamp;
  }

  /**
   * Sets the last execution time of a command in the queue.
   */
  private setCommandLastExecutionTime(
    command: Command,
    payload: Uint8Array,
    timestamp: number,
    executedFromQueue: boolean
  ) {
    const queue = this.getCommandQueue(command);

    queue.lastExecutedTimestamp = timestamp;

    const writeToHistory =
      (this.config.history && this.config.commands[command]?.history !== false) ||
      (!this.config.history && this.config.commands[command]?.history === true);

    if (writeToHistory) {
      queue.executionHistory.push({
        payload,
        timestamp,
        executedFromQueue,
      });
    }
  }

  /**
   * Gets the queue of a specific command, creating it if it does not exist.
   */
  private getCommandQueue(command: Command) {
    const commandStr = hexToString(command);
    let queue = this.commandsQueueMap.get(commandStr);
    if (queue) {
      return queue;
    }
    queue = {
      activeTimeoutId: null,
      lastExecutedTimestamp: null,
      payloadsQueue: [],
      executionHistory: [],
    };
    this.commandsQueueMap.set(commandStr, queue);

    return queue;
  }

  /**
   * Checks the command queue and executes the first command in the queue if the conditions are met.
   */
  private checkCommandQueueAndExecute(command: Command) {
    const queue = this.getCommandQueue(command);
    const minimumIntervalBetweenCommands = this.config.commands[command]?.interval ?? this.config.defaultInterval;

    queue.activeTimeoutId = null;
    const firstInQueue = queue.payloadsQueue.shift();
    if (!firstInQueue) {
      return;
    }
    this.sendCommandImmediately(command, firstInQueue.payload);
    this.setCommandLastExecutionTime(command, firstInQueue.payload, Date.now(), true);

    if (queue.payloadsQueue.length > 0) {
      queue.activeTimeoutId = setTimeout(() => {
        this.checkCommandQueueAndExecute(command);
      }, minimumIntervalBetweenCommands);
    }
  }

  /**
   * Cleans up all commands in the queue, clearing payloads and timeouts.
   */
  cleanup() {
    this.commandsQueueMap.forEach((queue, commandStr) => {
      if (this.config.commands[stringToHex(commandStr) as Command]?.persistInQueueAfterCleanup) {
        return;
      }

      // clean queue
      queue.payloadsQueue.length = 0;

      // clean timeout
      if (queue.activeTimeoutId) {
        clearTimeout(queue.activeTimeoutId);
      }
    });
  }

  /*
   * One of the way of using this functions is to add this in RoboClient constructor:
   * window.__commandsQueue = this.commandsQueue;
   *
   * Invoke this method in the DevTools Console when needed
   * __commandsQueue.dumpExecutionHistoryToConsole()
   */
  dumpExecutionHistoryToConsole() {
    this.commandsQueueMap.forEach((queue, commandStr) => {
      const commandReadableName =
        Object.entries(CommandsConfig).find(command => {
          return command[1].code === stringToHex(commandStr);
        })?.[0] ?? 'UNKNOWN';

      console.info(`[QueueHistory] ======== ${commandReadableName} ${commandStr}  ======== `);
      let prevTimestamp: null | number = null;
      queue.executionHistory.forEach(item => {
        console.info(
          [
            `[QueueHistory] ${format(item.timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}`,
            `[${item.executedFromQueue ? 'queue' : 'immediate'}] ${commandStr} [${[...item.payload].join(', ')}]`,
            `delta: ${prevTimestamp !== null ? item.timestamp - prevTimestamp : 0} ms`,
          ].join(' ')
        );
        prevTimestamp = item.timestamp;
      });
      console.info(`[QueueHistory] ======================= `);
    });
  }
}
