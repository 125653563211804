import { Button } from '@mui/material';
import { PlayIcon } from '@webapp/components/icons';
import { CodeMotorId } from '@webapp/store/types';

interface MotorPlayButtonProps {
  motorId: CodeMotorId;
  probeMotor: (motorId: CodeMotorId) => void;
  disabled: boolean;
}

const MotorPlayButton: React.FunctionComponent<MotorPlayButtonProps> = ({ motorId, probeMotor, disabled }) => {
  return (
    <Button
      variant="contained"
      color="quaternary"
      disabled={disabled}
      onClick={() => probeMotor(motorId)}
      sx={{
        width: '38px',
        height: '38px',
        minWidth: '38px',
        minHeight: '38px',
        borderRadius: '50%',
        padding: '0',
        opacity: disabled ? 0.5 : 1,
      }}
      aria-label="Probe motor"
    >
      <PlayIcon sx={{ width: '12px' }} />
    </Button>
  );
};

export default MotorPlayButton;
