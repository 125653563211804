import { EditorType } from '@webapp/store/types';

import {
  onElementClick,
  onElementDragStart,
  onElementDrag,
  onElementDragEnd,
} from '@webapp/components/editors/robo-code/handlers/toolbox-handlers';

export const useEditorHandlers = (editorType: EditorType) => {
  if (editorType === EditorType.Code) {
    return {
      onElementClick,
      onElementDragStart,
      onElementDrag,
      onElementDragEnd,
    };
  }

  return {};
};
