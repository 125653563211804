import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { FULL_ACTIVITY_QUERY } from '@store/queries/activity';

export const activityApiGraphql = createApi({
  reducerPath: 'activityApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getActivity: builder.query({
      query: id => ({
        document: FULL_ACTIVITY_QUERY,
        variables: { id },
      }),
    }),
  }),
});

export const { useGetActivityQuery } = activityApiGraphql;
