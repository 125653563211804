export enum AUTH_EVENTS {
  LOGIN_SUCCESS = 'auth.login_success',
  LOGIN_FAILURE = 'auth.login_failure',
  LOGOUT = 'auth.logout',
  PASSWORD_RESET_SUCCESS = 'auth.password_reset_success',
  PASSWORD_RESET_FAILURE = 'auth.password_reset_failure',
}

export interface AUTH_EVENTS_PAYLOAD {
  [AUTH_EVENTS.LOGIN_SUCCESS]: {
    method?: string;
    userId?: string;
  };
  [AUTH_EVENTS.LOGIN_FAILURE]: {
    error: string;
    method?: string;
  };
  [AUTH_EVENTS.LOGOUT]: {
    userId?: string;
  };
  [AUTH_EVENTS.PASSWORD_RESET_SUCCESS]: {
    userId?: string;
  };
  [AUTH_EVENTS.PASSWORD_RESET_FAILURE]: {
    error: string;
    userId?: string;
  };
}
