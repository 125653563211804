import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 47 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.637 2.565a1.71 1.71 0 00-3.42 0V5.21c-3.88.37-7.541 1.994-10.346 4.643-2.791 2.636-4.536 6.103-4.948 9.81H6.4a5.557 5.557 0 000 11.115h34.2a5.557 5.557 0 00.333-11.105c-.41-3.71-2.156-7.182-4.95-9.82-2.804-2.649-6.465-4.272-10.346-4.643V2.565zm13.68 20.52h1.282a2.137 2.137 0 110 4.274H6.4a2.137 2.137 0 110-4.275h32.917zM33.635 12.34c2.12 2.002 3.456 4.58 3.85 7.324H10.37c.394-2.743 1.73-5.322 3.85-7.324 2.558-2.417 6.049-3.79 9.707-3.79 3.659 0 7.15 1.373 9.708 3.79zm-9.708 20.15c.945 0 1.71.765 1.71 1.709v6.84a1.71 1.71 0 01-3.42 0v-6.84c0-.944.766-1.71 1.71-1.71zm-6 2.85a1.71 1.71 0 00-2.962-1.71l-2.137 3.703a1.71 1.71 0 102.962 1.71l2.137-3.703zm12.84-2.335a1.71 1.71 0 00-.626 2.335l2.138 3.703a1.71 1.71 0 002.961-1.71l-2.137-3.703a1.71 1.71 0 00-2.336-.625zm-7.695-18.47a.855.855 0 111.71 0 .855.855 0 01-1.71 0zm.855-4.275a4.275 4.275 0 100 8.55 4.275 4.275 0 000-8.55z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
