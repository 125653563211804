import { createSvgIcon } from '@mui/material';

const Icon = createSvgIcon(
  <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 8C5.44772 8 5 8.44772 5 9V15C5 15.5523 5.44772 16 6 16C6.55228 16 7 15.5523 7 15V9C7 8.44772 6.55228 8 6 8Z" />
    <path d="M9 9C9 8.44772 9.44772 8 10 8C10.5523 8 11 8.44772 11 9V15C11 15.5523 10.5523 16 10 16C9.44772 16 9 15.5523 9 15V9Z" />
    <path d="M4 2C4 0.89543 4.89543 0 6 0H10C11.1046 0 12 0.89543 12 2V4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6V17C15 18.6569 13.6569 20 12 20H4C2.34315 20 1 18.6569 1 17V6C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H4V2ZM6 4H10V2H6V4ZM3 6V17C3 17.5523 3.44772 18 4 18H12C12.5523 18 13 17.5523 13 17V6H3Z" />
  </svg>,
  'Remove'
);

export default Icon;
