import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M37 56.5C48.3218 56.5 57.5 47.3218 57.5 36C57.5 24.6782 48.3218 15.5 37 15.5C25.6782 15.5 16.5 24.6782 16.5 36C16.5 47.3218 25.6782 56.5 37 56.5Z"
          stroke="#5A418B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37 52C45.8366 52 53 44.8366 53 36C53 27.1634 45.8366 20 37 20C28.1634 20 21 27.1634 21 36C21 44.8366 28.1634 52 37 52ZM57 36C57 47.0457 48.0457 56 37 56C25.9543 56 17 47.0457 17 36C17 24.9543 25.9543 16 37 16C48.0457 16 57 24.9543 57 36ZM37 46C42.5228 46 47 41.5228 47 36C47 30.4772 42.5228 26 37 26C31.4772 26 27 30.4772 27 36C27 41.5228 31.4772 46 37 46ZM51 36C51 43.732 44.732 50 37 50C29.268 50 23 43.732 23 36C23 28.268 29.268 22 37 22C44.732 22 51 28.268 51 36ZM45 36C45 40.4183 41.4183 44 37 44C32.5817 44 29 40.4183 29 36C29 31.5817 32.5817 28 37 28C41.4183 28 45 31.5817 45 36Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
