import { Client, Message, SubscribeOptions, TypedArray, UnsubscribeOptions, Qos } from 'paho-mqtt';

export type MQTTMessage = Message;

// Create necessary types
type ConnectionOptions = {
  cleanSession?: boolean;
  reconnect?: boolean;
  maxReconnectAttempts?: number;
  reconnectInterval?: number;
  timeout?: number;
  keepAliveInterval?: number;

  onSubscribe?: (client: MQTTClient, topic: string) => void;
  onUnsubscribe?: (client: MQTTClient, topic: string) => void;
  onConnectionLost?: (client: MQTTClient, responseObject: object) => void;
  onDisconnected?: (client: MQTTClient) => void;
  onConnectionFailure?: (client: MQTTClient, error: any) => void;
};

type MQTTClientOptions = {
  url: string;
  username: string;
  password: string;
  clientId: string;
  debug?: boolean;
};

/**
 * Represents an MQTT client that can connect to an MQTT broker, subscribe to topics, and receive messages.
 * @class
 * @see {@link https://www.eclipse.org/paho/files/jsdoc/Paho.MQTT.Client.html}
 */
export class MQTTClient {
  private options: ConnectionOptions;
  private debug: boolean;
  private username: string;
  private password: string;
  private reconnectAttempts: number;
  private timer: ReturnType<typeof setTimeout> | null;
  private client: Client;
  private subscriptions: { [key: string]: { active: boolean } };
  private onMessageArrivedQueue: ((client: MQTTClient, message: Message) => void)[];
  private onMessageDeliveredQueue: ((client: MQTTClient, message: Message) => void)[];
  private onConnectionLostQueue: ((client: MQTTClient, responseObject: object) => void)[];
  private onConnectedQueue: ((client: MQTTClient, message: Message) => void)[]; // todo Revise
  private onDisconnectedQueue: ((client: MQTTClient) => void)[];
  private onConnectionFailureQueue: ((client: MQTTClient, error: any) => void)[];
  /**
   * Creates an instance of MQTTClient.
   * @constructor
   * @param {MQTTClientOptions} options - The options for the MQTT client.
   * @param {ConnectionOptions} [connectionOptions={}] - The options for the MQTT connection.
   */
  constructor(
    { url, username, password, clientId, debug = false }: MQTTClientOptions,
    connectionOptions: ConnectionOptions = {}
  ) {
    this.options = connectionOptions;

    this.options.cleanSession = connectionOptions.cleanSession === true;
    this.options.reconnect = connectionOptions.reconnect !== false;
    this.options.maxReconnectAttempts = connectionOptions.maxReconnectAttempts || 5;
    this.options.reconnectInterval = connectionOptions.reconnectInterval || 3000;
    this.options.timeout = connectionOptions.timeout || 15;
    this.options.keepAliveInterval = connectionOptions.keepAliveInterval || 5;

    this.options.onSubscribe = connectionOptions.onSubscribe;
    this.options.onUnsubscribe = connectionOptions.onUnsubscribe;

    this.debug = debug;

    this.username = username;
    this.password = password;

    this.reconnectAttempts = 0;

    this.timer = null;

    this.client = new Client(url, clientId);

    this.subscriptions = {};

    this.onMessageArrivedQueue = [
      (_client, message) =>
        this.log(
          'Received "%s" from "%s"',
          this.toHexString(message.payloadBytes as TypedArray),
          message.destinationName
        ),
    ];

    this.onMessageDeliveredQueue = [
      (_client, message) =>
        this.log(
          'Delivered "%s" to "%s"',
          this.toHexString(message.payloadBytes as TypedArray),
          message.destinationName
        ),
    ];

    this.onConnectionLostQueue = [];

    if (this.options.onConnectionLost) {
      this.onConnectionLostQueue.push(this.options.onConnectionLost);
    }

    this.onConnectedQueue = [];

    this.onDisconnectedQueue = [];
    if (this.options.onDisconnected) {
      this.onDisconnectedQueue.push(this.options.onDisconnected);
    }

    this.onConnectionFailureQueue = [];
    if (this.options.onConnectionFailure) {
      this.onConnectionFailureQueue.push(this.options.onConnectionFailure);
    }

    this.client.onConnectionLost = responseObject => {
      console.warn('onConnectionLost', responseObject);
      if (responseObject.errorCode === 0) {
        // ignore if the client has closed the connection explicitly
        return;
      }

      this.onConnectionLostQueue.forEach(callback => callback(this, responseObject));

      const tryRerunConnection =
        this.options.reconnect && this.reconnectAttempts < (this.options.maxReconnectAttempts || 0);

      if (tryRerunConnection) {
        this.timer = setTimeout(() => {
          this.log('Connection lost. Trying to reconnect ...');
          this.connect();
        }, this.options.reconnectInterval);
      } else {
        this.log('Connection lost. No more reconnection attempts will be made.');

        this.reconnectAttempts = 0;

        if (this.timer) {
          clearTimeout(this.timer); // clear the timeout
          this.timer = null;
        }
      }
    };

    this.client.onMessageArrived = message => {
      this.onMessageArrivedQueue.forEach(callback => callback(this, message));
    };

    this.client.onMessageDelivered = message => {
      this.onMessageDeliveredQueue.forEach(callback => callback(this, message));
    };

    // !fixme There is no onConnected event in paho-mqtt
    this.client.onConnected = message => {
      this.onConnectedQueue.forEach(callback => callback(this, message));
    };
  }

  /**
   * Connects to the MQTT broker with the specified options.
   * @param {object} [options={}] - The connection options.
   * @param {function} [options.onSuccess] - A callback function to be called when the connection is successful.
   * @param {function} [options.onFailure] - A callback function to be called when the connection fails.
   */
  connect(options: { onSuccess?: () => void; onFailure?: (error: any) => void } = {}) {
    this.reconnectAttempts++;

    this.log(`Connecting to MQTT broker with attempt %s...`, this.reconnectAttempts);

    this.client.connect({
      userName: this.username,
      password: this.password,

      onSuccess: () => {
        this.log('Connection succeed');
        this.reconnectAttempts = 0;
        options.onSuccess && options.onSuccess();
      },

      onFailure: error => {
        const tryRerunConnection =
          this.options.reconnect && this.reconnectAttempts < (this.options.maxReconnectAttempts || 0);

        if (tryRerunConnection) {
          this.error('Connection failure. Trying to rerun...', error.errorMessage);

          this.timer = setTimeout(() => {
            this.connect();
          }, this.options.reconnectInterval);
        } else {
          this.error('Connection failure', error.errorMessage);

          this.reconnectAttempts = 0;

          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }

          options.onFailure && options.onFailure(error);
          this.onConnectionFailureQueue.forEach(callback => callback(this, error));
        }
      },

      timeout: this.options.timeout,
      keepAliveInterval: this.options.keepAliveInterval,
      cleanSession: this.options.cleanSession,
    });
  }

  /**
   * Disconnects from the MQTT broker.
   */
  disconnect() {
    // initial values
    this.reconnectAttempts = 0;

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.isConnected() && this.client.disconnect();

    this.log(`Disconnected from MQTT broker`);

    this.unsubscribeFromAll();
    this.onDisconnectedQueue.forEach(callback => callback(this));
  }

  /**
   * Subscribes to the specified MQTT topic with the specified options.
   * @param {string} topic - The MQTT topic to subscribe to.
   * @param {SubscribeOptions} [options={}] - The options for the subscription.
   */
  subscribe(topic: string, options: SubscribeOptions = {}) {
    const { qos = 1 } = options;

    if (this.isConnected()) {
      // Check if there's already a subscription for this topic
      if (this.subscriptions[topic] && this.subscriptions[topic].active) {
        this.log(`Already subscribed to topic "%s"`, topic);
        return;
      }

      this.client.subscribe(topic, {
        onSuccess: () => {
          this.log(`Subscribed to topic "%s"`, topic);
          this.subscriptions[topic] = { active: true };

          this.options.onSubscribe && this.options.onSubscribe(this, topic);

          options.onSuccess && options.onSuccess();
        },
        onFailure: error => {
          this.error('Subscribe error:', error);
          options.onFailure && options.onFailure(error);
        },

        qos,
      });
    }
  }

  /**
   * Unsubscribes from the specified MQTT topic with the specified options.
   * @param {string} topic - The MQTT topic to unsubscribe from.
   * @param {UnsubscribeOptions} [options={}] - The options for the unsubscription.
   */
  unsubscribe(topic: string, options: UnsubscribeOptions = {}) {
    if (this.isConnected()) {
      this.log(`Unsubscribed from topic "%s"`, topic);
      this.client.unsubscribe(topic);

      delete this.subscriptions[topic];

      this.options.onUnsubscribe && this.options.onUnsubscribe(this, topic);
      options.onSuccess && options.onSuccess();
    }
  }

  /**
   * Unsubscribes from all MQTT topics that the client is currently subscribed to.
   * @param {UnsubscribeOptions} [options={}] - The options for the unsubscription.
   */
  unsubscribeFromAll(options: UnsubscribeOptions = {}) {
    Object.keys(this.subscriptions).forEach(topic => {
      this.unsubscribe(topic, options);
    });

    options.onSuccess && options.onSuccess();
  }

  /**
   * Publishes a message to the specified MQTT topic with the specified options.
   * @param {string} topic - The MQTT topic to publish the message to.
   * @param {string|Uint8Array} message - The message to publish to the MQTT topic.
   * @param {number} [qos=1] - The quality of service level to use for the message.
   * @param {boolean} [retained=true] - Whether the message should be retained by the broker.
   */
  publish(topic: string, message: string | Uint8Array, qos: Qos = 1, retained = true) {
    if (this.isConnected()) {
      const mqttMessage = new Message(message);
      mqttMessage.destinationName = topic;
      mqttMessage.qos = qos;
      mqttMessage.retained = retained;

      this.client.send(mqttMessage);
    }
  }

  /**
   * Returns an array of the MQTT topics that the client is currently subscribed to.
   * @returns {object} An object of the MQTT topics that the client is currently subscribed to.
   */
  getSubscriptions(): { [key: string]: { active: boolean } } {
    return this.subscriptions;
  }

  /**
   * Sets the callback function to be called when the MQTT client successfully connects to the broker.
   * @param {function} callback - The callback function to be called when the client successfully connects.
   * @example
   * mqttClient.onConnected(() => {
   *   console.log('MQTT client connected successfully');
   * });
   */
  onConnected(callback: (client: MQTTClient, message: Message) => void) {
    this.onConnectedQueue.push(callback);
  }

  /**
   * Remove the callback function to be called when the MQTT client successfully connects to the broker.
   * @param {function} callback - The callback function to remove.
   * @example
   * mqttClient.offConnected(handler);
   */
  offConnected(callback: (client: MQTTClient, message: Message) => void) {
    this.removeCallback(this.onConnectedQueue, callback);
  }

  /**
   * Sets the callback function to be called when the MQTT client is disconnected from the broker.
   * @param {function} callback - The callback function to be called when the client is disconnected.
   */
  onDisconnected(callback: (client: MQTTClient) => void) {
    this.onDisconnectedQueue.push(callback);
  }

  /**
   * Sets the callback function to be called when the MQTT client loses its connection to the broker.
   * @param {function} callback - The callback function to be called when the client loses its connection.
   */
  onConnectionLost(callback: (client: MQTTClient, responseObject: object) => void) {
    this.onConnectionLostQueue.push(callback);
  }

  /**
   * Remove the callback function to be called when the MQTT client loses its connection to the broker.
   * @param {function} callback - The callback function to remove.
   */
  offConnectionLost(callback: (client: MQTTClient, responseObject: object) => void) {
    this.removeCallback(this.onConnectionLostQueue, callback);
  }

  /**
   * Sets the callback function to be called when the MQTT client fails to connect to the broker.
   * @param {function} callback - The callback function to be called when the client fails to connect.
   */
  onConnectionFailure(callback: (client: MQTTClient, error: any) => void) {
    this.onConnectionFailureQueue.push(callback);
  }

  /**
   * Remove the callback function to be called when the MQTT client fails to connect to the broker.
   * @param {function} callback - The callback function to remove.
   */
  offConnectionFailure(callback: (client: MQTTClient, error: any) => void) {
    this.removeCallback(this.onConnectionFailureQueue, callback);
  }

  /**
   * Sets the callback function to be called when the MQTT client successfully subscribes to a topic.
   * @param {function} callback - The callback function to be called when the client successfully subscribes.
   */
  onSubscribe(callback: (client: MQTTClient, topic: string) => void) {
    this.options.onSubscribe = callback;
  }

  /**
   * Sets the callback function to be called when the MQTT client successfully unsubscribes from a topic.
   * @param {function} callback - The callback function to be called when the client successfully unsubscribes.
   */
  onUnsubscribe(callback: (client: MQTTClient, topic: string) => void) {
    this.options.onUnsubscribe = callback;
  }

  /**
   * Adds the callback function to be called when a message arrives from the broker.
   * @param {function} callback - The callback function to be called when a message arrives.
   * @example
   * mqttClient.onMessageArrived((client, message) => {
   *   console.log(`Message "${message.payloadString}" arrived on topic "${message.destinationName}"`);
   * });
   */
  onMessageArrived(callback: (client: MQTTClient, message: Message) => void) {
    this.onMessageArrivedQueue.push(callback);
  }

  /**
   * Removes the callback function to be called when a message arrives from the broker.
   * @param {function} callback - The callback function to remove.
   * @example
   * mqttClient.offMessageArrived(handler);
   */
  offMessageArrived(callback: (client: MQTTClient, message: Message) => void) {
    this.removeCallback(this.onMessageArrivedQueue, callback);
  }

  /**
   * Adds the callback function to be called when a message is successfully delivered to the broker.
   * @param {function} callback - The callback function to be called when a message is successfully delivered.
   * @example
   * mqttClient.onMessageDelivered((client, message) => {
   *   console.log(`Message "${message.payloadString}" delivered`);
   * });
   */
  onMessageDelivered(callback: (client: MQTTClient, message: Message) => void) {
    this.onMessageDeliveredQueue.push(callback);
  }

  /**
   * Checks if the MQTT client is currently connected to the broker.
   * @returns {boolean} - True if the client is connected, false otherwise.
   */
  isConnected(): boolean {
    return this.client.isConnected();
  }

  /**
   * Returns the MQTT client instance.
   * @returns {Client} - The MQTT client instance.
   */
  getClient(): Client {
    return this.client;
  }

  /**
   * Converts an array of numbers to a string of hexadecimal values.
   *
   * @fixme Move this to a utility class.
   *
   * @param {number[] | Uint8Array} arr - The array of numbers to convert.
   * @returns {string} - The string of hexadecimal values.
   */
  private toHexString(arr: number[] | Uint8Array | TypedArray): string {
    const result: string[] = [];

    for (let i = 0; i < arr.length; i++) {
      let hex = arr[i].toString(16);
      hex = hex.length === 1 ? '0' + hex : hex; // Ensuring two digits
      result.push('0x' + hex.toUpperCase());
    }

    return result.join(' ');
  }

  /**
   * Logs the specified arguments to the console if debug mode is enabled.
   *
   * @fixme Move this to a utility class.
   *
   * @param {...any} args - The arguments to log to the console.
   */
  private log(...args: any[]): void {
    if (!this.debug) return;

    const source = '[MQTT-CLIENT]';

    const sourceStyle = 'color: green;';
    const messageStyle = 'color: orange;';
    const valueStyle = 'color: yellow;';

    // Start the styledMessage array with the source and first part of the message
    const styledMessage = [`%c${source}%c ${args[0]}`, sourceStyle, messageStyle];

    // Build the rest of the styled message.
    for (let i = 1; i < args.length; i++) {
      styledMessage.push('%c' + args[i] + '%c');
      styledMessage.push(valueStyle);
      styledMessage.push(messageStyle); // Reset color back to messageStyle
    }

    console.log(...styledMessage);
  }

  /**
   * Logs error messages to the console.
   * Unlike the `log` method, this will always output errors regardless of debug mode.
   *
   * @param {...any} args - The arguments to log to the console as an error.
   */
  private error(...args: any[]): void {
    const source = '[MQTT-CLIENT]';

    const sourceStyle = 'color: red; font-weight: bold;';
    const messageStyle = 'color: red;';
    const valueStyle = 'color: orange;';

    // Start the styledMessage array with the source and first part of the message
    const styledMessage = [`%c${source}%c ${args[0]}`, sourceStyle, messageStyle];

    // Build the rest of the styled message.
    for (let i = 1; i < args.length; i++) {
      styledMessage.push('%c' + args[i] + '%c');
      styledMessage.push(valueStyle);
      styledMessage.push(messageStyle); // Reset color back to messageStyle
    }

    console.error(...styledMessage);
  }

  /**
   * Removes callback from handlers queue
   *
   * @param {Function[]} queue - The callbacks queue.
   * @param {Function} callback - The callback function to remove.
   */
  private removeCallback<T extends (...args: any[]) => any>(queue: T[], callback: T): void {
    const callbackIndex = queue.findIndex(cb => cb === callback);
    if (callbackIndex !== -1) {
      queue.splice(callbackIndex, 1);
    }
  }

  /**
   * Waits for a specific MQTT message that satisfies a given condition.
   * This method listens for incoming MQTT messages and uses the provided
   * `checkFn` function to determine if a received message meets the specified criteria.
   * If a message meeting the criteria is received before the timeout, the promise is resolved with that message.
   * If no such message is received before the timeout expires, the promise is rejected.
   *
   * @param {function} checkFn - A function that takes an MQTT message as its first argument, a resolve function as its second argument,
   * and a reject function as its third argument. The `checkFn` should call the resolve function with the desired result if the message meets the criteria,
   * or call the reject function if the message should be treated as an error case. The function does not need to call resolve or reject
   * if the message does not meet the criteria; in that case, waiting will continue until the timeout is reached.
   * @param {object} [options={}] - Optional parameters for configuring the behavior of the method.
   * @param {number} [options.timeout=30000] - The maximum amount of time (in milliseconds) to wait for a message that satisfies the condition.
   * If not specified, a default timeout of 30 seconds is used. If the timeout is exceeded, the promise is rejected.
   *
   * @returns {Promise<Message>} A promise that resolves with the message that satisfies the condition specified in `checkFn`,
   * or rejects if the timeout is reached without receiving a suitable message.
   *
   * @example
   * // Wait for an MQTT message with a payload containing the text "success"
   * mqttClient.waitForSpecificMQTTMessage((message, resolve) => {
   *   if (message.payloadString.includes("success")) {
   *     resolve(message);
   *   }
   * }, { timeout: 10000 })
   * .then(message => console.log("Success message received:", message.payloadString))
   * .catch(error => console.error("Did not receive the expected message within the timeout:", error));
   */
  waitForSpecificMQTTMessage<T>(
    checkFn: (message: Message, resolve: (value: T) => void, reject: (reason?: any) => void) => void,
    options: { timeout?: number } = {}
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        this.offMessageArrived(handleMessage);
        reject('Timeout exceed');
      }, options.timeout || 30000);

      const resolveWrapped = (result: T) => {
        this.offMessageArrived(handleMessage);
        clearTimeout(timeoutId);
        resolve(result);
      };

      const rejectWrapped = (reason?: any) => {
        this.offMessageArrived(handleMessage);
        clearTimeout(timeoutId);
        reject(reason);
      };

      const handleMessage = (_: MQTTClient, message: Message) => {
        checkFn(message, resolveWrapped, rejectWrapped);
      };

      this.onMessageArrived(handleMessage);
    });
  }
}
