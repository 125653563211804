import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
        <circle cx="29" cy="29" r="14" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 0C27.8954 0 27 0.89543 27 2V8C27 9.10457 27.8954 10 29 10C30.1046 10 31 9.10457 31 8V2C31 0.895431 30.1046 0 29 0ZM49.5061 8.4939C48.725 7.71285 47.4587 7.71285 46.6777 8.4939L42.435 12.7365C41.654 13.5176 41.654 14.7839 42.435 15.565C43.2161 16.346 44.4824 16.346 45.2635 15.565L49.5061 11.3223C50.2871 10.5413 50.2871 9.27495 49.5061 8.4939ZM56 27C57.1046 27 58 27.8954 58 29C58 30.1046 57.1046 31 56 31H50C48.8954 31 48 30.1046 48 29C48 27.8954 48.8954 27 50 27H56ZM49.5061 49.5061C50.2871 48.725 50.2871 47.4587 49.5061 46.6777L45.2635 42.435C44.4824 41.654 43.2161 41.654 42.435 42.435C41.654 43.2161 41.654 44.4824 42.435 45.2635L46.6777 49.5061C47.4587 50.2871 48.725 50.2871 49.5061 49.5061ZM31 56C31 57.1046 30.1046 58 29 58C27.8954 58 27 57.1046 27 56V50C27 48.8954 27.8954 48 29 48C30.1046 48 31 48.8954 31 50V56ZM8.4939 49.5061C9.27495 50.2871 10.5413 50.2871 11.3223 49.5061L15.565 45.2635C16.346 44.4824 16.346 43.2161 15.565 42.435C14.7839 41.654 13.5176 41.654 12.7365 42.435L8.4939 46.6777C7.71286 47.4587 7.71286 48.725 8.4939 49.5061ZM2 31C0.89543 31 0 30.1046 0 29C0 27.8954 0.895431 27 2 27H8C9.10457 27 10 27.8954 10 29C10 30.1046 9.10457 31 8 31H2ZM8.4939 8.4939C7.71285 9.27495 7.71285 10.5413 8.4939 11.3223L12.7365 15.565C13.5176 16.346 14.7839 16.346 15.565 15.565C16.346 14.7839 16.346 13.5176 15.565 12.7365L11.3223 8.4939C10.5413 7.71286 9.27495 7.71286 8.4939 8.4939Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
