// For internal urls use navigate from gatsby
// For external urls open in new tab

import { navigate as gatsbyNavigate } from 'gatsby';

export const navigate = (to, options = {}) => {
  const isExternal = /^https?:\/\//.test(to);
  if (isExternal) {
    window.open(to, '_blank');
  } else {
    return gatsbyNavigate(to, options);
  }
};
