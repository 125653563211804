import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
  progressSx?: SxProps<Theme>;
}

export const Button: React.FC<CustomButtonProps> = ({
  children,
  isLoading,
  color,
  onClick,
  sx,
  progressSx,
  ...props
}) => {
  return (
    <MuiButton
      color={color}
      onClick={e => {
        if (!isLoading) {
          onClick?.(e);
        }
      }}
      sx={{
        ...sx,
        cursor: isLoading ? 'default' : 'pointer',
      }}
      {...props}
    >
      {children}
      {isLoading && (
        <CircularProgress
          color={color}
          sx={{
            padding: '5px',
            marginLeft: '10px',
            ...progressSx,
          }}
        />
      )}
    </MuiButton>
  );
};
