import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24.5" r="22.6071" fill="#7E63B6" stroke="#FBD602" strokeWidth="2.78571" />
        <mask
          id="mask0_17_5992"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="48"
          height="49"
        >
          <circle cx="24" cy="24.5" r="24" fill="#666666" />
        </mask>
        <g mask="url(#mask0_17_5992)">
          <path d="M21.3994 38.5464H17.7292V53.1769H21.3994V38.5464Z" fill="#0F1135" />
          <path
            d="M19.7051 47.0497C20.9249 46.9734 21.8519 45.9227 21.7756 44.7029C21.6993 43.4831 20.6486 42.5561 19.4288 42.6324C18.209 42.7087 17.282 43.7594 17.3583 44.9792C17.4346 46.199 18.4853 47.126 19.7051 47.0497Z"
            fill="#2C318C"
          />
          <path d="M21.8423 51.9617V56.0185H11.8526V54.2857L16.8155 51.9617H21.8423Z" fill="#2C318C" />
          <path d="M27.5763 53.1763H31.2465V38.5458H27.5763V53.1763Z" fill="#0F1135" />
          <path
            d="M30.884 46.4957C31.7963 45.6823 31.8765 44.2835 31.0631 43.3712C30.2497 42.459 28.8509 42.3788 27.9386 43.1921C27.0264 44.0055 26.9462 45.4044 27.7596 46.3166C28.5729 47.2289 29.9718 47.309 30.884 46.4957Z"
            fill="#2C318C"
          />
          <path d="M27.1329 51.9617V56.0185H37.1226V54.2857L32.1603 51.9617H27.1329Z" fill="#2C318C" />
          <path
            d="M30.0578 39.0414H17.9778C15.7616 39.0414 13.9662 37.8107 13.9662 36.2941L34.072 36.1966C34.0707 37.7139 32.274 39.0414 30.0578 39.0414Z"
            fill="#2C318C"
          />
          <path d="M37.99 25.3247L35.5224 26.0067L39.5449 40.5608L42.0125 39.8788L37.99 25.3247Z" fill="#0F1135" />
          <path
            d="M38.2411 46.2192C38.1695 45.7896 38.198 45.3493 38.3246 44.9326C38.4512 44.5159 38.6723 44.1341 38.9707 43.8169C39.2692 43.4997 39.6368 43.2557 40.0451 43.104C40.4533 42.9523 40.8911 42.897 41.3242 42.9423C41.7573 42.9876 42.1742 43.1324 42.5421 43.3653C42.9101 43.5983 43.2193 43.9131 43.4456 44.2852C43.6719 44.6572 43.8092 45.0766 43.8468 45.5105C43.8843 45.9444 43.8211 46.3811 43.6622 46.7865L45.0936 46.9362C45.162 46.6989 45.2093 46.4559 45.2349 46.2102C45.307 45.6507 45.2665 45.0824 45.1158 44.5388C44.9651 43.9952 44.7072 43.4872 44.3572 43.0447C44.0073 42.6022 43.5724 42.2342 43.0782 41.9623C42.5839 41.6903 42.0403 41.52 41.4792 41.4612C40.9181 41.4025 40.351 41.4565 39.8111 41.6202C39.2713 41.7839 38.7696 42.0538 38.3356 42.4142C37.9016 42.7746 37.544 43.2182 37.284 43.7188C37.0239 44.2194 36.8666 44.767 36.8213 45.3293C36.7962 45.575 36.7923 45.8224 36.8096 46.0689L38.2411 46.2192Z"
            fill="#0F1135"
          />
          <path
            d="M42.5949 42.7047C43.4592 41.8405 43.4592 40.4393 42.5949 39.5751C41.7307 38.7108 40.3296 38.7108 39.4653 39.5751C38.6011 40.4393 38.6011 41.8405 39.4653 42.7047C40.3296 43.5689 41.7307 43.5689 42.5949 42.7047Z"
            fill="#A5AFD9"
          />
          <path d="M6.57497 39.3611L9.04263 40.0431L13.0651 25.4891L10.5975 24.807L6.57497 39.3611Z" fill="#0F1135" />
          <path
            d="M10.343 45.6984C10.4146 45.2688 10.386 44.8285 10.2594 44.4118C10.1329 43.9951 9.91174 43.6132 9.61331 43.2961C9.31488 42.9789 8.94722 42.7349 8.53898 42.5832C8.13075 42.4315 7.693 42.3762 7.25985 42.4215C6.82671 42.4668 6.4099 42.6116 6.04192 42.8445C5.67393 43.0774 5.36473 43.3922 5.13843 43.7643C4.91213 44.1364 4.77485 44.5558 4.73729 44.9896C4.69972 45.4235 4.7629 45.8602 4.92189 46.2657L3.49046 46.4154C3.42197 46.178 3.37467 45.9351 3.34913 45.6894C3.29133 45.1367 3.34294 44.5781 3.50102 44.0455C3.6591 43.5128 3.92054 43.0165 4.27043 42.5848C4.62032 42.1532 5.0518 41.7947 5.54024 41.5298C6.02867 41.265 6.56449 41.0989 7.11711 41.0411C7.66973 40.9833 8.22832 41.0349 8.761 41.193C9.29367 41.3511 9.78999 41.6125 10.2216 41.9624C10.6533 42.3123 11.0117 42.7438 11.2766 43.2322C11.5415 43.7206 11.7076 44.2565 11.7654 44.8091C11.7888 45.055 11.7909 45.3024 11.7718 45.5487L10.343 45.6984Z"
            fill="#0F1135"
          />
          <path
            d="M7.55438 42.8317C8.77657 42.8317 9.76735 41.8409 9.76735 40.6187C9.76735 39.3966 8.77657 38.4058 7.55438 38.4058C6.33219 38.4058 5.34141 39.3966 5.34141 40.6187C5.34141 41.8409 6.33219 42.8317 7.55438 42.8317Z"
            fill="#A5AFD9"
          />
          <path
            d="M40.9759 26.5437C41.0597 25.3243 40.1391 24.268 38.9198 24.1842C37.7005 24.1005 36.6441 25.021 36.5604 26.2403C36.4766 27.4596 37.3971 28.516 38.6165 28.5998C39.8358 28.6835 40.8921 27.763 40.9759 26.5437Z"
            fill="#A5AFD9"
          />
          <path
            d="M11.3229 28.2106C12.2837 27.4553 12.4503 26.064 11.695 25.1032C10.9397 24.1423 9.5485 23.9757 8.58764 24.731C7.62678 25.4863 7.46015 26.8775 8.21545 27.8384C8.97076 28.7992 10.362 28.9659 11.3229 28.2106Z"
            fill="#A5AFD9"
          />
          <path
            d="M34.7097 10.6807C34.4748 10.6798 34.2455 10.7526 34.0541 10.8887C33.8627 11.0248 33.7187 11.2175 33.6423 11.4396C33.566 11.6618 33.5612 11.9022 33.6285 12.1273C33.6959 12.3523 33.832 12.5506 34.0178 12.6943L32.5335 14.0934L31.6383 13.5126L31.4176 13.8527L32.2302 14.3793L29.7132 16.7504L31.7836 17.5687L33.1537 14.9789L34.1663 15.6313L34.3863 15.2912L33.3492 14.6194L34.2844 12.8517C34.4386 12.9153 34.6049 12.9439 34.7715 12.9355C34.9381 12.927 35.1007 12.8817 35.2477 12.8029C35.3947 12.724 35.5224 12.6136 35.6215 12.4795C35.7207 12.3453 35.7889 12.1909 35.8212 12.0273C35.8535 11.8636 35.8491 11.6949 35.8083 11.5331C35.7676 11.3714 35.6914 11.2207 35.5854 11.0919C35.4794 10.9631 35.3462 10.8595 35.1953 10.7883C35.0444 10.7172 34.8797 10.6805 34.7129 10.6807H34.7097ZM13.9914 10.5032C14.2263 10.5024 14.4555 10.5751 14.647 10.7112C14.8384 10.8473 14.9824 11.04 15.0587 11.2621C15.135 11.4843 15.1399 11.7247 15.0725 11.9498C15.0052 12.1748 14.869 12.3731 14.6832 12.5168L16.1676 13.9159L17.0627 13.3351L17.2828 13.6752L16.4709 14.2005L18.9878 16.5723L16.9156 17.3919L15.5454 14.8033L14.538 15.4558L14.3179 15.1157L15.3551 14.4445L14.418 12.6736C14.2821 12.73 14.1365 12.7589 13.9895 12.7588C13.6899 12.7588 13.4027 12.6398 13.1908 12.428C12.979 12.2162 12.8601 11.9289 12.8601 11.6294C12.8601 11.3299 12.979 11.0426 13.1908 10.8308C13.4027 10.619 13.6899 10.5 13.9895 10.5L13.9914 10.5032Z"
            fill="#FBD602"
          />
          <path
            d="M36.4909 16.2696H12.0359C11.2158 16.2696 10.5509 16.9344 10.5509 17.7546V35.0279C10.5509 35.848 11.2158 36.5128 12.0359 36.5128H36.4909C37.311 36.5128 37.9759 35.848 37.9759 35.0279V17.7546C37.9759 16.9344 37.311 16.2696 36.4909 16.2696Z"
            fill="#F26222"
          />
          <path
            d="M34.436 18.7846H14.2657C13.7481 18.7846 13.3286 19.2042 13.3286 19.7217V32.6833C13.3286 33.2008 13.7481 33.6204 14.2657 33.6204H34.436C34.9536 33.6204 35.3731 33.2008 35.3731 32.6833V19.7217C35.3731 19.2042 34.9536 18.7846 34.436 18.7846Z"
            fill="#F27483"
          />
          <path
            d="M17.7292 26.9143C18.8951 26.9143 19.8402 25.9692 19.8402 24.8033C19.8402 23.6374 18.8951 22.6923 17.7292 22.6923C16.5633 22.6923 15.6182 23.6374 15.6182 24.8033C15.6182 25.9692 16.5633 26.9143 17.7292 26.9143Z"
            fill="#0F1135"
          />
          <path
            d="M18.5103 24.6677C18.9954 24.6677 19.3886 24.2745 19.3886 23.7894C19.3886 23.3043 18.9954 22.911 18.5103 22.911C18.0252 22.911 17.6319 23.3043 17.6319 23.7894C17.6319 24.2745 18.0252 24.6677 18.5103 24.6677Z"
            fill="white"
          />
          <path
            d="M30.3843 26.822C31.5502 26.822 32.4953 25.8768 32.4953 24.711C32.4953 23.5451 31.5502 22.6 30.3843 22.6C29.2185 22.6 28.2733 23.5451 28.2733 24.711C28.2733 25.8768 29.2185 26.822 30.3843 26.822Z"
            fill="#0F1135"
          />
          <path
            d="M31.2498 24.6187C31.7349 24.6187 32.1281 24.2254 32.1281 23.7403C32.1281 23.2552 31.7349 22.862 31.2498 22.862C30.7647 22.862 30.3714 23.2552 30.3714 23.7403C30.3714 24.2254 30.7647 24.6187 31.2498 24.6187Z"
            fill="white"
          />
          <path
            d="M23.9893 27.2118C23.4608 27.2118 23.0858 27.0976 22.8812 26.871C22.8291 26.8178 22.7879 26.7547 22.7603 26.6855C22.7326 26.6162 22.7188 26.5422 22.7199 26.4677L22.7986 26.4741C22.7985 26.5382 22.811 26.6017 22.8355 26.661C22.8599 26.7203 22.8959 26.7741 22.9412 26.8194C23.1303 27.0246 23.493 27.1331 23.9893 27.1331C24.4514 27.1331 24.7857 27.0292 24.9574 26.833C25.0011 26.7849 25.0347 26.7285 25.0564 26.6672C25.078 26.6059 25.0871 26.5409 25.0832 26.4761L25.1613 26.4657C25.167 26.5417 25.1571 26.618 25.1324 26.69C25.1076 26.762 25.0685 26.8282 25.0174 26.8846C24.827 27.1021 24.4817 27.2118 23.9893 27.2118Z"
            fill="#0F1135"
          />
          <circle cx="24" cy="24.5" r="22.6071" stroke="#FBD602" strokeWidth="2.78571" />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
