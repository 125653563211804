import { createApi } from '@reduxjs/toolkit/query/react';

import { gql } from '@lib/store/strapi-graphql-query';
import protectedQuery from '@store/utils/protected-query.graphql';
import { FeatureEntityResponseCollection } from '@store/gql/graphql';

export const featureApiGraphql = createApi({
  reducerPath: 'featureApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getFeatureById: builder.query({
      query: id => ({
        document: gql`
          query GetFeature($id: ID!) {
            feature(id: $id) {
              data {
                id
                attributes {
                  name
                  description
                  enabled
                  createdAt
                  updatedAt
                }
              }
            }
          }
        `,
        variables: { id },
      }),
    }),

    getFeatureByName: builder.query({
      query: name => ({
        document: gql`
          query GetFeatureByName($name: String!) {
            features(filters: { name: { eq: $name } }) {
              data {
                id
                attributes {
                  name
                  description
                  enabled
                  createdAt
                  updatedAt
                }
              }

              meta {
                pagination {
                  page
                  pageSize
                  total
                  pageCount
                }
              }
            }
          }
        `,
        variables: { name },
      }),
    }),

    getFeatures: builder.query<{ features: FeatureEntityResponseCollection }, void>({
      query: () => ({
        document: gql`
          query GetFeatures {
            features {
              data {
                id
                attributes {
                  name
                  description
                  enabled
                  createdAt
                  updatedAt
                }
              }

              meta {
                pagination {
                  page
                  pageSize
                  total
                  pageCount
                }
              }
            }
          }
        `,
      }),
    }),
  }),
});

export const { useGetFeatureByIdQuery, useGetFeatureByNameQuery, useGetFeaturesQuery } = featureApiGraphql;
