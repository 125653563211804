import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <rect opacity="0.3" x="19" y="18" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="19" y="24" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="19" y="30" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="25" y="18" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="25" y="24" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="25" y="30" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="31" y="18" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="31" y="24" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.5" x="31" y="30" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="37" y="18" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.7" x="37" y="24" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.7" x="37" y="30" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect x="43" y="18" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect x="43" y="24" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.7" x="43" y="30" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect x="49" y="18" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect x="49" y="24" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="49" y="30" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.5" x="37" y="36" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="37" y="42" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="37" y="48" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="43" y="36" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="43" y="42" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="43" y="48" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="49" y="36" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="49" y="42" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="49" y="48" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="19" y="36" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="19" y="42" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="19" y="48" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.4" x="25" y="36" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.4" x="25" y="42" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="25" y="48" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.5" x="31" y="36" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.4" x="31" y="42" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
        <rect opacity="0.3" x="31" y="48" width="6" height="6" rx="3" fill="white" stroke="#5A418B" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
