import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          opacity="0.3"
          d="M22 18.5C23.3807 18.5 24.5 19.6193 24.5 21C24.5 22.3807 23.3807 23.5 22 23.5C20.6193 23.5 19.5 22.3807 19.5 21C19.5 19.6193 20.6193 18.5 22 18.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M22 24.5C23.3807 24.5 24.5 25.6193 24.5 27C24.5 28.3807 23.3807 29.5 22 29.5C20.6193 29.5 19.5 28.3807 19.5 27C19.5 25.6193 20.6193 24.5 22 24.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M22 30.5C23.3807 30.5 24.5 31.6193 24.5 33C24.5 34.3807 23.3807 35.5 22 35.5C20.6193 35.5 19.5 34.3807 19.5 33C19.5 31.6193 20.6193 30.5 22 30.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M28 18.5C29.3807 18.5 30.5 19.6193 30.5 21C30.5 22.3807 29.3807 23.5 28 23.5C26.6193 23.5 25.5 22.3807 25.5 21C25.5 19.6193 26.6193 18.5 28 18.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M28 24.5C29.3807 24.5 30.5 25.6193 30.5 27C30.5 28.3807 29.3807 29.5 28 29.5C26.6193 29.5 25.5 28.3807 25.5 27C25.5 25.6193 26.6193 24.5 28 24.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M28 30.5C29.3807 30.5 30.5 31.6193 30.5 33C30.5 34.3807 29.3807 35.5 28 35.5C26.6193 35.5 25.5 34.3807 25.5 33C25.5 31.6193 26.6193 30.5 28 30.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M34 18.5C35.3807 18.5 36.5 19.6193 36.5 21C36.5 22.3807 35.3807 23.5 34 23.5C32.6193 23.5 31.5 22.3807 31.5 21C31.5 19.6193 32.6193 18.5 34 18.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M34 24.5C35.3807 24.5 36.5 25.6193 36.5 27C36.5 28.3807 35.3807 29.5 34 29.5C32.6193 29.5 31.5 28.3807 31.5 27C31.5 25.6193 32.6193 24.5 34 24.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M34 30.5C35.3807 30.5 36.5 31.6193 36.5 33C36.5 34.3807 35.3807 35.5 34 35.5C32.6193 35.5 31.5 34.3807 31.5 33C31.5 31.6193 32.6193 30.5 34 30.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M40 18.5C41.3807 18.5 42.5 19.6193 42.5 21C42.5 22.3807 41.3807 23.5 40 23.5C38.6193 23.5 37.5 22.3807 37.5 21C37.5 19.6193 38.6193 18.5 40 18.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M40 24.5C41.3807 24.5 42.5 25.6193 42.5 27C42.5 28.3807 41.3807 29.5 40 29.5C38.6193 29.5 37.5 28.3807 37.5 27C37.5 25.6193 38.6193 24.5 40 24.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M40 30.5C41.3807 30.5 42.5 31.6193 42.5 33C42.5 34.3807 41.3807 35.5 40 35.5C38.6193 35.5 37.5 34.3807 37.5 33C37.5 31.6193 38.6193 30.5 40 30.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M46 18.5C47.3807 18.5 48.5 19.6193 48.5 21C48.5 22.3807 47.3807 23.5 46 23.5C44.6193 23.5 43.5 22.3807 43.5 21C43.5 19.6193 44.6193 18.5 46 18.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M46 24.5C47.3807 24.5 48.5 25.6193 48.5 27C48.5 28.3807 47.3807 29.5 46 29.5C44.6193 29.5 43.5 28.3807 43.5 27C43.5 25.6193 44.6193 24.5 46 24.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M46 30.5C47.3807 30.5 48.5 31.6193 48.5 33C48.5 34.3807 47.3807 35.5 46 35.5C44.6193 35.5 43.5 34.3807 43.5 33C43.5 31.6193 44.6193 30.5 46 30.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M52 18.5C53.3807 18.5 54.5 19.6193 54.5 21C54.5 22.3807 53.3807 23.5 52 23.5C50.6193 23.5 49.5 22.3807 49.5 21C49.5 19.6193 50.6193 18.5 52 18.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M52 24.5C53.3807 24.5 54.5 25.6193 54.5 27C54.5 28.3807 53.3807 29.5 52 29.5C50.6193 29.5 49.5 28.3807 49.5 27C49.5 25.6193 50.6193 24.5 52 24.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M52 30.5C53.3807 30.5 54.5 31.6193 54.5 33C54.5 34.3807 53.3807 35.5 52 35.5C50.6193 35.5 49.5 34.3807 49.5 33C49.5 31.6193 50.6193 30.5 52 30.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M40 36.5C41.3807 36.5 42.5 37.6193 42.5 39C42.5 40.3807 41.3807 41.5 40 41.5C38.6193 41.5 37.5 40.3807 37.5 39C37.5 37.6193 38.6193 36.5 40 36.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M40 42.5C41.3807 42.5 42.5 43.6193 42.5 45C42.5 46.3807 41.3807 47.5 40 47.5C38.6193 47.5 37.5 46.3807 37.5 45C37.5 43.6193 38.6193 42.5 40 42.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M40 48.5C41.3807 48.5 42.5 49.6193 42.5 51C42.5 52.3807 41.3807 53.5 40 53.5C38.6193 53.5 37.5 52.3807 37.5 51C37.5 49.6193 38.6193 48.5 40 48.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M46 36.5C47.3807 36.5 48.5 37.6193 48.5 39C48.5 40.3807 47.3807 41.5 46 41.5C44.6193 41.5 43.5 40.3807 43.5 39C43.5 37.6193 44.6193 36.5 46 36.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M46 42.5C47.3807 42.5 48.5 43.6193 48.5 45C48.5 46.3807 47.3807 47.5 46 47.5C44.6193 47.5 43.5 46.3807 43.5 45C43.5 43.6193 44.6193 42.5 46 42.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M46 48.5C47.3807 48.5 48.5 49.6193 48.5 51C48.5 52.3807 47.3807 53.5 46 53.5C44.6193 53.5 43.5 52.3807 43.5 51C43.5 49.6193 44.6193 48.5 46 48.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M52 36.5C53.3807 36.5 54.5 37.6193 54.5 39C54.5 40.3807 53.3807 41.5 52 41.5C50.6193 41.5 49.5 40.3807 49.5 39C49.5 37.6193 50.6193 36.5 52 36.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M52 42.5C53.3807 42.5 54.5 43.6193 54.5 45C54.5 46.3807 53.3807 47.5 52 47.5C50.6193 47.5 49.5 46.3807 49.5 45C49.5 43.6193 50.6193 42.5 52 42.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M52 48.5C53.3807 48.5 54.5 49.6193 54.5 51C54.5 52.3807 53.3807 53.5 52 53.5C50.6193 53.5 49.5 52.3807 49.5 51C49.5 49.6193 50.6193 48.5 52 48.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M22 36.5C23.3807 36.5 24.5 37.6193 24.5 39C24.5 40.3807 23.3807 41.5 22 41.5C20.6193 41.5 19.5 40.3807 19.5 39C19.5 37.6193 20.6193 36.5 22 36.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M22 42.5C23.3807 42.5 24.5 43.6193 24.5 45C24.5 46.3807 23.3807 47.5 22 47.5C20.6193 47.5 19.5 46.3807 19.5 45C19.5 43.6193 20.6193 42.5 22 42.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M22 48.5C23.3807 48.5 24.5 49.6193 24.5 51C24.5 52.3807 23.3807 53.5 22 53.5C20.6193 53.5 19.5 52.3807 19.5 51C19.5 49.6193 20.6193 48.5 22 48.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M28 36.5C29.3807 36.5 30.5 37.6193 30.5 39C30.5 40.3807 29.3807 41.5 28 41.5C26.6193 41.5 25.5 40.3807 25.5 39C25.5 37.6193 26.6193 36.5 28 36.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M28 42.5C29.3807 42.5 30.5 43.6193 30.5 45C30.5 46.3807 29.3807 47.5 28 47.5C26.6193 47.5 25.5 46.3807 25.5 45C25.5 43.6193 26.6193 42.5 28 42.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M28 48.5C29.3807 48.5 30.5 49.6193 30.5 51C30.5 52.3807 29.3807 53.5 28 53.5C26.6193 53.5 25.5 52.3807 25.5 51C25.5 49.6193 26.6193 48.5 28 48.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          d="M34 36.5C35.3807 36.5 36.5 37.6193 36.5 39C36.5 40.3807 35.3807 41.5 34 41.5C32.6193 41.5 31.5 40.3807 31.5 39C31.5 37.6193 32.6193 36.5 34 36.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M34 42.5C35.3807 42.5 36.5 43.6193 36.5 45C36.5 46.3807 35.3807 47.5 34 47.5C32.6193 47.5 31.5 46.3807 31.5 45C31.5 43.6193 32.6193 42.5 34 42.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <path
          opacity="0.3"
          d="M34 48.5C35.3807 48.5 36.5 49.6193 36.5 51C36.5 52.3807 35.3807 53.5 34 53.5C32.6193 53.5 31.5 52.3807 31.5 51C31.5 49.6193 32.6193 48.5 34 48.5Z"
          fill="white"
          stroke="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
