export const colors = {
  violet: {
    main: '#7E63B6',
    900: '#5A418B',
    700: '#6C4EA6',
    500: '#7E63B6',
    300: '#9882C4',
    100: '#AF9ED1',
  },

  black: {
    main: '#373737',
    500: '#373737',
    400: '#525252',
    300: '#7d7d7d',
    200: '#a9a9a9',
    100: '#eaeaea',
    '050': '#f4f4f4',
  },

  white: {
    main: 'rgba(255, 255, 255, 1.0)',
    500: 'rgba(255, 255, 255, 0.9)',
    400: 'rgba(255, 255, 255, 0.8)',
    300: 'rgba(255, 255, 255, 0.6)',
    200: 'rgba(255, 255, 255, 0.4)',
    100: 'rgba(255, 255, 255, 0.1)',
    '050': 'rgba(255, 255, 255, 0.05)',
  },

  secondary: {
    lightBlue: '#F3F8FB',
    whiteSmoke: '#F5F5F5',
    liveApp: '#CBDBFD',
    codeApp: '#F8B7B9',
    blocklyApp: '#FFDC83',
    blue: '#89D4E3',
    orange: '#F26730',
  },

  overlays: {
    white40: 'rgba(255, 255, 255, 0.4)',
    white60: 'rgba(255, 255, 255, 0.6)',
  },

  semantic: {
    green: '#36AD58',
    greenLight: '#92DDA9',
    red: '#DD383F',
    yellow: '#FFAE3B',
  },

  illustrations: {
    violet: '#9268A8',
    darkViolet: '#5C4B9B',
    green: '#36A958',
    greenDark: '#22933F',
    orange: '#F27D27',
    orangeDark: '#EE6730',
    blue: '#85D0DF',
    blueDark: '#77C3C9',
    peach: '#FF927B',
    yellow: '#FFAE3B',
    red: '#D9383F',
    black: '#000000',
  },
};
