import React from 'react';
import { Box } from '@mui/material';

export const ShakeAnimation = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      data-test-id="animation-shake"
      sx={{
        animation: 'shake 0.5s cubic-bezier(.36,.07,.19,.97) both',
        '@keyframes shake': {
          '0%, 100%': {
            transform: 'translateX(0)',
          },
          '10%, 30%, 50%, 70%, 90%': {
            transform: 'translateX(-10px)',
          },
          '20%, 40%, 60%, 80%': {
            transform: 'translateX(10px)',
          },
        },
      }}
    >
      {children}
    </Box>
  );
};
