import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import { CssBaseline } from '@mui/material';

import theme from '../themes/general.theme';
import { graphql, useStaticQuery } from 'gatsby';
import { StrapiFeaturesContext } from '@lib/hooks/use-features';
import { AppSettingsContext } from '@lib/hooks/use-app-settings';
import { AnimationsManager } from '@components/Animation/animations-provider';

const defaultFeatures = [];
const defaultSettings = [];

export default function Layout({ children }) {
  const queryResult = useStaticQuery(graphql`
    query webAppSettingsAndFeatures {
      allStrapiFeature {
        nodes {
          name
          enabled
        }
      }
      allStrapiAppSettings(filter: { key: { eq: "frontend_app" } }) {
        nodes {
          settings {
            name
            value
          }
          key
        }
      }
    }
  `);

  return (
    <>
      <StrapiFeaturesContext.Provider value={queryResult?.allStrapiFeature?.nodes ?? defaultFeatures}>
        <AppSettingsContext.Provider value={queryResult?.allStrapiAppSettings?.nodes[0].settings ?? defaultSettings}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline enableColorScheme={true} />
            <AnimationsManager>{children}</AnimationsManager>
          </ThemeProvider>
        </AppSettingsContext.Provider>
      </StrapiFeaturesContext.Provider>
    </>
  );
}
