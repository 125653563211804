// module id is a string in the format of SYSTEM_1, BUTTON_2, etc
export type ModuleId = `${ModuleTypes}_${number}`;

// module id prefix
export type ModuleIdPrefix = `${ModuleTypes}_`;

// A type that represents a specific module ID for a given module type
export type SpecificModuleId<T extends ModuleTypes> = `${T}_${number}`;

// A mapping from module type strings to the corresponding classes, module positions, and ID prefixes
export enum ModuleTypes {
  Accelerometer = 'ACCELEROMETER',
  Button = 'BUTTON',
  Camera = 'CAMERA',
  Claw = 'CLAW',
  Hinge = 'HINGE',
  IR = 'IR',
  Knob = 'KNOB',
  LedDisplay = 'LEDDISPLAY',
  LedMatrix = 'LEDMATRIX',
  LedRGB = 'LEDRGB',
  LightSensor = 'LIGHTSENSOR',
  Linetracker = 'LINETRACKER',
  Meteo = 'METEO',
  Motion = 'MOTION',
  Motor = 'MOTOR',
  ProperServo = 'PROPERSERVO',
  Servo = 'SERVO',
  System = 'SYSTEM',
  Ultrasonic = 'ULTRASONIC',
  Weather = 'WEATHER',
}

export enum ModulesCollectionTypes {
  Accelerometer = 'accelerometers',
  Button = 'buttons',
  Camera = 'cameras',
  Claw = 'claws',
  Hinge = 'hinges',
  IR = 'IRs',
  Knob = 'knobs',
  LedDisplay = 'ledDisplays',
  LedMatrix = 'ledMatrices',
  LedRGB = 'ledRGBs',
  LightSensor = 'lightSensors',
  Linetracker = 'linetrackers',
  Meteo = 'meteos',
  Motion = 'motions',
  Motor = 'motors',
  ProperServo = 'properServos',
  Servo = 'servos',
  System = 'systems',
  Ultrasonic = 'ultrasonics',
  Weather = 'weathers',
}

export type ModuleMapping = {
  className: keyof typeof ModuleTypes;
  modulePositions: number[]; // the bit in configuration binary string
  idPrefix: string;
  enabled: boolean;
  triggerIdOffset?: number; // used to offset trigger ids for modules with possible triggers. e.g.`triggerId = triggerIdOffset + index`
};

export interface ModuleEntity {
  type: string;
  id: string;
  data?: object;
}

export interface ModulesState {
  model: {
    [key: string]: {
      [key: string]: never;
    };
  };
}

export interface ModulesStore {
  addModule(module: ModuleEntity): void;

  updateModule(module: ModuleEntity): void;

  removeModule(module: { type: string; id: string }): void;

  getState(): ModulesState;
}
