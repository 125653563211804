import { Fragment } from 'react';

import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';
import { DriveWidgetData } from '@webapp/components/blocks/widgets/code/drive-action/code-drive-widget';

const AZIMUTH = {
  n: 'N',
  ne: 'NE',
  e: 'E',
  se: 'SE',
  s: 'S',
  sw: 'SW',
  w: 'W',
  nw: 'NW',
};

// Extracted for future purposes
const fillColor = 'white';
const strokeColor = '#5A418B';
type IconProps = {
  value: DriveWidgetData;
};

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { distance, bend: angle, isDistanceInfinite } = value;

  let distanceText = distance;

  if (isDistanceInfinite) {
    if (distance < 0) {
      distanceText = '-∞';
    } else {
      distanceText = '∞';
    }
  }

  // doc: distance is in centimeters
  // doc: angle is in degrees, [-90, 90].  -90 is West, 0 is North, 90 is East
  // doc: the negative distance is South, positive is North
  // doc: the negative angle is West, positive is East

  //can be only N or S

  const arrowStartsFrom = distance < 0 ? AZIMUTH.n : AZIMUTH.s;

  let arrowPointsTo;

  // todo: define cases as a function of angle and arrowStartsFrom
  if (arrowStartsFrom === AZIMUTH.s) {
    // Arrow starts from South
    if (angle <= -85) {
      arrowPointsTo = AZIMUTH.w;
    } else if (angle < -30) {
      arrowPointsTo = AZIMUTH.nw;
    } else if (angle < 30) {
      arrowPointsTo = AZIMUTH.n;
    } else if (angle < 85) {
      arrowPointsTo = AZIMUTH.ne;
    } else {
      arrowPointsTo = AZIMUTH.e;
    }
  } else {
    // Arrow starts from North
    if (angle <= -85) {
      arrowPointsTo = AZIMUTH.w;
    } else if (angle < -30) {
      arrowPointsTo = AZIMUTH.sw;
    } else if (angle < 30) {
      arrowPointsTo = AZIMUTH.s;
    } else if (angle < 85) {
      arrowPointsTo = AZIMUTH.se;
    } else {
      arrowPointsTo = AZIMUTH.e;
    }
  }

  //calculate arrow name from arrowStartsFrom and arrowPointsTo
  const arrowName = arrowStartsFrom + '_' + arrowPointsTo;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '73px' }}>
        {arrows[arrowName as keyof typeof arrows]}

        <text x="50%" y="50%" fill={fillColor} fontSize="10" textAnchor="middle" dominantBaseline="middle">
          <tspan dy={arrowStartsFrom === AZIMUTH.s ? 3 : -3}>{distanceText}</tspan>
        </text>
      </svg>
    </SvgIcon>
  );
};

const arrows = {
  // Arrow pointing from South to North
  S_N: (
    <Fragment>
      <path
        d="M37.707 14.2822C37.3165 13.8916 36.6833 13.8916 36.2928 14.2822L24.707 25.8679C24.077 26.4979 24.5232 27.575 25.4141 27.575H31.9999V32.6025C31.9999 33.1548 32.4476 33.6025 32.9999 33.6025H40.9999C41.5522 33.6025 41.9999 33.1548 41.9999 32.6025V27.575H48.5857C49.4766 27.575 49.9228 26.4979 49.2928 25.8679L37.707 14.2822Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 44C41.5523 44 42 44.4477 42 45V51C42 51.5523 41.5523 52 41 52H33C32.4477 52 32 51.5523 32 51V45C32 44.4477 32.4477 44 33 44H41Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  S_NE: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8994 44.0001C30.3471 44.0001 29.8994 44.4478 29.8994 45.0001V51.0001C29.8994 51.5524 30.3471 52.0001 30.8994 52.0001H38.8994C39.4517 52.0001 39.8994 51.5524 39.8994 51.0001V45.0001C39.8994 44.4478 39.4517 44.0001 38.8994 44.0001H30.8994Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M49 11.8995C49.5523 11.8995 50 12.3473 50 12.8995V29.2843C50 30.1752 48.9229 30.6214 48.2929 29.9914L43.636 25.3345L39.8995 29.071V32.9999C39.8995 33.5522 39.4518 33.9999 38.8995 33.9999H30.8995C30.3472 33.9999 29.8995 33.5522 29.8995 32.9999V27C29.8995 25.531 30.533 24.21 31.5417 23.2952C31.5644 23.2669 31.5889 23.2395 31.6152 23.2132L36.5649 18.2635L31.9081 13.6066C31.2782 12.9767 31.7243 11.8995 32.6152 11.8995H49Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),
  S_NW: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 44.0001C41.5523 44.0001 42 44.4478 42 45.0001V51.0001C42 51.5524 41.5523 52.0001 41 52.0001H33C32.4477 52.0001 32 51.5524 32 51.0001V45.0001C32 44.4478 32.4477 44.0001 33 44.0001H41Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M22.8994 11.8995C22.3471 11.8995 21.8994 12.3472 21.8994 12.8995V29.2843C21.8994 30.1752 22.9766 30.6214 23.6065 29.9914L28.2634 25.3345L31.9999 29.071V32.9999C31.9999 33.5522 32.4476 33.9999 32.9999 33.9999H40.9999C41.5522 33.9999 41.9999 33.5522 41.9999 32.9999V27C41.9999 25.531 41.3664 24.21 40.3578 23.2952C40.335 23.2669 40.3105 23.2395 40.2842 23.2132L35.3345 18.2635L39.9913 13.6066C40.6213 12.9767 40.1751 11.8995 39.2842 11.8995H22.8994Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),
  S_E: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 44.0001C31.4477 44.0001 31 44.4478 31 45.0001V51.0001C31 51.5524 31.4477 52.0001 32 52.0001H40C40.5523 52.0001 41 51.5524 41 51.0001V45.0001C41 44.4478 40.5523 44.0001 40 44.0001H32Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M57.293 23.2932C57.6836 23.6837 57.6836 24.3169 57.293 24.7074L45.7073 36.2932C45.0773 36.9231 44.0001 36.477 44.0001 35.5861V29.0003H41.0001V32.9999C41.0001 33.5522 40.5524 33.9999 40.0001 33.9999H32.0001C31.4479 33.9999 31.0001 33.5522 31.0001 32.9999L31.0002 26.0003C31.0002 22.1343 34.1342 19.0003 38.0001 19.0003H44.0001V12.4145C44.0001 11.5236 45.0773 11.0774 45.7073 11.7074L57.293 23.2932Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),
  S_W: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 44.0001C41.5523 44.0001 42 44.4478 42 45.0001V51.0001C42 51.5524 41.5523 52.0001 41 52.0001H33C32.4477 52.0001 32 51.5524 32 51.0001V45.0001C32 44.4478 32.4477 44.0001 33 44.0001H41Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M15.707 23.2932C15.3164 23.6837 15.3164 24.3169 15.707 24.7074L27.2927 36.2932C27.9227 36.9231 28.9998 36.477 28.9998 35.5861V29.0003H31.9999V32.9999C31.9999 33.5522 32.4476 33.9999 32.9999 33.9999H40.9999C41.5521 33.9999 41.9999 33.5522 41.9999 32.9999L41.9999 26.0003C41.9999 22.1343 38.8658 19.0003 34.9999 19.0003H28.9998V12.4145C28.9998 11.5236 27.9227 11.0774 27.2927 11.7074L15.707 23.2932Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  N_S: (
    <Fragment>
      <path
        d="M35.293 58.7179C35.6835 59.1084 36.3167 59.1084 36.7072 58.7179L48.293 47.1321C48.923 46.5021 48.4768 45.425 47.5859 45.425H41.0001V40.3975C41.0001 39.8452 40.5524 39.3975 40.0001 39.3975H32.0001C31.4478 39.3975 31.0001 39.8452 31.0001 40.3975V45.425H24.4143C23.5234 45.425 23.0772 46.5021 23.7072 47.1321L35.293 58.7179Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 29C31.4477 29 31 28.5523 31 28L31 22C31 21.4477 31.4477 21 32 21L40 21C40.5523 21 41 21.4477 41 22V28C41 28.5523 40.5523 29 40 29L32 29Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  N_SE: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 28.9999C31.4477 28.9999 31 28.5522 31 27.9999L31 21.9999C31 21.4476 31.4477 20.9999 32 20.9999L40 20.9999C40.5523 20.9999 41 21.4476 41 21.9999V27.9999C41 28.5522 40.5523 28.9999 40 28.9999L32 28.9999Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M50.1006 61.1005C50.6529 61.1005 51.1006 60.6528 51.1006 60.1005V43.7157C51.1006 42.8248 50.0234 42.3786 49.3935 43.0086L44.7366 47.6655L41.0001 43.929L41.0001 40.0001C41.0001 39.4478 40.5524 39.0001 40.0001 39.0001H32.0001C31.4478 39.0001 31.0001 39.4478 31.0001 40.0001L31.0001 46C31.0001 47.469 31.6336 48.79 32.6422 49.7048C32.665 49.7332 32.6895 49.7605 32.7158 49.7868L37.6655 54.7365L33.0087 59.3934C32.3787 60.0233 32.8249 61.1005 33.7158 61.1005H50.1006Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  N_SW: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1006 28.9999C42.6529 28.9999 43.1006 28.5522 43.1006 27.9999V21.9999C43.1006 21.4476 42.6529 20.9999 42.1006 20.9999H34.1006C33.5483 20.9999 33.1006 21.4476 33.1006 21.9999V27.9999C33.1006 28.5522 33.5483 28.9999 34.1006 28.9999H42.1006Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M24 61.1005C23.4477 61.1005 23 60.6528 23 60.1005V43.7157C23 42.8248 24.0771 42.3786 24.7071 43.0086L29.364 47.6655L33.1005 43.929L33.1005 40.0001C33.1005 39.4478 33.5482 39.0001 34.1005 39.0001H42.1005C42.6528 39.0001 43.1005 39.4478 43.1005 40.0001V46.0001C43.1005 47.469 42.467 48.7901 41.4583 49.7048C41.4356 49.7332 41.4111 49.7605 41.3848 49.7868L36.4351 54.7366L41.0919 59.3934C41.7218 60.0233 41.2757 61.1005 40.3848 61.1005L24 61.1005Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  N_E: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 28.9999C31.4477 28.9999 31 28.5522 31 27.9999L31 21.9999C31 21.4476 31.4477 20.9999 32 20.9999H40C40.5523 20.9999 41 21.4476 41 21.9999V27.9999C41 28.5522 40.5523 28.9999 40 28.9999H32Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M57.293 49.7068C57.6836 49.3163 57.6836 48.6831 57.293 48.2926L45.7073 36.7068C45.0773 36.0769 44.0002 36.523 44.0002 37.4139L44.0002 43.9997H41.0001L41.0001 40.0001C41.0001 39.4478 40.5524 39.0001 40.0001 39.0001H32.0001C31.4479 39.0001 31.0001 39.4478 31.0001 40.0001L31.0002 46.9997C31.0002 50.8657 34.1342 53.9997 38.0002 53.9997H44.0002V60.5855C44.0002 61.4764 45.0773 61.9226 45.7073 61.2926L57.293 49.7068Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),

  N_W: (
    <Fragment>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 28.9999C41.5523 28.9999 42 28.5522 42 27.9999V21.9999C42 21.4476 41.5523 20.9999 41 20.9999H33C32.4477 20.9999 32 21.4476 32 21.9999V27.9999C32 28.5522 32.4477 28.9999 33 28.9999H41Z"
        fill={fillColor}
        stroke={strokeColor}
      />
      <path
        d="M15.707 49.7068C15.3164 49.3163 15.3164 48.6831 15.707 48.2926L27.2927 36.7068C27.9227 36.0769 28.9999 36.523 28.9999 37.4139L28.9998 43.9997H31.9999L31.9999 40.0001C31.9999 39.4478 32.4476 39.0001 32.9999 39.0001H40.9999C41.5521 39.0001 41.9999 39.4478 41.9999 40.0001L41.9999 46.9997C41.9999 50.8657 38.8658 53.9997 34.9998 53.9997H28.9998V60.5855C28.9998 61.4764 27.9227 61.9226 27.2927 61.2926L15.707 49.7068Z"
        fill={fillColor}
        stroke={strokeColor}
      />
    </Fragment>
  ),
};

export default Icon;
