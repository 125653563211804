import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M.59 2.064a.922.922 0 0 0-.328.197C-.005 2.528 0 2.454 0 6c0 3.545-.005 3.472.261 3.739.249.248.356.261 2.146.261H4v4H2.407c-1.79 0-1.897.013-2.146.261C-.005 14.528 0 14.455 0 18s-.005 3.472.261 3.739C.542 22.02-.188 22 10 22s9.458.02 9.739-.261c.262-.262.261-.255.261-3.146V16h1.593c1.79 0 1.897-.013 2.146-.261.266-.267.261-.194.261-3.739s.005-3.472-.261-3.739C23.49 8.013 23.383 8 21.593 8H20V5.407c0-2.891.001-2.884-.261-3.146-.281-.281.452-.261-9.756-.258-7.948.002-9.229.011-9.393.061M13 6v2H2V4h11v2m5 0v2h-3V4h3v2m-1 6v2H6v-4h11v2m5 0v2h-3v-4h3v2m-9 6v2H2v-4h11v2m5 0v2h-3v-4h3v2"
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
