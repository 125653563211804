import { useState, useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { LightSensor } from '@lib/robo/modules';
import { LightSensorCondition } from '@lib/robo/modules/light-sensor';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import MultitrackSlider from '@webapp/components/ui/sliders/multitrack-slider';
import ComparisonToggleButton from '@webapp/components/ui/buttons/comparison-toggle-button';

import { LightLowIcon, LightHighIcon } from '@webapp/components/icons';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';

import { ModuleId } from '@lib/robo/types';

const LightTriggerWidget: ExecutableTriggerWidgetComponent<LightTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<LightTriggerWidgetData>(id);

  const widgetData = widget?.data;

  const { model: roboModel } = useRobo();
  const moduleId = widgetData?.moduleIds[0] as ModuleId;

  const [lightLevel, setLightLevel] = useState<number>(
    widgetData?.lightLevel ?? LightTriggerWidget.initialData.lightLevel
  );

  const [condition, setCondition] = useState<LightSensorCondition>(
    widgetData?.condition ?? LightTriggerWidget.initialData.condition
  );

  const LIGHT_SENSOR = roboModel?.modules.lightSensors[moduleId];

  let currentLightLevel = -1;
  if (LIGHT_SENSOR) {
    const moduleDataState = LIGHT_SENSOR?.getDataState();
    const currentLightLevelRaw = moduleDataState?.sensorData?.intensity;
    currentLightLevel = currentLightLevelRaw ? LightSensor.convertLumenToPercentages(currentLightLevelRaw) : 0;
  }

  /**
   * Starts a batch sensors check for the light sensor when the widget is mounted and stops it when the widget is unmounted.
   */
  useEffect(() => {
    if (!roboModel) return;

    const isCheckRunning = roboModel.isRunningBatchSensorsCheck();

    if (LIGHT_SENSOR) {
      if (!isCheckRunning) {
        roboModel.startBatchSensorsCheck([LIGHT_SENSOR.id as ModuleId]);
      }
    } else {
      if (isCheckRunning) {
        roboModel.stopBatchSensorsCheck();
      }
    }

    return () => {
      if (roboModel.isRunningBatchSensorsCheck()) {
        roboModel.stopBatchSensorsCheck();
      }
    };
  }, [LIGHT_SENSOR, roboModel]);

  const handleLightLevelChange = (newLightLevel: number) => {
    setLightLevel(newLightLevel);
  };

  const onLightLevelChanged = (newLightLevel: number) => {
    updateWidgetData(id, { lightLevel: newLightLevel });
  };

  const handleConditionChange = (newCondition: LightSensorCondition) => {
    setCondition(newCondition);
    updateWidgetData(id, { condition: newCondition });
  };

  return (
    <Box sx={{ width: '360px', height: '240px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0} sx={{ height: '100%', flexDirection: 'column' }}>
        <Grid item sx={{ display: 'flex', height: '90px', justifyContent: 'center', alignItems: 'flex-end' }}>
          <ComparisonToggleButton
            mainColor="#DF1642"
            selected={condition === LightSensorCondition.LessThan}
            value={LightSensorCondition.LessThan}
            onChange={() =>
              handleConditionChange(
                condition === LightSensorCondition.LessThan
                  ? LightSensorCondition.GreaterThan
                  : LightSensorCondition.LessThan
              )
            }
          />

          <Typography variant="x-headline5-regular" color="#5A418B" sx={{ ml: 1, width: '40px', textAlign: 'left' }}>
            {lightLevel}%
          </Typography>
        </Grid>

        <Grid container item sx={{ flexGrow: 1, mt: -6 }}>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LightLowIcon sx={{ fontSize: '22px' }} />
          </Grid>

          <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MultitrackSlider
              value={lightLevel}
              secondValue={currentLightLevel}
              onChange={(_, newValue) => handleLightLevelChange(newValue as number)}
              onChangeCommitted={(_, newValue) => onLightLevelChanged(newValue as number)}
              sx={{ width: '100%' }}
              max={100}
              min={0}
              appearance={condition === LightSensorCondition.LessThan ? 'default' : 'reversed'}
            />
          </Grid>

          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LightHighIcon sx={{ fontSize: '30px' }} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Executes the trigger for the light sensor widget.
 */
LightTriggerWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;
      const moduleId = data?.moduleIds[0];
      const LIGHT_SENSOR = roboModel.modules.lightSensors[moduleId as ModuleId];

      if (!LIGHT_SENSOR) {
        reject(new Error('Light sensor module not found'));
        return;
      }

      // Create trigger for button module
      LIGHT_SENSOR.createTrigger(data.condition, data.lightLevel, ({ isError }) => {
        if (isError) {
          reject(new Error('Error with light sensor trigger'));
        } else {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
          });
        }
      });
    },
    `LightTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

export type LightTriggerWidgetData = {
  condition: LightSensorCondition;
  lightLevel: number;
};

LightTriggerWidget.initialData = {
  condition: LightSensorCondition.LessThan,
  lightLevel: 30,
};

export default LightTriggerWidget;
