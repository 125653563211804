// // Example usage
// console.log(isSubset(['MOTOR', 'MOTOR'], ['HINGE', 'MOTOR', 'MOTOR', 'CLAW', 'MOTOR'])); // true
// console.log(isSubset(['MOTOR', 'CLAW'], ['HINGE', 'MOTOR', 'MOTOR', 'CLAW', 'MOTOR'])); // true
// console.log(isSubset(['MOTOR', 'LED'], ['HINGE', 'MOTOR', 'MOTOR', 'CLAW', 'MOTOR'])); // false
// console.log(isSubset(['MOTOR', 'CLAW', 'CLAW'], ['HINGE', 'MOTOR', 'MOTOR', 'CLAW', 'MOTOR'])); // false

/**
 * Checks if a given array is a subset of another array.
 *
 * @param {Array} search - The array to search for.
 * @param {Array} haystack - The array to search in.
 * @returns {boolean} - True if search is a subset of haystack, false otherwise.
 */
export const isSubset = (search, haystack) => {
  // Create a map to count the occurrences in the haystack
  const haystackCount = {};
  for (const item of haystack) {
    haystackCount[item] = (haystackCount[item] || 0) + 1;
  }

  // Check if each element in search is present in the haystack with the required frequency
  for (const item of search) {
    if (!haystackCount[item]) {
      // If the item is not present in the haystack
      return false;
    }
    haystackCount[item] -= 1;
    if (haystackCount[item] < 0) {
      // If the haystack does not have enough occurrences of the item
      return false;
    }
  }

  return true;
};
