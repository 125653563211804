import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M52.1094 42.275V30.525C52.1094 28.1726 50.2024 26.2656 47.85 26.2656H25.525C23.1726 26.2656 21.2656 28.1726 21.2656 30.525V42.275C21.2656 44.6274 23.1726 46.5344 25.525 46.5344H47.85C50.2024 46.5344 52.1094 44.6274 52.1094 42.275ZM42.7094 30.0844H47.85C48.0933 30.0844 48.2906 30.2817 48.2906 30.525V32.1406H42.7094V30.0844ZM33.3094 32.1406V30.0844H40.0656V32.1406H33.3094ZM44.7656 38.0156H38.0094V34.7844H44.7656V38.0156ZM25.525 30.0844H30.6656V32.1406H25.0844V30.525C25.0844 30.2816 25.2816 30.0844 25.525 30.0844ZM35.3656 38.0156H28.6094V34.7844H35.3656V38.0156ZM25.0844 38.0156V34.7844H25.9656V38.0156H25.0844ZM47.4094 34.7844H48.2906V38.0156H47.4094V34.7844ZM25.0844 42.275V40.6594H30.6656V42.7156H25.525C25.2816 42.7156 25.0844 42.5183 25.0844 42.275ZM40.0656 40.6594V42.7156H33.3094V40.6594H40.0656ZM42.7094 42.7156V40.6594H48.2906V42.275C48.2906 42.5183 48.0934 42.7156 47.85 42.7156H42.7094Z"
          fill="white"
          stroke="#5A418B"
          strokeWidth="1.46875"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
