import { gql } from '@lib/store/strapi-graphql-query';

export const USER_LOGIN_MUTATION = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt

      user {
        id
        email
        username

        role {
          name
          type
        }

        blocked
        confirmed
      }
    }
  }
`;
export const USER_TEST_LOGIN_MUTATION = gql`
  mutation TestLogin($type: String!, $password: String!) {
    testLogin(input: { type: $type, password: $password }) {
      jwt

      user {
        id
        email
        username

        role {
          name
          type
        }
        confirmed
        blocked
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
      user {
        id
        username
        email
        role {
          name
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`;
