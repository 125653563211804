import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30Z"
          fill={color}
        />
        <path
          d="M12.5478 9.51617C12.1018 9.95217 12.0668 10.5592 12.5478 11.0922L16.2948 15.0002L12.5478 18.9082C12.0668 19.4412 12.1018 20.0492 12.5478 20.4822C12.9928 20.9182 13.7448 20.8902 14.1628 20.4822C14.5808 20.0762 18.6648 15.7872 18.6648 15.7872C18.7707 15.685 18.8549 15.5626 18.9124 15.4272C18.9699 15.2919 18.9995 15.1463 18.9995 14.9992C18.9995 14.8521 18.9699 14.7065 18.9124 14.5711C18.8549 14.4357 18.7707 14.3133 18.6648 14.2112C18.6648 14.2112 14.5808 9.92417 14.1628 9.51617C13.7448 9.10717 12.9928 9.08017 12.5478 9.51617Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
