import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ color, width, height, direction, justify }) => ({
  color: '#fff',
  display: 'flex',
  flexDirection: direction || 'column',
  justifyContent: justify || 'center',
  alignItems: 'center',
  cursor: 'pointer',
  height: width || '100%',
  width: height || '100%',
  background: color,
  borderRadius: '10px',
}));

export default StyledBox;
