import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#034FCC';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23 19L23 18.25H23L23 19ZM28 19L20.5 23.3301L20.5 14.6699L28 19ZM16.75 27C16.75 27.4142 16.4142 27.75 16 27.75C15.5858 27.75 15.25 27.4142 15.25 27L16.75 27ZM16.75 26L16.75 27L15.25 27L15.25 26L16.75 26ZM23 19.75C19.5482 19.75 16.75 22.5482 16.75 26L15.25 26C15.25 21.7198 18.7198 18.25 23 18.25L23 19.75Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
