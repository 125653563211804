import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.049 5.552a1.595 1.595 0 113.19 0 1.595 1.595 0 01-3.19 0zM24.644.709a4.842 4.842 0 00-1.218 9.53v1.736H13.421a6.494 6.494 0 00-6.494 6.494v20.835A6.494 6.494 0 0013.42 45.8h21.64a6.494 6.494 0 006.495-6.495V18.47a6.494 6.494 0 00-6.495-6.494h-9.2V10.24A4.844 4.844 0 0024.644.71zM13.94 15.222h-.52a3.247 3.247 0 00-3.247 3.247v20.835a3.247 3.247 0 003.247 3.248h21.64a3.247 3.247 0 003.248-3.248V18.47a3.247 3.247 0 00-2.967-3.235c-.02.055-.042.11-.066.165l-2.497 5.618a2.435 2.435 0 01-2.226 1.447h-11.82a2.435 2.435 0 01-2.224-1.447L14.01 15.4a2.538 2.538 0 01-.07-.177zm17.86 0H17.486l1.776 3.995h10.764l1.775-3.995zM2.918 28.467c1.12 0 2.03.908 2.03 2.029v3.219a2.03 2.03 0 11-4.06 0v-3.219c0-1.12.909-2.03 2.03-2.03zm44.676 2.029a2.03 2.03 0 00-4.059 0v3.219a2.03 2.03 0 104.06 0v-3.219zM16.611 32.91a.79.79 0 111.581 0 .79.79 0 01-1.58 0zm.79-4.037a4.037 4.037 0 100 8.074 4.037 4.037 0 000-8.075zm13.68 3.247a.79.79 0 100 1.58.79.79 0 000-1.58zm-4.038.79a4.037 4.037 0 118.075 0 4.037 4.037 0 01-8.075 0z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
