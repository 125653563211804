import { SectionEntity } from '@webapp/store/types';

const defaultToggles = {
  active: false,
  hidden: false,
  disabled: false,
  highlighted: false,
};

// Code sections
const sectionsArray: SectionEntity[] = [
  {
    id: 'code-motion-section',
    name: 'Motion',
    type: 'motion-section',
    icon: 'MotionIcon',
    ...defaultToggles,
  },

  {
    id: 'code-visual-section',
    name: 'Visual',
    type: 'visual-section',
    icon: 'VisualIcon',
    ...defaultToggles,
  },

  {
    id: 'code-sound-section',
    name: 'Sound',
    type: 'sound-section',
    icon: 'SoundIcon',
    ...defaultToggles,
  },

  {
    id: 'code-sensing-section',
    name: 'Sensing',
    type: 'sensing-section',
    icon: 'SensingIcon',
    ...defaultToggles,
  },

  {
    id: 'code-special-section',
    name: 'Special',
    type: 'special-section',
    icon: 'SpecialIcon',
    ...defaultToggles,
  },
];

// Convert the array into the entities and ids structure
const sectionsInitialState = {
  ids: sectionsArray.map(section => section.id),
  entities: sectionsArray.reduce(
    (entities, section) => {
      entities[section.id] = section;
      return entities;
    },
    {} as Record<string, SectionEntity>
  ),
};

export default sectionsInitialState;
