import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

// todo: extract to theme
const border = '1';
const borderColor = '#5A418B';

const usualColor = 'rgba(227, 24, 113, 1)';
const usualBadgeColor = 'rgba(181, 19, 90, 1)';

const activeColor = '#36C960';
const activeBadgeColor = '#36C960';

const disabledColor = '#aaaaaa';

const ElementWrapper = styled(Button)(({ width, height, sx }) => ({
  width: width,
  height: height,
  borderRadius: '0',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  clipPath: `polygon(0 0, calc(100% - ${height / 2}px) 0, 100% 50%, calc(100% - ${height / 2}px) 100%, 0 100%)`,
  padding: `${border}px 0 ${border}px ${border}px`,
  backgroundColor: borderColor,
  '&:hover': {
    backgroundColor: borderColor,
  },
  ...sx,
}));

const ContentElement = styled(Box, { shouldForwardProp: prop => !['active', 'playing'].includes(prop) })(
  ({ sx, disabled, active, playing }) => ({
    backgroundColor: disabled ? disabledColor : playing && active ? activeColor : usualColor,
    borderRadius: '4px 0 0 4px',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    boxSizing: 'border-box',
    // transition: active ? 'none' : 'background-color 1s',
    ...sx,
  })
);

const BadgeWrapper = styled(Box)(({ sx, height }) => ({
  backgroundColor: borderColor,
  height: '100%',
  width: `${height / 2}px`,
  flexGrow: 0,
  flexShrink: 0,
  boxSizing: 'border-box',
  ...sx,
}));

const ElementBadge = styled(Box, { shouldForwardProp: prop => !['active', 'playing'].includes(prop) })(
  ({ sx, height, disabled, active, playing }) => ({
    backgroundColor: disabled ? disabledColor : playing && active ? activeBadgeColor : usualBadgeColor,
    color: 'white',
    fontSize: '12px',
    fontWeight: '900',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    paddingLeft: '3px',
    height: `${height - 2 * border}px`,
    clipPath: `polygon(0 0, calc(100% - ${border}px) 50%, 0 100%)`,
  })
);

const ElementIcon = styled(Box)(({ sx }) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  ...sx,
}));

const ElementLabel = styled(Box)(({ sx }) => ({
  color: 'white',
  fontSize: '10px',
  fontWeight: '700',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...sx,
}));

const TriggerElement = ({
  icon,
  label,
  badge,
  width,
  disabled,
  height,
  onClick,
  onDragStart,
  iconProps,
  labelProps,
  badgeProps,
  isActive = false,
  isPlaying = false,
  ...props
}) => {
  return (
    <ElementWrapper width={width} height={height} onClick={onClick} onDragStart={onDragStart} {...props}>
      <ContentElement disabled={disabled} active={isActive} playing={isPlaying}>
        <ElementIcon {...iconProps}>{icon}</ElementIcon>
        {!!label && <ElementLabel {...labelProps}>{label}</ElementLabel>}
      </ContentElement>
      <BadgeWrapper height={height}>
        <ElementBadge {...badgeProps} height={height} disabled={disabled} active={isActive} playing={isPlaying}>
          {badge}
        </ElementBadge>
      </BadgeWrapper>
    </ElementWrapper>
  );
};

TriggerElement.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.element]),
  width: PropTypes.number,
  height: PropTypes.number,
  disabled: PropTypes.bool,
  iconProps: PropTypes.object,
  labelProps: PropTypes.object,
  badgeProps: PropTypes.object,
  onClick: PropTypes.func,
  onDragStart: PropTypes.func,
  isActive: PropTypes.bool,
  isPlaying: PropTypes.bool,
};

TriggerElement.defaultProps = {
  width: 60,
  height: 48,
  disabled: false,
  label: '',
  badge: '',
  iconProps: {},
  labelProps: {},
  badgeProps: {},
  isActive: false,
  isPlaying: false,
};

export default TriggerElement;
