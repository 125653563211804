/**
 * Clamp the given value between the given minimum and maximum values.
 *
 * @param {number} value - The value to clamp.
 * @param {number} min - The minimum value to clamp to.
 * @param {number} max - The maximum value to clamp to.
 * @returns {number} - The clamped value.
 */
export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

/**
 * Maps a value from one range to another.
 *
 * @param {number} value - The value to map.
 * @param {[number, number]} inputRange - The input range [min, max].
 * @param {[number, number]} outputRange - The output range [min, max].
 * @returns {number} - The mapped value.
 */
export const mapToRange = (
  value: number,
  [inputMin, inputMax]: [number, number],
  [outputMin, outputMax]: [number, number]
) => {
  return ((outputMax - outputMin) / (inputMax - inputMin)) * (value - inputMin) + outputMin;
};
