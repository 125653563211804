import React from 'react';
import { ValueProps, SliderGeometry } from '../types';

interface ValueIndicatorProps extends ValueProps {
  sliderGeometry: SliderGeometry;
  customRender?: (props: ValueProps) => React.ReactNode;
}

const ValueIndicator: React.FC<ValueIndicatorProps> = ({
  trackId,
  handleId,
  value,
  min,
  max,
  sliderGeometry,
  customRender,
}) => {
  const position = sliderGeometry.getHandlePosition(trackId, value);

  if (customRender) {
    return customRender({ trackId, handleId, value, min, max });
  }

  return (
    <text x={position.x} y={position.y} dy="-1em" textAnchor="middle" fontSize="12" fill="#000">
      {value.toFixed(1)}
    </text>
  );
};

export default React.memo(ValueIndicator);
