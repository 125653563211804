import { useRef, useCallback } from 'react';

import { Box } from '@mui/material';

import { CircularSlider as CSComponent, TrackProps, HandleProps } from '../circular-slider';

interface CustomCircularSliderProps {
  mainColor?: string;
  railColor?: string;
  labelColor?: string;
  value: number | [number, number];
  onChange?: (event: Event, value: number | number[]) => void;
  onChangeCommitted?: (event: Event, value: number | number[]) => void;
  min?: number;
  max?: number;
  step?: number;
  valueLabelDisplay?: 'on' | 'off';
  radius?: number;
  width?: number;
  height?: number;
  children?: React.ReactNode;
  startAngle?: number;
  endAngle?: number;
}

/**
 * Circular Slider component
 */
const CircularSlider: React.FunctionComponent<CustomCircularSliderProps> = ({
  value,
  onChange,
  onChangeCommitted,
  min = 0,
  max = 100,
  step = 1,
  valueLabelDisplay = 'off',
  radius = 100,
  mainColor,
  railColor,
  labelColor,
  width,
  height,
  children,

  startAngle = 0,
  endAngle = 360,
}) => {
  const boxRef = useRef<HTMLDivElement>(null); // Create a ref

  if (width || height) {
    console.error('Width and height are not supported yet. Use radius instead.');
  }

  /**
   * Render the label
   */
  const labelRenderer = useCallback(
    (value: number) =>
      valueLabelDisplay === 'on' && (
        <text
          className="value-placeholder"
          x="0"
          y="3"
          fontSize="12"
          textAnchor="middle"
          fill={labelColor}
          fontWeight="bold"
          style={{ userSelect: 'none', pointerEvents: 'none' }}
        >
          {value.toString()}
        </text>
      ),
    [labelColor, valueLabelDisplay]
  );

  /**
   * Handle the onChange event
   * @param trackValues
   */
  const handleOnChange = useCallback(
    (trackValues: { [trackId: string]: number[] }) => {
      const track1Values = trackValues['track1'];

      let newValue: number | [number, number];

      if (track1Values.length === 1) {
        newValue = track1Values[0];
      } else {
        newValue = track1Values as [number, number];
      }

      if (onChange) {
        const event = new Event('CustomEvent');
        onChange(event, newValue);
      }
    },
    [onChange]
  );

  /**
   * Handle the onChangeCommitted event
   * @param trackValues
   */
  const handleOnChangeCommitted = useCallback(
    (trackValues: { [trackId: string]: number[] }) => {
      const track1Values = trackValues['track1'];

      let newValue: number | [number, number];

      if (track1Values.length === 1) {
        newValue = track1Values[0];
      } else {
        newValue = track1Values as [number, number];
      }

      if (onChangeCommitted) {
        const event = new Event('CustomEvent');
        onChangeCommitted(event, newValue);
      }
    },
    [onChangeCommitted]
  );

  /**
   * Get the values
   */
  const values = Array.isArray(value) ? value : [value];

  /**
   * Define the handles
   */
  const handles: HandleProps[] = values.map((value, index) => ({
    id: `handle-${index}`,
    trackId: 'track1',
    value: value,
    label: labelRenderer,
  }));

  /**
   * Define the tracks
   */
  const tracks: TrackProps[] = [
    {
      id: 'track1',
      type: 'circular',
      min: min,
      max: max,
      startPoint: 0,
      startAngle: startAngle,
      endAngle: endAngle,
      step: step,
      values: values,
      handles: handles,
      shouldRenderActiveTrack: handles.length < 2,
      shouldRenderConnector: handles.length === 2,
      connectorColor: mainColor,
      connectorWidth: 10,
    },
  ];

  return (
    <Box
      ref={boxRef} // Attach ref to the Box component
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <CSComponent
        radius={radius}
        tracks={tracks}
        defaultHandleSize={30}
        defaultHandleColor={'#fefefe'}
        defaultTrackColor={railColor}
        defaultActiveTrackColor={mainColor}
        onChange={handleOnChange}
        onChangeCommitted={handleOnChangeCommitted}
      >
        {children}
      </CSComponent>
    </Box>
  );
};

export default CircularSlider;
