import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <rect
          x="24.9712"
          y="0.798706"
          width="5.69746"
          height="34.1848"
          rx="2.84873"
          transform="rotate(45 24.9712 0.798706)"
          fill="white"
        />
        <rect
          width="5.69746"
          height="34.1848"
          rx="2.84873"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 4.82764 0.798706)"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
