export const shadows = [
  'none',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 16px rgba(39, 39, 39, 0.08)',
  '0px 0px 24px rgba(39, 39, 39, 0.08)',
  '0px 8px 32px rgba(39, 39, 39, 0.1)',
  '0px 8px 32px rgba(39, 39, 39, 0.1)',
  '0px 0px 8px rgba(126, 99, 182, 0.15)',
  '0px 0px 40px rgba(126, 99, 182, 0.16)',

  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
  '0px 0px 8px rgba(39, 39, 39, 0.08)',
];
