import { overrides } from '../config/theme-typography';
import { colors as themeColors } from '../config/theme-colors';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ThemeOptions } from '@mui/system';

/**
 *
 * @type {ThemeOptions}
 */

export const MuiTextFieldOverrides = {
  styleOverrides: {
    root: {
      color: themeColors.black['300'],

      '& .MuiInputBase-root': {
        borderRadius: '8px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',

        ...overrides.extra['x-button2-regular'],
        height: '40px',

        input: {
          padding: '0 0 0 12px',
          color: themeColors.black.main,
        },
      },
      // disable default border
      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        borderBottom: 'none !important',
      },
      // align label
      '& .MuiInputLabel-root': {
        zIndex: 10,
        paddingLeft: '16px',
        paddingTop: '5px',

        '&.Mui-focused': {
          paddingTop: '0',
        },
      },

      // icons
      '& .MuiInputAdornment-positionStart': {
        marginLeft: '16px',
        marginRight: 0,

        '&.MuiInputAdornment-filled, &.MuiInputAdornment-outlined': {
          marginLeft: 0,
          marginTop: '0 !important',
        },
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '14px',
      },
    },
  },

  variants: [
    {
      props: { variant: 'standard' },
      style: {
        '& .MuiInputBase-root': {
          backgroundColor: themeColors.white.main,
        },
        input: {
          color: themeColors.black['300'],
        },

        '& .Mui-disabled': {
          boxShadow: 'none',
        },

        '& .Mui-focused.MuiInput-root': {
          outlineColor: themeColors.secondary.blue,
          outlineWidth: '2px',
          outlineStyle: 'solid',
        },
      },
    },
    {
      props: { variant: 'filled' },
      style: {
        input: {
          color: themeColors.black['300'],
        },

        '.MuiInputBase-root': {
          boxShadow: 'none',
        },

        '&& .Mui-disabled': {
          boxShadow: 'none',
          backgroundColor: themeColors.secondary.whiteSmoke,
        },

        '& .Mui-focused.MuiInput-root': {
          outlineColor: themeColors.secondary.blue,
          outlineWidth: '2px',
          outlineStyle: 'solid',
        },
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        borderWidth: '2px',
      },
    },

    // colors
    {
      props: { color: 'error' },
      style: {
        '& .MuiInputBase-colorError': {
          outlineColor: themeColors.secondary.codeApp,
          outlineWidth: '2px',
          outlineStyle: 'solid',
        },
      },
    },

    {
      props: { color: 'success' },
      style: {
        '& .MuiInputBase-colorSuccess': {
          outlineColor: themeColors.semantic.greenLight,
          outlineWidth: '2px',
          outlineStyle: 'solid',
        },
      },
    },

    // sizes
    {
      props: { size: 'extraLarge' },
      style: {
        '& .MuiInputBase-root': {
          ...overrides.extra['x-button1-regular'],
          height: '64px',
        },

        '& .MuiInputLabel-root': {
          paddingTop: '16px',
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        '& .MuiInputBase-root': {
          ...overrides.extra['x-button1-regular'],
          height: '48px',
        },

        '& .MuiInputLabel-root': {
          paddingTop: '8px',
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        '& .MuiInputBase-root': {
          ...overrides.extra['x-button2-regular'],
          height: '40px',
        },

        '& .MuiInputLabel-root': {
          paddingTop: '5px',
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        '& .MuiInputBase-root': {
          ...overrides.extra['x-button3-regular'],
          height: '36px',
        },

        '& .MuiInputLabel-root': {
          paddingTop: '5px',
        },
      },
    },
  ],
};
