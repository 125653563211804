const SoundsMap = {
  'bell-sound': 'Bell',
  'party-sound': 'Party',
  'car-sound': 'Car',
  'chick-sound': 'Chick',
  'clock-sound': 'Clock',
  'cat-sound': 'Cat',
  'dog-sound': 'Dog',
  'robot-sound': 'Robot',
  'owl-sound': 'Owl',
  'spaceship-sound': 'Spaceship',
  'dinosaur-sound': 'Dinosaur',
  'horse-sound': 'Horse',
};

export enum SoundsMapEnum {
  BellSound = 'Bell',
  PartySound = 'Party',
  CarSound = 'Car',
  ChickSound = 'Chick',
  ClockSound = 'Clock',
  CatSound = 'Cat',
  DogSound = 'Dog',
  RobotSound = 'Robot',
  OwlSound = 'Owl',
  SpaceshipSound = 'Spaceship',
  DinosaurSound = 'Dinosaur',
  HorseSound = 'Horse',
}

export default SoundsMap;
