import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/configureStore';
import { ModuleState, ModuleData } from '@webapp/store/types';
import { ModuleId, ModulesCollectionTypes, ModuleTypes } from '@lib/robo/types';
import { RoboModel } from '@lib/robo/robo-model';

export const selectModel = (state: RootState) => state.webapp.model;

// Use these selectors in your components or thunks like this:
// const specificMotor = useSelector((state: RootState) => selectModuleById(state, 'motors', 'motor1'));
// const allMotors = useSelector((state: RootState) => selectAllModulesByType(state, 'motors'));

/**
 * Selects a module from the model state by its type and ID.
 * @param state - The root state of the application.
 * @param moduleType - The type of the module to select.
 * @param moduleId - The ID of the module to select.
 * @returns The selected module, or undefined if it does not exist.
 */
export const selectModuleById = createSelector(
  selectModel,
  (_state: RootState, moduleType: ModulesCollectionTypes, moduleId: string) => ({ moduleType, moduleId }),
  (modelState, { moduleType, moduleId }) => modelState[moduleType]?.[moduleId]
);

/**
 * Selects all modules of a given type from the model state.
 * @param moduleType - The type of module to select.
 * @returns An array of all modules of the given type.
 */
export const selectAllModulesByType = createSelector(
  selectModel,
  (_state: RootState, moduleType: keyof ModuleState) => moduleType,
  (modelState, moduleType) => Object.values(modelState[moduleType] || {})
);

/**
 * Selects all modules from the model state and returns them as an object with keys
 * representing the module types and values representing an array of modules of that type.
 * @returns An object with keys representing the module types and values representing an array of modules of that type.
 */
export const selectAllModules = createSelector(selectModel, modelState => {
  return Object.keys(modelState).reduce(
    (allModules, type) => {
      allModules[type] = Object.values(modelState[type as keyof ModuleState]);
      return allModules;
    },
    {} as { [type: string]: ModuleData[] }
  );
});

const getConnectedModuleIds = (moduleState: ModuleState) => {
  // Extract the module IDs from each module type category that is connected
  const moduleTypes = Object.keys(moduleState) as Array<keyof ModuleState>;
  const connectedModuleIds: ModuleId[] = [];

  moduleTypes.forEach(moduleType => {
    const moduleTypeData = moduleState[moduleType];
    connectedModuleIds.push(...(Object.keys(moduleTypeData) as ModuleId[]));
  });

  return connectedModuleIds;
};

const parseModuleType = (moduleId: ModuleId): ModuleTypes => RoboModel.parseModuleId(moduleId).type;

/**
 * Returns an array of IDs for all modules that are currently connected in the model state.
 * @returns {ModuleId[]} An array of module IDs.
 */
export const selectConnectedModuleIds = createSelector([selectModel], getConnectedModuleIds);

/**
 * Returns an array of IDs for all modules that are currently connected in the model state.
 * As input needs ModuleState but now whole store state.
 * @returns {string[]} An array of module IDs.
 */
export const selectConnectedModuleIdsFromModel = createSelector([(model: ModuleState) => model], getConnectedModuleIds);

/**
 * Returns an array of module types for all modules that are currently connected in the model state.
 * @returns {ModuleTypes[]} An array of module types.
 */
export const selectConnectedModuleTypes = createSelector([selectConnectedModuleIds], (moduleIds: ModuleId[]) => {
  return moduleIds.map<ModuleTypes>(parseModuleType);
});

/**
 * Returns an array of module types for all modules that are currently connected in the model state.
 * @returns {ModuleTypes[]} An array of module types.
 */
export const selectConnectedModuleTypesFromModel = createSelector(
  [selectConnectedModuleIdsFromModel],
  (connectedModuleIds: ModuleId[]) => {
    return connectedModuleIds.map<ModuleTypes>(parseModuleType);
  }
);
