import { createApi } from '@reduxjs/toolkit/query/react';

import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { BASE_FIRMWARE_URL, firmwaresConfiguration } from '@tools/config/firmwares';
import { DeviceType, FirmwareChannel } from '@tools/types/firmwareTypes';

export const firmwareApiGraphql = createApi({
  reducerPath: 'firmwareApiGraphql',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_FIRMWARE_URL,
    prepareHeaders: (headers: Headers) => {
      headers.append('Accept', 'application/json, text/plain, */*');
      return headers;
    },
  }),
  endpoints: builder => ({
    getDeviceFirmwaresList: builder.query<string[], { deviceType: DeviceType; channel: FirmwareChannel }>({
      query: ({ deviceType, channel }) => {
        const configuration = firmwaresConfiguration[deviceType];
        if (!configuration.channels?.[channel]?.directory) {
          throw new Error('There is no directory for firmware channel: ' + channel);
        }
        return `${configuration.channels?.[channel]?.directory}`;
      },
      transformResponse: (response: string[], _, { deviceType }) => {
        return response.filter(
          file => new RegExp(firmwaresConfiguration[deviceType].firmwareVersionRegexp).test(file) || file === 'latest'
        );
      },
    }),
  }),
});

export const { useGetDeviceFirmwaresListQuery } = firmwareApiGraphql;
