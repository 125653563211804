import { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '@store/configureStore';

import { getWidgetEntityForElement } from '@webapp/components/blocks/widgets/widgets-factory';

import { v4 as uuidv4 } from 'uuid';

import {
  addGroup,
  updateGroup,
  removeGroup,
  addEdge,
  removeEdge,
  addAction,
  updateAction,
  removeAction,
  addTrigger,
  updateTrigger,
  removeTrigger,
  addLabel,
  removeLabel,
  updateLabel,
  addWidget,
  removeWidget,
} from '@webapp/store/slices/code/editor.slice';

import {
  CodeEditorState,
  CodeGroupId,
  CodeEditorAddGroupPayload,
  CodeEditorUpdateGroupPayload,
  CodeEditorUpdateActionPayload,
  CodeEditorAddEdgePayload,
  CodeEditorAddLabelPayload,
  CodeEditorUpdateLabelPayload,
  CodeEditorLabelType,
  CodeEditorTrigger,
  CodeEditorTriggerPlacement,
  CodeEditorAddTriggerPayload,
  CodeEditorUpdateTriggerPayload,
  CodeEditorAddActionPayload,
} from '@webapp/store/types';

import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';

const codeEditorMiddleware: Middleware<Record<string, never>, RootState> = store => next => action => {
  const { dispatch, getState } = store;
  const codeEditorState: CodeEditorState = getState().webapp.code.editor;

  switch (action.type) {
    case addGroup.type:
      return handleAddGroup(codeEditorState, action, dispatch, next);
    case updateGroup.type:
      return handleUpdateGroup(codeEditorState, action, dispatch, next);
    case removeGroup.type:
      return handleRemoveGroup(codeEditorState, action, dispatch, next);
    case addAction.type:
      return handleAddAction(codeEditorState, action, dispatch, next, store);
    case removeAction.type:
      return handleRemoveAction(codeEditorState, action, dispatch, next, store);
    case updateAction.type:
      return handleUpdateAction(codeEditorState, action, dispatch, next);
    case addEdge.type:
      return handleAddEdge(codeEditorState, action, dispatch, next);
    case removeEdge.type:
      return handleRemoveEdge(codeEditorState, action, dispatch, next);
    case addLabel.type:
      return handleAddLabel(codeEditorState, action, dispatch, next);
    case removeLabel.type:
      return handleRemoveLabel(codeEditorState, action, dispatch, next);
    case updateLabel.type:
      return handleUpdateLabel(codeEditorState, action, dispatch, next);
    case addTrigger.type:
      return handleAddTrigger(codeEditorState, action, dispatch, next, store);
    case updateTrigger.type:
      return handleUpdateTrigger(codeEditorState, action, dispatch, next, store);
    case removeTrigger.type:
      return handleRemoveTrigger(codeEditorState, action, dispatch, next, store);
    default:
      return next(action);
  }
};

const handleAddGroup = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorAddGroupPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorAddGroupPayload>) => unknown
) => {
  const groupId = action.payload.id;

  const isOnlyGroup = Object.keys(state.groups).length === 0;
  // if it's the first group, create a start label and add it to the group
  if (isOnlyGroup) {
    const label = {
      id: CodeEditorLabelType.Start,
      groupId,
      type: CodeEditorLabelType.Start,
    };

    // adding group
    next(action);

    // adding label to the group
    dispatch(addLabel({ id: label.id, label }));
  } else {
    next(action);
  }
};
/**
 * Handles the update group action
 */
const handleUpdateGroup = (
  _state: CodeEditorState,
  action: PayloadAction<CodeEditorUpdateGroupPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorUpdateGroupPayload>) => unknown
) => {
  const changes = action.payload.group;
  const groupId = action.payload.id;

  if (changes.actionIds && changes.actionIds.length === 0) {
    dispatch(removeGroup(groupId));
  }

  return next(action);
};

/**
 * Handles the remove group action
 */
const handleRemoveGroup = (
  state: CodeEditorState,
  action: PayloadAction<CodeGroupId>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeGroupId>) => unknown
) => {
  // if group is removed:
  // - remove all edges that are connected to the group
  // - remove all actions that are connected to the group
  // - remove all triggers that are connected to the group
  // - remove all labels that are connected to the group
  const groupId = action.payload;
  const { edges, actions, triggers, labels } = state;

  // remove all edges that are connected to the group
  for (const edgeId in edges) {
    if (edges[edgeId].sourceGroupId === groupId || edges[edgeId].targetGroupId === groupId) {
      dispatch(removeEdge(edgeId));
    }
  }

  // remove all actions that are connected to the group
  for (const actionId in actions) {
    if (actions[actionId].groupId === groupId) {
      dispatch(removeAction(actionId));
    }
  }

  // remove all labels that are connected to the group
  // if it is start label - move it to other group
  for (const labelId in labels) {
    if (labels[labelId].groupId === groupId) {
      if (labels[labelId].type === CodeEditorLabelType.Start) {
        const newGroupId = Object.keys(state.groups).find(id => id !== groupId);
        // in case it was last group, remove label
        if (newGroupId) {
          dispatch(
            updateLabel({
              id: labelId,
              label: {
                groupId: newGroupId,
              },
            })
          );
        } else {
          dispatch(removeLabel(labelId));
        }
      } else {
        dispatch(removeLabel(labelId));
      }
    }
  }

  // remove all triggers that are connected to the group
  for (const triggerId in triggers) {
    if (triggers[triggerId].groupId === groupId) {
      dispatch(removeTrigger(triggerId));
    }
  }

  return next(action);
};

/**
 * Handles the add edge action
 */
const handleAddEdge = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorAddEdgePayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorAddEdgePayload>) => unknown
) => {
  const edgeId = action.payload.id;
  const sourceGroupId = action.payload.edge.sourceGroupId;

  // add edge to the source group if it's not already there
  const sourceGroup = state.groups[sourceGroupId];
  const edgeAlreadyInGroup = sourceGroup.edgeIds?.includes(edgeId);

  if (!edgeAlreadyInGroup) {
    dispatch(
      updateGroup({
        id: sourceGroupId,
        group: {
          edgeIds: [...(sourceGroup.edgeIds || []), edgeId],
        },
      })
    );
  }

  return next(action);
};

/**
 * Handles the remove edge action
 */
const handleRemoveEdge = (
  state: CodeEditorState,
  action: PayloadAction<string>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<string>) => unknown
) => {
  const edgeId = action.payload;
  const { triggers } = state;

  // remove all triggers that are connected to the edge
  for (const triggerId in triggers) {
    if (triggers[triggerId].edgeId === edgeId) {
      dispatch(removeTrigger(triggerId));
    }
  }

  // remove edge from the source group's edgeIds
  const sourceGroupId = state.edges[edgeId].sourceGroupId;
  const sourceGroup = state.groups[sourceGroupId];

  if (sourceGroup?.edgeIds?.includes(edgeId)) {
    dispatch(
      updateGroup({
        id: sourceGroupId,
        group: {
          edgeIds: sourceGroup.edgeIds.filter(id => id !== edgeId),
        },
      })
    );
  }

  return next(action);
};

/**
 * Handles the add action action
 */
const handleAddAction = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorAddActionPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorAddActionPayload>) => unknown,
  store: { getState: () => RootState }
) => {
  const { getState } = store;
  const targetAction = action.payload.action;
  const elementId = targetAction.elementId;
  const applicationState = getState();

  // add widget to the action
  const widgetId = uuidv4();
  const element = elementsSelectors.selectById(applicationState, elementId);

  if (!element) {
    console.error('Element not found for action', targetAction);
    return next(action);
  }

  const widgetEntity = getWidgetEntityForElement(element, widgetId);

  dispatch(addWidget({ id: widgetEntity.id, widget: widgetEntity }));

  // add widgetId to the action
  action.payload.action.widgetId = widgetId;

  // perform action
  next(action);

  // add action to the group
  const groupId = targetAction.groupId;
  const group = state.groups[groupId];

  if (!group) {
    console.error('Group not found for action', targetAction, state.groups);
    return;
  }

  // add action to the group
  dispatch(
    updateGroup({
      id: groupId,
      group: {
        actionIds: [...group.actionIds, targetAction.id],
      },
    })
  );
};

/**
 * Handles the update action action
 */
const handleUpdateAction = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorUpdateActionPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorUpdateActionPayload>) => unknown
) => {
  const changes = action.payload.action;
  const actionId = action.payload.id;

  // if groupId is changed, clean up the old group's actions, add the action to the new group
  if (changes.groupId && changes.groupId !== state.actions[actionId].groupId) {
    const newGroupId = changes.groupId;
    const oldGroupId = state.actions[actionId].groupId;

    const oldGroup = state.groups[oldGroupId];
    const newGroup = state.groups[newGroupId];

    // perform action
    const result = next(action);

    // add action to new group
    const actionAlreadyInGroup = newGroup.actionIds.includes(actionId);

    if (!actionAlreadyInGroup) {
      dispatch(
        updateGroup({
          id: newGroupId,
          group: {
            actionIds: [...newGroup.actionIds, actionId],
          },
        })
      );
    }

    // remove action from old group
    dispatch(
      updateGroup({
        id: oldGroupId,
        group: {
          actionIds: oldGroup.actionIds.filter(id => id !== actionId),
        },
      })
    );

    return result;
  }

  return next(action);
};

/**
 * Handles the remove action
 */
const handleRemoveAction = (
  state: CodeEditorState,
  action: PayloadAction<string>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<string>) => unknown
) => {
  const actionId = action.payload;
  const targetAction = state.actions[actionId];
  const groupId = targetAction.groupId;
  const group = state.groups[groupId];

  const widgetId = targetAction.widgetId;

  // remove widget from the state
  if (widgetId) {
    const widget = state.widgets[widgetId];

    if (widget) {
      dispatch(removeWidget(widgetId));
    }
  }

  // perform action
  next(action);

  // remove action from the group's actionIds
  dispatch(
    updateGroup({
      id: groupId,
      group: {
        actionIds: group.actionIds.filter(id => id !== actionId),
      },
    })
  );
};

/**
 * Handles the add label action
 */
const handleAddLabel = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorAddLabelPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorAddLabelPayload>) => unknown
) => {
  const label = action.payload.label;
  const labelId = action.payload.id;

  // add label to the group
  const groupId = label.groupId;
  const group = state.groups[groupId];

  if (!group) {
    console.error('Group not found for label', label, state.groups);
    return next(action);
  }

  const labelAlreadyInGroup = group.labelIds?.includes(labelId);

  if (!labelAlreadyInGroup) {
    dispatch(
      updateGroup({
        id: groupId,
        group: {
          labelIds: [...(group.labelIds || []), labelId],
        },
      })
    );
  }

  return next(action);
};

/**
 * Handles the remove label from the group
 */
const handleRemoveLabel = (
  state: CodeEditorState,
  action: PayloadAction<string>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<string>) => unknown
) => {
  const labelId = action.payload;

  // remove label from the group's labelIds
  const groupId = state.labels[labelId].groupId;
  const group = state.groups[groupId];

  if (group?.labelIds?.includes(labelId)) {
    dispatch(
      updateGroup({
        id: groupId,
        group: {
          labelIds: group.labelIds.filter(id => id !== labelId),
        },
      })
    );
  }

  return next(action);
};

/**
 * Handles the update label action
 */
const handleUpdateLabel = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorUpdateLabelPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorUpdateLabelPayload>) => unknown
) => {
  const changes = action.payload.label;
  const labelId = action.payload.id;

  // if groupId is changed, clean up the old group's actions, add the action to the new group
  if (changes.groupId && changes.groupId !== state.labels[labelId].groupId) {
    const newGroupId = changes.groupId;
    const oldGroupId = state.labels[labelId].groupId;

    const oldGroup = state.groups[oldGroupId];
    const newGroup = state.groups[newGroupId];

    // perform action
    const result = next(action);

    // add action to new group
    const labelAlreadyInGroup = newGroup.labelIds?.includes(labelId);

    if (!labelAlreadyInGroup) {
      dispatch(
        updateGroup({
          id: newGroupId,
          group: {
            labelIds: [...newGroup.labelIds, labelId],
          },
        })
      );
    }

    // remove action from old group
    dispatch(
      updateGroup({
        id: oldGroupId,
        group: {
          labelIds: oldGroup.labelIds.filter(id => id !== labelId),
        },
      })
    );

    return result;
  }

  return next(action);
};

/**
 * Handles the add trigger action
 */
const handleAddTrigger = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorAddTriggerPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorAddTriggerPayload>) => unknown,
  store: { getState: () => RootState }
) => {
  const { getState } = store;
  const applicationState = getState();

  const targetTrigger = action.payload.trigger;
  const elementId = targetTrigger.elementId;
  const element = elementsSelectors.selectById(applicationState, elementId);

  if (!element) {
    console.error('Element not found for trigger', targetTrigger);
    return next(action);
  }

  // add widget to the trigger
  const widgetId = uuidv4();

  const widgetEntity = getWidgetEntityForElement(element, widgetId);

  dispatch(addWidget({ id: widgetEntity.id, widget: widgetEntity }));

  // add widgetId to the trigger
  action.payload.trigger.widgetId = widgetId;

  // perform action
  return next(action);
};

/**
 * Handles the update trigger action
 */
const handleUpdateTrigger = (
  state: CodeEditorState,
  action: PayloadAction<CodeEditorUpdateTriggerPayload>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<CodeEditorUpdateTriggerPayload>) => unknown,
  store: { getState: () => RootState }
) => {
  const changes = action.payload.trigger;
  const triggerId = action.payload.id;
  const oldGroupId = state.triggers[triggerId].groupId;

  const { getState } = store;

  // if edgeId is changed, recheck if the edge is in the group and add it if it's not
  if (changes.groupId && changes.edgeId && changes.edgeId !== state.triggers[triggerId].edgeId) {
    const newGroupId = changes.groupId;

    const oldGroup = state.groups[oldGroupId];
    const newGroup = state.groups[newGroupId];

    const triggerAlreadyInGroup = oldGroup === newGroup;

    if (!triggerAlreadyInGroup) {
      // add trigger to the new group
      dispatch(
        updateGroup({
          id: newGroupId,
          group: {
            triggerIds: [...newGroup.triggerIds, triggerId],
          },
        })
      );

      // remove trigger from old group
      const oldTriggerIds = oldGroup.triggerIds.filter(id => id !== triggerId);

      dispatch(
        updateGroup({
          id: oldGroupId,
          group: {
            triggerIds: oldTriggerIds,
          },
        })
      );
    }

    // perform action
    // it stores the changes within groups
    next(action);

    // Now get the updated state
    const updatedCodeEditorState: CodeEditorState = getState().webapp.code.editor;

    // rearrange triggers types for target edge
    rearrangeTriggersPlacementsForEdge(
      updatedCodeEditorState,
      dispatch,
      updatedCodeEditorState.triggers[triggerId].edgeId
    );

    // rearrange triggers types for source edge
    rearrangeTriggersPlacementsForEdge(updatedCodeEditorState, dispatch, state.triggers[triggerId].edgeId);

    return;
  } else if (
    changes.groupId &&
    changes.groupId === state.triggers[triggerId].groupId &&
    changes.edgeId &&
    changes.edgeId === state.triggers[triggerId].edgeId
  ) {
    // we are dealing with trigger type change only. The edge and group are the same. We need to rearrange triggers types in the group
    // perform action
    next(action);

    // Now get the updated state
    const updatedCodeEditorState: CodeEditorState = getState().webapp.code.editor;

    rearrangeTriggersPlacementsForEdge(updatedCodeEditorState, dispatch, state.triggers[triggerId].edgeId);

    return;
  } else {
    // perform action
    next(action);
  }
};

/**
 * Handles the remove trigger action
 */
const handleRemoveTrigger = (
  state: CodeEditorState,
  action: PayloadAction<string>,
  dispatch: AppDispatch,
  next: (action: PayloadAction<string>) => unknown,
  store: { getState: () => RootState }
) => {
  const { getState } = store;
  const triggerId = action.payload;
  const trigger = state.triggers[triggerId];

  // if trigger is not found, return
  if (!trigger) {
    return next(action);
  }

  const edgeId = trigger.edgeId;
  const groupId = trigger.groupId;
  const group = state.groups[groupId];
  const widgetId = trigger.widgetId;

  if (widgetId) {
    const widget = state.widgets[widgetId];

    // remove widget from the state
    if (widget) {
      dispatch(removeWidget(widgetId));
    }
  }

  // perform action
  next(action);

  // remove trigger from the group's triggerIds
  dispatch(
    updateGroup({
      id: groupId,
      group: {
        triggerIds: group.triggerIds.filter(id => id !== triggerId),
      },
    })
  );

  // Get the updated state
  const updatedCodeEditorState: CodeEditorState = getState().webapp.code.editor;

  // rearrange triggers types for target edge
  rearrangeTriggersPlacementsForEdge(updatedCodeEditorState, dispatch, edgeId);
};

/**
 * Rearrange triggers types for the edge.
 * When we move out of the group the BASE trigger and there is OR or AND trigger
 * we should change OR or AND trigger type to BASE trigger type.
 */
const rearrangeTriggersPlacementsForEdge = (state: CodeEditorState, dispatch: AppDispatch, edgeId: string) => {
  // get all trigger for the edge
  const triggersForEdge = Object.values(state.triggers).filter(trigger => trigger.edgeId === edgeId);
  rearrangeTriggersPlacements(dispatch, triggersForEdge);
};

/**
 * Rearrange triggers types in existing group.
 * When we move out of the group the BASE trigger and there is OR or AND trigger
 * we should change OR or AND trigger type to BASE trigger type.
 */
const rearrangeTriggersPlacements = (dispatch: AppDispatch, triggers: CodeEditorTrigger[]) => {
  // check if there is a BASE trigger in the group
  const baseTrigger = triggers.find(trigger => trigger.placement === CodeEditorTriggerPlacement.Base);

  // in case there is BASE trigger in the group, we don't need to rearrange triggers types
  if (baseTrigger) {
    return;
  }

  // take either OR or AND trigger type and change it to BASE trigger type
  const orTrigger = triggers.find(trigger => trigger.placement === CodeEditorTriggerPlacement.Or);
  const andTrigger = triggers.find(trigger => trigger.placement === CodeEditorTriggerPlacement.And);

  if (orTrigger) {
    dispatch(updateTrigger({ id: orTrigger.id, trigger: { placement: CodeEditorTriggerPlacement.Base } }));
  } else if (andTrigger) {
    dispatch(updateTrigger({ id: andTrigger.id, trigger: { placement: CodeEditorTriggerPlacement.Base } }));
  }
};

export default codeEditorMiddleware;
