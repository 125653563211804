import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#D4D4D4';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 21C18.3137 21 21 18.3137 21 15C21 11.6863 18.3137 9 15 9C11.6863 9 9 11.6863 9 15C9 18.3137 11.6863 21 15 21Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0C14.4477 0 14 0.447715 14 1V6C14 6.55228 14.4477 7 15 7C15.5523 7 16 6.55228 16 6V1C16 0.447715 15.5523 0 15 0ZM25.6066 4.3934C25.2161 4.00287 24.5829 4.00287 24.1924 4.3934L20.6569 7.92893C20.2663 8.31946 20.2663 8.95262 20.6569 9.34315C21.0474 9.73367 21.6805 9.73367 22.0711 9.34315L25.6066 5.80761C25.9971 5.41709 25.9971 4.78392 25.6066 4.3934ZM29 14C29.5523 14 30 14.4477 30 15C30 15.5523 29.5523 16 29 16H24C23.4477 16 23 15.5523 23 15C23 14.4477 23.4477 14 24 14H29ZM25.6066 25.6066C25.9971 25.2161 25.9971 24.5829 25.6066 24.1924L22.0711 20.6569C21.6805 20.2663 21.0474 20.2663 20.6569 20.6569C20.2663 21.0474 20.2663 21.6805 20.6569 22.0711L24.1924 25.6066C24.5829 25.9971 25.2161 25.9971 25.6066 25.6066ZM16 29C16 29.5523 15.5523 30 15 30C14.4477 30 14 29.5523 14 29V24C14 23.4477 14.4477 23 15 23C15.5523 23 16 23.4477 16 24V29ZM4.3934 25.6066C4.78392 25.9971 5.41709 25.9971 5.80761 25.6066L9.34315 22.0711C9.73367 21.6805 9.73367 21.0474 9.34315 20.6569C8.95262 20.2663 8.31946 20.2663 7.92893 20.6569L4.3934 24.1924C4.00287 24.5829 4.00287 25.2161 4.3934 25.6066ZM1 16C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H6C6.55228 14 7 14.4477 7 15C7 15.5523 6.55228 16 6 16H1ZM4.3934 4.3934C4.00287 4.78392 4.00287 5.41709 4.3934 5.80761L7.92893 9.34315C8.31946 9.73367 8.95262 9.73367 9.34315 9.34315C9.73367 8.95262 9.73367 8.31946 9.34315 7.92893L5.80761 4.3934C5.41709 4.00287 4.78392 4.00287 4.3934 4.3934Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
