import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 29 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.552.724C6.753.724.432 7.046.432 14.844v19.259c0 1.162.941 2.104 2.103 2.104h6.586v2.172H7.672a1.448 1.448 0 100 2.897h10.863a1.448 1.448 0 000-2.897h-1.449v-2.896c0-.123-.015-.243-.044-.357 6.788-2.49 11.63-9.01 11.63-16.66v-3.621c0-7.799-6.322-14.12-14.12-14.12zM14.19 38.38h-2.173v-2.205a17.77 17.77 0 002.173-.266v2.471zm-1.386-5.186c7.314-.92 12.972-7.163 12.972-14.727v-3.621c0-6.2-5.025-11.224-11.224-11.224-6.2 0-11.224 5.025-11.224 11.224v5.43a7.241 7.241 0 0111.586 5.794v1.448c0 2.17-.795 4.154-2.11 5.676zM3.328 26.07v7.241h2.896c3.2 0 5.793-2.593 5.793-5.793V26.07a4.345 4.345 0 00-8.69 0zm6.517-14.483a.724.724 0 111.448 0 .724.724 0 01-1.448 0zm.724-3.62a3.62 3.62 0 00-.086 7.24l.042.045 2.385 2.384a1.81 1.81 0 002.56 0l2.384-2.384c.03-.03.058-.061.083-.093a3.62 3.62 0 10-2.007-1.056l-1.74 1.741-1.399-1.398a3.62 3.62 0 00-2.222-6.48zm7.241 3.62a.724.724 0 111.449 0 .724.724 0 01-1.449 0z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
