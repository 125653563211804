import { createSelector } from '@reduxjs/toolkit';
import { CodeEditorState, LiveEditorState } from '@webapp/store/types';
import { ModuleId } from '@lib/robo/types';
import { defaultMemoize } from 'reselect';
import { shallowEqual } from 'react-redux';
import { isEqual } from 'lodash';

export const selectUsedModulesIds = createSelector(
  [(state: LiveEditorState | CodeEditorState) => state.widgets],
  widgets => {
    const moduleIds = new Set<ModuleId>();

    Object.values(widgets).forEach(widget => {
      widget.data?.moduleIds.forEach(id => {
        moduleIds.add(id as ModuleId);
      });
    });

    return [...moduleIds].sort();
  }
);

const selectWidgetsModulesIdsMapOptions = {
  memoize: defaultMemoize,
  memoizeOptions: {
    equalityCheck: shallowEqual,
    resultEqualityCheck: isEqual,
    maxSize: 10,
  },
  argsMemoize: defaultMemoize,
  argsMemoizeOptions: {
    equalityCheck: shallowEqual,
    resultEqualityCheck: isEqual,
    maxSize: 10,
  },
};

export const selectWidgetsModulesIdsMap = createSelector(
  [(state: LiveEditorState | CodeEditorState) => state.widgets],
  widgets => {
    return Object.keys(widgets).reduce((acc, widgetId) => {
      return {
        ...acc,
        [widgetId]: (widgets[widgetId].data?.moduleIds ?? []) as ModuleId[],
      };
    }, {}) as { [key: string]: ModuleId[] };
  },
  selectWidgetsModulesIdsMapOptions
);
