import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/configureStore';
import { createSelector } from '@reduxjs/toolkit';

export type TutorialState = {
  tutorialMetadata: {
    startedAt: number | null;
    finishedAt: number | null;
  };
  stepsMetadata: {
    [key: number]: {
      startedAt: number | null;
      finishedAt: number | null;
      data: {
        [key: string]: unknown;
      };
    };
  };
  activeStepIndex: number;
  isExpanded: boolean;
  canGoNext: boolean;
  canGoBack: boolean;
};

const initialState: TutorialState = {
  activeStepIndex: 0,
  isExpanded: true,
  canGoNext: true,
  canGoBack: true,
  tutorialMetadata: {
    startedAt: null,
    finishedAt: null,
  },
  stepsMetadata: {},
};

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState: initialState,

  reducers: {
    setActiveStepIndex: (state, { payload: { index } }: PayloadAction<{ index: number }>) => {
      state.activeStepIndex = index;
    },
    setIsExpanded: (state, { payload: { isExpanded } }: PayloadAction<{ isExpanded: boolean }>) => {
      state.isExpanded = isExpanded;
    },
    setCanGoNext: (state, { payload: canGo }: PayloadAction<boolean>) => {
      state.canGoNext = canGo;
    },
    setCanGoBack: (state, { payload: canGo }: PayloadAction<boolean>) => {
      state.canGoBack = canGo;
    },
    setTutorialStartedAt: (state, { payload: { timestamp } }: PayloadAction<{ timestamp: number | null }>) => {
      state.tutorialMetadata.startedAt = timestamp;
    },
    setTutorialFinishedAt: (state, { payload: { timestamp } }: PayloadAction<{ timestamp: number | null }>) => {
      state.tutorialMetadata.finishedAt = timestamp;
    },
    setTutorialStepStartedAt: (
      state,
      { payload: { stepIndex, timestamp } }: PayloadAction<{ stepIndex: number; timestamp: number | null }>
    ) => {
      if (!state.stepsMetadata[stepIndex]) {
        state.stepsMetadata[stepIndex] = {
          startedAt: timestamp,
          finishedAt: null,
          data: {},
        };
      } else {
        state.stepsMetadata[stepIndex].startedAt = timestamp;
      }
    },
    setTutorialStepFinishedAt: (
      state,
      { payload: { stepIndex, timestamp } }: PayloadAction<{ stepIndex: number; timestamp: number | null }>
    ) => {
      state.stepsMetadata[stepIndex].finishedAt = timestamp;
    },
    setTutorialStepData: (
      state,
      { payload: { stepIndex, data } }: PayloadAction<{ stepIndex: number; data: { [key: string]: unknown } }>
    ) => {
      state.stepsMetadata[stepIndex].data = data;
    },
    reset: () => initialState,
  },
});

export default tutorialSlice.reducer;

export const {
  setActiveStepIndex,
  setIsExpanded,
  setCanGoNext,
  setCanGoBack,
  setTutorialStartedAt,
  setTutorialFinishedAt,
  setTutorialStepStartedAt,
  setTutorialStepFinishedAt,
  setTutorialStepData,
  reset,
} = tutorialSlice.actions;

export const selectTutorial = (state: RootState) => state.tutorial;

export const selectIsTutorialActive = createSelector([(state: TutorialState) => state], state => {
  return state.tutorialMetadata.startedAt !== null;
});
