import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.723 2.096a1.397 1.397 0 00-2.794 0V4.24c-5.488.527-9.78 5.151-9.78 10.777v2.445H2.673c-1.137 0-2.139.936-2.076 2.166.205 4.012 2.002 7.8 5.028 10.625 3.155 2.945 7.395 4.609 11.813 4.67v2.097h-3.589a1.397 1.397 0 100 2.794h9.78a1.397 1.397 0 000-2.794H20.23v-2.273c3.574-.504 6.92-2.062 9.526-4.494 3.177-2.966 5-6.995 5.046-11.228l3.984-1.406c1.472-.52 1.575-2.562.162-3.227l-4.398-2.07-.097-.045a10.84 10.84 0 00-8.73-7.947V2.096zm6.287 12.922v3.842h1.047-1.047c0 3.488-1.484 6.854-4.16 9.351-2.679 2.5-6.332 3.92-10.16 3.92-3.828 0-7.48-1.42-10.16-3.92-2.317-2.162-3.74-4.976-4.08-7.954h12.494v-5.239a8.033 8.033 0 0116.066 0zm-4.191-1.048a1.397 1.397 0 100-2.794 1.397 1.397 0 000 2.794zm7.334.953v1.757l2.134-.753-2.134-1.004z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
