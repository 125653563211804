import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 55 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5 8.5V8H21H19H18.5V8.5C18.5 13.4706 22.5294 17.5 27.5 17.5C32.4706 17.5 36.5 13.4706 36.5 8.5V8H36H34H33.5V8.5C33.5 11.8137 30.8137 14.5 27.5 14.5C24.1863 14.5 21.5 11.8137 21.5 8.5Z"
          fill="white"
          stroke="#5A418B"
        />
        <mask id="path-2-outside-1_1348_319" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17" fill="black">
          <rect fill="white" width="17" height="17" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16ZM10.5 12C12.433 12 14 10.433 14 8.5C14 6.567 12.433 5 10.5 5C8.567 5 7 6.567 7 8.5C7 10.433 8.567 12 10.5 12Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16ZM10.5 12C12.433 12 14 10.433 14 8.5C14 6.567 12.433 5 10.5 5C8.567 5 7 6.567 7 8.5C7 10.433 8.567 12 10.5 12Z"
          fill="white"
        />
        <path
          d="M15 8.5C15 12.0899 12.0899 15 8.5 15V17C13.1944 17 17 13.1944 17 8.5H15ZM8.5 2C12.0899 2 15 4.91015 15 8.5H17C17 3.80558 13.1944 0 8.5 0V2ZM2 8.5C2 4.91015 4.91015 2 8.5 2V0C3.80558 0 0 3.80558 0 8.5H2ZM8.5 15C4.91015 15 2 12.0899 2 8.5H0C0 13.1944 3.80558 17 8.5 17V15ZM13 8.5C13 9.88071 11.8807 11 10.5 11V13C12.9853 13 15 10.9853 15 8.5H13ZM10.5 6C11.8807 6 13 7.11929 13 8.5H15C15 6.01472 12.9853 4 10.5 4V6ZM8 8.5C8 7.11929 9.11929 6 10.5 6V4C8.01472 4 6 6.01472 6 8.5H8ZM10.5 11C9.11929 11 8 9.88071 8 8.5H6C6 10.9853 8.01472 13 10.5 13V11Z"
          fill="#5A418B"
          mask="url(#path-2-outside-1_1348_319)"
        />
        <mask
          id="path-4-outside-2_1348_319"
          maskUnits="userSpaceOnUse"
          x="38"
          y="0"
          width="17"
          height="17"
          fill="black"
        >
          <rect fill="white" x="38" width="17" height="17" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.5 16C50.6421 16 54 12.6421 54 8.5C54 4.35786 50.6421 1 46.5 1C42.3579 1 39 4.35786 39 8.5C39 12.6421 42.3579 16 46.5 16ZM48.5 12C50.433 12 52 10.433 52 8.5C52 6.567 50.433 5 48.5 5C46.567 5 45 6.567 45 8.5C45 10.433 46.567 12 48.5 12Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5 16C50.6421 16 54 12.6421 54 8.5C54 4.35786 50.6421 1 46.5 1C42.3579 1 39 4.35786 39 8.5C39 12.6421 42.3579 16 46.5 16ZM48.5 12C50.433 12 52 10.433 52 8.5C52 6.567 50.433 5 48.5 5C46.567 5 45 6.567 45 8.5C45 10.433 46.567 12 48.5 12Z"
          fill="white"
        />
        <path
          d="M53 8.5C53 12.0899 50.0899 15 46.5 15V17C51.1944 17 55 13.1944 55 8.5H53ZM46.5 2C50.0899 2 53 4.91015 53 8.5H55C55 3.80558 51.1944 0 46.5 0V2ZM40 8.5C40 4.91015 42.9101 2 46.5 2V0C41.8056 0 38 3.80558 38 8.5H40ZM46.5 15C42.9101 15 40 12.0899 40 8.5H38C38 13.1944 41.8056 17 46.5 17V15ZM51 8.5C51 9.88071 49.8807 11 48.5 11V13C50.9853 13 53 10.9853 53 8.5H51ZM48.5 6C49.8807 6 51 7.11929 51 8.5H53C53 6.01472 50.9853 4 48.5 4V6ZM46 8.5C46 7.11929 47.1193 6 48.5 6V4C46.0147 4 44 6.01472 44 8.5H46ZM48.5 11C47.1193 11 46 9.88071 46 8.5H44C44 10.9853 46.0147 13 48.5 13V11Z"
          fill="#5A418B"
          mask="url(#path-4-outside-2_1348_319)"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
