import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg width="50" height="20" viewBox="0 0 50 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 9C9 4.02944 12.5777 0 16.9924 0H33.0076C37.4217 0 41 4.03955 41 9"
          fill="white"
        />
        <rect y="13" width="50" height="7" rx="3.5" fill="white" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
