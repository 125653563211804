import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#D4D4D4';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C10.4477 0 10 0.447715 10 1C10 1.55228 10.4477 2 11 2C11.5523 2 12 1.55228 12 1C12 0.447715 11.5523 0 11 0ZM18.7782 3.22183C18.3876 2.8313 17.7545 2.8313 17.364 3.22183C16.9734 3.61235 16.9734 4.24551 17.364 4.63604C17.7545 5.02656 18.3876 5.02656 18.7782 4.63604C19.1687 4.24551 19.1687 3.61235 18.7782 3.22183ZM21 10C21.5523 10 22 10.4477 22 11C22 11.5523 21.5523 12 21 12C20.4477 12 20 11.5523 20 11C20 10.4477 20.4477 10 21 10ZM18.7782 18.7782C19.1687 18.3876 19.1687 17.7545 18.7782 17.364C18.3876 16.9734 17.7545 16.9734 17.364 17.364C16.9734 17.7545 16.9734 18.3876 17.364 18.7782C17.7545 19.1687 18.3876 19.1687 18.7782 18.7782ZM12 21C12 21.5523 11.5523 22 11 22C10.4477 22 10 21.5523 10 21C10 20.4477 10.4477 20 11 20C11.5523 20 12 20.4477 12 21ZM3.22183 18.7782C3.61235 19.1687 4.24551 19.1687 4.63604 18.7782C5.02656 18.3876 5.02656 17.7545 4.63604 17.364C4.24551 16.9734 3.61235 16.9734 3.22183 17.364C2.8313 17.7545 2.8313 18.3876 3.22183 18.7782ZM1 12C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10C1.55228 10 2 10.4477 2 11C2 11.5523 1.55228 12 1 12ZM3.22183 3.22183C2.8313 3.61235 2.8313 4.24551 3.22183 4.63604C3.61235 5.02656 4.24551 5.02656 4.63604 4.63604C5.02656 4.24551 5.02656 3.61235 4.63604 3.22183C4.24551 2.8313 3.61235 2.8313 3.22183 3.22183Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
