import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="23" y="7" width="2" height="17" rx="1" transform="rotate(90 23 7)" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M27 8L20 12V4L27 8Z" fill={color} />
        <path fillRule="evenodd" clipRule="evenodd" d="M3 8L10 12L10 4L3 8Z" fill={color} />
        <rect x="30" width="16" height="2" rx="1" transform="rotate(90 30 0)" fill={color} />
        <rect x="2" width="16" height="2" rx="1" transform="rotate(90 2 0)" fill={color} />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
