import { ModuleId, ModuleTypes } from '@lib/robo/types';
import { ExecutableActionWidgetComponent, ExecutableTriggerWidgetComponent } from './execution-flow.types';

export enum WidgetType {
  // Live Widgets
  MotorWidget = 'motor-widget',
  MotorAngleWidget = 'motor-angle-widget',
  JoystickWidget = 'joystick-widget',
  RgbLightWidget = 'rgb-light-widget',
  LaughingFaceWidget = 'laughing-face-widget',
  SmilingFaceWidget = 'smiling-face-widget',
  LookingFaceWidget = 'looking-face-widget',
  MotionSensorWidget = 'motion-sensor-widget',
  LightSensorWidget = 'light-sensor-widget',
  DistanceSensorWidget = 'distance-sensor-widget',
  ButtonWidget = 'button-widget',
  LineFollowerWidget = 'line-follower-widget',
  LiveSoundWidget = 'live-sound-widget',
  LedPixelDisplayWidget = 'led-pixel-display-widget',
  AccelerometerWidget = 'accelerometer-widget',

  // Code Actions
  CodeMotorWidget = 'code-motor-widget',
  CodeAngleWidget = 'code-angle-widget',
  CodeDriveWidget = 'code-drive-widget',
  CodeTurnWidget = 'code-turn-widget',
  CodeLineFollowerWidget = 'code-line-follower-widget',
  CodeShineWidget = 'code-shine-widget',
  CodeFlashWidget = 'code-flash-widget',
  CodeDrawWidget = 'code-draw-widget',
  CodeTextWidget = 'code-text-widget',
  CodeAnimateWidget = 'code-animate-widget',
  CodeWaitWidget = 'code-wait-widget',
  CodeMessageWidget = 'code-message-widget',
  CodeSoundWidget = 'code-sound-widget',

  // Code Triggers
  CodeRepeatTriggerWidget = 'code-repeat-trigger-widget',
  CodeTimerTriggerWidget = 'code-timer-trigger-widget',
  CodeClockTriggerWidget = 'code-clock-trigger-widget',
  CodeAccelerometerTriggerWidget = 'code-accelerometer-trigger-widget',
  CodeLightTriggerWidget = 'code-light-trigger-widget',
  CodeDistanceTriggerWidget = 'code-distance-trigger-widget',
  CodeButtonTriggerWidget = 'code-button-trigger-widget',
  CodeObstacleTriggerWidget = 'code-obstacle-trigger-widget',
  CodeMotionTriggerWidget = 'code-motion-trigger-widget',
}

export type WidgetId = string;

export interface WidgetEntity<T = Record<string, unknown>> {
  id: WidgetId;
  type: WidgetType;

  props?: WidgetProps;
  data: WidgetData<T>;
}

export type WidgetsState = {
  [id: WidgetId]: WidgetEntity;
};

export type WidgetData<T = Record<string, unknown>> = {
  id: WidgetId;
  elementId: string;
  moduleIds: ModuleId[];
  requiredModuleTypes: ModuleTypes[];
  runtime?: Record<string, unknown>;
} & T;

export type WidgetProps = {
  width: number;
  height: number;
  x: number;
  y: number;

  active: boolean;
  hidden: boolean;
  disabled: boolean;
  highlighted: boolean;

  badge?: string | boolean;
  counter: number;
};

// App specific definitions
export interface AvailableWidgetComponents {
  [key: string]: WidgetComponentType;
}

export type WidgetComponentType = ExecutableActionWidgetComponent | ExecutableTriggerWidgetComponent;

// Payloads
export type AddWidgetPayload<T = Record<string, unknown>> = {
  id: WidgetId;
  widget: WidgetEntity<T>;
};

export type RemoveWidgetPayload = WidgetId;

export type UpdateWidgetPayload<T = Record<string, unknown>> = {
  id: WidgetId;
  widget: Partial<WidgetEntity<T>>;
};

export type UpdateWidgetDataPayload<T = Record<string, unknown>> = {
  id: WidgetId;
  data: Partial<WidgetData<T>>;
};

export type UpdateWidgetPropsPayload = {
  id: WidgetId;
  props: Partial<WidgetProps>;
};
