import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.621 2.015a8.23 8.23 0 00-3.012 11.242l.68 1.178a19.044 19.044 0 00-2.42 9.32c0 4.767 1.745 9.127 4.63 12.476l-4.12 4.12a1.74 1.74 0 102.46 2.46l4.158-4.16A19.05 19.05 0 0020 42.886c4.434 0 8.516-1.509 11.76-4.041l3.967 3.966a1.74 1.74 0 102.46-2.46l-3.888-3.887a19.059 19.059 0 004.831-12.709c0-3.26-.815-6.33-2.253-9.015l.855-1.482a8.23 8.23 0 00-14.207-8.31A19.237 19.237 0 0020 4.625c-1.096 0-2.17.092-3.216.269A8.23 8.23 0 005.62 2.015zm-.475 5.897a4.751 4.751 0 017.55-2.487l-3.83 2.211-3.829 2.21a4.75 4.75 0 01.11-1.934zm-.798 15.842C4.348 15.11 11.356 8.102 20 8.102c8.645 0 15.652 7.008 15.652 15.652 0 8.645-7.008 15.652-15.652 15.652-8.644 0-15.652-7.007-15.652-15.652zM32.98 5.027a4.751 4.751 0 012.323 4.82l-3.829-2.21-3.83-2.212a4.75 4.75 0 015.336-.398zM20 11.146c.96 0 1.738.778 1.738 1.739v11.453l5.143 5.143a1.74 1.74 0 11-2.46 2.46l-5.397-5.398a2.61 2.61 0 01-.764-1.845V12.885c0-.96.779-1.74 1.74-1.74z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
