import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 36 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35 19C35 9.61116 27.3888 2 18 2C8.61116 2 1 9.61116 1 19"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          x="32.5562"
          y="0.585786"
          width="4"
          height="24"
          rx="2"
          transform="rotate(45 32.5562 0.585786)"
          fill={color}
          stroke="white"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
