import { TypedArray } from 'paho-mqtt';

/**
 * Convert a string representation of a hex value to its numerical counterpart.
 * For instance, "0x61" => 0x61.
 * @param {string} hexString - The hex string to convert (e.g., "0x61").
 * @returns {number} - The numerical hex value (e.g., 0x61).
 */
export const convertStringRepresentationToHex = (hexString: string) => {
  if (typeof hexString === 'string' && /^0x[0-9a-fA-F]+$/.test(hexString)) {
    return parseInt(hexString, 16);
  }

  return NaN;
};

/**
 * Convert a numerical hex value to its string representation.
 * For instance, 0x01 => "0x01".
 * @param {number} hexNumber - The hex number to convert (e.g., 0x01).
 * @returns {string} - The string representation (e.g., "0x01").
 */
export const convertHexToStringRepresentation = (hexNumber: number): string => {
  if (typeof hexNumber !== 'number') {
    throw new Error('Invalid hex number');
  }

  const hexString = hexNumber.toString(16);
  return '0x' + hexString.padStart(2, '0').toUpperCase();
};

/**
 * Converts an array of bytes to an integer value.
 * @param {Array<number>} bytes - The array of bytes to be converted. Order of bytes [Less Significant Byte,....., Most Significant Byte]
 * @return {number} - The resulting integer value.
 */
export const convertBytesArrayToInt = (bytes: Array<number>) => {
  const binary = bytes.reduce((acc, b) => {
    return b.toString(2).padStart(8, '0') + acc;
  }, '');

  return parseInt(binary, 2);
};

/**
 * Converts a signed integer to a byte array.
 * @param {number} number - The signed integer to convert.
 * @param {number} bytesLength - The number of bytes to represent the integer (1, 2, or 4).
 * @param {boolean} littleEndian - Whether to use little-endian byte order.
 * @returns {number[]} An array of bytes representing the signed integer.
 * @throws {Error} If an unsupported bytesLength is provided.
 */
export const convertSignedNumberToBytes = (number: number, bytesLength: number, littleEndian: boolean): number[] => {
  const buffer = new ArrayBuffer(bytesLength);
  const view = new DataView(buffer);

  if (bytesLength === 4) {
    view.setInt32(0, number, littleEndian);
  } else if (bytesLength === 2) {
    view.setInt16(0, number, littleEndian);
  } else if (bytesLength === 1) {
    view.setInt8(0, number);
  } else {
    throw new Error('Unsupported size');
  }

  return Array.from(new Uint8Array(buffer));
};

/**
 * Converts an unsigned integer to a byte array.
 * @param {number} number - The unsigned integer to convert.
 * @param {number} bytesLength - The number of bytes to represent the integer (1, 2, or 4).
 * @param {boolean} littleEndian - Whether to use little-endian byte order.
 * @returns {number[]} An array of bytes representing the unsigned integer.
 * @throws {Error} If an unsupported bytesLength is provided.
 */
export const convertUnsignedNumberToBytes = (number: number, bytesLength: number, littleEndian: boolean) => {
  const buffer = new ArrayBuffer(bytesLength); // Create a buffer of 4 bytes (32 bits)
  const view = new DataView(buffer);

  if (bytesLength === 4) {
    view.setUint32(0, number, littleEndian);
  } else if (bytesLength === 2) {
    view.setUint16(0, number, littleEndian);
  } else if (bytesLength === 1) {
    view.setUint8(0, number);
  } else {
    throw new Error('Unsupported size');
  }

  // Convert the ArrayBuffer to an array of bytes
  const bytes = new Uint8Array(buffer);

  return [...bytes];
};

/**
 * Concatenates two Uint8Arrays into a single Uint8Array.
 * @param {Uint8Array} uint8Array1 - The first Uint8Array to concatenate.
 * @param {Uint8Array} uint8Array2 - The second Uint8Array to concatenate.
 * @returns {Uint8Array} A new Uint8Array containing the concatenated contents of uint8Array1 followed by uint8Array2.
 */
export function concatUint8Arrays(uint8Array1: Uint8Array, uint8Array2: Uint8Array): Uint8Array {
  // Create a new Uint8Array with a combined length
  const combinedArray = new Uint8Array(uint8Array1.length + uint8Array2.length);
  // Copy the contents of the Uint8Array into the start of the combined array
  combinedArray.set(uint8Array1, 0);
  // Copy the contents of the ArrayBuffer into the combined array, starting after the end of the Uint8Array
  combinedArray.set(uint8Array2, uint8Array1.length);

  return combinedArray;
}

/**
 * Ensures that the input is a TypedArray.
 * If the input is an ArrayBuffer, it converts it to a Uint8Array.
 * If it's already a TypedArray, it returns it as-is.
 *
 * @param {ArrayBuffer | TypedArray} payloadBytes - The input data to ensure as a TypedArray.
 * @returns {TypedArray} A TypedArray representation of the input data.
 */
export const ensureTypedArray = (payloadBytes: ArrayBuffer | TypedArray) => {
  return payloadBytes instanceof ArrayBuffer ? new Uint8Array(payloadBytes) : payloadBytes;
};

/**
 * Converts a number to its binary string representation.
 *
 * @param {number} num - The number to convert to binary.
 * @param {number} [length=8] - The desired length of the binary string. Defaults to 8.
 * @returns {string} The binary string representation of the number, padded with leading zeros if necessary.
 */
export const toBinaryString = (num: number, length = 8) => {
  return num.toString(2).padStart(length, '0');
};

/**
 * Converts a Uint8Array to a space-separated binary string representation.
 *
 * @param {Uint8Array} body - The Uint8Array to convert.
 * @returns {string} A string where each byte of the input is represented as an 8-bit binary string,
 *                   with spaces separating each byte's representation.
 *
 * @example
 * const arr = new Uint8Array([5, 10, 15]);
 * console.log(outputBinary(arr));
 * // Output: "00000101 00001010 00001111"
 */
export const outputBinary = (body: Uint8Array) => {
  return Array.from(body)
    .map(byte => toBinaryString(byte))
    .join(' ');
};

/**
 * Checks if a string contains only ASCII characters.
 *
 * @param  str - The string to check.
 * @returns True if the string contains only ASCII characters, false otherwise.
 *
 * @example
 * console.log(isAsciiString("Hello")); // true
 * console.log(isAsciiString("こんにちは")); // false
 */
export const isAsciiString = (str: string) => {
  return /^[\x20-\x7E]*$/.test(str);
};
