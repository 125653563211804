import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 26 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5.5a2 2 0 100 4h21a2 2 0 100-4h-21z" fill="#fff" stroke="#5A418B" />
        <rect x="3.5" y="22.5" width="18.525" height="18.525" rx="4.866" fill="#fff" stroke="#5A418B" />
        <path
          d="M8.5 18.5a1 1 0 100 2h7a1 1 0 100-2h-7zm0-8a1 1 0 100 2h7a1 1 0 100-2h-7zm0 4a1 1 0 100 2h3a1 1 0 100-2h-3zm0-8a1 1 0 000 2h3a1 1 0 100-2h-3z"
          fill="#fff"
          stroke="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
