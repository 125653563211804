import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M40.5 23C42.9853 23 45 20.9853 45 18.5C45 16.0147 42.9853 14 40.5 14C38.0147 14 36 16.0147 36 18.5C36 20.9853 38.0147 23 40.5 23Z"
          fill="white"
        />
        <path
          d="M41.4421 25.1566C40.3503 24.9369 39.1631 24.9465 38.0907 25.1971C30.8055 26.0432 28.44 34.4014 29.1079 40.7744C29.3976 43.5297 33.7797 43.5586 33.487 40.7744C33.244 38.4519 33.348 34.9469 34.5935 32.4557C34.5935 35.4837 34.5935 38.5116 34.5935 41.5386C34.5935 41.6456 34.6042 41.7458 34.609 41.849C34.6061 41.8981 34.5944 41.9395 34.5944 41.9897C34.5944 49.1972 34.5818 56.4019 34.2814 63.6036C34.1482 66.8051 39.1563 66.7925 39.2886 63.6036C39.5238 57.9592 39.5832 52.3119 39.5968 46.6636C39.834 46.679 40.0712 46.679 40.3085 46.6655C40.3231 52.3138 40.3804 57.9611 40.6138 63.6036C40.747 66.7925 45.7551 66.8051 45.621 63.6036C45.3205 56.4019 45.3098 49.1972 45.3098 41.9897C45.3098 41.7661 45.2797 41.5627 45.234 41.3671C45.2272 38.238 45.1066 35.1059 45.1436 31.9787C46.635 34.4814 46.7711 38.2987 46.5125 40.7725C46.2189 43.5547 50.6019 43.5268 50.8916 40.7725C51.5751 34.2675 49.0958 25.6924 41.4421 25.1566Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
