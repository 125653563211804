import { gql } from '@lib/store/strapi-graphql-query';

import { WITH_RELATED_GRADES } from '@store/fragments/grade';
import { WITH_RELATED_TAGS } from '@store/fragments/tag';
import { WITH_RELATED_SUBJECTS } from '@store/fragments/subject';

import { WITH_GALLERY } from '@store/fragments/shared/gallery';
import { WITH_MATERIALS } from '@store/fragments/content/material';
import { WITH_GOALS } from '@store/fragments/content/goal';
import { WITH_LEVEL } from '@store/fragments/content/level';
import { WITH_DURATION } from '@store/fragments/atoms/time-selector';
import { WITH_LINKABLE_LIST } from '@store/fragments/atoms/linkable-list';

import { WITH_PROJECT_OVERVIEW, WITH_PROJECTS_OVERVIEW } from '@store/fragments/entities/project';
import { WITH_LESSONS_OVERVIEW } from '@store/fragments/entities/lesson';
import { WITH_CHALLENGES_OVERVIEW } from '@store/fragments/entities/challenge';

import { WITH_COMMON_SECTION } from '@store/fragments/sections/common-section';
import { WITH_LESSON_PLAN_SECTION } from '@store/fragments/sections/lesson-plan-section';
import { WITH_LESSON_RUN_SECTION } from '@store/fragments/sections/lesson-run-section';

export const FULL_LESSON_QUERY = gql`
  ${WITH_RELATED_GRADES}
  ${WITH_RELATED_SUBJECTS}
  ${WITH_RELATED_TAGS}

  ${WITH_GALLERY}

  ${WITH_MATERIALS}
  ${WITH_GOALS}
  ${WITH_LEVEL}

  ${WITH_DURATION}
  ${WITH_LINKABLE_LIST}

  ${WITH_PROJECT_OVERVIEW}

  ${WITH_PROJECTS_OVERVIEW}
  ${WITH_LESSONS_OVERVIEW}
  ${WITH_CHALLENGES_OVERVIEW}

  ${WITH_COMMON_SECTION}
  ${WITH_LESSON_PLAN_SECTION}
  ${WITH_LESSON_RUN_SECTION}

  query GetLesson($id: ID!) {
    lesson(id: $id) {
      data {
        id
        attributes {
          name
          description
          slug

          gallery {
            ...GALLERY
          }

          goals {
            ...GOALS
          }

          materials {
            ...MATERIALS
          }

          level {
            ...LEVEL
          }

          preparationDuration {
            ...DURATION
          }

          learningDuration {
            ...DURATION
          }

          grades {
            ...RELATED_GRADES
          }

          subjects {
            ...RELATED_SUBJECTS
          }

          tags {
            ...RELATED_TAGS
          }

          project {
            ...PROJECT_OVERVIEW
          }

          linkedItems {
            __typename
            ... on ComponentItemsLinkedProjects {
              projects {
                ...PROJECTS_OVERVIEW
              }
            }

            ... on ComponentItemsLinkedLessons {
              lessons {
                ...LESSONS_OVERVIEW
              }
            }

            ... on ComponentItemsLinkedChallenges {
              challenges {
                ...CHALLENGES_OVERVIEW
              }
            }
          }

          sections {
            __typename
            ... on ComponentSectionsCommonSection {
              ...COMMON_SECTION
            }

            ... on ComponentSectionsLessonPlanSection {
              ...LESSON_PLAN_SECTION
            }

            ... on ComponentSectionsLessonRunSection {
              ...LESSON_RUN_SECTION
            }
          }
        }
      }
    }
  }
`;
