import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M44.3145 27.2355L36.524 15.5498L28.7336 27.2355H34.5764V47.6855C34.5764 48.7612 35.4484 49.6331 36.524 49.6331C37.5997 49.6331 38.4717 48.7612 38.4717 47.6855V27.2355H44.3145Z"
          fill="white"
        />
        <path
          d="M54.0526 52.5546C55.1283 52.5546 56.0002 53.4265 56.0002 54.5022C56.0002 55.5778 55.1283 56.4498 54.0526 56.4498H18.9955C17.9198 56.4498 17.0479 55.5778 17.0479 54.5022C17.0479 53.4265 17.9198 52.5546 18.9955 52.5546H54.0526Z"
          fill="white"
        />
        <path
          d="M44.3145 27.2355L36.524 15.5498L28.7336 27.2355H34.5764V47.6855C34.5764 48.7612 35.4484 49.6331 36.524 49.6331C37.5997 49.6331 38.4717 48.7612 38.4717 47.6855V27.2355H44.3145Z"
          stroke="#5A418B"
          strokeWidth="2.045"
        />
        <path
          d="M54.0526 52.5546C55.1283 52.5546 56.0002 53.4265 56.0002 54.5022C56.0002 55.5778 55.1283 56.4498 54.0526 56.4498H18.9955C17.9198 56.4498 17.0479 55.5778 17.0479 54.5022C17.0479 53.4265 17.9198 52.5546 18.9955 52.5546H54.0526Z"
          stroke="#5A418B"
          strokeWidth="2.045"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
