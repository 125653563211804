export const transformEntity = entity => {
  const { id, __typename, attributes } = entity;
  const transformed = { id, __typename, ...attributes };
  return transformed;
};

export const transformCollection = collection => {
  const data = collection?.data || [];
  const transformed = data.map(transformEntity);
  return transformed;
};
