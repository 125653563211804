import { useState, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';

import { Box, Grid, Typography } from '@mui/material';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { adjustColorBrightness, hexToRgb } from '@lib/utils/color-utils';

import RandomButton from '@webapp/components/ui/buttons/random-button';
import ColorButton from '@webapp/components/ui/buttons/color-button';

import { SpeedIcon, FrequencyIcon } from '@webapp/components/icons';

import Slider from '@webapp/components/ui/sliders/slider';
import IconWithText from '@webapp/components/ui/icon-with-text';
import ColorWheel from '@webapp/components/ui/color/color-wheel';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';
import { ModuleId } from '@lib/robo/types';

const widgetConfig = {
  frequency: {
    min: 1,
    max: 10,
    step: 1,
  },

  blinks: {
    min: 1,
    max: 11,
    step: 1,
    maxAsInfinite: true,
  },
};

/**
 * The Flash Action Widget
 * @param id The widget id
 * @returns The flash widget
 */
const FlashWidget: ExecutableActionWidgetComponent<FlashWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<FlashWidgetData>(id);

  const widgetData = widget?.data;

  const { model: roboModel } = useRobo();
  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const LEDRGB = roboModel?.modules.ledRGBs[moduleId];

  const [color, setColor] = useState<string>(widget?.data.color ?? FlashWidget.initialData.color);
  const [isColorRandom, setIsColorRandom] = useState<boolean>(
    widget?.data.isColorRandom ?? FlashWidget.initialData.isColorRandom
  );
  const [frequency, setFrequency] = useState<number>(widget?.data.frequency ?? FlashWidget.initialData.frequency);

  const [blinks, setBlinks] = useState<number>(widget?.data.blinks ?? FlashWidget.initialData.blinks);
  const [isBlinksInfinite, setIsBlinksInfinite] = useState<boolean>(
    widget?.data.isBlinksInfinite ?? FlashWidget.initialData.isBlinksInfinite
  );

  /**
   * Handle the random toggle
   */
  const handleRandomToggle = () => {
    const newIsColorRandom = !isColorRandom;
    setIsColorRandom(newIsColorRandom);
    updateWidgetData<FlashWidgetData>(id, { isColorRandom: newIsColorRandom });
  };

  /**
   * Handles the frequency change
   * @param newFrequency The new frequency
   */
  const handleFrequencyChange = (newFrequency: number) => {
    setFrequency(newFrequency);
  };

  /**
   * Handle the frequency changed event. Updates the widget data
   */
  const onFrequencyChanged = (newFrequency: number) => {
    updateWidgetData<FlashWidgetData>(id, { frequency: newFrequency });
  };

  /**
   * Handles the blinks change
   * @param newBlinks The new blinks
   */
  const handleBlinksChange = (newBlinks: number) => {
    if (newBlinks >= widgetConfig.blinks.max && widgetConfig.blinks.maxAsInfinite) {
      setBlinks(newBlinks);
      setIsBlinksInfinite(true);
    } else {
      setBlinks(newBlinks);
      setIsBlinksInfinite(false);
    }
  };

  /**
   * Handle the blinks changed event. Updates the widget data
   */
  const onBlinksChanged = (newBlinks: number) => {
    updateWidgetData<FlashWidgetData>(id, { blinks: newBlinks, isBlinksInfinite: isBlinksInfinite });
  };

  /**
   * Handles the color change
   * @param newColor The new color
   */
  const handleColorChange = (newColor: string) => {
    setColor(newColor);
  };

  /**
   * Handle the color changed event. Updates the widget data
   */
  const onColorChanged = (newColor: string) => {
    updateWidgetData<FlashWidgetData>(id, { color: newColor });
  };

  /**
   * The view color - the color that is displayed on the screen
   */
  const viewColor = useMemo(() => {
    return adjustColorBrightness(color, 100);
  }, [color]);

  /**
   * The debounced set color function
   */
  const debouncedSetColor = useMemo(
    () =>
      debounce((color: string) => {
        if (LEDRGB) {
          const colorRgb = hexToRgb(color);
          LEDRGB.setColor(colorRgb.red, colorRgb.green, colorRgb.blue);
          LEDRGB.setActive(true);
          LEDRGB.execute();
        }
      }, 200),
    [LEDRGB]
  );

  /**
   * Display the color and brightness changes on the LEDRGB
   */
  useEffect(() => {
    if (LEDRGB) {
      debouncedSetColor(viewColor);
    }
  }, [LEDRGB, viewColor, debouncedSetColor]);

  /**
   * Deactivate the LEDRGB when the component unmounts
   */
  useEffect(() => {
    return () => {
      if (LEDRGB) {
        debouncedSetColor.cancel();
        LEDRGB.setActive(false);
        LEDRGB.execute();
      }
    };
  }, [LEDRGB, debouncedSetColor]);

  return (
    <Box sx={{ width: '300px', height: '290px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0}>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <RandomButton
            selected={isColorRandom}
            value={isColorRandom}
            onChange={handleRandomToggle}
            mainColor="#FEC84B"
            secondaryColor="#E9E9E9"
          >
            Random
          </RandomButton>

          {!isColorRandom && (
            <ColorButton mainColor={viewColor} sx={{ ml: 1 }}>
              Color
            </ColorButton>
          )}
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconWithText
            icon={<SpeedIcon sx={{ fontSize: '2rem' }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B" textAlign="center">
                Blinks
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center  ', alignItems: 'center' }}>
          <IconWithText
            icon={<FrequencyIcon sx={{ fontSize: '2rem' }} />}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Frequency
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          xs={8}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '218px',
          }}
        >
          <ColorWheel
            color={color}
            onChange={handleColorChange}
            onChangeCommitted={onColorChanged}
            disabled={isColorRandom}
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
          <Slider
            value={blinks}
            valueLabelDisplay="on"
            max={widgetConfig.blinks.max}
            min={widgetConfig.blinks.min}
            step={widgetConfig.blinks.step}
            maxAsInfinite={widgetConfig.blinks.maxAsInfinite}
            onChange={(_, newBlinks) => handleBlinksChange(newBlinks as number)}
            onChangeCommitted={(_, newBlinks) => onBlinksChanged(newBlinks as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="default"
          />
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 2 }}>
          <Slider
            value={frequency}
            valueLabelDisplay="on"
            max={widgetConfig.frequency.max}
            min={widgetConfig.frequency.min}
            step={widgetConfig.frequency.step}
            onChange={(_, newFrequency) => handleFrequencyChange(newFrequency as number)}
            onChangeCommitted={(_, newFrequency) => onFrequencyChanged(newFrequency as number)}
            orientation="vertical"
            sx={{ height: '100%' }}
            mainColor="#FEC84B"
            railColor="#E9E9E9"
            labelColor="#5A418B"
            appearance="default"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Executes the motor widget
 * @param signal The abort signal
 * @param roboModel The RoboModel
 * @param widgetId The widget id
 * @param getWidgetById The getWidgetById function
 * @param updateWidgetData The updateWidgetData function
 * @returns The execution result
 */
FlashWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById, updateWidgetData }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData?.moduleIds[0] as ModuleId;

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    const LED = roboModel.modules.ledRGBs[moduleId];

    if (!LED) {
      throw new Error('LED not found');
    }

    const blinks = widgetData.blinks;
    const frequency = widgetData.frequency * 1000;

    const color = adjustColorBrightness(widgetData.color, 100);
    const colorRgb = hexToRgb(color);

    LED.setLedAction(colorRgb.red, colorRgb.green, colorRgb.blue, frequency, blinks, ({ isError }) => {
      if (isError) {
        reject(new Error('Error setting shine action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    });
  });
};

// Interface for widget data
export interface FlashWidgetData {
  color: string;
  isColorRandom: boolean;
  frequency: number;
  blinks: number;
  isBlinksInfinite: boolean;
}

FlashWidget.initialData = {
  color: '#ffffff',
  isColorRandom: false,
  frequency: 1,
  blinks: 1,
  isBlinksInfinite: false,
};

export default FlashWidget;
