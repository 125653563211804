import { Box, SxProps, Typography } from '@mui/material';

import { isAsciiString } from '@lib/utils/hex';

type RestrictedTextAreaProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  maxLength: number;
  skipNonAscii?: boolean;
  skipNewline?: boolean;
  sx?: SxProps;
  inputSx?: SxProps;
  inputProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
};

export const RestrictedTextArea = ({
  value,
  onChange,
  maxLength,
  skipNonAscii = true,
  skipNewline = true,
  sx,
  inputSx,
  inputProps,
}: RestrictedTextAreaProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '20px',
        overflow: 'hidden',
        padding: '20px 0px 40px',
        backgroundColor: '#FEC84B',
        ...sx,
      }}
    >
      <Box
        component="textarea"
        aria-label="restricted text area"
        aria-multiline="true"
        value={value}
        onChange={e => {
          // skip non ascii characters
          if (skipNonAscii && !isAsciiString(e.target.value)) {
            return;
          }

          // skip newline characters
          if (skipNewline && e.target.value.includes('\n')) {
            return;
          }

          onChange(e);
        }}
        sx={{
          width: '100%',
          height: '100%',
          fontSize: '20px',
          color: '#5A418B',
          border: 'none',
          resize: 'none',
          fontWeight: 600,
          padding: '0px 20px',
          lineHeight: '26px',
          fontFamily: 'Raleway',
          backgroundColor: 'transparent',
          '&:focus': {
            outline: 'none',
          },
          ...inputSx,
        }}
        maxLength={maxLength}
        {...inputProps}
      />
      <Typography
        sx={{
          position: 'absolute',
          bottom: '10px',
          right: '20px',
          color: '#5A418B',
        }}
        variant="x-small-semibold"
      >
        {maxLength - value.length} characters left
      </Typography>
    </Box>
  );
};
