import React from 'react';
import BasicElement from './basic-element';

const SoundElement = props => {
  return (
    <BasicElement
      sx={{
        backgroundColor: '#FA612D',

        '&:hover': {
          backgroundColor: '#FF763F',
        },

        '&:active': {
          backgroundColor: '#E2541B',
        },
      }}
      {...props}
    />
  );
};

export default SoundElement;
