import { createSvgIcon } from '@mui/material';

const Icon = createSvgIcon(
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.884422 19.2594C0.371859 19.772 0.371859 20.603 0.884423 21.1156C1.39699 21.6281 2.22801 21.6281 2.74058 21.1156L11 12.8562L19.2594 21.1156C19.772 21.6281 20.603 21.6281 21.1156 21.1156C21.6281 20.603 21.6281 19.772 21.1156 19.2594L12.8562 11L21.1156 2.74058C21.6281 2.22802 21.6281 1.39699 21.1156 0.884422C20.603 0.371859 19.772 0.371859 19.2594 0.884422L11 9.14384L2.74058 0.884423C2.22801 0.37186 1.39699 0.37186 0.884422 0.884423C0.371859 1.39699 0.371859 2.22802 0.884422 2.74058L9.14384 11L0.884422 19.2594Z" />
  </svg>,

  'Close'
);

export default Icon;
