import { useState, useCallback } from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { WidgetId } from '@webapp/store/types';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';

const widgetConfig = {
  message: {
    maxLength: 256,
    placeholder: 'Hello, my name is Robo!',
  },
};

const TextareaContainer = styled(Box)(() => ({
  position: 'relative',
  width: '300px',
  height: '200px',
  backgroundColor: '#1CA543',
  borderRadius: '20px',
  padding: '20px 20px 5px 20px',
  display: 'flex',
  flexDirection: 'column',
  '&:focus-within': {
    boxShadow: '0 0 0 2px #168f3a',
  },
}));

const StyledTextarea = styled('textarea')(() => ({
  flex: '1 1 auto',
  width: '100%',
  backgroundColor: 'transparent',
  color: 'white',
  fontSize: '16px',
  border: 'none',
  resize: 'none',
  outline: 'none',
  padding: 0,
  margin: 0,
  whiteSpace: 'pre-wrap',
  overflowY: 'auto',
  lineHeight: '1.3',
  '&::placeholder': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

const CharacterCount = styled(Typography)(() => ({
  height: '24px',
  marginTop: '5px',
  textAlign: 'right',
  color: 'rgba(255, 255, 255, 0.7)',
  fontSize: '12px',
}));

const MessageWidget: ExecutableActionWidgetComponent<MessageWidgetData> = ({ id }: { id: WidgetId }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<MessageWidgetData>(id);

  const widgetData = widget?.data;

  const [message, setMessage] = useState<string>(widgetData?.message ?? MessageWidget.initialData.message);

  const maxLength = widgetConfig.message.maxLength;

  /**
   * Sanitize the input to remove any characters that are not visible alphanumeric or common punctuation
   * @param input - The input to sanitize
   * @returns The sanitized input
   */
  const sanitizeInput = (input: string): string => {
    // Remove any characters that are not visible alphanumeric or common punctuation
    return input.replace(/[^a-zA-Z0-9\s.,!?-]/g, '');
  };

  /**
   * Handle the change event for the message input
   * @param e - The change event
   */
  const handleMessageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const sanitizedInput = sanitizeInput(e.target.value);
      const truncatedInput = sanitizedInput.slice(0, maxLength);
      setMessage(truncatedInput);
      updateWidgetData(id, { message: truncatedInput });
    },
    [id, maxLength, updateWidgetData]
  );

  return (
    <TextareaContainer>
      <StyledTextarea
        value={message}
        onChange={handleMessageChange}
        placeholder={widgetConfig.message.placeholder}
        maxLength={maxLength}
      />
      <CharacterCount>{`${maxLength - message.length}/${maxLength} characters left`}</CharacterCount>
    </TextareaContainer>
  );
};

MessageWidget.execute = async ({ signal, widgetId, getWidgetById }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const message = widgetData.message ?? widgetConfig.message.placeholder;

    // Create toast element
    const toast = document.createElement('div');
    toast.style.position = 'fixed';
    toast.style.top = '110px';
    toast.style.left = '50%';
    toast.style.transform = 'translateX(-50%) translateY(-20px)';
    toast.style.backgroundColor = 'white';
    toast.style.color = 'rgba(0, 0, 0, 0.87)';
    toast.style.padding = '16px 24px';
    toast.style.borderRadius = '20px';
    toast.style.boxShadow =
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)';
    toast.style.zIndex = '1300';
    toast.style.minWidth = '250px';
    toast.style.maxWidth = '80%';
    toast.style.textAlign = 'center';
    toast.style.fontSize = '16px';
    toast.style.opacity = '0';
    toast.style.transition = 'all 0.3s ease-out';

    // Replace new lines with <br> tags
    toast.innerHTML = message.replace(/\n/g, '<br>');

    // Add toast to the body
    document.body.appendChild(toast);

    // Trigger entrance animation
    setTimeout(() => {
      toast.style.opacity = '1';
      toast.style.transform = 'translateX(-50%) translateY(0)';
    }, 10);

    // Remove toast after 3 seconds
    const timeoutId = setTimeout(() => {
      // Trigger exit animation
      toast.style.opacity = '0';
      toast.style.transform = 'translateX(-50%) translateY(-20px)';

      // Remove element after animation completes
      setTimeout(() => {
        if (document.body.contains(toast)) {
          document.body.removeChild(toast);
        }
        // Resolve after removing the toast
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }, 300);
    }, 3000);

    // Set up signal to remove toast if aborted
    signal.addEventListener('abort', () => {
      clearTimeout(timeoutId);
      if (document.body.contains(toast)) {
        toast.style.opacity = '0';
        toast.style.transform = 'translateX(-50%) translateY(-20px)';
        setTimeout(() => {
          if (document.body.contains(toast)) {
            document.body.removeChild(toast);
          }
        }, 300);
      }
      reject(new Error('Message action aborted'));
    });
  });
};

// Interface for widget data
export interface MessageWidgetData {
  message: string;
}

MessageWidget.initialData = {
  message: '',
};

export default MessageWidget;
