import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.9796 41.8931C25.6037 41.8931 29.8784 40.3992 33.346 37.8685L42.9346 47.4427C43.6787 48.1856 44.885 48.1856 45.629 47.4427L47.4368 45.6376C48.1808 44.8947 48.1808 43.6902 47.4368 42.9473L37.8602 33.3851C40.4353 29.9067 41.9578 25.6041 41.9578 20.9465C41.9578 9.37802 32.5655 -9.15527e-05 20.9796 -9.15527e-05C9.39374 -9.15527e-05 0.00152588 9.37802 0.00152588 20.9465C0.00152588 32.515 9.39374 41.8931 20.9796 41.8931ZM18.3333 18.3132H11.8691V23.5798H18.3333V29.949H23.5856V23.5798H30.0901V18.3132H23.5856V11.8696H18.3333V18.3132Z"
          fill="#5A418B"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
