import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { CodeEditorState, EditorType } from '@webapp/store/types';
import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';
import { addEntry } from '@webapp/store/slices/live/history.slice';
import { addWidget, removeWidget, updateGroup } from '@webapp/store/slices/code/editor.slice';
import { AppDispatch, RootState } from '@store/configureStore';
import { selectIsTutorialActive } from '@store/slices/tutorial.slice';

type handlerFunctionType = (
  store: MiddlewareAPI<AppDispatch, RootState>,
  next: Dispatch<AnyAction>,
  action: any
) => any;

const handleAddWidget: handlerFunctionType = (store, next, action) => {
  const result = next(action);

  const { dispatch, getState } = store;
  const applicationState = getState();
  const editorState: CodeEditorState = getState().webapp.code.editor;

  const widgetId = action.payload.id;
  const widget = editorState.widgets[widgetId];
  const element = elementsSelectors.selectById(applicationState, widget.data.elementId);
  if (element) {
    dispatch(
      addEntry({
        action: `add:widget:${element.type}`,
        scope: EditorType.Code,
      })
    );
  }

  return result;
};

const handleRemoveWidget: handlerFunctionType = (store, next, action) => {
  const { dispatch, getState } = store;
  const applicationState = getState();
  const editorState: CodeEditorState = getState().webapp.code.editor;

  const widgetId = action.payload;
  const widget = editorState.widgets[widgetId];
  const element = elementsSelectors.selectById(applicationState, widget.data.elementId);
  if (element) {
    dispatch(
      addEntry({
        action: `remove:widget:${element.type}`,
        scope: EditorType.Code,
      })
    );
  }

  return next(action);
};

const handleUpdateGroup: handlerFunctionType = (store, next, action) => {
  const { dispatch, getState } = store;

  const groupId = action.payload.id;
  const groupBefore = (getState().webapp.code.editor as CodeEditorState).groups[groupId];
  const result = next(action);
  const groupAfter = (getState().webapp.code.editor as CodeEditorState).groups[groupId];

  if (groupBefore && groupAfter && groupAfter.edgeIds.length > groupBefore.edgeIds.length) {
    dispatch(
      addEntry({
        action: 'connect:groups',
        scope: EditorType.Code,
      })
    );
  }

  return result;
};

export const historyActionsMiddleware: Middleware<Record<string, never>, RootState, AppDispatch> =
  store => next => action => {
    const isTutorialActive = selectIsTutorialActive(store.getState().tutorial);

    if (isTutorialActive) {
      switch (action.type) {
        case addWidget.type:
          return handleAddWidget(store, next, action);
        case removeWidget.type:
          return handleRemoveWidget(store, next, action);
        case updateGroup.type:
          return handleUpdateGroup(store, next, action);
      }
    }

    return next(action);
  };
