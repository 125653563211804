import { RootState, useDispatch, useSelector } from '@store/configureStore';

import { setId, setName, connect, disconnect, BridgeState } from '@webapp/store/slices/devices/bridge.slice';

export const useBridge = () => {
  const dispatch = useDispatch();
  const bridge = useSelector((state: RootState) => state.webapp.devices.bridge as BridgeState);

  const connectToBridge = () => dispatch(connect());
  const disconnectFromBridge = () => dispatch(disconnect());
  const setBridgeId = (id: string) => dispatch(setId(id));
  const setBridgeName = (name: string) => dispatch(setName(name));

  return {
    ...bridge,
    connect: connectToBridge,
    disconnect: disconnectFromBridge,
    setId: setBridgeId,
    setName: setBridgeName,
  };
};
