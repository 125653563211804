import { useContext } from 'react';

import { EditorContext } from '@webapp/components/hoc/with-editor';
import StyledIconButton from '@webapp/components/blocks/component/styled-icon-button';
import { Typography } from '@mui/material';
import { colors as themeColors } from '@themes/config/theme-colors';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';

import IconLoader from '@webapp/components/icon-loader/icon-loader';

import { useSelector } from '@store/configureStore';
import { elementsSelectors } from '@webapp/store/slices/live/elements.slice';
import SoundsMap from './sounds-map';

const LiveSoundWidget = ({ counter, data }) => {
  const { isPlaying } = useContext(EditorContext);
  const { model: roboModel } = useRobo();
  const { addHistoryEntry } = useActionsHistory();

  const elementEntity = useSelector(state => elementsSelectors.selectElementById(state, data.elementId));

  const moduleId = data.moduleIds[0];
  const SYSTEM = roboModel?.systems[moduleId];
  const Icon = <IconLoader name={elementEntity.icon} />;

  const handleClick = () => {
    if (!isPlaying) {
      return;
    }

    addHistoryEntry({
      action: `interact:widget:${elementEntity.type}`,
      scope: 'live',
    });

    SYSTEM?.playSound(SoundsMap[elementEntity.type]);
  };

  return (
    <StyledIconButton color="#FA612D" width="100%" height="100%" onClick={handleClick} size="large">
      {Icon}
      <Typography variant="x-tiny-bold" color={themeColors.white['500']}>
        {elementEntity.name}
      </Typography>
    </StyledIconButton>
  );
};

LiveSoundWidget.initialProps = {
  width: 1,
  height: 1,
};

LiveSoundWidget.initialData = {};

export default LiveSoundWidget;
