import { CodeMotorAlignment, CodeMotorId, CodeMotorConfig } from '@webapp/store/types';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

// Default motor configuration
export const defaultMotorsConfig: Record<CodeMotorId, CodeMotorConfig> = {
  MOTOR_1: {
    motorId: 'MOTOR_1',
    alignment: CodeMotorAlignment.Right,
  },
  MOTOR_2: {
    motorId: 'MOTOR_2',
    alignment: CodeMotorAlignment.Left,
  },
  MOTOR_3: {
    motorId: 'MOTOR_3',
    alignment: CodeMotorAlignment.Right,
  },
  MOTOR_4: {
    motorId: 'MOTOR_4',
    alignment: CodeMotorAlignment.Left,
  },
  MOTOR_5: {
    motorId: 'MOTOR_5',
    alignment: CodeMotorAlignment.Right,
  },
  MOTOR_6: {
    motorId: 'MOTOR_6',
    alignment: CodeMotorAlignment.Left,
  },
};

export const useSharedMotorsConfig = (): Record<CodeMotorId, CodeMotorConfig> => {
  const { getMotorsConfig } = useCodeEditor();

  const stateMotorsConfig = getMotorsConfig();

  return getSharedMotorsConfig(stateMotorsConfig);
};

// in case we need dynamic motors alignment - this is good point to start
export const getSharedMotorsConfig = (
  stateMotorsConfig: Record<CodeMotorId, CodeMotorConfig>
): Record<CodeMotorId, CodeMotorConfig> => {
  if (Object.keys(stateMotorsConfig).length === 0) {
    // If the state is empty, return the default config
    return defaultMotorsConfig;
  }

  return stateMotorsConfig;
};
