import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#034FCC';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8887 17.3328L23.222 20.6662V13.9995L29.8887 17.3328Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1112 23.9998L18.7778 20.6665V27.3332L12.1112 23.9998Z"
          fill={color}
        />
        <rect
          x="25.4443"
          y="16.2217"
          width="2.22222"
          height="11.1111"
          transform="rotate(90 25.4443 16.2217)"
          fill={color}
        />
        <rect
          x="27.6665"
          y="22.8887"
          width="2.22222"
          height="11.1111"
          transform="rotate(90 27.6665 22.8887)"
          fill={color}
        />
        <path
          d="M14.333 17.333C12.4921 17.333 10.9997 18.8254 10.9997 20.6663V20.6663"
          stroke={color}
          strokeWidth="2.22222"
        />
        <path
          d="M27.6665 23.9995C29.5075 23.9995 30.9998 22.5071 30.9998 20.6662V20.6662"
          stroke={color}
          strokeWidth="2.22222"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
