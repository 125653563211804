import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 14C5.61553 14 4.26215 13.5895 3.11101 12.8203C1.95987 12.0511 1.06266 10.9579 0.532846 9.67879C0.00303297 8.3997 -0.13559 6.99224 0.134506 5.63437C0.404603 4.2765 1.07129 3.02922 2.05026 2.05026C3.02922 1.07129 4.2765 0.404603 5.63437 0.134506C6.99224 -0.13559 8.3997 0.00303297 9.67879 0.532846C10.9579 1.06266 12.0511 1.95987 12.8203 3.11101C13.5895 4.26215 14 5.61553 14 7C13.9979 8.85588 13.2597 10.6351 11.9474 11.9474C10.6351 13.2597 8.85588 13.9979 7 14ZM7 1.75C5.96165 1.75 4.94662 2.05791 4.08326 2.63479C3.2199 3.21166 2.547 4.0316 2.14964 4.99091C1.75228 5.95023 1.64831 7.00583 1.85088 8.02423C2.05345 9.04263 2.55347 9.97809 3.28769 10.7123C4.02192 11.4465 4.95738 11.9466 5.97578 12.1491C6.99418 12.3517 8.04978 12.2477 9.00909 11.8504C9.9684 11.453 10.7883 10.7801 11.3652 9.91675C11.9421 9.05339 12.25 8.03835 12.25 7C12.2486 5.60804 11.695 4.27349 10.7108 3.28923C9.72651 2.30496 8.39196 1.75139 7 1.75Z" />
        <path d="M6.125 7C6.125 6.76794 6.21719 6.54538 6.38128 6.38128C6.54538 6.21719 6.76794 6.125 7 6.125L10.5 6.125C10.7321 6.125 10.9546 6.21719 11.1187 6.38128C11.2828 6.54538 11.375 6.76794 11.375 7C11.375 7.23206 11.2828 7.45462 11.1187 7.61872C10.9546 7.78281 10.7321 7.875 10.5 7.875L7 7.875C6.76794 7.875 6.54538 7.78281 6.38128 7.61872C6.21719 7.45462 6.125 7.23206 6.125 7Z" />
        <path d="M7.875 3.5V7C7.875 7.23206 7.78281 7.45462 7.61872 7.61872C7.45462 7.78281 7.23206 7.875 7 7.875C6.76794 7.875 6.54538 7.78281 6.38128 7.61872C6.21719 7.45462 6.125 7.23206 6.125 7V3.5C6.125 3.26794 6.21719 3.04538 6.38128 2.88128C6.54538 2.71719 6.76794 2.625 7 2.625C7.23206 2.625 7.45462 2.71719 7.61872 2.88128C7.78281 3.04538 7.875 3.26794 7.875 3.5Z" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
