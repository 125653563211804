import { createRoot } from 'react-dom/client';

import { kebabToPascalCase } from '@lib/utils/string-utils';

import TriggerElementTemplate from '@webapp/components/blocks/element-templates/trigger-element';
import * as availableTemplates from '@webapp/components/blocks/element-templates';

import { ElementEntity } from '@webapp/store/types';

import IconLoader from '@webapp/components/icon-loader/icon-loader';

import config from '../config';

/**
 * This function is triggered when an element is clicked.
 */
export const onElementClick = (event: MouseEvent, toolboxElement: ElementEntity) => {
  // console.log('onElementClick', { event, toolboxElement });
};

export const onElementDragStart = (event: DragEvent, toolboxElement: ElementEntity) => {
  if (!event.dataTransfer) {
    return;
  }

  event.dataTransfer.setData('application/reactflow', toolboxElement.id);
  event.dataTransfer.effectAllowed = 'copy';

  // todo: Find a better way to determine if the element is a trigger
  const isTrigger = toolboxElement.sectionId === 'code-sensing-section';

  // Dynamically create a preview element
  const dragPreview = document.createElement('div');

  // Set the preview element styles
  if (isTrigger) {
    dragPreview.style.width = `${config.triggerWidth}px`; // Set the width of the preview
    dragPreview.style.height = `${config.triggerHeight}px`; // Set the height of the preview
  } else {
    dragPreview.style.width = `${config.actionSize}px`; // Set the width of the preview (actions have a fixed size)
    dragPreview.style.height = `${config.actionSize}px`; // Set the height of the preview (actions have a fixed size)
  }

  dragPreview.style.position = 'absolute'; // Position absolutely
  dragPreview.style.top = '-1000px'; // Position out of the viewport to avoid flickering
  dragPreview.style.pointerEvents = 'none'; // Disable pointer events
  dragPreview.style.zIndex = '10000'; // Set a high z-index

  // Append to body to ensure it's in the DOM
  document.body.appendChild(dragPreview);

  // Create a root for the temporary node
  const root = createRoot(dragPreview);

  const IconComponent = <IconLoader name={toolboxElement.icon!} />;

  if (isTrigger) {
    // Render the React component into the div
    root.render(
      <TriggerElementTemplate
        width={config.triggerWidth}
        height={config.triggerHeight}
        icon={IconComponent}
        badge={toolboxElement.badge}
        iconProps={{
          sx: {
            transformOrigin: 'center center',
            height: ` ${config.triggerHeight}px`,
            width: `${config.triggerHeight}px`,
            marginLeft: '30px',
          },
        }}
      />
    );
  } else {
    const componentName = kebabToPascalCase(toolboxElement.template);
    const Component = availableTemplates[componentName];
    // Render the React component into the div
    root.render(
      <Component
        width={`${config.actionSize}px`}
        height={`${config.actionSize}px`}
        icon={IconComponent}
        badge={toolboxElement.badge}
      />
    );
  }

  // Using requestAnimationFrame to ensure the image is rendered
  event.dataTransfer.setDragImage(dragPreview, -50, -40);

  // Clean up: remove the drag preview element from the DOM after the drag starts
  setTimeout(() => {
    root.unmount();
    document.body.removeChild(dragPreview);
  }, 0);
};

/**
 * This function is triggered when an action element is dropped.
 * @param {string} elementId - The id of the element being dropped.
 */
export const onElementDragEnd = (event: DragEvent, toolboxElement: ElementEntity) => {
  // console.log('onElementDragEndx', { event, toolboxElement });
};

/**
 * This function is triggered when an action element is dragging.
 * @param {string} elementId - The id of the element being dragged.
 */
export const onElementDrag = (event: DragEvent, toolboxElement: ElementEntity) => {
  // console.log('onElementDrag', { event, toolboxElement });
};
