import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_FILE = gql`
  fragment FILE on UploadFileEntityResponse {
    data {
      attributes {
        name
        caption
        alternativeText
        url
        previewUrl
        formats
        mime
      }
    }
  }
`;

export const WITH_FILES = gql`
  fragment FILES on UploadFileRelationResponseCollection {
    data {
      attributes {
        name
        caption
        alternativeText
        url
        previewUrl
        formats
        mime
      }
    }
  }
`;
