import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#DF1642';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 7.57735C20.6188 7.22008 21.3812 7.22008 22 7.57735L32.1244 13.4226C32.7432 13.7799 33.1244 14.4402 33.1244 15.1547V26.8453C33.1244 27.5598 32.7432 28.2201 32.1244 28.5774L22 34.4226C21.3812 34.7799 20.6188 34.7799 20 34.4226L9.87564 28.5774C9.25684 28.2201 8.87564 27.5598 8.87564 26.8453V15.1547C8.87564 14.4402 9.25684 13.7799 9.87564 13.4226L20 7.57735Z"
          stroke={color}
          strokeWidth="2"
        />

        <path d="M9.3335 14L21.0002 19.8333" stroke={color} strokeWidth="2" strokeLinecap="round" />

        <path d="M21 19.8345L32.6662 14.0001" stroke={color} strokeWidth="2" strokeLinecap="round" />

        <path d="M21 19.8335L21 33.8335" stroke={color} strokeWidth="2" strokeLinecap="round" />

        <path
          d="M15.5 23.5C15.5 23.8293 15.4101 24.1048 15.2911 24.2833C15.1697 24.4654 15.057 24.5 15 24.5C14.943 24.5 14.8303 24.4654 14.7089 24.2833C14.5899 24.1048 14.5 23.8293 14.5 23.5C14.5 23.1707 14.5899 22.8952 14.7089 22.7167C14.8303 22.5346 14.943 22.5 15 22.5C15.057 22.5 15.1697 22.5346 15.2911 22.7167C15.4101 22.8952 15.5 23.1707 15.5 23.5Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M22.5 15C22.5 15.057 22.4654 15.1697 22.2833 15.2911C22.1048 15.4101 21.8293 15.5 21.5 15.5C21.1707 15.5 20.8952 15.4101 20.7167 15.2911C20.5346 15.1697 20.5 15.057 20.5 15C20.5 14.943 20.5346 14.8303 20.7167 14.7089C20.8952 14.5899 21.1707 14.5 21.5 14.5C21.8293 14.5 22.1048 14.5899 22.2833 14.7089C22.4654 14.8303 22.5 14.943 22.5 15Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M26.5 13C26.5 13.057 26.4654 13.1697 26.2833 13.2911C26.1048 13.4101 25.8293 13.5 25.5 13.5C25.1707 13.5 24.8952 13.4101 24.7167 13.2911C24.5346 13.1697 24.5 13.057 24.5 13C24.5 12.943 24.5346 12.8303 24.7167 12.7089C24.8952 12.5899 25.1707 12.5 25.5 12.5C25.8293 12.5 26.1048 12.5899 26.2833 12.7089C26.4654 12.8303 26.5 12.943 26.5 13Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M18.5 13C18.5 13.057 18.4654 13.1697 18.2833 13.2911C18.1048 13.4101 17.8293 13.5 17.5 13.5C17.1707 13.5 16.8952 13.4101 16.7167 13.2911C16.5346 13.1697 16.5 13.057 16.5 13C16.5 12.943 16.5346 12.8303 16.7167 12.7089C16.8952 12.5899 17.1707 12.5 17.5 12.5C17.8293 12.5 18.1048 12.5899 18.2833 12.7089C18.4654 12.8303 18.5 12.943 18.5 13Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M22.5 11C22.5 11.057 22.4654 11.1697 22.2833 11.2911C22.1048 11.4101 21.8293 11.5 21.5 11.5C21.1707 11.5 20.8952 11.4101 20.7167 11.2911C20.5346 11.1697 20.5 11.057 20.5 11C20.5 10.943 20.5346 10.8303 20.7167 10.7089C20.8952 10.5899 21.1707 10.5 21.5 10.5C21.8293 10.5 22.1048 10.5899 22.2833 10.7089C22.4654 10.8303 22.5 10.943 22.5 11Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M25.5 27.5C25.5 27.8293 25.4101 28.1048 25.2911 28.2833C25.1697 28.4654 25.057 28.5 25 28.5C24.943 28.5 24.8303 28.4654 24.7089 28.2833C24.5899 28.1048 24.5 27.8293 24.5 27.5C24.5 27.1707 24.5899 26.8952 24.7089 26.7167C24.8303 26.5346 24.943 26.5 25 26.5C25.057 26.5 25.1697 26.5346 25.2911 26.7167C25.4101 26.8952 25.5 27.1707 25.5 27.5Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M27.5 23.5C27.5 23.8293 27.4101 24.1048 27.2911 24.2833C27.1697 24.4654 27.057 24.5 27 24.5C26.943 24.5 26.8303 24.4654 26.7089 24.2833C26.5899 24.1048 26.5 23.8293 26.5 23.5C26.5 23.1707 26.5899 22.8952 26.7089 22.7167C26.8303 22.5346 26.943 22.5 27 22.5C27.057 22.5 27.1697 22.5346 27.2911 22.7167C27.4101 22.8952 27.5 23.1707 27.5 23.5Z"
          fill="none"
          stroke={color}
        />
        <path
          d="M29.5 20.5C29.5 20.8293 29.4101 21.1048 29.2911 21.2833C29.1697 21.4654 29.057 21.5 29 21.5C28.943 21.5 28.8303 21.4654 28.7089 21.2833C28.5899 21.1048 28.5 20.8293 28.5 20.5C28.5 20.1707 28.5899 19.8952 28.7089 19.7167C28.8303 19.5346 28.943 19.5 29 19.5C29.057 19.5 29.1697 19.5346 29.2911 19.7167C29.4101 19.8952 29.5 20.1707 29.5 20.5Z"
          fill="none"
          stroke={color}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
