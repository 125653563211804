import { Toolbar, Grid, Box, AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useBridge } from '@webapp/hooks/use-bridge-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';

import Toolbox from '@webapp/components/toolbox';
import NameProject from '@webapp/components/project-actions/name-project';

import { PlayStopButton, BUTTON_TYPES } from './play-stop-button';
import { useEditor } from '@webapp/hooks/use-editor-hook';

const BottomNavBar = styled(AppBar)(() => ({
  margin: 'auto',
  boxShadow: 'none',
  position: 'fixed',
  backgroundColor: 'transparent',
  borderRadius: 'unset',
  top: 'auto',
  bottom: 0,
  width: '100%',
}));

const BottomBar = ({ editorType, handlePlayClick }) => {
  const { connected: isBridgeConnected } = useBridge();
  const { connected: isRoboConnected, store } = useRobo();

  const systemStore = store.model.systems?.['SYSTEM_1'];
  const batteryStatus = systemStore?.batteryStatus ?? null;

  const { isPlaying, playButtonIsBlocked } = useEditor(editorType);

  const hasActiveConnection = isBridgeConnected && isRoboConnected;
  const isCharging = batteryStatus === 'charging' || batteryStatus === 'charged';

  return (
    <BottomNavBar className="toolbox-bar">
      <Toolbar>
        <Grid container spacing={0}>
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginTop: '-40px',
            }}
          >
            {!isPlaying && <NameProject />}
          </Grid>

          <Grid item xs={7} sx={{ position: 'relative' }}>
            {!isPlaying && <Toolbox editor={editorType} />}
          </Grid>

          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <PlayStopButton
              type={isPlaying ? BUTTON_TYPES.stop : BUTTON_TYPES.play}
              // TODO: add feature flag for play during charging
              disabled={!hasActiveConnection || playButtonIsBlocked || isCharging}
              tooltip={isCharging ? 'Play mode is disabled during charge' : null}
              onClick={handlePlayClick}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </BottomNavBar>
  );
};

export default BottomBar;
