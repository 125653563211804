import { useCallback, useRef, useState, useEffect } from 'react';

import { anglesDifference } from '../utils/math';

import { TrackProps, SliderGeometry } from '../types';

interface SliderInteractionProps {
  tracks: TrackProps[];
  svgRef: React.RefObject<SVGSVGElement>;
  sliderGeometry: SliderGeometry;
  onChange?: (values: { [trackId: string]: number[] }) => void;
  onChangeStart?: (trackId: string, handleId: string, value: number) => void;
  onChangeEnd?: (trackId: string, handleId: string, value: number) => void;
  onChangeCommitted?: (values: { [trackId: string]: number[] }) => void;
  disabled: boolean;
  readOnly: boolean;
}

interface InteractionState {
  isInteracting: boolean;
  activeTrackId: string | null;
  activeHandleId: string | null;
}

export const useSliderInteraction = ({
  tracks,
  sliderGeometry,
  svgRef,
  onChange,
  onChangeStart,
  onChangeEnd,
  onChangeCommitted,
  disabled,
  readOnly,
}: SliderInteractionProps) => {
  const [interactionState, setInteractionState] = useState<InteractionState>({
    isInteracting: false,
    activeTrackId: null,
    activeHandleId: null,
  });

  const valuesRef = useRef<{ [trackId: string]: number[] }>({});

  // Update valuesRef when tracks change
  useEffect(() => {
    valuesRef.current = tracks.reduce(
      (acc, track) => {
        if (track.handles) {
          acc[track.id] = track.handles.map(h => h.value);
        }
        return acc;
      },
      {} as { [trackId: string]: number[] }
    );
  }, [tracks]);

  /**
   * Get the SVG point from the mouse or touch event
   */
  const getSVGPoint = useCallback(
    (clientX: number, clientY: number) => {
      if (!svgRef.current) return null;

      const svg = svgRef.current;
      const point = svg.createSVGPoint();
      point.x = clientX;
      point.y = clientY;
      return point.matrixTransform(svg.getScreenCTM()?.inverse());
    },
    [svgRef]
  );

  /**
   * Update the value of the handle
   */
  const updateValue = useCallback(
    (trackId: string, handleId: string, newAngle: number) => {
      const track = tracks.find(t => t.id === trackId);
      if (!track || !track.handles) return;

      const handleIndex = track.handles?.findIndex(h => h.id === handleId) ?? -1;
      if (handleIndex === -1) return;

      const newValue = sliderGeometry.getValueByAngle(trackId, newAngle);

      valuesRef.current[trackId] = [...track.handles.map(h => h.value)];
      valuesRef.current[trackId][handleIndex] = newValue;

      onChange?.(valuesRef.current);
    },
    [tracks, sliderGeometry, onChange]
  );

  const calculateNewAngle = useCallback(
    (trackId: string, handleId: string, clientX: number, clientY: number) => {
      const track = tracks.find(t => t.id === trackId);
      if (!track) return null;

      const { center, radius } = sliderGeometry;
      const svgPoint = getSVGPoint(clientX, clientY);
      if (!svgPoint) return null;

      // Find the correct handle and its current value
      const handleIndex = track.handles?.findIndex(h => h.id === handleId) ?? -1;
      if (handleIndex === -1) return null;

      const currentValue = valuesRef.current[trackId]?.[handleIndex] ?? track.handles?.[handleIndex].value ?? track.min;

      const currentAngle = sliderGeometry.getAngleByValue(trackId, currentValue);

      // Calculate the vector from center to current position
      const currentRadians = (currentAngle * Math.PI) / 180;
      const currentX = center.x + radius * Math.sin(currentRadians);
      const currentY = center.y - radius * Math.cos(currentRadians);

      // Calculate the vector from current position to new position
      const dx = svgPoint.x - currentX;
      const dy = svgPoint.y - currentY;

      // Calculate the tangent vector at the current point
      const tangentX = Math.cos(currentRadians);
      const tangentY = Math.sin(currentRadians);

      // Project the movement onto the tangent vector
      const projection = dx * tangentX + dy * tangentY;

      // Convert the projection to an angle
      let deltaAngle = (projection / radius) * (180 / Math.PI);

      // Limit the maximum delta angle (e.g., to 5 degrees)
      let maxDeltaAngle = 5;

      // If the track has a step, calculate the maximum delta angle based on the step
      if (track.step && track.step > 1) {
        const stepAngle = anglesDifference(track.startAngle, track.endAngle) / (track.max - track.min);

        maxDeltaAngle = stepAngle * track.step;
      }

      deltaAngle = Math.max(-maxDeltaAngle, Math.min(maxDeltaAngle, deltaAngle));

      // Calculate the new angle by adding delta to current angle
      const newAngle = currentAngle + deltaAngle;

      // Handle allowCrossing constraint
      if (track.allowCrossing === false && track.handles && track.handles.length > 1) {
        const sortedHandles = [...track.handles].sort((a, b) => a.value - b.value);
        const currentHandleIndex = sortedHandles.findIndex(h => h.id === handleId);
        const currentValueWithNewAngle = sliderGeometry.getValueByAngle(trackId, newAngle);

        if (currentHandleIndex > 0) {
          const previousHandleValue = sortedHandles[currentHandleIndex - 1].value;

          if (currentValueWithNewAngle < previousHandleValue) {
            return currentAngle;
          }
        }

        if (currentHandleIndex < sortedHandles.length - 1) {
          const nextHandleValue = sortedHandles[currentHandleIndex + 1].value;

          if (currentValueWithNewAngle > nextHandleValue) {
            return currentAngle;
          }
        }
      }

      return newAngle;
    },
    [tracks, sliderGeometry, getSVGPoint]
  );

  /**
   * Handle the mouse or touch start event
   */
  const handleInteractionStart = useCallback(
    (event: React.MouseEvent<SVGElement> | React.TouchEvent<SVGElement>, trackId: string, handleId: string) => {
      if (disabled || readOnly) return;
      event.preventDefault();

      const track = tracks.find(t => t.id === trackId);
      if (!track) return;

      const handle = track.handles?.find(h => h.id === handleId);
      if (!handle) return;

      setInteractionState({ isInteracting: true, activeTrackId: trackId, activeHandleId: handleId });

      onChangeStart?.(trackId, handleId, handle.value);

      // Ensure valuesRef is initialized for this track
      if (!valuesRef.current[trackId]) {
        valuesRef.current[trackId] = track.handles?.map(h => h.value) || [];
      }
    },
    [disabled, readOnly, tracks, onChangeStart]
  );

  /**
   * Handle the mouse or touch move event
   */
  const handleInteractionMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!interactionState.isInteracting) return;

      const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;

      if (interactionState.activeTrackId && interactionState.activeHandleId) {
        const newAngle = calculateNewAngle(
          interactionState.activeTrackId,
          interactionState.activeHandleId,
          clientX,
          clientY
        );

        if (newAngle !== null) {
          updateValue(interactionState.activeTrackId, interactionState.activeHandleId, newAngle);
        }
      }
    },
    [interactionState, calculateNewAngle, updateValue]
  );

  /**
   * Handle the mouse or touch end event
   */
  const handleInteractionEnd = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!interactionState.isInteracting) return;

      const { activeTrackId, activeHandleId } = interactionState;

      if (activeTrackId && activeHandleId) {
        const finalValue =
          valuesRef.current[activeTrackId]?.[
            tracks.find(t => t.id === activeTrackId)?.handles?.findIndex(h => h.id === activeHandleId) ?? -1
          ];

        onChangeEnd?.(activeTrackId, activeHandleId, finalValue);
        onChangeCommitted?.(valuesRef.current);
      }

      setInteractionState({ isInteracting: false, activeTrackId: null, activeHandleId: null });
    },
    [interactionState, tracks, onChangeEnd, onChangeCommitted]
  );

  // Set up event listeners
  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;

    const handleMouseMove = (e: MouseEvent) => handleInteractionMove(e);
    const handleTouchMove = (e: TouchEvent) => handleInteractionMove(e);
    const handleMouseUp = (e: MouseEvent) => handleInteractionEnd(e);
    const handleTouchEnd = (e: TouchEvent) => handleInteractionEnd(e);
    const handleMouseLeave = (e: MouseEvent) => handleInteractionEnd(e);

    document.addEventListener('mousemove', handleMouseMove, { passive: true });
    document.addEventListener('touchmove', handleTouchMove, { passive: true });
    document.addEventListener('mouseup', handleMouseUp, { passive: true });
    document.addEventListener('mouseleave', handleMouseLeave, { passive: true });
    document.addEventListener('touchend', handleTouchEnd, { passive: true });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleInteractionMove, handleInteractionEnd, svgRef]);

  /**
   * Return the interaction state
   */
  return {
    handleInteractionStart,
    isInteracting: interactionState.isInteracting,
    activeTrackId: interactionState.activeTrackId,
    activeHandleId: interactionState.activeHandleId,
  };
};
