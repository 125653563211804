export const weights = {
  black: { fontWeight: '900' },
  bold: { fontWeight: '700' },
  semibold: { fontWeight: '600' },
  regular: { fontWeight: '400' },
};

export const sizes = {
  basic: { fontSize: '1rem', lineHeight: '1.5rem' },
  xlarge: { fontSize: '8.75rem', lineHeight: '9rem' }, // 140px/144px
  large: { fontSize: '7rem', lineHeight: '7.5rem' }, // 112px/120px
  medium: { fontSize: '6rem', lineHeight: '7.5rem' }, // 96px/120px
  hero: { fontSize: '5rem', lineHeight: '6rem' }, // 80px/96px
  headline1: { fontSize: '3rem', lineHeight: '3.75rem' }, // 48px/60px
  headline2: { fontSize: '2.25rem', lineHeight: '3.25rem' }, // 36px/52px
  headline3: { fontSize: '2rem', lineHeight: '3rem' }, // 32px/48px
  headline4: { fontSize: '1.625rem', lineHeight: '2.5rem' }, // 26px/40px
  headline5: { fontSize: '1.25rem', lineHeight: '2rem' }, // 20px/32px
  lead: { fontSize: '1.5rem', lineHeight: '2.25rem' }, // 24px/36px
  body: { fontSize: '1.125rem', lineHeight: '1.5rem' }, // 18px/24px
  small: { fontSize: '1rem', lineHeight: '1.5rem' }, // 16px/24px
  xsmall: { fontSize: '0.75rem', lineHeight: '1rem' }, // 12px/16px
  tiny: { fontSize: '0.625rem', lineHeight: '1rem' }, // 10px/16px
  input1: { fontSize: '1.125rem', lineHeight: '1.75rem' }, // 18px/28px
  input2: { fontSize: '1rem', lineHeight: '1.5rem' }, // 16px/24px
  button1: { fontSize: '1.25rem', lineHeight: '2rem' }, // 20px/32px
  button2: { fontSize: '1rem', lineHeight: '1.75rem' }, // 16px/28px
  button3: { fontSize: '0.875rem', lineHeight: '1.25rem' }, // 14px/20px
};

// combine weights and sizes into one object
export const prepareExtraVariants = () => {
  const extraVariants = {};
  Object.keys(sizes).forEach(size => {
    Object.keys(weights).forEach(weight => {
      extraVariants[`x-${size}-${weight}`] = {
        ...weights[weight],
        ...sizes[size],
        fontFamily,
      };
    });
  });
  return extraVariants;
};

export const fontFamily = ['Raleway', 'sans-serif'].join(',');

export const overrides = {
  font: {
    fontFamily,
    fontWeightBlack: { ...weights.black },
    fontWeightBold: { ...weights.bold },
    fontWeightMedium: { ...weights.semibold },
    fontWeightRegular: { ...weights.regular },
    fontWeightLight: { ...weights.regular },
  },

  elements: {
    body1: { ...sizes.basic, ...weights.regular },
    body2: { ...sizes.small, ...weights.regular },

    button: { ...sizes.button2, ...weights.regular },

    h1: { ...sizes.hero, ...weights.black },
    h2: { ...sizes.headline1, ...weights.black },
    h3: { ...sizes.headline2, ...weights.bold },
    h4: { ...sizes.headline3, ...weights.bold },
    h5: { ...sizes.headline4, ...weights.bold },
    h6: { ...sizes.headline5, ...weights.bold },
  },

  extra: prepareExtraVariants(),
};

export const typography = {
  heading: {
    l: {
      fontWeight: 800,
      fontSize: '36px',
      lineHeight: '44px',
    },
    m: {
      fontWeight: 800,
      fontSize: '32px',
      lineHeight: '40px',
    },
    xs: {
      fontWeight: 800,
      fontSize: '24px',
      lineHeight: '32px',
    },
    xxs: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
    },
    xxs_regular: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '24px',
    },
  },

  paragraph: {
    xl: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '36px',
    },
    l: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
    },
    m: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '28px',
    },
    m_regular: {
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '28px',
    },
    s: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    s_link: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      textDecoration: 'underline',
    },
    s_regular: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    xs: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
    },
    xs_link: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      textDecoration: 'underline',
    },
    xs_regular: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    xxs: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
    },
  },
};
