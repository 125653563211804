import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';

import { Typography } from '@mui/material';

import { MOTOR_PLACEMENT, ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';
import { colors as themeColors } from '@themes/config/theme-colors';
import theme from '@themes/general.theme';
import MotorPlacementToggleButton from './motor-components/motor-placement-toggle-button';
import StyledBox from '@webapp/components/blocks/component/styled-box';
import StyledSlider from '@webapp/components/blocks/component/styled-slider';

import { EditorContext, useGetEditorType } from '@webapp/components/hoc/with-editor';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';
import { LiveWidgetProps } from '@webapp/types/types';
import { ModuleId } from '@lib/robo/types';
import { EditorType } from '@webapp/store/types';

const MIN_VALUE = -100;
const MAX_VALUE = 100;

const MotorWidget = ({ counter, data, disabled }: LiveWidgetProps) => {
  const [value, setValue] = useState(0);
  const [placement, setPlacement] = useState<MOTOR_PLACEMENT>(MOTOR_PLACEMENT.left);

  const { addHistoryEntry } = useActionsHistory();
  const editorType = useGetEditorType();
  const { isPlaying } = useContext(EditorContext);
  const { model: roboModel } = useRobo();

  const [thumbSize, setThumbSize] = useState(80);
  const rootRef = useRef<HTMLDivElement>(null);

  const moduleId = data.moduleIds[0] as ModuleId;
  const MOTOR = roboModel?.modules.motors[moduleId];

  const handleChangePlacement = (_: React.MouseEvent<HTMLElement>, value: MOTOR_PLACEMENT) => {
    if (value !== null) {
      setPlacement(value);
    }
  };

  const rotateMotor = (speed: number) => {
    MOTOR?.setSpeedDebounced(
      speed,
      placement === MOTOR_PLACEMENT.right ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw
    );

    addHistoryEntry(
      {
        action: 'interact:widget:motor',
        scope: editorType ? (editorType as EditorType) : 'common',
      },
      {
        debounce: true,
      }
    );
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      return;
    }

    rotateMotor(newValue);
    setValue(newValue);
  };

  const trackStyle = useMemo(() => {
    if (value > 0) {
      return {
        bottom: '50% !important',
        top: 'unset !important',
        height: `${(value / MAX_VALUE) * 50}% !important`,
        transition: 'none !important',
      };
    } else if (value < 0) {
      return {
        bottom: 'unset !important',
        top: '50% !important',
        height: `${(value / MIN_VALUE) * 50}% !important`,
        transition: 'none !important',
      };
    } else {
      return {
        display: 'none',
      };
    }
  }, [value]);

  // set thumb size in px based on the width of the root element
  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(elements => {
      const element = elements[0].target;
      setThumbSize(element.clientWidth - 5);
    });

    resizeObserver.observe(rootRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#156BFB'} ref={rootRef}>
      <Typography variant="x-lead-semibold" color={themeColors.white['500']}>
        {counter}
      </Typography>

      <StyledSlider
        orientation="vertical"
        min={MIN_VALUE}
        max={MAX_VALUE}
        value={value}
        onChange={handleSliderChange}
        onChangeCommitted={() => {
          setValue(0);
          MOTOR?.setSpeedDebounced.cancel();
          MOTOR?.setSpeed(0);
        }}
        thumb={`${thumbSize}px`}
        track={'normal'}
        border="2px solid #5A418B"
        disabled={!isPlaying || disabled}
        sx={{
          '& .MuiSlider-track': trackStyle,
          height: 'auto',
          flexGrow: 1,
          marginTop: '20px',
          marginBottom: '20px',
          [theme.breakpoints.down('lg')]: {
            marginTop: '10px',
            marginBottom: '10px',
          },
        }}
      />

      <MotorPlacementToggleButton onChange={handleChangePlacement} value={placement} />
    </StyledBox>
  );
};

MotorWidget.initialProps = {
  width: 1,
  height: 4,
};

MotorWidget.initialData = {};

MotorWidget.propTypes = {};

MotorWidget.defaultProps = {};

export default MotorWidget;
