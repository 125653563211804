import React, { useEffect } from 'react';
import { useAuth } from '@lib/auth';
import * as Sentry from '@sentry/browser';

export const SentryWrapper = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, email: user.email });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return children;
};
