import React from 'react';

import { styled } from '@mui/system';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

import theme from '@themes/general.theme';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    display: 'flex',
    color: 'white',
    border: 'none',
    [`&.Mui-selected`]: {
      color: 'white',
      fontWeight: 'bolder',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    borderTopRightRadius: '19px',
    borderBottomRightRadius: '19px',
    flexGrow: 1,
    padding: '0px',
  },
  [`& .${toggleButtonGroupClasses.firstButton}`]: {
    borderTopLeftRadius: '19px',
    borderBottomLeftRadius: '19px',
    flexGrow: 1,
    padding: '0px',
  },
}));

const MotorPlacementToggleButton = ({ onChange, value }) => {
  return (
    <StyledToggleButtonGroup
      color="secondary"
      value={value}
      exclusive
      onChange={onChange}
      size="small"
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.3011)',
        height: '38px',
        borderRadius: '19px',
        width: 'calc(100% - 10px)',
        marginTop: '0px',
        marginBottom: '10px',
        [theme.breakpoints.down('lg')]: {
          height: '25px',
          width: 'calc(100% - 5px)',
        },
      }}
    >
      <ToggleButton value="left">L</ToggleButton>
      <ToggleButton value="right">R</ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default MotorPlacementToggleButton;
