import React from 'react';
import BasicElement from './basic-element';

const SensingElement = props => {
  return (
    <BasicElement
      sx={{
        backgroundColor: '#E31871',

        '&:hover': {
          backgroundColor: '#FF2A89',
        },

        '&:active': {
          backgroundColor: '#C20E5C',
        },
      }}
      {...props}
    />
  );
};
export default SensingElement;
