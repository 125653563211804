import React, { useEffect, useMemo } from 'react';
import { isObject } from 'lodash';

import { Box } from '@mui/material';

import { AnimationName, Animations, useAnimations } from '@components/Animation/animations-provider';
import animationComponents from './animations';
import { AnimationComponentName } from './animations';
import { ElementsIdType } from '@constants/elements-ids';

const recursivelyCreateComponentsWithContent = (
  components: {
    type: any;
    props: any;
  }[],
  content: React.ReactNode
): React.ReactNode => {
  if (components.length === 0) {
    return content;
  }
  const currentComponent = components[0];
  const restComponents = components.slice(1);

  return React.createElement(
    currentComponent.type,
    currentComponent.props,
    restComponents.length > 0 ? recursivelyCreateComponentsWithContent(restComponents, content) : content
  );
};

export const AnimateGlobal = ({
  id,
  animations,
  children,
}: {
  id: ElementsIdType;
  animations: Animations;
  children: React.ReactNode;
}) => {
  const { registerElement, unregisterElement, getActiveAnimations } = useAnimations();
  const activeAnimations = getActiveAnimations(id);

  useEffect(() => {
    registerElement(id, animations);

    return () => {
      // stop all animations
      unregisterElement(id);
    };
  }, []);

  const activeAnimationComponents = activeAnimations
    ? Object.keys(activeAnimations).map(key => ({
        type: animationComponents[key as AnimationComponentName],
        props: isObject(activeAnimations[key]) ? activeAnimations[key] : {},
      }))
    : [];

  return (
    <Box className="animation-box">{recursivelyCreateComponentsWithContent(activeAnimationComponents, children)}</Box>
  );
};

export const Animate = ({
  animations,
  children,
  activeAnimations,
}: {
  animations: Animations;
  activeAnimations: AnimationName[];
  children: React.ReactNode;
}) => {
  const activeAnimationComponents = useMemo(
    () =>
      activeAnimations
        ? Object.keys(animations)
            .filter(an => activeAnimations.includes(an as AnimationComponentName))
            .map(key => ({
              type: animationComponents[key as AnimationComponentName],
              props: isObject(animations[key]) ? animations[key] : {},
            }))
        : [],
    [activeAnimations, animations]
  );

  return (
    <Box className="animation-box">{recursivelyCreateComponentsWithContent(activeAnimationComponents, children)}</Box>
  );
};
