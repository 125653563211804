import React from 'react';
import { TickRenderProps, SliderGeometry } from '../types';

interface TickProps extends TickRenderProps {
  sliderGeometry: SliderGeometry;
}

const Tick: React.FC<TickProps> = ({
  value,
  angle,
  trackId,
  color = '#000',
  length = 10,
  width = 2,
  label,
  style,
  sliderGeometry,
}) => {
  const { center, radius } = sliderGeometry;
  const radians = (angle - 90) * (Math.PI / 180);
  const innerPoint = {
    x: center.x + (radius - length) * Math.cos(radians),
    y: center.y + (radius - length) * Math.sin(radians),
  };
  const outerPoint = {
    x: center.x + radius * Math.cos(radians),
    y: center.y + radius * Math.sin(radians),
  };

  return (
    <g>
      <line
        x1={innerPoint.x}
        y1={innerPoint.y}
        x2={outerPoint.x}
        y2={outerPoint.y}
        stroke={color}
        strokeWidth={width}
        style={style}
      />
      {label && (
        <text x={innerPoint.x} y={innerPoint.y} dy="-0.5em" textAnchor="middle" fontSize="10" fill={color}>
          {typeof label === 'function' ? label(value) : label}
        </text>
      )}
    </g>
  );
};

export default React.memo(Tick);
