import { Typography } from '@mui/material';

import { ToolboxElementsContainer as ToolboxElementsContainer } from '@webapp/components/toolbox/styled/toolbox';
import ToolboxElement from '@webapp/components/toolbox/toolbox-element';

import { ScrollingContainer } from '@components/ScrollingContainer/scrolling-container';

export const ToolboxElements = ({ elementsToShow, elementHandlers, editor }) => {
  // show empty toolbox if there are no elements to show
  if (elementsToShow.length === 0) {
    return (
      <ToolboxElementsContainer className="toolbox-elements-empty">
        <Typography variant="x-headline5-bold" sx={{ color: '#C7C7C7' }}>
          No available elements
        </Typography>
      </ToolboxElementsContainer>
    );
  }

  // show full toolbox with elements
  return (
    <ScrollingContainer
      sx={{
        position: 'absolute',

        height: '90px',
        top: '-96px',

        maxWidth: '100%',
        minWidth: '530px',
        marginLeft: '13px',

        '&.toolbox-elements-empty': {
          width: '530px',
          marginLeft: '13px',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      {elementsToShow.map(element => {
        return (
          <ToolboxElement
            key={element.id}
            element={element}
            handlers={elementHandlers[editor]}
            className={`ToolboxElement toolbox-element-${element.active ? 'active' : 'default'}`}
          />
        );
      })}
    </ScrollingContainer>
  );
};
