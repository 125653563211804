import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex, hexToHsva, ColorResult } from '@uiw/color-convert';

import './color-wheel.css';

interface ColorWheelProps {
  color: string;
  onChange?: (color: string) => void;
  onChangeCommitted?: (color: string) => void;
  disabled?: boolean;
}

function ColorWheel({ color = '#FFFFFF', onChange, onChangeCommitted, disabled }: ColorWheelProps) {
  const [hsva, setHsva] = useState(hexToHsva(color));

  const handleChange = useCallback(
    (newColor: ColorResult) => {
      if (!newColor || typeof newColor === 'string') return;
      setHsva(newColor.hsva);
      if (onChange) {
        onChange(hsvaToHex(newColor.hsva));
      }
    },
    [onChange]
  );

  const handleChangeCommitted = useCallback(() => {
    if (!disabled) {
      onChangeCommitted?.(hsvaToHex(hsva));
    }
  }, [onChangeCommitted, disabled, hsva]);

  return (
    <>
      <Wheel
        color={hsva}
        className={`${disabled ? 'disabled' : ''}`}
        prefixCls={`color-wheel-pointer`}
        onChange={handleChange}
        onMouseUp={handleChangeCommitted}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
      />
    </>
  );
}

export default ColorWheel;
