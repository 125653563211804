import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { primaryColor, secondaryColor, ...otherProps } = props;
  return (
    <SvgIcon {...otherProps}>
      <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Special">
          <circle
            id="Ellipse 766"
            cx="30.333"
            cy="29.6349"
            r="28.3778"
            fill="#36A958"
            stroke="#5A418B"
            strokeWidth="2"
          />
          <path
            id="Star"
            d="M29.6016 41.7897C29.8508 41.6518 30.1493 41.6518 30.3985 41.7897L38.5701 46.3102C40.6902 47.483 43.127 45.6403 42.7474 43.3119L41.1578 33.5592C41.111 33.2722 41.2036 32.9836 41.3986 32.7836L48.1773 25.8308C49.7948 24.1718 48.9015 21.3119 46.575 20.9562L37.312 19.5399C37.0404 19.4984 36.7979 19.3207 36.6725 19.0534L32.5736 10.3144C31.5439 8.11893 28.4562 8.11893 27.4265 10.3144L23.3276 19.0534C23.2022 19.3207 22.9597 19.4984 22.6881 19.5399L13.4251 20.9562C11.0986 21.3119 10.2053 24.1718 11.8228 25.8308L18.6015 32.7836C18.7965 32.9836 18.8891 33.2722 18.8423 33.5592L17.2526 43.3119C16.8731 45.6403 19.3099 47.483 21.4299 46.3102L29.6016 41.7897Z"
            fill="white"
            stroke="#5A418B"
            strokeWidth="2"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Icon;
