import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_RELATED_SUBJECTS = gql`
  fragment RELATED_SUBJECTS on SubjectRelationResponseCollection {
    data {
      id
      attributes {
        name
        code
      }
    }
  }
`;
