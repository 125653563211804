import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
} from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';

import { useModelStore } from '@webapp/hooks/use-model-store';
import {
  selectAccelerometers,
  selectButtons,
  selectLightSensors,
  selectLineTrackers,
  selectMotions,
  selectUltrasonics,
} from '@webapp/components/record-sensors/sensor-selectors';
import {
  convertSensorRecordsToTable,
  createCSVFromTable,
  saveSensorValues,
} from '@webapp/components/record-sensors/utils';
import { ModuleId, ModulesCollectionTypes } from '@lib/robo/types';
import { RoboModel } from '@lib/robo/robo-model';
import { RoboClient } from '@lib/robo/robo-client';
import { formatSecondsToMinutes } from '@lib/utils';

import { useAppSettings } from '@lib/hooks/use-app-settings';
import { AppSettingsIds } from '@constants/app-settings-ids';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useEditor } from '@webapp/hooks/use-editor-hook';
import { downloadFile } from '@lib/utils/file';
import { EditorType } from '@webapp/store/types';
import { PlotExample } from './plot-example';
import { useEditorContext } from '../hoc/with-editor';

export type SensorsRecorderContextType = {
  startRecording: () => void;
  stopRecordingAndDownloadData: () => void;
  getSensorsValue: () => SensorsStore; // get current sensors values, could be used for plotting
  requestStopRecording: () => void; // show stop modal with confirm/reject recording
  isRecordingEnabled: boolean;
  isRecording: boolean;
  recordingStartTime: null | number;
  maxRecordingDuration: number;
};

export const SensorsRecorderContext = React.createContext<SensorsRecorderContextType>({
  startRecording: () => {
    throw new Error('Not implemented');
  },
  stopRecordingAndDownloadData: () => {
    throw new Error('Not implemented');
  },
  getSensorsValue: () => {
    throw new Error('Not implemented');
  },
  requestStopRecording: () => {
    throw new Error('Not implemented');
  },
  isRecordingEnabled: false,
  recordingStartTime: null,
  isRecording: false,
  maxRecordingDuration: 5 * 60 * 1000,
});

export const useSensorsRecorderContext = () => {
  return useContext(SensorsRecorderContext);
};

export type RecordSensorsConfig = {
  [key in ModulesCollectionTypes]?: Array<ModuleId>;
};

export type SensorsStore = {
  [key in ModulesCollectionTypes]?: {
    [key: string]: Array<[number, any]>;
  };
};

export enum StopRecordingDialogMode {
  AllowToClose = 'allow_to_close',
  NotAllowToClose = 'not_allow_to_close',
}

const useRecordSensorsValue = (
  recordSensorsConfig: RecordSensorsConfig | null,
  sensorsStoreRef: React.MutableRefObject<SensorsStore>
) => {
  const { model } = useModelStore();

  // "timestamp" is consistent time for all records at current render
  const timestamp = Date.now();

  const ultrasonicData = recordSensorsConfig ? selectUltrasonics(model, recordSensorsConfig.ultrasonics ?? []) : null;
  useEffect(() => {
    if (!ultrasonicData) return;
    saveSensorValues(sensorsStoreRef.current, ultrasonicData, ModulesCollectionTypes.Ultrasonic, timestamp);
  }, [ultrasonicData]);

  const buttonsData = recordSensorsConfig ? selectButtons(model, recordSensorsConfig.buttons ?? []) : null;
  useEffect(() => {
    if (!buttonsData) return;
    saveSensorValues(sensorsStoreRef.current, buttonsData, ModulesCollectionTypes.Button, timestamp);
  }, [buttonsData]);

  const accelerometersData = recordSensorsConfig
    ? selectAccelerometers(model, recordSensorsConfig.accelerometers ?? [])
    : null;
  useEffect(() => {
    if (!accelerometersData) return;
    saveSensorValues(sensorsStoreRef.current, accelerometersData, ModulesCollectionTypes.Accelerometer, timestamp);
  }, [accelerometersData]);

  const lightSensorsData = recordSensorsConfig
    ? selectLightSensors(model, recordSensorsConfig.lightSensors ?? [])
    : null;
  useEffect(() => {
    if (!lightSensorsData) return;
    saveSensorValues(sensorsStoreRef.current, lightSensorsData, ModulesCollectionTypes.LightSensor, timestamp);
  }, [lightSensorsData]);

  const linetrackersData = recordSensorsConfig
    ? selectLineTrackers(model, recordSensorsConfig.linetrackers ?? [])
    : null;
  useEffect(() => {
    if (!linetrackersData) return;
    saveSensorValues(sensorsStoreRef.current, linetrackersData, ModulesCollectionTypes.Linetracker, timestamp);
  }, [linetrackersData]);

  const motionsData = recordSensorsConfig ? selectMotions(model, recordSensorsConfig.motions ?? []) : null;
  useEffect(() => {
    if (!motionsData) return;
    saveSensorValues(sensorsStoreRef.current, motionsData, ModulesCollectionTypes.Motion, timestamp);
  }, [motionsData]);
};

export const SensorsRecorderProvider = ({ children }: { children: React.ReactNode }) => {
  const [recordingStartTime, setRecordingStartTime] = useState<null | number>(null);
  const [recordSensorsConfig, setRecordSensorsConfig] = useState<null | RecordSensorsConfig>(null);
  const [lastDownloadFilename, setLastDownloadFilename] = useState('');
  const sensorsStoreRef = useRef<SensorsStore>({});

  const [showStopRecordingDialog, setShowStopRecordingDialog] = useState<StopRecordingDialogMode | false>(false);
  const [showAutomaticallyStopDialog, setShowAutomaticallyStopDialog] = useState(false);

  const { client } = useRobo();
  const { getSettingValue } = useAppSettings();
  const { isPlaying, editorType } = useEditorContext();

  if (!editorType) {
    throw Error('Empty editorType');
  }

  const { usedAndConnectedModulesIds } = useEditor(editorType);

  // this variable tells if the recording is enabled, but it could be not started yet
  const isRecordingEnabled = recordingStartTime !== null;

  // this variable tells if recording is started
  const isRecording = recordSensorsConfig !== null;

  useEffect(() => {
    sensorsStoreRef.current = {};
  }, [recordSensorsConfig]);

  const getSensorsValue = useCallback(() => {
    return sensorsStoreRef.current;
  }, []);

  const downloadRecordedDataCSVFile = useCallback((recordingData: ReturnType<typeof convertSensorRecordsToTable>) => {
    const csvText = createCSVFromTable(recordingData);
    const downloadFilename = `${format(new Date(), 'yyyy-MM-dd HH-mm')}.csv`;
    downloadFile(csvText, 'text/plain', downloadFilename);
    setLastDownloadFilename(downloadFilename);
    setShowStopRecordingDialog(StopRecordingDialogMode.AllowToClose);
  }, []);

  const startRequestingData = useCallback((client: RoboClient, editorType: EditorType, modulesIds: ModuleId[]) => {
    const config = modulesIds.reduce<{
      [key in ModulesCollectionTypes]?: Array<ModuleId>;
    }>((acc, id) => {
      const { collectionType } = RoboModel.parseModuleId(id);
      if (!acc[collectionType]) {
        acc[collectionType] = [];
      }
      acc[collectionType].push(id);

      return acc;
    }, {});

    setRecordSensorsConfig(config);

    if (editorType === EditorType.Code) {
      client.startBatchSensorsCheck(modulesIds);
    } else if (editorType === EditorType.Live) {
      // for live startBatchSensorsCheck will run automatically
    }
  }, []);

  const stopRequestingData = useCallback((client: RoboClient, editorType: EditorType) => {
    if (editorType === EditorType.Code) {
      client.stopBatchSensorsCheck();
    } else if (editorType === EditorType.Live) {
      // for live startBatchSensorsCheck will run automatically
    }
  }, []);

  const startRecording = useCallback(() => {
    setRecordingStartTime(Date.now());

    if (isPlaying && client) {
      startRequestingData(client, editorType, usedAndConnectedModulesIds);
    }
  }, [usedAndConnectedModulesIds, client, isPlaying, editorType, startRequestingData]);

  const stopRecordingAndDownloadData = useCallback(() => {
    setRecordingStartTime(null);
    setRecordSensorsConfig(null);

    const result = convertSensorRecordsToTable(sensorsStoreRef.current);
    sensorsStoreRef.current = {};

    if (client) {
      stopRequestingData(client, editorType);
    }

    downloadRecordedDataCSVFile(result);
  }, [editorType, client, downloadRecordedDataCSVFile, stopRequestingData]);

  const requestStopRecording = () => {
    setShowStopRecordingDialog(StopRecordingDialogMode.AllowToClose);
  };

  useEffect(() => {
    // start recording, when user clicks on "Play" button after "Record sensors" button
    if (isPlaying && !isRecording && isRecordingEnabled && client) {
      startRequestingData(client, editorType, usedAndConnectedModulesIds);
    }

    // stop recording, when user clicks on "Stop" button and recording is in process
    if (!isPlaying && isRecording) {
      setShowStopRecordingDialog(StopRecordingDialogMode.NotAllowToClose);
    }
  }, [isPlaying]);

  let maxRecordingDuration: string | false | number = getSettingValue(AppSettingsIds.WebAppSensorsMaxRecordTime);
  maxRecordingDuration = maxRecordingDuration === false ? 5 * 60 * 1000 : parseInt(maxRecordingDuration);
  // if recording occurs longer than maxRecordingDuration, stop it and download result file
  useEffect(() => {
    if (!isRecording) return;

    const timeoutId = setTimeout(() => {
      stopRecordingAndDownloadData();
      setShowAutomaticallyStopDialog(true);
    }, maxRecordingDuration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRecording, maxRecordingDuration]);

  useRecordSensorsValue(recordSensorsConfig, sensorsStoreRef);

  return (
    <SensorsRecorderContext.Provider
      value={{
        startRecording,
        stopRecordingAndDownloadData,
        getSensorsValue,
        requestStopRecording,
        isRecordingEnabled,
        recordingStartTime,
        isRecording,
        maxRecordingDuration,
      }}
    >
      {children}
      <Dialog
        open={!!showStopRecordingDialog}
        onClose={() => {
          if (showStopRecordingDialog === 'allow_to_close') {
            setShowStopRecordingDialog(false);
          }
        }}
      >
        <DialogTitle>{'Record sensors'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Recorded data will be downloaded to your Downloads folder</DialogContentText>
          <PlotExample />
        </DialogContent>
        <DialogActions>
          <Button
            endIcon={<StopIcon />}
            variant="outlined"
            sx={{
              width: '100%',
            }}
            onClick={() => {
              stopRecordingAndDownloadData();
              setShowStopRecordingDialog(false);
            }}
          >
            Stop recording and download file
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAutomaticallyStopDialog} onClose={() => setShowAutomaticallyStopDialog(false)}>
        <DialogTitle>{'Record sensors'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box>Maximum record time is {formatSecondsToMinutes(Math.floor(maxRecordingDuration / 1000))}.</Box>
            Recorded data is downloaded as a file{' '}
            <Typography component="span" variant="x-basic-bold">
              {lastDownloadFilename}
            </Typography>{' '}
            to your Downloads folder
          </DialogContentText>
          <PlotExample />
        </DialogContent>
        <DialogActions>
          <Button
            endIcon={<StopIcon />}
            variant="outlined"
            sx={{
              width: '100%',
            }}
            onClick={() => {
              setShowAutomaticallyStopDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </SensorsRecorderContext.Provider>
  );
};
