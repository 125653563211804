import { memo } from 'react';
import PropTypes from 'prop-types';

import { kebabToPascalCase } from '@lib/utils/string-utils';

import * as availableElements from '@webapp/components/blocks/element-templates';

import IconLoader from '@webapp/components/icon-loader/icon-loader';

const ToolboxElement = ({ element, handlers, className, ...props }) => {
  const ElementTemplateComponent = availableElements[kebabToPascalCase(element.template)];

  const onClickHandler = event => {
    handlers.onClick && handlers.onClick(event, element);
  };

  const onDragStartHandler = (event, element) => {
    handlers.onDragStart && handlers.onDragStart(event, element);
  };

  const onDragEndHandler = (event, element) => {
    handlers.onDragEnd && handlers.onDragEnd(event, element);
  };

  const icon = <IconLoader name={element.icon} />;

  return (
    <ElementTemplateComponent
      icon={icon}
      label={element.name}
      badge={element.badge}
      className={className}
      onClick={event => onClickHandler(event, element)}
      onDragStart={event => onDragStartHandler(event, element)}
      onDragEnd={event => onDragEndHandler(event, element)}
      draggable={!!onDragStartHandler} // if there is no onDragStart handler, we don't want to make element draggable.
      disabled={element.disabled}
      role="button"
      aria-roledescription={`Create widget button`}
      aria-label={`Create widget ${element.name}`}
      data-module-index={element.counter}
      {...props}
    />
  );
};

ToolboxElement.propTypes = {
  element: PropTypes.object.isRequired,
  handlers: PropTypes.object,
};

ToolboxElement.defaultProps = {
  handlers: {},
};

export default memo(ToolboxElement);
