// we need this check because on touch devices "mouseover" and "mousedown" events could occur after "touchend"
export const isTouchEndCooldownPassed = (time: number | null) => {
  if (time === null) {
    return true;
  }
  return Date.now() - time > 100;
};

// Generate initial matrix state
export const createInitialMatrixState = (size: number): boolean[] => {
  return Array.from({ length: size * size }, () => false);
};

// Utility function to convert a one-dimensional array back to a two-dimensional array
export const convertToTwoDimensionalArray = (array: boolean[], size: number): boolean[][] => {
  const matrix = [];
  for (let i = 0; i < size; i++) {
    const start = i * size;
    const end = start + size;
    matrix.push(array.slice(start, end));
  }
  return matrix;
};

export const rotateOneDimensionalMatrix = (array: boolean[], size: number, angle: number) => {
  // Normalize the angle to one of the four valid rotations (0, 90, 180, 270)
  const normalizedAngle = angle % 360;
  if (normalizedAngle === 0 || normalizedAngle === 360) {
    // No rotation needed
    return [...array];
  }

  const rotated = new Array(array.length);

  for (let i = 0; i < size; i++) {
    for (let j = 0; j < size; j++) {
      // Calculate the original one-dimensional index
      const originalIndex = i * size + j;
      let newIndex;

      switch (normalizedAngle) {
        case 90:
          // Rotate 90 degrees clockwise
          newIndex = j * size + (size - 1 - i);
          break;
        case 180:
          // Rotate 180 degrees
          newIndex = (size - 1 - i) * size + (size - 1 - j);
          break;
        case 270:
          // Rotate 270 degrees clockwise (or 90 degrees counter-clockwise)
          newIndex = (size - 1 - j) * size + i;
          break;
        default:
          // This should never happen due to the normalization, but just in case,
          // handle unexpected angles by not rotating.
          newIndex = originalIndex;
      }

      // Place the element in the new position
      rotated[newIndex] = array[originalIndex];
    }
  }

  return rotated;
};
