import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M48.6342 26.6658L48.1142 27.167L48.6342 26.6658C47.7667 25.766 46.3674 25.8003 45.5396 26.7337L42.4576 30.2085C41.6587 31.1092 41.6839 32.5213 42.5204 33.3889C43.3878 34.2887 44.7871 34.2544 45.615 33.321L48.6969 29.8462C49.4959 28.9455 49.4707 27.5335 48.6342 26.6658ZM38.6055 24.5559C38.6055 23.352 37.6901 22.2778 36.4445 22.2778C35.1988 22.2778 34.2834 23.352 34.2834 24.5559V29.3708C34.2834 30.5747 35.1988 31.6489 36.4445 31.6489C37.6901 31.6489 38.6055 30.5747 38.6055 29.3708V24.5559ZM33.2265 33.9539C30.1792 33.9539 27.8034 36.6075 27.8034 39.7597C27.8034 40.9466 28.7059 42.007 29.9359 42.007H42.953C44.1829 42.007 45.0855 40.9466 45.0855 39.7597C45.0855 36.6075 42.7097 33.9539 39.6624 33.9539H33.2265ZM23.7266 42.5977C22.3219 42.5977 21.2778 43.8113 21.2778 45.1871V47.1329C21.2778 48.5087 22.3219 49.7222 23.7266 49.7222H49.1623C50.5669 49.7222 51.6111 48.5087 51.6111 47.1329V45.1871C51.6111 43.8113 50.5669 42.5977 49.1623 42.5977H23.7266ZM27.2209 26.7337C26.3931 25.8003 24.9938 25.766 24.1263 26.6658C23.2898 27.5334 23.2646 28.9455 24.0635 29.8462L27.1455 33.321C27.9733 34.2544 29.3726 34.2887 30.2401 33.3889C31.0766 32.5213 31.1018 31.1092 30.3029 30.2085L27.2209 26.7337Z"
          fill="white"
          stroke="#5A418B"
          strokeWidth="1.44444"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
