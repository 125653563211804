import React from 'react';
import { ToggleButton as MuiToggleButton, ToggleButtonProps, styled } from '@mui/material';
import { Box } from '@mui/material';

interface CustomRoundToggleIconButtonProps {
  icon?: React.ReactNode;
  text?: React.ReactNode | string;
  mainColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  buttonSize?: number;
}

type RoundToggleIconButtonProps = ToggleButtonProps & CustomRoundToggleIconButtonProps;

const StyledToggleButton = styled(MuiToggleButton, {
  shouldForwardProp: prop => !['mainColor', 'secondaryColor', 'tertiaryColor', 'buttonSize'].includes(prop as string),
})<{
  mainColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  buttonSize: number;
}>(({ theme, mainColor, secondaryColor, tertiaryColor, value, buttonSize }) => ({
  textTransform: 'none',
  borderRadius: '50%',
  border: 'none',
  width: `${buttonSize}px`,
  height: `${buttonSize}px`,
  padding: '0',
  backgroundColor: (value ? mainColor : secondaryColor) + ' !important',
  color: value ? tertiaryColor : mainColor,
  '&:active': {
    backgroundColor: value ? mainColor : secondaryColor + ' !important',
    color: value ? tertiaryColor : mainColor + ' !important',
  },
  '&:hover': {
    backgroundColor: value ? mainColor : secondaryColor + ' !important',
    opacity: 0.8,
  },
  '&[disabled]': {
    opacity: 0.5,
  },
}));

const RoundToggleIconButton: React.FunctionComponent<RoundToggleIconButtonProps> = ({
  icon,
  text,
  mainColor,
  secondaryColor,
  tertiaryColor,
  buttonSize = 42,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${buttonSize + 6}px`,
      }}
    >
      <StyledToggleButton
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        tertiaryColor={tertiaryColor}
        buttonSize={buttonSize}
        {...props}
      >
        {/* todo: Use icon loader instead */}
        {icon &&
          React.cloneElement(icon as React.ReactElement, {
            htmlColor: props.value ? tertiaryColor : mainColor,
          })}
      </StyledToggleButton>

      {text && (
        <Box
          sx={{
            flex: '1 1 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '4px',
          }}
        >
          {text}
        </Box>
      )}
    </Box>
  );
};

export default RoundToggleIconButton;
