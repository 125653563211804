import { createElementsSlice } from '@webapp/store/slices/shared/shared-elements-slice';
import elementsInitialState from '@webapp/store/state/live/elements.state';
import { EditorType } from '@webapp/store/types';

const specificSelectors = {
  // Define specific selectors here
};

const specificReducers = {
  // Define specific reducers here
};

const { elementsSlice, actions, selectors } = createElementsSlice(
  elementsInitialState,
  'live/elements',
  EditorType.Live,
  specificReducers
);
export const elementsActions = actions;

export const elementsSelectors = {
  ...selectors,
  ...specificSelectors,
};

export default elementsSlice.reducer;
