import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73 73" fill="none">
        <path
          d="M32.2962 25.9609L25.8563 31.2478H21.5088C20.4065 31.2478 19.5 32.1302 19.5 33.2343V39.18C19.5 40.2841 20.4065 41.1664 21.5088 41.1664L25.8563 41.1664L32.2962 46.4533C33.5939 47.5187 35.5882 46.622 35.5882 44.925V27.4893C35.5882 25.7922 33.5939 24.8955 32.2962 25.9609Z"
          fill="white"
          stroke="#5A418B"
          strokeLinecap="round"
        />
        <path
          d="M40.2137 39.42L40.5007 39.8294L40.2137 39.42C39.4291 39.9702 39.239 41.0522 39.7891 41.8368C40.3393 42.6214 41.4213 42.8115 42.2059 42.2613C43.9234 41.0571 45.0522 39.057 45.0522 36.7928C45.0522 34.5287 43.9234 32.5285 42.2059 31.3243C41.4213 30.7742 40.3393 30.9642 39.7891 31.7488C39.239 32.5334 39.4291 33.6155 40.2137 34.1656C41.0445 34.7481 41.5821 35.7077 41.5821 36.7928C41.5821 37.878 41.0445 38.8375 40.2137 39.42Z"
          fill="white"
          stroke="#5A418B"
          strokeLinecap="round"
        />
        <path
          d="M46.517 43.0251L46.8372 43.4051L46.517 43.0251C45.7842 43.6426 45.6907 44.7372 46.3081 45.47C46.9256 46.2028 48.0202 46.2963 48.753 45.6788C51.2786 43.5508 52.8878 40.3588 52.8878 36.7926C52.8878 33.2263 51.2786 30.0343 48.753 27.9063C48.0202 27.2888 46.9256 27.3823 46.3081 28.1152C45.6907 28.848 45.7842 29.9426 46.517 30.56C48.2935 32.0569 49.4177 34.2926 49.4177 36.7926C49.4177 39.2926 48.2935 41.5283 46.517 43.0251Z"
          fill="white"
          stroke="#5A418B"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
