import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Button } from '@mui/material';
import SimpleDialog, { propTypes as dialogPropTypes } from '@components/Dialog/simple-dialog';

import MotorPlayButton from './motor-play-button';
import MotorAlignmentToggle from './motor-alignment-toggle';

import { ALIGNMENT } from '../joystick-widget';

const JoystickSettingsModal = props => {
  const { isReversed, setIsReversed, setMotorAlignment, motorsState, motorsConfig, probeMotor, ...dialogProps } = props;

  return (
    <SimpleDialog {...dialogProps} onMouseDown={e => e.stopPropagation()}>
      <Grid container spacing={2} sx={{ width: '320px' }}>
        {Object.keys(motorsState).length === 0 ? (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography variant="x-body-semibold">There are no connected motors</Typography>
          </Grid>
        ) : (
          <>
            {Object.entries(motorsState).map(([motorId, motor]) => (
              <React.Fragment key={motorId}>
                <Grid item xs={6}>
                  <Typography variant="x-body-semibold">Motor {motor.index}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <MotorAlignmentToggle
                    motorId={motorId}
                    currentAlignment={motorsConfig[motorId].alignment}
                    setMotorAlignment={setMotorAlignment}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MotorPlayButton
                    motorId={motorId}
                    probeMotor={probeMotor}
                    disabled={motorsConfig[motorId].alignment === ALIGNMENT.none}
                  />
                </Grid>
              </React.Fragment>
            ))}

            <Grid item xs={12}></Grid>

            <Grid item xs={6}>
              <Typography variant="x-body-semibold">All</Typography>
            </Grid>

            <Grid item xs={6}>
              <Button
                selected={isReversed}
                variant="contained"
                color="quaternary"
                fullWidth
                sx={{
                  borderRadius: '19px',
                }}
                onClick={() => {
                  setIsReversed(!isReversed);
                }}
              >
                <Typography variant="x-body-semibold" sx={{ opacity: isReversed ? '1' : '0.5' }}>
                  Reverse
                </Typography>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </SimpleDialog>
  );
};

JoystickSettingsModal.propTypes = {
  ...dialogPropTypes,
  isReversed: PropTypes.bool.isRequired,
  setIsReversed: PropTypes.func.isRequired,
  setMotorAlignment: PropTypes.func.isRequired,
  motorsState: PropTypes.object.isRequired,
  motorsConfig: PropTypes.object.isRequired,
  probeMotor: PropTypes.func.isRequired,
};

export default JoystickSettingsModal;
