import { createEntityAdapter, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store/configureStore';
import { ElementsState, ElementEntity, EditorType } from '@webapp/store/types';

import { isSubset } from '@lib/utils/array-utils';

import { selectConnectedModuleIds, selectConnectedModuleTypes } from '@webapp/store/slices/model/model.selectors';
import { ModuleId, ModuleTypes } from '@lib/robo/types/modules';

/**
 * Checks if the conditions for required modules and module types are fulfilled.
 * Used to check if widget needed modules are connected.
 *
 * @param requiredModulesIds - An array of module IDs that are required.
 * @param requiredModuleTypes - An array of module types that are required.
 * @param connectedModuleIds - An array of module IDs that are currently connected.
 * @param connectedModuleTypes - An array of module types that are currently connected.
 * @returns A boolean indicating whether all required modules and types are fulfilled.
 */
export const isModulesConditionFulfilled = (
  requiredModulesIds: ModuleId[],
  requiredModuleTypes: ModuleTypes[],
  connectedModuleIds: ModuleId[],
  connectedModuleTypes: ModuleTypes[]
) => {
  let isConditionFullfilled = true;

  if (requiredModulesIds.length) {
    // check required modules fulfillment
    isConditionFullfilled &&= requiredModulesIds.every((moduleId: ModuleId) =>
      connectedModuleIds.includes(moduleId as ModuleId)
    );
  }

  if (requiredModuleTypes.length) {
    // check required module types fulfillment
    isConditionFullfilled &&= isSubset(requiredModuleTypes, connectedModuleTypes);
  }

  return isConditionFullfilled;
};

/**
 * Creates a sections slice with its actions, its reducer, its adapter, and its selectors.
 * @param elementsInitialState The initial state of the elements slice.
 * @param sliceName The name of the slice.
 * @param editor The editor type.
 * @param specificReducers The specific reducers of the slice.
 * @returns The elements slice, its actions, its reducer, its adapter, and its selectors.
 */
export const createElementsSlice = (
  elementsInitialState: ElementsState,
  sliceName: string,
  editor: EditorType,
  specificReducers: Record<string, (state: ElementsState, action: PayloadAction) => void>
) => {
  const elementsState = (state: RootState) => state.webapp[editor].toolbox.elements as ElementsState;

  const elementsAdapter = createEntityAdapter<ElementEntity>();

  const elementsSlice = createSlice({
    name: sliceName,
    initialState: elementsInitialState,
    reducers: {
      addElement: elementsAdapter.addOne,
      updateElement: elementsAdapter.updateOne,
      removeElement: elementsAdapter.removeOne,
      hideAllElements: (state: ElementsState) => {
        Object.values(state.entities).forEach(entity => {
          entity.hidden = true;
        });
      },
      showAllElements: (state: ElementsState) => {
        Object.values(state.entities).forEach(entity => {
          entity.hidden = false;
        });
      },
      hideElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].hidden = true;
      },
      showElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].hidden = false;
      },
      activateElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].active = true;
      },
      deactivateElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].active = false;
      },

      ...specificReducers,
    },
  });

  const adapterSelectors: ReturnType<typeof elementsAdapter.getSelectors> = elementsAdapter.getSelectors(elementsState);
  /**
   * Selects all elements belonging to a specific section.
   * @param state - The root state of the application.
   * @param sectionId - The ID of the section to select elements from.
   * @returns An array of elements belonging to the specified section.
   */
  const selectElementsBySection = createSelector(
    [adapterSelectors.selectAll, (_state: RootState, sectionId: string | null) => sectionId],
    (elements, sectionId) => {
      if (sectionId === null) {
        return [];
      }
      return elements.filter(element => element.sectionId === sectionId);
    }
  );

  /**
   * Selects elements that belong to connected modules.
   * @returns An array of elements that belong to connected modules.
   */
  const selectElementsForConnectedModules = createSelector(
    [adapterSelectors.selectAll, selectConnectedModuleIds, selectConnectedModuleTypes],
    (elements, connectedModuleIds, connectedModuleTypes) => {
      return elements.filter(element =>
        isModulesConditionFulfilled(
          element.requiredModules ?? [],
          element.requiredModuleTypes ?? [],
          connectedModuleIds,
          connectedModuleTypes
        )
      );
    }
  );

  /**
   * Selects elements that belong to connected modules within a specific section.
   * @param state - The root state of the application.
   * @param sectionId - The ID of the section to select elements from.
   * @returns An array of elements belonging to connected modules within the specified section.
   */
  const selectElementsForConnectedModulesBySection = createSelector(
    [selectElementsBySection, selectElementsForConnectedModules],
    (elementsBySection, elementsForConnectedModules) => {
      // Now we have two arrays, one filtered by section and one by connected modules.
      // We want the intersection of these two arrays.
      return elementsBySection.filter(element =>
        elementsForConnectedModules.some(connectedElement => connectedElement.id === element.id)
      );
    }
  );

  const selectElementById = createSelector([adapterSelectors.selectById], element => element);
  const selectAllElements = createSelector([adapterSelectors.selectAll], elements => elements);
  const selectActiveElement = createSelector([selectAllElements], elements => elements.find(element => element.active));

  return {
    elementsSlice: elementsSlice,
    actions: elementsSlice.actions,
    reducer: elementsSlice.reducer,
    elementsAdapter: elementsAdapter,
    selectors: {
      ...adapterSelectors,
      selectElementsBySection,
      selectElementsForConnectedModules,
      selectElementsForConnectedModulesBySection,
      selectElementById,
      selectAllElements,
      selectActiveElement,
    },
  };
};
