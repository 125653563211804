import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

import { combineReducers, configureStore, Reducer, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';

import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist';

// Common slices
import uiReducer from '@store/slices/ui.slice';
import navReducer from '@store/slices/nav.slice';
import authReducer from '@store/slices/auth.slice';
import tutorialReducer from '@store/slices/tutorial.slice';

// Common APIs
import { authApi } from '@store/api/auth.api';
import { featureApi } from '@store/api/feature.api';
import { userApiGraphql } from '@store/api/user.api.graphql';
import { featureApiGraphql } from '@store/api/feature.api.graphql';
import { appSettingsApiGraphql } from '@store/api/app-settings-api.graphql';
import { bridgeApiGraphql } from '@store/api/bridge.api.graphql';

// Steamhub
import { libraryApiGraphql } from '@steamhub/store/api/library.api.graphql';
import { projectApiGraphql } from '@steamhub/store/api/project.api.graphql';
import { lessonApiGraphql } from '@steamhub/store/api/lesson.api.graphql';
import { activityApiGraphql } from '@steamhub/store/api/activity.api.graphql';
import { resourceApiGraphql } from '@steamhub/store/api/resource.api.graphql';
import { dashboardApiGraphql } from '@steamhub/store/api/dashboard.api.graphql';
import { pageApiGraphql } from '@steamhub/store/api/page.api.graphql';
import { searchApiGraphql } from '@steamhub/store/api/search.api.graphql'; // todo revise
import { roomApiGraphql } from '@steamhub/store/api/room.api.graphql';
import { sectionApiGraphql } from '@steamhub/store/api/section.api.graphql';

// Webapp - Live
import liveEditorReducer from '@webapp/store/slices/live/editor.slice';
import liveHistoryReducer from '@webapp/store/slices/live/history.slice';
import liveSectionsReducer from '@webapp/store/slices/live/sections.slice';
import liveElementsReducer from '@webapp/store/slices/live/elements.slice';
import { tutorialApiGraphql } from '@webapp/store/api/tutorial.api.graphql';
import { editorProjectApiGraphql } from '@webapp/store/api/editor-project.api.graphql';

// Webapp - Code
import codeSectionsReducer from '@webapp/store/slices/code/sections.slice';
import codeElementsReducer from '@webapp/store/slices/code/elements.slice';
import codeEditorReducer from '@webapp/store/slices/code/editor.slice';
// todo: add History slices

// Webapp - Common
import { devicesMiddleware } from '@webapp/store/middlewares/devices.middleware';
import codeEditorMiddleware from '@webapp/components/editors/robo-code/middlewares/code-editor.middleware';

import bridgeReducer from '@webapp/store/slices/devices/bridge.slice';
import roboReducer from '@webapp/store/slices/devices/robo.slice';
import modelReducer from '@webapp/store/slices/model/model.slice';
import topBarSliceReducer from '@webapp/store/slices/live/top-bar.slice';
import updateManagerSliceReducer from '@webapp/store/slices/update-manager/update-manager.slice';
import { firmwareApiGraphql } from '@tools/store/api/firmware.api.graphql';
import { historyActionsMiddleware } from '@webapp/store/middlewares/history.middleware';

// Webapp - Tools

const webappReducers: Record<string, Reducer> = {
  topBar: topBarSliceReducer,
  devices: combineReducers({
    bridge: bridgeReducer,
    robo: roboReducer,
  }),
  model: modelReducer,
  code: combineReducers({
    toolbox: combineReducers({
      sections: codeSectionsReducer,
      elements: codeElementsReducer,
    }),

    editor: codeEditorReducer,
  }),

  live: combineReducers({
    toolbox: combineReducers({
      sections: liveSectionsReducer,
      elements: liveElementsReducer,
    }),
    editor: liveEditorReducer,
    history: liveHistoryReducer,
  }),
  updateManager: updateManagerSliceReducer,
};

const isSSR = typeof window === 'undefined';
const isDev = process.env.NODE_ENV !== 'production';

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  nav: navReducer,
  tutorial: tutorialReducer,

  [featureApi.reducerPath]: featureApi.reducer,
  [featureApiGraphql.reducerPath]: featureApiGraphql.reducer,
  [appSettingsApiGraphql.reducerPath]: appSettingsApiGraphql.reducer,
  [userApiGraphql.reducerPath]: userApiGraphql.reducer,
  [authApi.reducerPath]: authApi.reducer, // todo Move to user layer
  [bridgeApiGraphql.reducerPath]: bridgeApiGraphql.reducer,
  // Steamhub
  [libraryApiGraphql.reducerPath]: libraryApiGraphql.reducer,
  [projectApiGraphql.reducerPath]: projectApiGraphql.reducer,
  [lessonApiGraphql.reducerPath]: lessonApiGraphql.reducer,
  [activityApiGraphql.reducerPath]: activityApiGraphql.reducer,
  [resourceApiGraphql.reducerPath]: resourceApiGraphql.reducer,
  [dashboardApiGraphql.reducerPath]: dashboardApiGraphql.reducer,
  [pageApiGraphql.reducerPath]: pageApiGraphql.reducer,
  [searchApiGraphql.reducerPath]: searchApiGraphql.reducer, // todo revise
  [roomApiGraphql.reducerPath]: roomApiGraphql.reducer,
  [sectionApiGraphql.reducerPath]: sectionApiGraphql.reducer,
  [tutorialApiGraphql.reducerPath]: tutorialApiGraphql.reducer,
  [editorProjectApiGraphql.reducerPath]: editorProjectApiGraphql.reducer,
  [firmwareApiGraphql.reducerPath]: firmwareApiGraphql.reducer,

  // Webapp
  webapp: combineReducers(webappReducers),
});

// todo extract to separate file
const middlewares = [
  featureApi.middleware,
  featureApiGraphql.middleware,
  appSettingsApiGraphql.middleware,
  bridgeApiGraphql.middleware,
  userApiGraphql.middleware,
  authApi.middleware, // todo Move to user layer

  libraryApiGraphql.middleware,
  projectApiGraphql.middleware,
  lessonApiGraphql.middleware,
  activityApiGraphql.middleware,
  resourceApiGraphql.middleware,
  dashboardApiGraphql.middleware,
  pageApiGraphql.middleware,
  searchApiGraphql.middleware, // todo revise
  roomApiGraphql.middleware,
  sectionApiGraphql.middleware,
  tutorialApiGraphql.middleware,
  editorProjectApiGraphql.middleware,
  firmwareApiGraphql.middleware,

  devicesMiddleware,
  codeEditorMiddleware,
  historyActionsMiddleware,
] as Middleware[];

let reducer, persistor;

if (isSSR) {
  // SSR Setup;
  reducer = rootReducer;
} else {
  // CLIENT Setup
  const storage = new CookieStorage(Cookies, {
    setCookieOptions: {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      path: '/',
      httpOnly: true,
    },
  });

  const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['ui', 'auth'],
  };

  reducer = persistReducer(persistConfig, rootReducer);
}

const store = configureStore({
  preloadedState: {},
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // thunk: true
    }).concat(middlewares),
  devTools: isDev && !isSSR,
});

if (!isSSR) {
  persistor = persistStore(store);
  setupListeners(store.dispatch);

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  // }
}

export { store, persistor };

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
