import {
  normalizeAngle,
  calculateSweepAngle,
  anglesDifference,
  calculateSpiralRadius,
  calculateSpiralAngle,
  visualAnglesDistance,
} from './math';

/**
 * Converts polar coordinates to cartesian coordinates.
 *
 * @param centerX - The x coordinate of the center of the polar coordinates.
 * @param centerY - The y coordinate of the center of the polar coordinates.
 * @param radius - The radius of the polar coordinates.
 * @param angleInDegrees - The angle in degrees.
 * @returns The cartesian coordinates.
 */
export function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

/**
 * Describes an arc using SVG path data.
 *
 * @param x - The x coordinate of the center of the arc.
 * @param y - The y coordinate of the center of the arc.
 * @param radius - The radius of the arc.
 * @param startAngle - The start angle of the arc.
 * @param endAngle - The end angle of the arc.
 * @param direction - The direction of the arc.
 * @returns The SVG path data for the arc.
 */
export function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  direction: 'clockwise' | 'counterclockwise' = 'clockwise'
) {
  const start = polarToCartesian(x, y, radius, startAngle);
  const end = polarToCartesian(x, y, radius, endAngle);

  const angleDifference = visualAnglesDistance(startAngle, endAngle, direction);

  const largeArcFlag = angleDifference > 180 ? '1' : '0';

  const sweepFlag = direction === 'clockwise' ? '1' : '0';

  return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${end.x} ${end.y}`;
}

/**
 * Describes a spiral arc using SVG path data.
 *
 * @param centerX - The x coordinate of the center of the spiral arc.
 * @param centerY - The y coordinate of the center of the spiral arc.
 * @param startRadius - The radius of the start of the spiral arc.
 * @param startAngle - The start angle of the spiral arc.
 * @param endAngle - The end angle of the spiral arc.
 * @param expansionFactor - The expansion factor of the spiral arc.
 * @param direction - The direction of the spiral arc.
 * @returns The SVG path data for the spiral arc.
 */
export function describeSpiralArc(
  centerX: number,
  centerY: number,
  startRadius: number,
  startAngle: number,
  endAngle: number,
  expansionFactor: number,
  direction: 'clockwise' | 'counterclockwise' = 'clockwise'
) {
  const revolutions = (endAngle - startAngle) / 360;
  const totalAngle = anglesDifference(startAngle, endAngle);

  // Ensure a minimum of 72 steps per revolution (5-degree intervals)
  // but cap the total number of steps to prevent performance issues
  const stepsPerRevolution = 72;
  const maxTotalSteps = 360; // Arbitrary max, adjust as needed
  const steps = Math.min(Math.ceil(revolutions * stepsPerRevolution), maxTotalSteps);

  const path = [];
  const sweepFlag = direction === 'clockwise' ? 1 : 0;

  for (let i = 0; i <= steps; i++) {
    const t = i / steps;
    const angle = calculateSpiralAngle(t, startAngle, revolutions);
    const revolution = t * revolutions;
    const radius = calculateSpiralRadius(startRadius, revolution, expansionFactor);
    const point = polarToCartesian(centerX, centerY, radius, angle);

    if (i === 0) {
      path.push(`M ${point.x} ${point.y}`);
    } else {
      path.push(`A ${radius} ${radius} 0 0 ${sweepFlag} ${point.x} ${point.y}`);
    }
  }

  return path.join(' ');
}
