import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

type LineFollowerSensorIconPropsType = {
  enabledPositions: {
    left: boolean;
    middle: boolean;
    right: boolean;
  };
};

const Icon: IconType<LineFollowerSensorIconPropsType> = ({ enabledPositions, ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 194 194" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M53 53.5C53 47.701 48.299 43 42.5 43C36.701 43 32 47.701 32 53.5V69.5C32 75.299 36.701 80 42.5 80C48.299 80 53 75.299 53 69.5V53.5Z"
          stroke="white"
          strokeWidth="4"
          fill={enabledPositions?.left ? 'white' : ''}
        />
        <path
          d="M107 53.5C107 47.701 102.299 43 96.5 43C90.701 43 86 47.701 86 53.5V69.5C86 75.299 90.701 80 96.5 80C102.299 80 107 75.299 107 69.5V53.5Z"
          stroke="white"
          strokeWidth="4"
          fill={enabledPositions?.middle ? 'white' : ''}
        />
        <path
          d="M161 53.5C161 47.701 156.299 43 150.5 43C144.701 43 140 47.701 140 53.5V69.5C140 75.299 144.701 80 150.5 80C156.299 80 161 75.299 161 69.5V53.5Z"
          stroke="white"
          strokeWidth="4"
          fill={enabledPositions?.right ? 'white' : ''}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
