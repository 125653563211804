import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { htmlColor, ...rest } = props;

  const defaultColor = '#5A418B';

  const color = htmlColor || defaultColor;

  return (
    <SvgIcon {...rest}>
      <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.207 23.7929C25.5975 24.1834 25.5975 24.8166 25.207 25.2071C22.9408 27.4732 19.9687 28.6066 16.9999 28.6066C16.4476 28.6066 15.9998 28.1589 15.9999 27.6066C15.9999 27.0543 16.4476 26.6066 16.9999 26.6066C19.4599 26.6066 21.9167 25.6689 23.7927 23.7929C24.1833 23.4024 24.8164 23.4024 25.207 23.7929ZM27.6065 18C27.0542 18 26.6065 17.5523 26.6065 17C26.6065 14.5399 25.6687 12.0831 23.7927 10.2071C23.4022 9.81658 23.4022 9.18342 23.7927 8.79289C24.1833 8.40237 24.8164 8.40237 25.207 8.79289C27.4731 11.059 28.6065 14.0312 28.6065 17C28.6065 17.5523 28.1587 18 27.6065 18ZM10.207 25.2071C9.81643 25.5976 9.18327 25.5976 8.79274 25.2071C6.52661 22.941 5.39325 19.9688 5.39325 17C5.39325 16.4477 5.84096 16 6.39325 16C6.94553 16 7.39325 16.4477 7.39325 17C7.39325 19.4601 8.33095 21.9169 10.207 23.7929C10.5975 24.1834 10.5975 24.8166 10.207 25.2071ZM17.9998 6.3934C17.9998 6.94568 17.5521 7.3934 16.9998 7.3934C14.5398 7.3934 12.083 8.33111 10.207 10.2071C9.81643 10.5976 9.18327 10.5976 8.79274 10.2071C8.40222 9.81658 8.40222 9.18342 8.79274 8.79289C11.0589 6.52676 14.031 5.3934 16.9998 5.3934C17.5521 5.3934 17.9998 5.84111 17.9998 6.3934Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9999 18.6322C17.9012 18.6322 18.6317 17.9016 18.6317 17.0004C18.6317 16.0992 17.9012 15.3687 16.9999 15.3687C16.0987 15.3687 15.3682 16.0992 15.3682 17.0004C15.3682 17.9016 16.0987 18.6322 16.9999 18.6322Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2888 12.4426C10.9702 12.124 10.9702 11.6074 11.2888 11.2888V11.2888C11.6075 10.9702 12.1241 10.9702 12.4427 11.2888L17.396 16.2421C17.7146 16.5608 17.7146 17.0774 17.396 17.396V17.396C17.0774 17.7146 16.5608 17.7146 16.2422 17.396L11.2888 12.4426Z"
          fill={color}
        />
        <rect x="16.1846" y="5.57764" width="1.63178" height="5.71125" rx="0.815892" fill={color} />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
