import React, { useCallback, useEffect, useState } from 'react';
import { HandleProps, SliderGeometry, CustomStyle } from '../types';

interface HandleComponentProps extends HandleProps {
  sliderGeometry: SliderGeometry;
  defaultSize: number;
  defaultColor: string;
  disabled: boolean;
  readOnly: boolean;
  onInteractionStart: (event: React.MouseEvent | React.TouchEvent, trackId: string, handleId: string) => void;
  customRender?: (props: HandleProps) => React.ReactNode;
  animationDuration?: number;
}

const Handle: React.FC<HandleComponentProps> = ({
  id,
  trackId,
  value,
  angle,
  size,
  color,
  label,
  draggable = true,
  isActive,
  customStyle,
  sliderGeometry,
  defaultSize,
  defaultColor,
  disabled,
  readOnly,
  onInteractionStart,
  customRender,
  animationDuration = 300,
}) => {
  const [position, setPosition] = useState(sliderGeometry.getHandlePosition(trackId, value));
  const handleSize = size || defaultSize;
  const handleColor = color || defaultColor;

  useEffect(() => {
    setPosition(sliderGeometry.getHandlePosition(trackId, value));
  }, [sliderGeometry, trackId, value]);

  const handleStyle: CustomStyle = {
    fill: handleColor,
    cursor: draggable && !disabled && !readOnly ? 'pointer' : 'default',
    transition: `transform ${animationDuration}ms`,
    ...customStyle,
  };

  const handleInteractionStart = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (draggable && !disabled && !readOnly) {
        onInteractionStart(event, trackId, id);
      }
    },
    [draggable, disabled, readOnly, onInteractionStart, trackId, id]
  );

  if (customRender) {
    return customRender({
      id,
      trackId,
      value,
      angle,
      size: handleSize,
      color: handleColor,
      label,
      draggable,
      isActive,
      customStyle: handleStyle,
    });
  }

  return (
    <g
      transform={`translate(${position.x}, ${position.y})`}
      onMouseDown={handleInteractionStart}
      onTouchStart={handleInteractionStart}
    >
      <defs>
        <filter id="drop-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow dx="0" dy="2" stdDeviation="2" floodColor="rgba(0, 0, 0, 0.50)" />
        </filter>
      </defs>

      <circle r={handleSize / 2} style={handleStyle} filter="url(#drop-shadow)" />

      {label && typeof label === 'function' && label(value)}

      {label && typeof label !== 'function' && (
        <text dy=".3em" textAnchor="middle" style={{ pointerEvents: 'none', userSelect: 'none' }}>
          {label}
        </text>
      )}
    </g>
  );
};

export default React.memo(Handle);
