import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_TUTORIAL_STEP_COMMON_FIELDS = gql`
  fragment TUTORIAL_STEP_COMMON_FIELDS on ComponentItemsTutorialStepCommonFields {
    id
    title
    showProgress
    showOverlay
    text
    timeDelay
    backButtonText
    backButtonAction
    backButtonUrl
    nextButtonText
    nextButtonAction
    nextButtonUrl
  }
`;
