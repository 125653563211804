import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.813.998c-.07.017-.189.067-.265.112-.075.044-2.432 1.917-5.237 4.161-4.656 3.726-5.108 4.097-5.195 4.275l-.096.194-.01 6.734c-.007 4.426.003 6.786.03 6.884.06.226.203.411.402.522l.178.1 3.793.011c2.481.007 3.845-.003 3.944-.03a.84.84 0 0 0 .523-.403l.1-.178.011-3.19.011-3.19h3.996l.011 3.19.011 3.19.1.178c.11.197.294.34.515.401.198.055 7.532.055 7.73 0a.842.842 0 0 0 .515-.401l.1-.178V9.74l-.096-.194c-.087-.178-.539-.55-5.2-4.279-2.807-2.246-5.176-4.123-5.264-4.171a.902.902 0 0 0-.607-.098M16.5 6.88l4.5 3.6V22h-4.998l-.011-3.19-.011-3.19-.089-.167c-.106-.197-.269-.322-.52-.398-.271-.081-6.471-.081-6.742 0-.251.076-.414.201-.52.398l-.089.167-.011 3.19L7.998 22H3V10.48l4.49-3.597a2944.99 2944.99 0 0 1 4.5-3.6A919.11 919.11 0 0 1 16.5 6.88" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
