import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { FULL_SEARCH_QUERY } from '@store/queries/search';

export const searchApiGraphql = createApi({
  reducerPath: 'searchApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    search: builder.query({
      query: query => ({
        document: FULL_SEARCH_QUERY,
        variables: { query },
      }),
    }),
  }),
});

export const { useSearchQuery, useLazySearchQuery } = searchApiGraphql;
