import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query.graphql';

import { FULL_PROJECT_QUERY } from '@store/queries/project';

export const projectApiGraphql = createApi({
  reducerPath: 'projectApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getProject: builder.query({
      query: id => ({
        document: FULL_PROJECT_QUERY,
        variables: { id },
      }),
    }),
  }),
});

export const { useGetProjectQuery } = projectApiGraphql;
