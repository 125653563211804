import { useCallback } from 'react';

import { useSelector, useDispatch } from '@store/configureStore';

import { EditorType, ElementEntity, SectionEntity } from '@webapp/store/types';

import {
  sectionsActions as liveSectionActions,
  sectionsSelectors as liveSectionsSelector,
} from '@webapp/store/slices/live/sections.slice';

import {
  elementsActions as liveElementsActions,
  elementsSelectors as liveElementsSelector,
} from '@webapp/store/slices/live/elements.slice';

import {
  sectionsActions as codeSectionActions,
  sectionsSelectors as codeSectionsSelector,
} from '@webapp/store/slices/code/sections.slice';

import {
  elementsActions as codeElementsActions,
  elementsSelectors as codeElementsSelector,
} from '@webapp/store/slices/code/elements.slice';

export const useToolbox = (editor: EditorType) => {
  const dispatch = useDispatch();

  const sectionsActions = editor === EditorType.Live ? liveSectionActions : codeSectionActions;
  const sectionsSelectors = editor === EditorType.Live ? liveSectionsSelector : codeSectionsSelector;

  const elementsActions = editor === EditorType.Live ? liveElementsActions : codeElementsActions;
  const elementsSelectors = editor === EditorType.Live ? liveElementsSelector : codeElementsSelector;

  const sections: SectionEntity[] = useSelector(sectionsSelectors.selectAll);
  const elements: ElementEntity[] = useSelector(elementsSelectors.selectAll);

  const activeSection = useSelector(sectionsSelectors.selectActiveSection);

  const activeElement: ElementEntity | undefined = useSelector(elementsSelectors.selectActiveElement);

  const allElementsInSection = useSelector(state => {
    return elementsSelectors.selectElementsBySection(state, activeSection?.id ?? null);
  });

  const connectedElementsInSection = useSelector(state => {
    return elementsSelectors.selectElementsForConnectedModulesBySection(state, activeSection?.id ?? null);
  });

  const allConnectedElements: ElementEntity[] = useSelector(state => {
    return elementsSelectors.selectElementsForConnectedModules(state);
  });

  /** Activate section action */
  const activateSection = (id: string) => {
    dispatch(sectionsActions.activateSection(id));
  };

  /** Deactivate section action */
  const deactivateSection = (id: string) => {
    dispatch(sectionsActions.deactivateSection(id));
  };

  /** Deactivate all section action */
  const deactivateAllSections = () => {
    dispatch(sectionsActions.deactivateAllSections());
  };

  /** Activate element action */
  const activateElement = (id: string) => {
    dispatch(elementsActions.activateElement(id));
  };

  /** Deactivate element action */
  const deactivateElement = (id: string) => {
    dispatch(elementsActions.deactivateElement(id));
  };

  const showSection = (id: string) => {
    dispatch(sectionsActions.showSection(id));
  };

  const hideAllSections = () => {
    dispatch(sectionsActions.hideAllSections());
  };

  const showAllSections = () => {
    dispatch(sectionsActions.showAllSections());
  };

  const showElement = (id: string) => {
    dispatch(elementsActions.showElement(id));
  };

  const showAllElements = () => {
    dispatch(elementsActions.showAllElements());
  };

  const hideAllElements = () => {
    dispatch(elementsActions.hideAllElements());
  };

  const hideElement = (id: string) => {
    dispatch(elementsActions.hideElement(id));
  };

  const getSectionById = (id: string) => {
    return sections.find(section => section.id === id);
  };

  const getElementById = useCallback(
    (id: string) => {
      return elements.find(element => element.id === id);
    },
    [elements]
  );

  const isElementDisabled = useCallback(
    (isRoboConnected: boolean, id: string) => {
      const relatedElement = getElementById(id) as ElementEntity;
      if (!relatedElement) {
        throw Error(`ActionNode: related element not found elementId: ${id}`);
      }

      let disabled = relatedElement.hidden || relatedElement.disabled;

      if (isRoboConnected && !disabled) {
        disabled = !allConnectedElements.find((element: ElementEntity) => element.id === id);
      }

      return disabled;
    },
    [allConnectedElements, getElementById]
  );

  return {
    editor,
    sections,
    activeSection,
    allElementsInSection,
    connectedElementsInSection,
    allConnectedElements,

    activeElement,

    sectionsActions,
    sectionsSelectors,
    elementsActions,
    elementsSelectors,
    activateSection,
    deactivateSection,
    deactivateAllSections,
    activateElement,
    deactivateElement,
    showSection,
    showAllSections,
    hideAllSections,
    showElement,
    showAllElements,
    hideElement,
    hideAllElements,

    getSectionById,
    getElementById,
    isElementDisabled,
  };
};
