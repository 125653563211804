import { gql } from '@lib/store/strapi-graphql-query';

import { WITH_RELATED_GRADES } from '@store/fragments/grade';
import { WITH_RELATED_TAGS } from '@store/fragments/tag';
import { WITH_RELATED_SUBJECTS } from '@store/fragments/subject';
import { WITH_FILE } from '@store/fragments/file';
import { WITH_DURATION } from '@store/fragments/atoms/time-selector';

// todo pagination

export const DEFAULT_LIBRARY_QUERY = gql`
  ${WITH_DURATION}
  ${WITH_RELATED_SUBJECTS}
  ${WITH_RELATED_GRADES}
  ${WITH_RELATED_TAGS}
  ${WITH_FILE}

  query GetLibraryData(
    $includeProjects: Boolean!
    $includeLessons: Boolean!
    $includeActivities: Boolean!
    $includeResources: Boolean!
    $projectFilters: ProjectFiltersInput!
    $lessonFilters: LessonFiltersInput!
    $activityFilters: ActivityFiltersInput!
    $resourceFilters: ResourceFiltersInput!
  ) {
    projects(filters: $projectFilters, pagination: { start: 0, limit: 100 }) @include(if: $includeProjects) {
      data {
        __typename
        id
        attributes {
          name
          slug
          updatedAt

          preview {
            ...FILE
          }

          preparationDuration {
            ...DURATION
          }

          learningDuration {
            ...DURATION
          }

          subjects(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_SUBJECTS
          }

          grades(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_GRADES
          }

          tags {
            ...RELATED_TAGS
          }
        }
      }
    }

    lessons(filters: $lessonFilters, pagination: { start: 0, limit: 100 }) @include(if: $includeLessons) {
      data {
        __typename
        id
        attributes {
          name
          slug
          updatedAt

          preview {
            ...FILE
          }

          preparationDuration {
            ...DURATION
          }

          learningDuration {
            ...DURATION
          }

          subjects(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_SUBJECTS
          }

          grades(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_GRADES
          }

          tags {
            ...RELATED_TAGS
          }
        }
      }
    }

    activities(filters: $activityFilters, pagination: { start: 0, limit: 100 }) @include(if: $includeActivities) {
      data {
        __typename
        id
        attributes {
          name
          slug
          updatedAt

          preview {
            ...FILE
          }

          preparationDuration {
            ...DURATION
          }

          learningDuration {
            ...DURATION
          }

          subjects(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_SUBJECTS
          }

          grades(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_GRADES
          }

          tags {
            ...RELATED_TAGS
          }
        }
      }
    }

    resources(filters: $resourceFilters, pagination: { start: 0, limit: 100 }) @include(if: $includeResources) {
      data {
        __typename
        id
        attributes {
          name
          slug
          updatedAt

          preview {
            ...FILE
          }

          subjects(sort: ["ordering:asc", "name:asc"]) {
            ...RELATED_SUBJECTS
          }

          tags {
            ...RELATED_TAGS
          }
        }
      }
    }
  }
`;

export const LIBRARY_FILTERS_QUERY = gql`
  query GetLibraryFilters {
    subjects(sort: ["ordering:asc", "name:asc"]) {
      data {
        id
        attributes {
          name
          code
        }
      }
    }

    grades(sort: ["ordering:asc", "name:asc"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
