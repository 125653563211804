import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg width="23" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.781.351a.835.835 0 0 0-.387.346C13.307.865 8.28 18.862 8.28 19.008c0 .174.132.445.262.537.056.04.177.095.268.123.301.09.644-.064.797-.358.03-.058 1.195-4.172 2.587-9.142C14.953.321 14.8.929 14.595.62c-.16-.242-.543-.369-.814-.269M6.172 5.049c-.074.039-1.379 1.067-2.9 2.284C.307 9.706.366 9.651.366 10.005c0 .362-.065.301 2.923 2.692 1.527 1.222 2.834 2.246 2.905 2.276a.705.705 0 0 0 .957-.465.513.513 0 0 0 0-.385.748.748 0 0 0-.183-.309c-.069-.066-1.156-.942-2.416-1.949-1.26-1.006-2.29-1.842-2.29-1.856 0-.015 1.013-.837 2.251-1.827C7.018 6.18 7.034 6.167 7.118 6.005c.142-.276.041-.668-.228-.883-.187-.148-.512-.182-.718-.073m10.082-.005a.685.685 0 0 0-.422.648c0 .353-.027.328 2.545 2.381 1.318 1.052 2.396 1.921 2.396 1.932 0 .011-1.078.88-2.396 1.932-1.862 1.486-2.412 1.945-2.471 2.058-.21.412.043.933.494 1.017.324.061.309.072 3.262-2.29 1.846-1.477 2.811-2.272 2.868-2.366a.74.74 0 0 0-.001-.706c-.118-.195-5.585-4.575-5.777-4.629a.705.705 0 0 0-.498.023" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
