import { useCallback, useMemo } from 'react';
import { RootState, store, useDispatch, useSelector } from '@store/configureStore';

import { AddModulePayload, ModuleState, RemoveModulePayload, UpdateModulePayload } from '@webapp/store/types';
import { addModule, removeModule, updateModule } from '@webapp/store/slices/model/model.slice';

import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';

export type ModelStoreMethods = {
  methods: {
    addModule: (payload: AddModulePayload) => void;
    updateModule: (payload: UpdateModulePayload) => void;
    removeModule: (payload: RemoveModulePayload) => void;
    getState: () => { model: ModuleState };
  };
};

export type ModelStore = { model: ModuleState } & ModelStoreMethods;

export const useModelStore = (): ModelStore => {
  const dispatch = useDispatch();

  const model = useSelector((state: RootState) => state.webapp.model);

  const { addHistoryEntry } = useActionsHistory();

  const addModuleToModel = useCallback(
    (payload: AddModulePayload) => {
      addHistoryEntry({
        action: `connect:module:${payload.type}`,
        scope: 'common',
      });
      dispatch(addModule(payload));
    },
    [addHistoryEntry, dispatch]
  );

  const updateModuleInModel = useCallback(
    (payload: UpdateModulePayload) => {
      dispatch(updateModule(payload));
    },
    [dispatch]
  );

  const removeModuleFromModel = useCallback(
    (payload: RemoveModulePayload) => {
      addHistoryEntry({
        action: `disconnect:module:${payload.type}`,
        scope: 'common',
      });
      dispatch(removeModule(payload));
    },
    [addHistoryEntry, dispatch]
  );

  return {
    model,
    methods: useMemo(() => {
      return {
        addModule: addModuleToModel,
        updateModule: updateModuleInModel,
        removeModule: removeModuleFromModel,
        getState: () => {
          return { model: store.getState().webapp.model as ModuleState };
        },
      };
    }, [addModuleToModel, removeModuleFromModel, updateModuleInModel]),
  };
};
