import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_COLLECTION = gql`
  fragment COLLECTION on ComponentDashboardCollections {
    title
    content
    tags {
      id
      name
      limit
      tag {
        data {
          id
          attributes {
            name
            slug
            projects {
              ...PROJECTS_OVERVIEW
            }

            lessons {
              ...LESSONS_OVERVIEW
            }

            activities {
              ...ACTIVITIES_OVERVIEW
            }

            resources {
              ...RESOURCES_OVERVIEW
            }
          }
        }
      }
    }
  }
`;
