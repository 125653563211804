import { useContext } from 'react';
import PropTypes from 'prop-types';

import { useToolbox } from '@webapp/hooks/use-toolbox-hook';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useEditor } from '@webapp/hooks/use-editor-hook';
import { EditorContext, allowedHandlers } from '@webapp/components/hoc/with-editor';
import { useEditorHandlers } from '@webapp/hooks/use-editor-handlers-hook';

import ToolboxSection from './toolbox-section';
import { ToolboxElements } from './toolbox-elements';
import { ToolboxContainer } from './styled/toolbox';

const Toolbox = ({ editor }) => {
  const { connected } = useRobo();

  const {
    sections,
    activeSection,
    allElementsInSection,
    connectedElementsInSection,
    activateSection,
    deactivateSection,

    activeElement,
    activateElement,
    deactivateElement,
  } = useToolbox(editor);

  const { registeredHandlers } = useContext(EditorContext);
  const { widgets } = useEditor(editor);
  const editorHandlers = useEditorHandlers(editor);

  const elements = connected ? connectedElementsInSection : allElementsInSection;

  const elementsWithCreatedWidgets = Object.keys(widgets).reduce((acc, widgetId) => {
    const widget = widgets[widgetId];
    const widgetsElementId = widget.data.elementId;

    acc[widgetsElementId] = acc[widgetsElementId]
      ? [...acc[widgetsElementId], { id: widget.id, type: widget.type }]
      : [{ id: widget.id, type: widget.type }];
    return acc;
  }, {});

  const elementsToShow = elements.filter(element => {
    if (element.hidden) {
      return false;
    }

    const widgetsLimit = element.widgetsLimit;

    // if there is no limit, it means we can create unlimited amount of widgets. Show element in this case
    if (!widgetsLimit) {
      return true;
    }

    const alreadyCreatedWidgetsAmount = elementsWithCreatedWidgets[element.id]?.length || 0;

    return alreadyCreatedWidgetsAmount < widgetsLimit;
  });

  const sectionsToShow = sections.filter(section => !section.hidden);

  const elementHandlers = {
    code: {
      onClick: (event, toolboxElement) => {
        return editorHandlers.onElementClick(event, toolboxElement);
      },

      onDragStart: (event, toolboxElement) => {
        // we need to set alement as active in state so we will understand which element we are dragging/dropping
        // we can pass the element through dataTransfer, but it doesn't fulfill all the needs,
        // because it is not possible to access dataTransfer in onDragOver event due to browser security reasons
        activateElement(toolboxElement.id);
        return editorHandlers.onElementDragStart(event, toolboxElement);
      },

      onDragEnd: (event, toolboxElement) => {
        // we need to deactivate the active element after drag is finished
        if (activeElement) {
          deactivateElement(toolboxElement.id);
        }

        return editorHandlers.onElementDragEnd(event, toolboxElement);
      },

      onDrag: (event, toolboxElement) => {
        return editorHandlers.onElementDrag(event, toolboxElement);
      },
    },

    live: {
      onClick: (_event, toolboxElement) => {
        registeredHandlers[allowedHandlers.onWidgetInstantionTry] &&
          registeredHandlers[allowedHandlers.onWidgetInstantionTry](toolboxElement);
      },
    },
  };

  const handleSelectSection = clickedSectionId => {
    if (activeSection?.id === clickedSectionId) {
      deactivateSection(clickedSectionId);
      return;
    }

    activateSection(clickedSectionId);
  };

  return (
    <ToolboxContainer>
      {!!activeSection && (
        <ToolboxElements
          key={activeSection.id}
          elementsToShow={elementsToShow}
          elementHandlers={elementHandlers}
          editor={editor}
        />
      )}

      {sectionsToShow.map(section => {
        return <ToolboxSection key={section.id} section={section} onSectionClick={handleSelectSection} />;
      })}
    </ToolboxContainer>
  );
};

Toolbox.propTypes = {
  editor: PropTypes.string.isRequired,
};

export default Toolbox;
