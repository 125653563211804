import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie, setCookie } from 'react-use-cookie';
import { RoboClient } from '@lib/robo/robo-client';
import { WifiSecurityType } from '@webapp/store/types';

export interface FetchedRobo {
  name: string;
  alias: string;
  displayAlias: string;
  macAddress?: string;
  connected: boolean;
}

export interface BridgeState {
  id: string;
  name: string;
  firmware: string;
  ip: string;
  mac: string;
  wifiSecurityType: WifiSecurityType | null;
  connected: boolean;
  connecting: boolean;
  error: null | boolean;
  connectionLost: boolean;
  manuallyDisconnected: boolean;
  roboListFetched: boolean;
  roboList: FetchedRobo[];
}

const cookieName = 'bridgeId';

const getBridgeId = (): string => {
  // Todo - Revise when bridge name will be delivered from the backend
  return getCookie(cookieName) || '';
};

const initialState: BridgeState = {
  id: getBridgeId(),
  name: '', // it will be used, when we'll get to the user-friendly bridge name
  firmware: '',
  ip: '',
  mac: '',
  connected: false,
  connecting: false,
  error: null,
  connectionLost: false,
  manuallyDisconnected: false,
  wifiSecurityType: null,

  roboListFetched: false,
  roboList: [],
};

const bridgeSlice = createSlice({
  name: 'bridge',
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
      setCookie(cookieName, action.payload);
    },

    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },

    setFirmware: (state, action: PayloadAction<string>) => {
      state.firmware = action.payload;
    },

    setIP: (state, action: PayloadAction<string>) => {
      state.ip = action.payload;
    },

    setMac: (state, action: PayloadAction<string>) => {
      state.mac = action.payload;
    },

    setWifiSecurityType: (state, action: PayloadAction<WifiSecurityType | null>) => {
      state.wifiSecurityType = action.payload;
    },

    connect: state => {
      state.connecting = true;
    },

    connecting: state => {
      state.connecting = true;
    },

    connected: state => {
      state.connected = true;
      state.connecting = false;
      state.error = null;
      state.connectionLost = false;
      state.manuallyDisconnected = false;
    },

    disconnect: state => {
      state.connected = false;
      state.connecting = false;
      state.error = null;
      state.connectionLost = false;
      state.manuallyDisconnected = true;
      state.roboListFetched = false;
      state.roboList = [];
    },

    connectionLost: state => {
      state.connected = false;
      state.connecting = false;
      state.connectionLost = true;
      state.roboListFetched = false;
      state.roboList = [];
    },

    connectionFailure: state => {
      state.connected = false;
      state.connecting = false;
      state.error = true;
      state.roboListFetched = false;
      state.roboList = [];
    },

    disconnected: state => {
      state.connected = false;
      state.connecting = false;
      state.manuallyDisconnected = true;
      state.roboListFetched = false;
      state.roboList = [];
    },

    roboListUpdated: (state, action: PayloadAction<FetchedRobo[]>) => {
      state.roboList = action.payload.map(r => ({
        ...r,
        displayAlias: RoboClient.getDisplayAlias(r.alias),
      }));
      state.roboListFetched = true;
    },
  },
});

export const {
  setId,
  setMac,
  setName,
  setFirmware,
  setIP,
  setWifiSecurityType,
  connect,
  connecting,
  connected,
  disconnect,
  disconnected,
  connectionLost,
  connectionFailure,
  roboListUpdated,
} = bridgeSlice.actions;

export default bridgeSlice.reducer;
