/**
 * Joins array with objects into one string
 *
 * @param {array} originalArray
 * @param {string} fieldName
 * @param {string} delimiter
 *
 * @returns {string}
 */
export const joinObjects = (originalArray = [], fieldName = 'id', delimiter = ', ') => {
  let result = '';
  for (let item of originalArray) {
    result += item[fieldName] + delimiter;
  }
  return result.slice(0, -delimiter.length);
};
