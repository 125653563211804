import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab } from '@mui/material';

import { CloseIcon } from '@components/icons';

const SimpleDialog = props => {
  const {
    children,
    dense,
    open,
    title,

    onCancel,
    onClose,
    onSubmit,

    showCancelButton,
    showCloseButton,
    showSubmitButton,

    cancelButtonTitle,
    submitButtonTitle,

    allowSubmit,
    ...rest
  } = props;

  const handleCancelClicked = () => {
    onCancel && onCancel();
    onClose && onClose();
  };

  const handleSubmitClicked = () => {
    onSubmit && onSubmit();
    onClose && onClose();
  };

  const DialogCloseButton = styled(Fab)({
    position: 'absolute',
    top: '-16px',
    right: '-16px',
  });

  return (
    <Dialog
      open={open}
      onClose={handleCancelClicked}
      PaperProps={{ sx: { overflow: 'visible', p: dense ? 3 : 6 } }}
      {...rest}
    >
      {showCloseButton && (
        <DialogCloseButton size={'medium'} onClick={handleCancelClicked}>
          <CloseIcon color="tertiary" fontSize={'small'} />
        </DialogCloseButton>
      )}

      {title && (
        <DialogTitle variant={'h4'} sx={{ textAlign: 'center', mb: dense ? 0 : 3 }}>
          {title}
        </DialogTitle>
      )}

      <DialogContent>{children}</DialogContent>

      {(showCancelButton || showSubmitButton) && (
        <DialogActions sx={{ justifyContent: 'center', mt: dense ? 0 : 6 }}>
          {showCancelButton && (
            <Button onClick={handleCancelClicked} variant={'outlined'} color="primary" size="large">
              {cancelButtonTitle}
            </Button>
          )}

          {showSubmitButton && (
            <Button
              onClick={handleSubmitClicked}
              variant={'contained'}
              disabled={!allowSubmit}
              color="primary"
              size="large"
            >
              {submitButtonTitle}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export const propTypes = {
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  dense: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,

  showCancelButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showSubmitButton: PropTypes.bool,

  allowSubmit: PropTypes.bool,

  cancelButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  submitButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SimpleDialog.propTypes = propTypes;

SimpleDialog.defaultProps = {
  open: false,
  dense: false,
  title: '',

  showCloseButton: true,
  showCancelButton: true,
  showSubmitButton: true,

  allowSubmit: true,

  cancelButtonTitle: 'Cancel',
  submitButtonTitle: 'Submit',
};

export default SimpleDialog;
