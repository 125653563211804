import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  const { primaryColor, ...otherProps } = props;

  return (
    <SvgIcon {...otherProps}>
      <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.69 33.24a2.891 2.891 0 00-.416-.492c-2.196-2.077-5.088-3.456-7.917-4.616 1.319-3.207 2.578-6.441 3.716-9.716.242-.013.492-.06.758-.151l14.354-4.95c3.256-1.122 1.857-6.317-1.43-5.184-4.746 1.635-9.491 3.272-14.236 4.91-1.303-1.226-3.428-1.66-5.195-.995L12.693 1.25C10.38-1.343 6.592 2.474 8.892 5.052c3.21 3.6 6.42 7.198 9.633 10.798.193.216.396.384.605.515-1.214 3.461-2.558 6.88-3.962 10.267a3.22 3.22 0 00-.217.83l-.077.023c-3.307 1.304-6.908 2.788-9.52 5.261-.481.455-.73.963-.805 1.465a2.786 2.786 0 00-.154 2.6 757.856 757.856 0 014.257 9.97c.567 1.35 1.757 2.304 3.307 1.877 1.277-.352 2.446-1.953 1.877-3.308a894.923 894.923 0 00-3.99-9.376c1.132-.86 2.483-1.486 3.738-2.097a52.707 52.707 0 012.111-.96c.007 0 .499-.207.607-.247.464-.183 2.018-1.427 4.304-.375.221.148.461.274.719.375.095.037.49.201.586.241.719.307 1.427.632 2.132.966 1.4.667 2.922 1.383 4.12 2.403.198 3.421.42 6.837.656 10.255.241 3.437 5.62 3.462 5.377 0-.269-3.836-.52-7.672-.732-11.513-.043-.763-.34-1.356-.773-1.783z"
          fill={primaryColor}
        />
        <circle cx="26.473" cy="6.69" r="4.435" fill={primaryColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.507 17.557l-.365-1.57-18.233 5.086 18.598-3.516zM.885 9.834L0 11.182l16.244 9.713L.884 9.835zm8.332 1.899l6.226 6.697-4.951-7.687-1.275.99zm29.711 14.843l.468-1.543-8.953-1.867 8.485 3.41z"
          fill={primaryColor}
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
