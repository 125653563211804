export type EntityState<T> = {
  ids: string[];
  entities: { [id: string]: T };
};

export enum EditorType {
  Live = 'live',
  Code = 'code',
  Blockly = 'blockly',
}

export const WIFI_SECURITY_TYPES = {
  WIFI_AUTH_OPEN: 'WIFI_AUTH_OPEN',
  WIFI_AUTH_WEP: 'WIFI_AUTH_WEP',
  WIFI_AUTH_WPA_PSK: 'WIFI_AUTH_WPA_PSK',
  WIFI_AUTH_WPA2_PSK: 'WIFI_AUTH_WPA2_PSK',
  WIFI_AUTH_WPA_WPA2_PSK: 'WIFI_AUTH_WPA_WPA2_PSK',
  WIFI_AUTH_WPA2_ENTERPRISE: 'WIFI_AUTH_WPA2_ENTERPRISE',
  WIFI_AUTH_WPA3_PSK: 'WIFI_AUTH_WPA3_PSK',
  WIFI_AUTH_WPA2_WPA3_PSK: 'WIFI_AUTH_WPA2_WPA3_PSK',
} as const;

export type WifiSecurityType = keyof typeof WIFI_SECURITY_TYPES;
