import React from 'react';
import { Box } from '@mui/material';

interface ColoredDotProps {
  size?: number;
  color?: string;
}

export const RedDot: React.FunctionComponent<ColoredDotProps> = ({ size = 10, color = '#DF1642' }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <Box
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: color,
          borderRadius: '50%',
        }}
      />
    </Box>
  );
};

export default RedDot;
