import React, { useEffect } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors as themeColors } from '@themes/config/theme-colors';
import { LineFollowerSensorIcon } from '@webapp/components/icons';
import StyledIconButton from '../../component/styled-icon-button';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useSelector } from '@store/configureStore';
import { selectModuleById } from '@webapp/store/slices/model/model.selectors';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-xsmall-semibold',
  color: themeColors.white['500'],
}));

const LineFollowerWidget = ({ counter, data, disabled }) => {
  const { isPlaying, editorType } = useEditorContext();
  const { addHistoryEntry } = useActionsHistory();

  const moduleId = data?.moduleIds?.[0];
  const moduleState = useSelector(state => selectModuleById(state, 'linetrackers', moduleId));

  useEffect(() => {
    const hasActivity =
      moduleState?.sensorData?.positions.left === true ||
      moduleState?.sensorData?.positions.right === true ||
      moduleState?.sensorData?.positions.middle === true;

    if (isPlaying && hasActivity) {
      addHistoryEntry({
        action: 'interact:widget:line-sensor',
        scope: editorType,
      });
    }
  }, [isPlaying, moduleState?.sensorData?.positions, editorType]);

  return (
    <StyledIconButton
      color={disabled ? WidgetColors.disabledBackground : '#E31871'}
      width="100%"
      height="100%"
      size="large"
    >
      {!!counter && <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography>}

      <LineFollowerSensorIcon
        primaryColor={'rgba(255, 255, 255, 0.3)'}
        sx={{ width: '100%', height: '100%' }}
        enabledPositions={isPlaying ? moduleState?.sensorData?.positions : null}
      />
    </StyledIconButton>
  );
};

LineFollowerWidget.initialProps = {
  width: 2,
  height: 2,
};

LineFollowerWidget.initialData = {};

export default LineFollowerWidget;
