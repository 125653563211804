import React, { useRef, useCallback, useMemo } from 'react';
import { CircularSliderProps } from '../types';
import { useSliderGeometry } from '../hooks/useSliderGeometry';
import { useSliderInteraction } from '../hooks/useSliderInteraction';
import Track from './Track';
import Handle from './Handle';
import ValueIndicator from './ValueIndicator';
import Tick from './Tick';
import Connector from './Connector';

const CircularSlider: React.FunctionComponent<CircularSliderProps> = ({
  tracks,
  radius,
  width,
  height,
  ticks,
  onChange,
  onChangeStart,
  onChangeEnd,
  onChangeCommitted,
  renderTrack,
  renderHandle,
  renderValue,
  renderTick,
  renderConnector,
  defaultConnectorWidth = 2,
  defaultConnectorColor = '#3f51b5',
  defaultTrackWidth = 10,
  defaultTrackColor = '#e0e0e0',
  defaultActiveTrackColor = '#3f51b5',
  defaultHandleSize = 20,
  defaultHandleColor = '#3f51b5',
  disabled = false,
  readOnly = false,
  ariaLabel,
  ariaLabelledBy,
  animationDuration = 300,
  hideOverflow = false,
  allowTouchEvents = true,
  allowMouseEvents = true,
  svgProps,
  children,
  defaultShouldRenderValue = false,
  defaultShouldRenderTicks = false,
  defaultShouldRenderConnector = false,
  defaultShouldRenderTrack = true,
  defaultShouldRenderActiveTrack = true,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const sliderGeometry = useSliderGeometry({ radius, width, height, tracks });

  const { handleInteractionStart, isInteracting, activeTrackId, activeHandleId } = useSliderInteraction({
    tracks,
    sliderGeometry,
    svgRef,
    onChange,
    onChangeStart,
    onChangeEnd,
    onChangeCommitted,
    disabled,
    readOnly,
  });

  /**
   * Handle the track interaction
   */
  const handleTrackInteraction = useCallback(
    (trackId: string, value: number) => {
      if (disabled || readOnly) return;

      const track = tracks.find(t => t.id === trackId);
      if (!track || !track.handles) return;

      // Find the nearest handle
      const nearestHandle = track.handles.reduce((nearest, handle) => {
        const currentDistance = Math.abs(handle.value - value);
        const nearestDistance = Math.abs(nearest.value - value);
        return currentDistance < nearestDistance ? handle : nearest;
      });

      onChangeStart?.(trackId, nearestHandle.id, value);
      onChangeEnd?.(trackId, nearestHandle.id, value);
      onChangeCommitted?.({ [trackId]: track.handles.map(h => (h.id === nearestHandle.id ? value : h.value)) });
    },
    [disabled, readOnly, tracks, onChangeStart, onChangeEnd, onChangeCommitted]
  );

  /**
   * Render the tracks
   */
  const renderTracks = useMemo(() => {
    return tracks.map(track => (
      <Track
        key={track.id}
        {...track}
        sliderGeometry={sliderGeometry}
        defaultWidth={defaultTrackWidth}
        defaultColor={defaultTrackColor}
        defaultActiveColor={defaultActiveTrackColor}
        disabled={disabled}
        readOnly={readOnly}
        onInteraction={handleTrackInteraction}
        customRender={renderTrack}
      />
    ));
  }, [
    tracks,
    sliderGeometry,
    defaultTrackWidth,
    defaultTrackColor,
    defaultActiveTrackColor,
    disabled,
    readOnly,
    handleTrackInteraction,
    renderTrack,
  ]);

  /**
   * Render the handles
   */
  const renderHandles = useMemo(() => {
    return tracks.flatMap(
      track =>
        track.handles?.map(handle => (
          <Handle
            key={`${track.id}-${handle.id}`}
            {...handle}
            trackId={track.id}
            sliderGeometry={sliderGeometry}
            defaultSize={defaultHandleSize}
            defaultColor={defaultHandleColor}
            disabled={disabled}
            readOnly={readOnly}
            isActive={isInteracting && activeTrackId === track.id && activeHandleId === handle.id}
            onInteractionStart={event => handleInteractionStart(event, track.id, handle.id)}
            customRender={renderHandle}
            animationDuration={animationDuration}
          />
        )) || []
    );
  }, [
    isInteracting,
    tracks,
    sliderGeometry,
    defaultHandleSize,
    defaultHandleColor,
    disabled,
    readOnly,
    activeTrackId,
    activeHandleId,
    handleInteractionStart,
    renderHandle,
    animationDuration,
  ]);

  /**
   * Render the values
   */
  const renderValues = useMemo(() => {
    return tracks.flatMap(
      track =>
        track.handles?.map(handle => (
          <ValueIndicator
            key={`${track.id}-${handle.id}-value`}
            trackId={track.id}
            handleId={handle.id}
            value={handle.value}
            min={track.min}
            max={track.max}
            sliderGeometry={sliderGeometry}
            customRender={renderValue}
          />
        )) || []
    );
  }, [tracks, sliderGeometry, renderValue]);

  /**
   * Render the ticks
   */
  const renderTicks = useMemo(() => {
    if (!ticks) return null;

    return tracks.flatMap(track => {
      const { id: trackId, startAngle, endAngle, min, max } = track;
      const tickValues = typeof ticks === 'number' ? null : ticks.values;

      if (tickValues) {
        return tickValues.map(value => {
          const angle = sliderGeometry.getAngleByValue(trackId, value);
          return (
            <Tick
              key={`${trackId}-tick-${value}`}
              value={value}
              angle={angle}
              trackId={trackId}
              sliderGeometry={sliderGeometry}
              color={ticks.color}
              length={ticks.length}
              width={ticks.width}
              label={ticks.label}
              style={ticks.style}
            />
          );
        });
      } else {
        const interval = typeof ticks === 'number' ? (max - min) / (ticks - 1) : ticks.interval || (max - min) / 10;
        const tickCount = Math.floor((max - min) / interval) + 1;
        return Array.from({ length: tickCount }, (_, i) => {
          const value = min + i * interval;
          const angle = sliderGeometry.getAngleByValue(trackId, value);
          return (
            <Tick
              key={`${trackId}-tick-${i}`}
              value={value}
              angle={angle}
              trackId={trackId}
              sliderGeometry={sliderGeometry}
              color={typeof ticks === 'object' ? ticks.color : undefined}
              length={typeof ticks === 'object' ? ticks.length : undefined}
              width={typeof ticks === 'object' ? ticks.width : undefined}
              label={typeof ticks === 'object' ? ticks.label : undefined}
              style={typeof ticks === 'object' ? ticks.style : undefined}
            />
          );
        });
      }
    });
  }, [tracks, ticks, sliderGeometry]);

  const renderConnectors = useMemo(() => {
    return tracks.flatMap(track => {
      if (!track.handles || track.handles.length < 2) return [];
      if (!track.shouldRenderConnector) return [];

      const sortedHandles = [...track.handles].sort((a, b) => a.value - b.value);
      return sortedHandles
        .slice(0, -1)
        .map((handle, index) => (
          <Connector
            key={`${track.id}-connector-${index}`}
            trackId={track.id}
            startValue={handle.value}
            endValue={sortedHandles[index + 1].value}
            sliderGeometry={sliderGeometry}
            width={track.connectorWidth || defaultConnectorWidth}
            color={track.connectorColor || defaultConnectorColor}
            customRender={renderConnector}
          />
        ));
    });
  }, [tracks, sliderGeometry, defaultConnectorWidth, defaultConnectorColor, renderConnector]);

  /**
   * Render the slider
   */
  return (
    <div style={{ position: 'relative' }}>
      <svg
        ref={svgRef}
        width={width || radius * 2}
        height={height || radius * 2}
        viewBox={`0 0 ${width || radius * 2} ${height || radius * 2}`}
        style={{ overflow: hideOverflow ? 'hidden' : 'visible' }}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        {...svgProps}
      >
        {defaultShouldRenderTrack && renderTracks}
        {renderConnectors}
        {defaultShouldRenderTicks && renderTicks}
        {renderHandles}
        {defaultShouldRenderValue && renderValues}
      </svg>

      {children && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default CircularSlider;
