import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
        <rect opacity="0.3" x="12" y="12" width="6" height="6" rx="3" fill="white" />
        <rect x="12" y="18" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="12" y="24" width="6" height="6" rx="3" fill="white" />
        <rect x="18" y="12" width="6" height="6" rx="3" fill="white" />
        <rect x="12" y="12" width="6" height="6" rx="3" fill="white" />
        <rect x="18" y="18" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="18" y="24" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="24" y="12" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="24" y="18" width="6" height="6" rx="3" fill="white" />
        <rect x="24" y="24" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="30" y="12" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="30" y="18" width="6" height="6" rx="3" fill="white" />
        <rect x="30" y="24" width="6" height="6" rx="3" fill="white" />
        <rect x="36" y="12" width="6" height="6" rx="3" fill="white" />
        <rect x="36" y="18" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="36" y="24" width="6" height="6" rx="3" fill="white" />
        <rect x="42" y="12" width="6" height="6" rx="3" fill="white" />
        <rect x="42" y="18" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="42" y="24" width="6" height="6" rx="3" fill="white" />
        <rect x="30" y="30" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="30" y="36" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="30" y="42" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="36" y="30" width="6" height="6" rx="3" fill="white" />
        <rect x="36" y="36" width="6" height="6" rx="3" fill="white" />
        <rect x="36" y="42" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="42" y="30" width="6" height="6" rx="3" fill="white" />
        <rect x="42" y="36" width="6" height="6" rx="3" fill="white" />
        <rect x="42" y="42" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="12" y="30" width="6" height="6" rx="3" fill="white" />
        <rect x="12" y="36" width="6" height="6" rx="3" fill="white" />
        <rect x="12" y="42" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="18" y="30" width="6" height="6" rx="3" fill="white" />
        <rect x="18" y="36" width="6" height="6" rx="3" fill="white" />
        <rect x="18" y="42" width="6" height="6" rx="3" fill="white" />
        <rect x="24" y="30" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="24" y="36" width="6" height="6" rx="3" fill="white" />
        <rect opacity="0.3" x="24" y="42" width="6" height="6" rx="3" fill="white" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
