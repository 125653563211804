import { createApi } from '@reduxjs/toolkit/query/react';

import protectedQuery from '@store/utils/protected-query.graphql';
import { ASSIGN_BRIDGE_TO_USER_MUTATION, BRIDGE_QUERY, USER_BRIDGES_QUERY } from '@store/queries/bridge';

export const bridgeApiGraphql = createApi({
  reducerPath: 'bridgeApiGraphql',
  baseQuery: protectedQuery,

  endpoints: builder => ({
    getBridgeById: builder.query({
      query: id => ({
        document: BRIDGE_QUERY,
        variables: { id },
      }),
    }),
    getUserBridges: builder.query({
      query: () => ({
        document: USER_BRIDGES_QUERY,
      }),
    }),
    assignBridgeToUser: builder.mutation({
      query: ({ userId, bridgeId, bridgeMac }) => ({
        document: ASSIGN_BRIDGE_TO_USER_MUTATION,
        variables: { userId, bridgeId, bridgeMac },
      }),
    }),
  }),
});

export const { useGetBridgeByIdQuery, useAssignBridgeToUserMutation, useGetUserBridgesQuery } = bridgeApiGraphql;
