import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

const Icon: IconType = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.353.416c-.225.066-.46.177-.626.297a410.74 410.74 0 0 0-2.939 2.481L6.95 5.6l-2.399.001c-2.662 0-2.685.002-3.108.225a2.127 2.127 0 0 0-.985 1.099l-.09.233-.01 2.987c-.011 3.3-.017 3.187.211 3.644.155.31.528.681.857.852.444.232.451.233 3.124.233l2.398.001 2.882 2.484c1.585 1.366 2.947 2.522 3.027 2.568.424.248 1.067.307 1.553.144a2.092 2.092 0 0 0 1.323-1.406c.044-.17.052-.669.052-3.314 0-3.031-.002-3.118-.069-3.257-.105-.216-.241-.281-.585-.281-.31 0-.401.04-.551.24-.072.096-.073.134-.09 3.201-.016 2.806-.023 3.114-.076 3.209-.123.223-.399.403-.614.401a.672.672 0 0 1-.227-.061c-.071-.033-1.453-1.202-3.072-2.598-1.619-1.395-2.986-2.56-3.038-2.59-.082-.045-.466-.055-2.72-.07l-2.625-.017-.134-.083a.895.895 0 0 1-.227-.228l-.094-.144v-5.67l.1-.149a.948.948 0 0 1 .228-.228l.127-.078 2.625-.018c2.38-.016 2.634-.023 2.73-.077.057-.032 1.396-1.156 2.975-2.498a386.599 386.599 0 0 1 3.006-2.54c.377-.277.793-.16 1.021.289l.085.169.001 2.95c0 1.644.015 3.01.033 3.087.07.302.28.454.629.454.285 0 .493-.103.587-.291.059-.118.063-.321.063-3.275v-3.15l-.093-.253c-.197-.539-.613-1.028-1.06-1.247-.408-.199-.955-.252-1.367-.132m.553 9.242c-.201.091-.291.271-.291.58 0 .308.09.488.291.579.14.064.271.066 3 .067l2.854.001.154-.079c.205-.104.29-.27.29-.568 0-.299-.085-.465-.29-.569l-.154-.079-2.854.001c-2.729.001-2.86.003-3 .067" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
