import React from 'react';
import { Grid, Typography } from '@mui/material';
import SimpleDialog from '@components/Dialog/simple-dialog';
import MotorAlignmentToggle from './motor-alignment-toggle';
import MotorPlayButton from './motor-play-button';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import { useSharedMotorsConfig } from './motor-alignment-helper';

import { CodeMotorAlignment, CodeMotorId } from '@webapp/store/types';
import { Motor } from '@lib/robo/modules/motor';

interface MotorSettingsModalProps {
  open: boolean;
  onClose: () => void;
  probeMotor: (motorId: CodeMotorId) => void;
  connectedMotors: Record<CodeMotorId, Motor>;
}

const MotorSettingsModal: React.FunctionComponent<MotorSettingsModalProps> = ({
  open,
  onClose,
  probeMotor,
  connectedMotors,
}) => {
  const { updateMotorsConfig } = useCodeEditor();
  const motorsConfig = useSharedMotorsConfig();

  const handleAlignmentChange = (motorId: CodeMotorId, alignment: CodeMotorAlignment) => {
    updateMotorsConfig({
      ...motorsConfig,
      [motorId]: {
        ...motorsConfig[motorId],
        alignment,
      },
    });
  };

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      showCloseButton={false}
      showCancelButton={false}
      showSubmitButton={false}
      dense={true}
    >
      <Grid container spacing={2} sx={{ width: '300px' }}>
        {Object.entries(motorsConfig).map(([motorId, motorConfig], index) => (
          <React.Fragment key={motorId}>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                opacity: connectedMotors[motorId as CodeMotorId] ? 1 : 0.3,
                color: '#5A418B',
              }}
            >
              <Typography variant="x-body-semibold">Motor {index + 1}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MotorAlignmentToggle
                motorId={motorId as CodeMotorId}
                currentAlignment={motorConfig.alignment}
                setMotorAlignment={handleAlignmentChange}
              />
            </Grid>

            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <MotorPlayButton
                motorId={motorId as CodeMotorId}
                probeMotor={probeMotor}
                disabled={!connectedMotors[motorId as CodeMotorId] || motorConfig.alignment === CodeMotorAlignment.None}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </SimpleDialog>
  );
};

export default MotorSettingsModal;
