import { gql } from '@lib/store/strapi-graphql-query';

export const WITH_TOC_ENTRY = gql`
  fragment TOC_ENTRY on ComponentItemsToCEntry {
    entry {
      title
      link
      hours
      minutes
    }

    items {
      title
      link
      hours
      minutes
    }
  }
`;
