import { SvgIcon } from '@mui/material';

const Icon = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.781.027c-.054.014-1.742.847-3.75 1.851C.615 3.586.374 3.711.271 3.828a1.535 1.535 0 0 0-.184.275c-.064.133-.074.187-.082.457l-.009.307.086.128c.047.071.147.175.222.231.075.056.487.277.915.491l.779.389.008 2.367c.009 2.355.009 2.368.068 2.559.158.513.421.904.858 1.272.829.697 2.073 1.155 3.721 1.367.481.062 2.091.069 2.581.012.173-.02.32-.032.325-.026.006.006-.204.238-.467.517-.262.278-.503.558-.535.622-.047.094-.055.145-.043.277.016.171.062.257.19.352.072.054.104.055 1.718.063 1.843.008 1.786.013 1.962-.181.116-.129.158-.272.121-.408-.032-.116-.097-.192-.836-.976-.311-.331-.546-.602-.533-.615a.802.802 0 0 1 .171-.068c1.306-.418 2.266-1.184 2.561-2.046.042-.122.089-.299.104-.394.017-.11.028-.995.028-2.433v-2.26l.793-.397c.882-.441.957-.496 1.109-.803.076-.155.086-.2.094-.456.007-.243.001-.299-.045-.388-.135-.266-.058-.223-4.009-2.197L8.253.023 8.067.012a1.253 1.253 0 0 0-.286.015m2.596 3.268c1.307.654 2.366 1.198 2.353 1.21a.741.741 0 0 1-.16.066 1.138 1.138 0 0 0-.27.143c-.074.055-1.072.567-2.217 1.139L8 6.893l-2.083-1.04C4.772 5.281 3.774 4.769 3.7 4.714a1.138 1.138 0 0 0-.27-.143.741.741 0 0 1-.16-.066c-.013-.012 1.043-.556 2.346-1.208a1784.82 1784.82 0 0 1 2.377-1.189c.004-.001 1.077.533 2.384 1.187M5.873 8.042c1.395.695 1.881.926 1.987.942.267.04.366.006 1.28-.449l.86-.427v3.433l-.127.029c-.514.117-1.114.173-1.873.173-.896 0-1.51-.072-2.254-.263-.738-.189-1.369-.499-1.638-.805L4 10.552V8.836c0-.944.006-1.716.013-1.716.007 0 .844.415 1.86.922M12 8.836v1.716l-.108.123a1.748 1.748 0 0 1-.286.241c-.186.123-.544.311-.592.311-.019 0-.026-.579-.021-1.807L11 7.613l.48-.245c.264-.135.489-.246.5-.247.011 0 .02.771.02 1.715m-1.322 5.474c.087.093.151.176.143.184-.007.008-.16.011-.338.007l-.324-.008.163-.18a1.15 1.15 0 0 1 .18-.177c.01.002.089.08.176.174" />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
