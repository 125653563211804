import MQTTClientCommands from './types/commands';

type CommandsCollection = {
  [key in keyof typeof MQTTClientCommands]: {
    code: (typeof MQTTClientCommands)[key];
    send?: boolean;
    receive?: boolean;
    deprecated?: boolean;
    description?: string;
  };
};

// todo: Add missing commands. Revise deprecated commands.
export const Commands: CommandsCollection = {
  CMD_ROBOID: {
    // out
    code: MQTTClientCommands.CMD_ROBOID, // 0x00
    send: true,
    description: '',
  },
  CMD_CONFIGURATION: {
    // out, in
    code: MQTTClientCommands.CMD_CONFIGURATION, // 0x01
    send: true,
    receive: true,
    description: 'Retrieve configuration',
  },
  CMD_FWUPDATE: {
    // out
    code: MQTTClientCommands.CMD_FWUPDATE, // 0x02
    send: true,
    description: '',
  },
  CMD_FWUPDATE_PROCESS: {
    // out, in
    code: MQTTClientCommands.CMD_FWUPDATE_PROCESS, // 0x03
    send: true,
    receive: true,
    description: '',
  },
  CMD_START_OTA_SERVER: {
    // out
    code: MQTTClientCommands.CMD_START_OTA_SERVER, // 0x04
    send: true,
    description: '',
  },
  CMD_CURRENT_TIME: {
    // out
    code: MQTTClientCommands.CMD_CURRENT_TIME, // 0x05
    send: true,
    description: '',
  },
  CMD_BT_NAME: {
    // out
    code: MQTTClientCommands.CMD_BT_NAME, // 0x06
    send: true,
    description: '',
  },
  CMD_FWVERSION: {
    // out, in
    code: MQTTClientCommands.CMD_FWVERSION, // 0x07
    send: true,
    receive: true,
    description: '',
  },
  CMD_WIFI_SCAN: {
    // out, in
    code: MQTTClientCommands.CMD_WIFI_SCAN, // 0x0a
    send: true,
    receive: true,
    description: '',
  },
  CMD_BAT_STATE: {
    // out, in
    code: MQTTClientCommands.CMD_BAT_STATE, // 0x10
    send: true,
    receive: true,
    description: '',
  },
  CMD_LOW_BAT_ALLERT: {
    // in
    code: MQTTClientCommands.CMD_LOW_BAT_ALLERT, // 0x11
    receive: true,
    description: '',
  },
  CMD_SOUND_LOUDNESS: {
    // out
    code: MQTTClientCommands.CMD_SOUND_LOUDNESS, // 0x12
    send: true,
    description: '',
  },
  CMD_APP_TYPE: {
    // ??
    code: MQTTClientCommands.CMD_APP_TYPE, // 0x20
    deprecated: true,
    description: '??',
  },
  CMD_RUN_STOP: {
    // out, in
    code: MQTTClientCommands.CMD_RUN_STOP, // 0x30
    send: true,
    receive: true,
    description: '',
  },
  CMD_SET_WIFI_SSID: {
    // out
    code: MQTTClientCommands.CMD_SET_WIFI_SSID, // 0x45
    send: true,
    description: '',
  },
  CMD_SET_WIFI_PSK: {
    // out
    code: MQTTClientCommands.CMD_SET_WIFI_PSK, // 0x46
    send: true,
    description: '',
  },
  CMD_MODULES_FWUPDATE: {
    // out, in
    code: MQTTClientCommands.CMD_MODULES_FWUPDATE, // 0x49
    send: true,
    receive: true,
    description: '',
  },
  CMD_MOTOR_SPEED: {
    // out, in
    code: MQTTClientCommands.CMD_MOTOR_SPEED, // 0x50
    send: true,
    receive: true,
    description: '',
  },
  CMD_SERVO_POSITION: {
    // out, in
    code: MQTTClientCommands.CMD_SERVO_POSITION, // 0x51
    send: true,
    receive: true,
    description: '',
  },
  CMD_MATRIX: {
    // out, in
    code: MQTTClientCommands.CMD_MATRIX, // 0x52
    send: true,
    receive: true,
    description: '',
  },
  CMD_RGB: {
    // out, in
    code: MQTTClientCommands.CMD_RGB, // 0x53
    send: true,
    receive: true,
    description: '',
  },
  CMD_DISPLAY_ANIMATION: {
    // out, in
    code: MQTTClientCommands.CMD_DISPLAY_ANIMATION, // 0x54
    send: true,
    receive: true,
    description: '',
  },
  CMD_DISPLAY_LOAD_ANIMATION: {
    // out
    code: MQTTClientCommands.CMD_DISPLAY_LOAD_ANIMATION, // 0x55
    send: true,
    description: '',
  },
  CMD_DISPLAY_LOAD_TEXT: {
    // out
    code: MQTTClientCommands.CMD_DISPLAY_LOAD_TEXT, // 0x56
    send: true,
    description: '',
  },
  CMD_DISPLAY_CUSTOM_IMAGE: {
    // out
    code: MQTTClientCommands.CMD_DISPLAY_CUSTOM_IMAGE, // 0x57
    send: true,
    description: '',
  },
  CMD_DISPLAY_IMAGE: {
    // out
    code: MQTTClientCommands.CMD_DISPLAY_IMAGE, // 0x58
    send: true,
    description: '',
  },
  CMD_DISPLAY_SET_TEXT: {
    // out, in
    code: MQTTClientCommands.CMD_DISPLAY_SET_TEXT, // 0x59
    send: true,
    receive: true,
    description: '',
  },
  CMD_DISPLAY_STOP: {
    // out, in
    code: MQTTClientCommands.CMD_DISPLAY_STOP, // 0x5a
    send: true,
    receive: true,
    description: '',
  },
  CMD_MOTOR_ANGLE: {
    // out, in
    code: MQTTClientCommands.CMD_MOTOR_ANGLE, // 0x5b
    send: true,
    receive: true,
    description: '',
  },
  CMD_AVAILABLE_SOUNDS: {
    // out, in
    code: MQTTClientCommands.CMD_AVAILABLE_SOUNDS, // 0x60
    send: true,
    receive: true,
    description: '',
  },
  CMD_PLAY_SOUND: {
    // out
    code: MQTTClientCommands.CMD_PLAY_SOUND, // 0x61
    send: true,
    description: '',
  },
  CMD_ZERO_SERVO: {
    // out
    code: MQTTClientCommands.CMD_ZERO_SERVO, // 0x66
    send: true,
    description: '',
  },
  CMD_LIGHT_LEVEL: {
    // out, in
    code: MQTTClientCommands.CMD_LIGHT_LEVEL, // 0x80
    send: true,
    receive: true,
    description: '',
  },
  CMD_SOUND_LEVEL: {
    // out, in
    code: MQTTClientCommands.CMD_SOUND_LEVEL, // 0x81
    send: true,
    receive: true,
    description: '',
  },
  CMD_MOTION_DET: {
    // out, in
    code: MQTTClientCommands.CMD_MOTION_DET, // 0x83
    send: true,
    receive: true,
    description: '',
  },
  CMD_GET_DISTANCE: {
    // out, in
    code: MQTTClientCommands.CMD_GET_DISTANCE, // 0x84
    send: true,
    receive: true,
    description: '',
  },
  CMD_GET_BUTTON: {
    // out, in
    code: MQTTClientCommands.CMD_GET_BUTTON, // 0x85
    send: true,
    receive: true,
    description: '',
  },
  CMD_GET_LINETRACKER: {
    // out, in
    code: MQTTClientCommands.CMD_GET_LINETRACKER, // 0x86
    send: true,
    receive: true,
    description: '',
  },
  CMD_ACCELEROMETER_STATE: {
    // out
    code: MQTTClientCommands.CMD_ACCELEROMETER_STATE, // 0x87
    send: true,
    description: '',
  },
  CMD_GET_ACC: {
    // out, in
    code: MQTTClientCommands.CMD_GET_ACC, // 0x89
    send: true,
    receive: true,
    description: '',
  },
  CMD_GET_GYR: {
    // out, in
    code: MQTTClientCommands.CMD_GET_GYR, // 0x8a
    send: true,
    receive: true,
    description: '',
  },
  CMD_BATCH_SENSORS: {
    // out, in
    code: MQTTClientCommands.CMD_BATCH_SENSORS, // 0x90
    send: true,
    receive: true,
    description: '',
  },
  CMD_SET_TUNE: {
    // out
    code: MQTTClientCommands.CMD_SET_TUNE, // 0x92
    send: true,
    description: '',
  },
  CMD_UPLOAD_CUSTOM_TUNE: {
    // out
    code: MQTTClientCommands.CMD_UPLOAD_CUSTOM_TUNE, // 0x93
    send: true,
    description: '',
  },
  CMD_PLAY_NOTE: {
    // out
    code: MQTTClientCommands.CMD_PLAY_NOTE, // 0x94
    send: true,
    description: '',
  },
  CMD_SET_MOTOR_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_MOTOR_ACTION, // 0xa0
    send: true,
    description: '',
  },
  CMD_SET_SERVO_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_SERVO_ACTION, // 0xa1
    send: true,
    description: '',
  },
  CMD_SET_RGB_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_RGB_ACTION, // 0xa2
    send: true,
    description: '',
  },
  CMD_SET_MATRIX_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_MATRIX_ACTION, // 0xa3
    send: true,
    description: '',
  },
  CMD_SET_PLAY_SOUND_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_PLAY_SOUND_ACTION, // 0xa4
    send: true,
    description: '',
  },
  CMD_SET_DRIVE_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_DRIVE_ACTION, // 0xa6
    send: true,
    description: '',
  },
  CMD_SET_LINETRACKER_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_LINETRACKER_ACTION, // 0xa9
    send: true,
    description: '',
  },
  CMD_SET_GYRO_TURN_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_GYRO_TURN_ACTION, // 0xaa
    send: true,
    description: '',
  },
  CMD_SET_STEER_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_STEER_ACTION, // 0xab
    send: true,
    description: '',
  },
  CMD_SET_TURN_ACTION: {
    // out
    code: MQTTClientCommands.CMD_SET_TURN_ACTION, // 0xac
    send: true,
    description: '',
  },
  CMD_SET_DISTANCE_TRIGGER: {
    // out
    code: MQTTClientCommands.CMD_SET_DISTANCE_TRIGGER, // 0xb0
    send: true,
    description: '',
  },
  CMD_SET_BUTTON_TRIGGER: {
    // out
    code: MQTTClientCommands.CMD_SET_BUTTON_TRIGGER, // 0xb1
    send: true,
    description: '',
  },
  CMD_SET_LIGHT_TRIGGER: {
    // out
    code: MQTTClientCommands.CMD_SET_LIGHT_TRIGGER, // 0xb2
    send: true,
    description: '',
  },
  CMD_SET_MOTION_TRIGGER: {
    // out
    code: MQTTClientCommands.CMD_SET_MOTION_TRIGGER, // 0xb3
    send: true,
    description: '',
  },
  CMD_SET_LINETRACKER_TRIGGER: {
    // out
    code: MQTTClientCommands.CMD_SET_LINETRACKER_TRIGGER, // 0xb5
    send: true,
    description: '',
  },
  CMD_SET_ACCELEROMETER_TRIGGER: {
    // out
    code: MQTTClientCommands.CMD_SET_ACCELEROMETER_TRIGGER, // 0xb6
    send: true,
    description: '',
  },
  CMD_ACTION_OR_TRIGGER_RESPONSE: {
    // in
    code: MQTTClientCommands.CMD_ACTION_OR_TRIGGER_RESPONSE, // 0xc0
    receive: true,
    description: '',
  },
  MAX_CMDS: {
    // ??
    code: MQTTClientCommands.MAX_CMDS, // 0xc1
    send: false,
    receive: false,
    deprecated: false,
    description: '??',
  },
};
