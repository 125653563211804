import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

import { AngleWidgetData } from '@webapp/components/blocks/widgets/code/angle-action/code-angle-widget';

type IconProps = {
  value: AngleWidgetData;
};

const Icon: IconType<IconProps> = ({ value, ...rest }) => {
  const { angleValue } = value;

  let minAngle, maxAngle;

  if (Array.isArray(angleValue)) {
    [minAngle, maxAngle] = [...angleValue].sort((a, b) => a - b);
  } else {
    minAngle = angleValue;
    maxAngle = undefined;
  }

  return (
    <SvgIcon {...rest}>
      <svg
        width="45"
        height="45"
        viewBox="0 0 40 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fontSize: '42px' }}
      >
        {maxAngle === undefined ? (
          <text x="21" y="26" fill="white" fontSize="10" textAnchor="middle">
            {minAngle}
          </text>
        ) : (
          <text x="21" y="22" fill="white" fontSize="10" textAnchor="middle">
            {minAngle} -{' '}
            <tspan x={21} dy={10}>
              {maxAngle}
            </tspan>
          </text>
        )}

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5036 2.85211C19.4313 2.32386 20.5678 2.32336 21.4955 2.85161C22.6566 3.51277 24.5477 4.58968 27.3538 6.18759C30.9341 8.22636 34.5144 10.2651 36.475 11.3816C37.4194 11.9194 38 12.9211 38 14.0079V30.9921C38 32.0789 37.4168 33.0821 36.4724 33.6199L21.4964 42.1479C20.5687 42.6761 19.4313 42.6761 18.5036 42.1479L3.52761 33.6199C2.5832 33.0821 2 32.0789 2 30.9921L2 14.0079C2 12.9211 2.58319 11.9179 3.52761 11.3801L18.5036 2.85211Z"
          stroke="white"
          strokeWidth="2.016"
        />
      </svg>
    </SvgIcon>
  );
};

export default Icon;
